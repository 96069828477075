import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'

type GetOrderSumResponse = {
  provider: string;
  store: string;
  sum_week: number;
  sum_month: number;
}

export async function getOrderSum(
  provider: string,
  store: string,
): Promise<GetOrderSumResponse> {
  return apiCreator
    .get<GetOrderSumResponse>(
      `${API_URL.TWIN}/procurement-order-sum`,
      {
        params: {
          provider,
          store,
        },
      },
    )
    .then(({ data }) => data)
}
