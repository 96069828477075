import { getApi } from '..'

export type OrderFile = {
  filename: string;
  s3Url: string | null;
  data: File | null;
}

export async function uploadOrderFilesToS3(
  files: Array<File>,
  abortSignal: AbortSignal,
): Promise<Array<OrderFile>> {
  const { orderFiles: orderFilesApi, awsS3 } = getApi()

  return Promise.all(
    files
      .map(async (file): Promise<OrderFile> => {
        const fileName = file.name
        const { url: presignedS3UploadLink } = await orderFilesApi.getPresignedS3UploadLink(fileName, abortSignal)
        await awsS3.uploadFileToS3(file, presignedS3UploadLink, abortSignal)

        return Promise.resolve({
          filename: fileName,
          s3Url: presignedS3UploadLink,
          data: file,
        })
      }),
  )
}
