import { defaultError } from '@app/utils/errors'
import { successMessage } from '@app/utils/feedback'
import { Select, SelectItem } from '@mantine/core'
import React, { LegacyRef, forwardRef, useMemo, useState } from 'react'
import { getApi } from '../../../../../api'
import { LoaderContainer } from '../../../../../components/loader-container'
import { DefaultModal } from '../../../../../components/popups/default-modal'
import { DEFAULT_SELECT_LIST_LIMIT } from '../../../../../config/constants'
import { i18n_get } from '../../../../../lng/i18n'
import { getPopupDefaultButtons } from '@appscience/popups'
import { verify } from '@appscience/utils'
import { SimpleTableLabel } from '@appscience/simple-table'
import { AddItemsToPackagePopupSumLabel } from './content/result-sum-label'
import { AddItemsToPackagePopupTable } from './content/table'
import {
  AddItemsToPackagePopupProps,
  AddItemsToPackagePopupStaticData,
} from './model'
import { useAddItemsToPackagePopupLoadedState } from './use-loaded-state'

export function AddItemsToPackagePopup(props: AddItemsToPackagePopupProps) {
  const { onSuccess, closeFn, title } = props
  const { staticData, loadingState, setLoadingState } =
    useAddItemsToPackagePopupLoadedState(props)

  return (
    <DefaultModal
      title={title}
      createContent={() =>
        loadingState === 'loading' ? (
          <LoaderContainer />
        ) : (
          <AddItemsToOrderPopupContent
            staticData={verify(staticData)}
            closeFn={closeFn}
            onSuccess={onSuccess}
            loadingState={loadingState}
            setLoadingState={setLoadingState}
          />
        )
      }
      closeFn={closeFn}
      className='w-[1056px] min-h-[400px] py-8 px-9'
    />
  )
}

interface ContentProps {
  staticData: AddItemsToPackagePopupStaticData
  onSuccess: () => Promise<void>
  closeFn: () => void
  setLoadingState: (v: DefaultLoadingState) => void
  loadingState: DefaultLoadingState
}

interface ItemComponentProps extends SelectItem {
  value: string
  date: string
  tagToHub: string
  tagToRu: string
  tagToTransit: string
}

export const ItemComponent = forwardRef(
  (
    {
      value,
      packageData,
      packageInvoice,
      shipmentTag,
      className,
      ...others
    }: ItemComponentProps,
    ref: LegacyRef<HTMLDivElement>,
  ) => (
    <div
      {...others}
      ref={ref}
      className={`grid gap-[10 grid-cols-[150px_150px_150px] ${className}`}
    >
      <div>{packageData}</div>
      <div>{packageInvoice}</div>
      <div>{shipmentTag}</div>
    </div>
  ),
)

function AddItemsToOrderPopupContent({
  staticData,
  onSuccess,
  closeFn,
  setLoadingState,
  loadingState,
}: ContentProps) {
  const { packages, items } = staticData
  const [selectedPackageId, setSelectedPackageId] = useState<string | null>(
    null,
  )
  const selectedPackage = selectedPackageId
    ? packages.find(x => x.id === selectedPackageId)
    : undefined
  const data = useMemo(
    () =>
      packages.map(packageData => ({
        value: packageData.id,
        label: `${packageData.id} ${packageData.packageDate} ${packageData.packageInvoice} ${packageData.shipmentTag}`,
        packageData: packageData.packageDate,
        packageInvoice: packageData.packageInvoice,
        shipmentTag: packageData.shipmentTag,
      })),
    [packages],
  )

  const onSave = () => {
    if (selectedPackageId === null) {
      return
    }
    setLoadingState('saving')
    getApi()
      .provider.addItemsToPackage({
        packageId: selectedPackageId,
        itemIds: items.map(x => x.id),
      })
      .then(() => {
        successMessage(i18n_get.t('AddItemsToPackagePopup.SuccessMessage'))
        return onSuccess()
      })
      .then(closeFn)
      .catch(defaultError)
      .finally(() => setLoadingState('normal'))
  }

  return (
    <div className='flex flex-col grow mt-2.5 bg-inherit'>
      <AddItemsToPackagePopupTable items={items} />
      <Select
        label={
          <SimpleTableLabel
            label={i18n_get.t('Provider.InWarehouse.SelectAPackage')}
          />
        }
        placeholder={i18n_get.t('Provider.InWarehouse.SelectAPackage')}
        value={selectedPackageId}
        onChange={setSelectedPackageId}
        data={data}
        required
        itemComponent={ItemComponent}
        limit={DEFAULT_SELECT_LIST_LIMIT}
        searchable
        className='w-full pt-3 mb-auto'
      />
      {selectedPackage && (
        <AddItemsToPackagePopupSumLabel
          selectedPackage={selectedPackage}
          itemsSum={items.reduce((acc, x) => acc + x.invoicePrice, 0)}
        />
      )}
      <div className='flex justify-end mt-4 space-x-2'>
        {getPopupDefaultButtons({
          success: {
            onClick: onSave,
            disabled: selectedPackageId === null,
            loading: loadingState === 'saving',
          },
          cancel: { onClick: closeFn },
        })}
      </div>
    </div>
  )
}
