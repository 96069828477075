import {createAtom} from '@reatom/core'
import {createPrimitiveAtom} from '@reatom/core/primitives'
import produce from 'immer'
import {PropType, updateObject, verify} from '@appscience/utils'
import {createLSDecorator} from '@app/utils/reatom'
import {registerPageItemErrorsAtom} from './errors/item-errors-atom'
import {RowSelectionState} from '@tanstack/react-table'
import {createSettingsAtom} from 'src/components/table/utils/settings-atom'
import { TABLE_ID } from 'src/components/table/utils/table-id'

export interface ToWarehouseRegisterItem {
  id: string
  amoId: string
  orderNumbering: string
  invoiceName: string
  originalName: string
  brand: string
  catalogueId: string
  quantity: number
  sumCost: number
  itemCost: number
  orderInvoice: string
  exportComment: string
  actuallyDelivered: number
  temperatureRegime: string | null
  providerHazmatType: string
  hazmatComment: string | null
  storeOrderNumber: string
  complianceComments: string
  sdComments: string
  procurementComments: string
  locations: Array<string>
  countryOfOrigin: string
  lot: string | null
  expirationDate: Date | null
  currencyId: string
  provider: string
  warehouseId: string
  hsnCodes: string
  procurement: string
  partnerInvoice: string
  track: string
  casId: string
  poNumber: string
}

const selectedRowIdsAtom = createPrimitiveAtom<RowSelectionState>({}, null, {
  decorators: [createLSDecorator('RegistrationPage.SelectedRowIdsAtom')],
})

//-----------------------------------------------------------------------------------------------

type CommonModeData = {
  warehouseId: string
}
export type RegisterHubPackageModeData = {
  type: 'hub-package'
  packageId: string
}
export type RegisterProviderOrderModeData = {
  type: 'provider-order'
  orderId: string
} & CommonModeData
export type RegisterProviderItemsModeData = {
  type: 'provider-items'
  itemIds: Array<string>
}
export type RegisterPageModeData =
  | RegisterHubPackageModeData
  | RegisterProviderItemsModeData
  | RegisterProviderOrderModeData
export type RegisterPageModeType = PropType<RegisterPageModeData, 'type'>

export const registerPageModeDataAtom =
  createPrimitiveAtom<RegisterPageModeData>({} as RegisterPageModeData)

export const registerPageSettingsAtom = createSettingsAtom({
  tableId: TABLE_ID.TO_WAREHOUSE_REGISTER_ITEMS,
})

const itemsAtom = createAtom(
  {
    init: (items: Array<ToWarehouseRegisterItem>) => items,
    updateField: <K extends keyof ToWarehouseRegisterItem>(
      id: string,
      key: K,
      value: ToWarehouseRegisterItem[K],
    ) => ({id, key, value}),
  },
  ({onAction, schedule}, state: Array<ToWarehouseRegisterItem> = []) => {
    onAction('init', value => {
      state = value
      schedule(dispatch => {
        dispatch(registerPageItemErrorsAtom.reset())
        dispatch(
          selectedRowIdsAtom.set(
            state.reduce((acc, item) => {
              acc[item.id] = true
              return acc
            }, {} as RowSelectionState),
          ),
        )
      })
    })
    onAction('updateField', ({id, key, value}) => {
      state = produce(state, draft => {
        const isHub = registerPageModeDataAtom.getState().type === 'hub-package'
        if (isHub && key === 'locations') {
          draft.forEach(item => {
            if (item.id === id || item.locations.length === 0) {
              item.locations = value as Array<string>
            }
          })
          return
        }
        const item = verify(draft.find(x => x.id === id))
        updateObject(item, key, value)
      })
      schedule(dispatch => {
        dispatch(registerPageItemErrorsAtom.checkField(id, key, value))
      })
    })
    return state
  },
  {
    decorators: [createLSDecorator('processedPageAtoms.itemsAtom')],
  },
)

export const registerPageAtoms = {
  selectedRowIds: selectedRowIdsAtom,
  items: itemsAtom,
}
