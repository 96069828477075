import {primitiveAtom} from '@app/utils/reatom'
import {createPrimitiveAtom} from '@reatom/core/primitives'
import {RowSelectionState} from '@tanstack/react-table'
import {PaymentMethodId} from '../../../../atoms/static-data/payments-method-atom'
import {toWarehouseCurrentTab} from '../common/model'

//-----------------------ITEMS TABLE--------------------------
export type ToProviderViewItem = Pick<
  ToProviderItem,
  | 'id'
  | 'orderId'
  | 'amoId'
  | 'invoiceName'
  | 'originalName'
  | 'brand'
  | 'catalogueId'
  | 'sumCost'
  | 'currencyId'
  | 'quantity'
  | 'invoiceNumber'
  | 'esdDate'
  | 'sdComment'
  | 'express'
  | 'orderNumber'
  | 'airtableLink'
>

export interface ToProviderItem {
  id: string
  airtableLink: string
  orderId: string
  amoId: string
  poNumber: string
  invoiceName: string
  originalName: string
  brand: string
  catalogueId: string
  sumCost: number
  cost: number
  tax: number
  otherCost: number
  currencyId: string
  quantity: number
  invoiceNumber: string
  orderNumber: string
  esdDate: Date | null
  dealDate: Date | null
  deadlineDate: Date | null
  client: string
  salesId: string
  procurement: string
  scd: string
  isKit: boolean
  countryId: string
  storeId: string
  hazmatComment: string
  temperature: string
  sdComment: string
  express: boolean
  store: string
  partnerInvoice: string
  track: string
  casId: string
}

//-----------------------ORDERS TABLE--------------------------
export type ToProviderViewOrder = ToProviderOrder

export interface ToProviderOrder {
  id: string
  store: string
  orderDate: Date | null
  latestEsdDate: Date | null
  invoiceNumber: string
  orderNumber: string
  paymentMethodId: PaymentMethodId
  sumCost: number
  currencyId: string
  fullItemsQuantity: number
  sentToProviderItemsQuantity: number
  scd: string
  provider: string
  procurement: string
  paidDate: Date | null
  trackNumber: string
}

export const toProviderAtoms = {
  itemsTableAtoms: {
    selectedOrderId: primitiveAtom<string | null>(null, dispatch =>
      dispatch(toWarehouseCurrentTab.set('byItems')),
    ),
    selectedRowIds: createPrimitiveAtom<RowSelectionState>({}),
  },
  ordersTableAtoms: {
    selectedRowId: createPrimitiveAtom<RowSelectionState>({}),
  },
  items: createPrimitiveAtom<Record<string, ToProviderItem>>({}),
  orders: createPrimitiveAtom<Record<string, ToProviderOrder>>({}),
}
