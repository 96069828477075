import { InterfaceIdsEnum, InterfaceSectionIdsEnum, UserPermissions } from 'src/api/iam/groups/model'
import { UserWithId } from 'src/api/iam/users/model'
import { AppRoute } from 'src/routes/types'
import { UserType } from 'src/views/iam/common/types'
import { BASE_ROUTES, COMPLIANCE_ROUTES, IAM_ROUTES, PROCUREMENT_BASE_ROUTE, PROCUREMENT_NEW_ORDER_ROUTES, PROCUREMENT_ORDERS_ROUTES, PROVIDER_BASE_ROUTE, PROVIDER_FROM_WAREHOUSE_ROUTES, PROVIDER_IN_WAREHOUSE_ROUTES, PROVIDER_TO_WAREHOUSE_ROUTES } from './routes'
import { UserRole } from 'src/atoms/user-atom'
import { isFC } from 'src/views/iam/common/utils'

export function getAllowedRoutesSet(
  userPermissions: UserPermissions | null,
  userWithId: UserWithId | null,
  userRole: UserRole,
): Set<AppRoute> {
  if (!userPermissions || !userWithId) {
    return new Set([])
  }

  const procurementInterface = userPermissions?.interfaces
    ?.find(groupInterface => groupInterface.id === InterfaceIdsEnum.Procurement)
  const hasProcurementOrdersPage = procurementInterface?.sections
    ?.find(section => section.id === InterfaceSectionIdsEnum.Orders)
  const hasProcurementNewOrderPage =
    !isFC(userRole)
    && procurementInterface?.sections
      ?.find(section => section.id === InterfaceSectionIdsEnum.NewOrder)
  const hasProcurementPage =
    hasProcurementOrdersPage
    || hasProcurementNewOrderPage

  const providerInterface = userPermissions?.interfaces
    ?.find(groupInterface => groupInterface.id === InterfaceIdsEnum.Provider)
  const hasProviderToWarehousePage = providerInterface?.sections
    ?.find(section => section.id === InterfaceSectionIdsEnum.ToWarehouse)
  const hasProviderInWarehousePage = providerInterface?.sections
    ?.find(section => section.id === InterfaceSectionIdsEnum.InWarehouse)
  const hasProviderFromWarehousePage = providerInterface?.sections
    ?.find(section => section.id === InterfaceSectionIdsEnum.FromWarehouse)
  const hasProviderPage =
    hasProviderFromWarehousePage
    || hasProviderToWarehousePage
    || hasProviderInWarehousePage

  const hasIAMPage = [
    UserType.Admin,
    UserType.SuperAdmin,
  ].includes(userWithId.role)

  const allowedRoutes = [
    ...BASE_ROUTES,
    ...COMPLIANCE_ROUTES,
    ...hasProcurementPage ? [PROCUREMENT_BASE_ROUTE] : [],
    ...hasProcurementOrdersPage ? PROCUREMENT_ORDERS_ROUTES : [],
    ...hasProcurementNewOrderPage ? PROCUREMENT_NEW_ORDER_ROUTES : [],
    ...hasProviderPage ? [PROVIDER_BASE_ROUTE] : [],
    ...hasProviderFromWarehousePage ? PROVIDER_FROM_WAREHOUSE_ROUTES : [],
    ...hasProviderInWarehousePage ? PROVIDER_IN_WAREHOUSE_ROUTES : [],
    ...hasProviderToWarehousePage ? PROVIDER_TO_WAREHOUSE_ROUTES : [],
    ...hasIAMPage ? IAM_ROUTES : [],
  ].filter(Boolean)

  return new Set(allowedRoutes)
}
