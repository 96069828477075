import { AxiosResponse } from 'axios'
import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'

export type LoginRequest = {
  email: string;
  password: string;
}
export type LoginResponseData = {
  auth_token: string;
}
export type LoginResponse = AxiosResponse<LoginResponseData>

export async function login(userData: LoginRequest): Promise<LoginResponse> {
  return apiCreator
    .post<LoginResponseData, LoginResponse, LoginRequest>(
      `${API_URL.AUTH}/login`,
      userData,
      {
        headers: {
          'public-request': 'true',
        },
      },
    )
    .then(res => res)
}
