import {useAtom} from '@reatom/react'
import React from 'react'
import {Button} from '@mantine/core'
import {pageLoadingAtom} from '../../../../atoms/page-loading-atom'
import {useRedirectFn} from '../../../../hooks/route-hooks'
import {useAtomState} from '../../../../hooks/use-atom-state'
import {i18n_get} from '../../../../lng/i18n'
import {createPackagePageModeAtom} from '../model/atoms'

export function CreatePackagePageLayoutActions() {
  const [state, {set: setLoadingState}] = useAtom(pageLoadingAtom)
  const redirectTo = useRedirectFn('/provider')
  const pageMode = useAtomState(createPackagePageModeAtom)

  if (state === 'loading') {
    return null
  }

  //При state === 'saving' срабатывает useEffect, который сохраняет данные из формы.
  return (
    <div className='flex items-center mr-3 space-x-3'>
      <Button
        onClick={() =>
          redirectTo(
            pageMode.type === 'edit' ? '/from-warehouse' : '/in-warehouse',
          )
        }
        disabled={state === 'saving'}
      >
        {i18n_get.t('Common.Cancel')}
      </Button>
      <Button
        onClick={() => setLoadingState('saving')}
        loading={state === 'saving'}
      >
        {i18n_get.t('Common.Save')}
      </Button>
    </div>
  )
}
