import apiCreator from 'src/api/api-creator'
import { AxiosResponse } from 'axios'

export type UploadRequest = FormData
export type UploadAnaloguesExcelFileResponseData = void
export type UploadAnaloguesExcelFileResponse = AxiosResponse<UploadAnaloguesExcelFileResponseData>

export async function uploadFileToS3(
  file: File,
  presignedS3UploadLink: string,
  abortSignal: AbortSignal,
): Promise<UploadAnaloguesExcelFileResponseData> {
  return apiCreator
    .put<
      UploadAnaloguesExcelFileResponseData,
      UploadAnaloguesExcelFileResponse,
      File
    >(
      presignedS3UploadLink,
      file,
      {
        signal: abortSignal,
      },
    )
    .then(({ data }) => data)
}
