import {defaultError} from '@app/utils/errors'
import {useEffect, useState} from 'react'
import {getApi} from '../../../../../api'
import {warehousesAtom} from '../../../../../atoms/static-data/warehouses-atom'
import {useAtomState} from '../../../../../hooks/use-atom-state'
import {
  getOneAreaWarehouses,
  selectedWarehousesAtom,
} from '../../../common/selected-warehouses-atom'
import {
  AddItemsToPackagePopupItem,
  AddItemsToPackagePopupProps,
  AddItemsToPackagePopupStaticData,
} from './model'

export function useAddItemsToPackagePopupLoadedState({
  getItems,
  closeFn,
}: AddItemsToPackagePopupProps) {
  const warehousesObj = useAtomState(warehousesAtom)
  const selectedWarehouses = useAtomState(selectedWarehousesAtom)
  const [loadingState, setLoadingState] =
    useState<DefaultLoadingState>('loading')
  const [staticData, setStaticData] =
    useState<AddItemsToPackagePopupStaticData | null>(null)

  useEffect(() => {
    if (selectedWarehouses.length === 0) {
      return
    }
    setLoadingState('loading')
    loadData({
      getItems,
      warehousesIds: getOneAreaWarehouses(
        selectedWarehouses,
        warehousesObj,
      ).map(x => x.id),
    })
      .then(data => {
        if (data.items.length === 0) {
          defaultError({
            toastMessage: 'Не выбрано ни одного айтема.',
          })
          closeFn()
        }
        setStaticData(data)
        setLoadingState('normal')
      })
      .catch(defaultError)
  }, [getItems, closeFn, selectedWarehouses, warehousesObj])

  return {
    staticData,
    loadingState,
    setLoadingState,
  }
}

interface LoadDataParams {
  getItems: () => Promise<Array<AddItemsToPackagePopupItem>>
  warehousesIds: Array<string>
}

async function loadData({
  getItems,
  warehousesIds,
}: LoadDataParams): Promise<AddItemsToPackagePopupStaticData> {
  const [items, packagesObj] = await Promise.all([
    getItems(),
    getApi().provider.fromWarehouse.getReadyToShipPackages({
      warehouseIds: warehousesIds,
    }),
  ])
  return {
    items,
    packages: Object.values(packagesObj).map(x => ({
      id: x.id,
      fromWarehouseTitle: x.fromWarehouseId,
      toWarehouseTitle: x.toWarehouseId,
      temperatures: x.temperatures,
      cost: x.invoicePriceSum,
      currencyId: x.currencyId,
      procurementComment: x.procurementComment,
      providerHub: x.providerHub,
      providerTransit: x.providerTransit,
      packageDate: x.packageDate,
      packageInvoice: x.packageInvoice,
      shipmentTag: x.tag,
    })),
  }
}
