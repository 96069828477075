import React from 'react'
import { LoginForm } from './login-form'
import { Route, Switch } from 'react-router-dom'
import { createStyles } from '@mantine/core'
import { TypedRoute } from 'src/routes/components/typed-route'
import { TypedRedirect } from 'src/routes/components/typed-redirect'

const useStyles = createStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    background: 'url(/img/others/img-17.jpg) no-repeat',
    backgroundSize: 'cover',
  },
}))

export const AuthView: React.FC = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.container}>
      <div className='p-16 pt-14 bg-white rounded-xl w-[400px]'>
        <img
          src='/img/logo-white.svg'
          className='mb-6 w-[235px]'
          alt=''
        />
        <Switch>
          <TypedRoute
            path='/auth/login'
            exact
          >
            <LoginForm />
          </TypedRoute>
          <Route
            path='*'
          >
            <TypedRedirect
              to={'/auth/login'}
            />
          </Route>
        </Switch>
      </div>
    </div>
  )
}
