import React from 'react'
import { Text, createStyles } from '@mantine/core'
import { Link } from 'react-router-dom'
import { i18n_get } from 'src/lng/i18n'

const useStyles = createStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}))

export const Page404: React.FC = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.container}>
      <div className='p-10'>
        <Text
          size='lg'
        >
          {i18n_get.t('404.Title')}
        </Text>
        <div className='flex'>
          <Text
            size='lg'
          >
            {i18n_get.t('404.YouMayGoTo')}
            {' '}
            <Link to='/'>
              {i18n_get.t('404.MainPage')}
            </Link>
            {' '}
            {i18n_get.t('404.ForChoosingPage')}
          </Text>
        </div>
      </div>
    </div>
  )
}
