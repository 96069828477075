import { logError } from '@appscience/utils'
import { createAtom } from '@reatom/core'
import { createPrimitiveAtom } from '@reatom/core/primitives'
import { toast } from 'react-hot-toast'
import { getApi } from 'src/api'
import { OrderFile } from 'src/api/order-files/upload-order-files-to-s3'
import { i18n_get } from 'src/lng/i18n'
import { getFilenameFromS3Url } from './order-files.utils'

export const orderFilesLoadingAtom = createPrimitiveAtom<boolean>(false)

type OrderFilesAtomAtomState = {
  abortController: AbortController | null;
  attachedFilesMap: Map<string, OrderFile>;
}

function getInitialState(): OrderFilesAtomAtomState {
  return {
    abortController: null,
    attachedFilesMap: new Map<string, OrderFile>(),
  }
}

export const orderFilesAtom = createAtom(
  {
    init: (urls: Array<string>) => urls,
    setAbortController: (value: AbortController | null) => value,
    cancelRequests: () => { },
    reset: () => { },
    setAttachedFilesMap: (newAttachedFilesMap: Map<string, OrderFile>) => newAttachedFilesMap,
    uploadFiles: (files: Array<File>) => files,
    deleteFile: (fileName: string) => fileName,
  },
  ({ onAction, schedule }, state = getInitialState()) => {
    onAction('init', (urls: Array<string>) => {
      const attachedFilesMap = urls
        ?.reduce((result, s3Url) => {
          const filename = getFilenameFromS3Url(s3Url)
          return result.set(
            filename,
            {
              filename,
              data: null,
              s3Url,
            },
          )
        },
        new Map<string, OrderFile>(),
      )

      schedule(dispatch => {
        dispatch(orderFilesAtom.setAttachedFilesMap(attachedFilesMap))
      })
    })

    onAction('setAbortController', abortController => {
      state = {
        ...state,
        abortController,
      }
    })

    onAction('cancelRequests', () => {
      state.abortController?.abort?.()
    })

    onAction('reset', () => {
      state = getInitialState()
    })

    onAction('setAttachedFilesMap', newAttachedFilesMap => {
      state = {
        ...state,
        attachedFilesMap: newAttachedFilesMap,
      }
    })

    onAction('uploadFiles', (files: Array<File>) => {
      schedule(async dispatch => {
        dispatch(orderFilesAtom.cancelRequests())
        const abortController = new AbortController()
        const abortSignal = abortController.signal

        dispatch([
          orderFilesLoadingAtom.set(true),
          orderFilesAtom.setAbortController(abortController),
        ])

        try {
          const orderFiles = await getApi().orderFiles.uploadOrderFilesToS3(files, abortSignal)

          const prevAttachedFilesMap = new Map<string, OrderFile>(state.attachedFilesMap)
          const attachedFilesMap = orderFiles
            .reduce((result, orderFile) =>
              result.set(orderFile.filename, orderFile),
              prevAttachedFilesMap,
            )
          dispatch(orderFilesAtom.setAttachedFilesMap(attachedFilesMap))
        }
        catch (error) {
          const errorMessage = `${i18n_get.t('OrderFiles.Upload.ResponseError')}: ${(error as Error)?.message}`
          toast.error(errorMessage, {
            duration: 10000,
          })
          logError(errorMessage)

          dispatch(orderFilesAtom.cancelRequests())
        }
        finally {
          dispatch(orderFilesLoadingAtom.set(false))
        }
      })
    })

    onAction('deleteFile', (fileName: string) => {
      if (!fileName) {
        return
      }

      schedule(dispatch => {
        const prevAttachedFilesMap = new Map<string, OrderFile>(state.attachedFilesMap)
        prevAttachedFilesMap.delete(fileName)

        dispatch(orderFilesAtom.setAttachedFilesMap(prevAttachedFilesMap))
      })
    })

    return state
  },
)
