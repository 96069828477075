import {AutocompleteItem, SelectItem} from '@mantine/core'

export function mapToSelectItem<ID extends string>(
  data: TitleWithId<ID> | string,
): SelectItem {
  return typeof data === 'string'
    ? {value: data, label: data}
    : {value: data.id, label: data.title}
}

export function mapItemWithNameToSelect({name}: {name: string}): SelectItem {
  return {
    value: name,
    label: name,
  }
}

export function mapToAutocompleteItem<ID extends string>(
  data: TitleWithId<ID>,
): AutocompleteItem & {id: ID} {
  return {id: data.id, value: data.title}
}

export function removeFalseValues(o: {[x in number]: boolean}) {
  const obj = {...o}
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] === false) {
      delete obj[key]
    }
  }
  return obj
}
