import {assert} from '@appscience/utils'
import {Store} from '@reatom/core'
import {createPrimitiveAtom} from '@reatom/core/primitives'
import {getTime, hoursToMilliseconds} from 'date-fns'
import {indexBy} from 'ramda'
import {getApi} from '../../api'

let lastInitTimestamp: number | null

export const transportersAtom = createPrimitiveAtom<
  Record<string, TitleWithId>
>({})

export async function updateTransportersAtom({dispatch}: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }
  const data = await getApi().listsApi.getTransporters()
  const record = indexBy(x => x.id, data)
  dispatch(transportersAtom.set(record))
  lastInitTimestamp = getTime(new Date())
  return record
}

export function getTransportersTitle(id: string) {
  return (
    assert(
      transportersAtom.getState()[id],
      `TransporterAtom error: transporter with id "${id}" not found`,
    )?.title || ''
  )
}
