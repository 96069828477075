import { AppRoute } from 'src/routes/types'

export const BASE_ROUTES: Array<AppRoute> = [
  '/',
  '/auth',
  '/auth/forgot',
  '/auth/login',
  '/auth/register',
  '/reorder',
  '/delivery-point',
  '/analogues',
]
export const COMPLIANCE_ROUTES: Array<AppRoute> = [
  '/compliance/scifinder',
  '/compliance/excel-upload',
]
export const IAM_ROUTES: Array<AppRoute> = [
  '/iam/users',
  '/iam/users/add',
  '/iam/users/edit',
  '/iam/groups',
  '/iam/groups/add',
  '/iam/groups/edit',
]
export const PROCUREMENT_BASE_ROUTE: AppRoute = '/procurement'
export const PROCUREMENT_NEW_ORDER_ROUTES: Array<AppRoute> = [
  '/procurement/new-order',
  '/procurement/new-order/create',
]
export const PROCUREMENT_ORDERS_ROUTES: Array<AppRoute> = [
  '/procurement/pre-orders',
  '/procurement/pre-orders/edit',
]
export const PROCUREMENT_ROUTES: Array<AppRoute> = [
  PROCUREMENT_BASE_ROUTE,
  ...PROCUREMENT_NEW_ORDER_ROUTES,
  ...PROCUREMENT_ORDERS_ROUTES,
]
export const PROVIDER_BASE_ROUTE: AppRoute = '/provider'
export const PROVIDER_TO_WAREHOUSE_ROUTES: Array<AppRoute> = [
  '/provider/to-warehouse',
  '/provider/to-warehouse/process',
]
export const PROVIDER_IN_WAREHOUSE_ROUTES: Array<AppRoute> = [
  '/provider/in-warehouse',
  '/provider/in-warehouse/create-package',
]
export const PROVIDER_FROM_WAREHOUSE_ROUTES: Array<AppRoute> = [
  '/provider/from-warehouse',
  '/provider/from-warehouse/edit-package',
]
export const PROVIDER_ROUTES: Array<AppRoute> = [
  PROVIDER_BASE_ROUTE,
  ...PROVIDER_TO_WAREHOUSE_ROUTES,
  ...PROVIDER_IN_WAREHOUSE_ROUTES,
  ...PROVIDER_FROM_WAREHOUSE_ROUTES,
]
export const ALL_ROUTES: Array<AppRoute> = [
  ...BASE_ROUTES,
  ...COMPLIANCE_ROUTES,
  ...IAM_ROUTES,
  ...PROCUREMENT_ROUTES,
  ...PROVIDER_ROUTES,
]

export const DEFAULT_PRIORITIZED_PROCUREMENT_ROUTES: Array<AppRoute> = [
  PROCUREMENT_NEW_ORDER_ROUTES[0],
  PROCUREMENT_ORDERS_ROUTES[0],
]

export const DEFAULT_PRIORITIZED_PROVIDER_ROUTES: Array<AppRoute> = [
  PROVIDER_TO_WAREHOUSE_ROUTES[0],
  PROVIDER_IN_WAREHOUSE_ROUTES[0],
  PROVIDER_FROM_WAREHOUSE_ROUTES[0],
]

export const DEFAULT_PRIORITIZED_IAM_ROUTES: Array<AppRoute> = [
  IAM_ROUTES[0],
]

export const DEFAULT_PRIORITIZED_COMPLIANCE_ROUTES: Array<AppRoute> = [
  COMPLIANCE_ROUTES[0],
]

export const DEFAULT_PRIORITIZED_ROUTES: Array<AppRoute> = [
  ...DEFAULT_PRIORITIZED_PROCUREMENT_ROUTES,
  ...DEFAULT_PRIORITIZED_PROVIDER_ROUTES,
  ...DEFAULT_PRIORITIZED_IAM_ROUTES,
  ...DEFAULT_PRIORITIZED_COMPLIANCE_ROUTES,
  '/reorder',
  '/delivery-point',
]
