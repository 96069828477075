import { API_URL } from 'src/config/environment'
import {SplitSetPopupEditableItem} from '../../views/warehouse/to-warehouse/provider/table-by-items/popups/split-set/content'
import apiCreator from '../api-creator'

interface Params {
  itemId: string
  newItems: Array<SplitSetPopupEditableItem>
}

interface ChildrenItemRequestData {
  catalogue_id: string
  name: string
  quantity: number
}

interface RequestData {
  children_items: Array<ChildrenItemRequestData>
  id: string
}

export function splitItemIntoSubsets(params: Params): Promise<void> {
  const data: RequestData = {
    id: params.itemId,
    children_items: params.newItems.map(remapToChildrenItemRequestData),
  }

  return apiCreator
    .post(
      `${API_URL.TWIN}/split-set`,
      data,
    )
}

function remapToChildrenItemRequestData(
  data: SplitSetPopupEditableItem,
): ChildrenItemRequestData {
  return {
    catalogue_id: data.catalogueId,
    name: data.invoiceName,
    quantity: data.quantity,
  }
}
