import { i18n_get } from '../lng/i18n'

export const IN_PROGRESS_ORDER_STATUS = 'in_progress'
export const PO_SUBMITTED_ORDER_STATUS = 'po_submitted'
export const STORE_HANDLING_ORDER_STATUS = 'store_handling'
export const WAITING_PREPAID_ORDER_STATUS = 'waiting_prepaid'
export const UNDER_TRACKING_ORDER_STATUS = 'under_tracking'
export const ON_MODERATION_ORDER_STATUS = 'on_moderation'
export const ON_REVISION_ORDER_STATUS = 'on_revision'
export const MODERATION_PASSED_ORDER_STATUS = 'moderation_passed'

export type OrderStatusId =
  | 'in_progress'
  | 'po_submitted'
  | 'store_handling'
  | 'waiting_prepaid'
  | 'under_tracking'
  | 'on_moderation'
  | 'on_revision'
  | 'moderation_passed'
  | 'preparing_po'
  | 'tbd'

export function isUnderTrackingStatus(status: OrderStatusId): boolean {
  return status === UNDER_TRACKING_ORDER_STATUS
}

export function isWaitingPrepaymentStatus(status: OrderStatusId): boolean {
  return status === WAITING_PREPAID_ORDER_STATUS
}

export type TermsOfPayment = 'Net 7' | 'Net 10' | 'Net 12' | 'Net 14' | 'Net 15' | 'Net 30' | 'Net 60' | 'Net 45' | 'Net 20' | 'Prepayment' | ''

export function isModerationStatus(status: OrderStatusId): boolean {
  return (
    status === 'on_moderation' ||
    status === 'on_revision' ||
    status === 'moderation_passed'
  )
}

export function getOrderStatusPosition(status: OrderStatusId): number {
  const positions: Record<OrderStatusId, number> = {
    in_progress: 1,
    po_submitted: 2,
    store_handling: 3,
    waiting_prepaid: 4,
    under_tracking: 5,
    on_moderation: 6,
    on_revision: 7,
    moderation_passed: 8,
    preparing_po: 9,
    tbd: 10,
  }
  return positions[status]
}

//------------------------------------------------------
export type ModeratorStatus = 'approve' | 'reject' | 'on_moderation'


export function getModeratorStatuses(): Array<TitleWithId<ModeratorStatus>> {
  return [
    { id: 'approve', title: i18n_get.t('Procurement.Moderation.Approved') },
    { id: 'reject', title: i18n_get.t('Procurement.Moderation.Rejected') },
    {
      id: 'on_moderation',
      title: i18n_get.t('Procurement.Moderation.OnModeration'),
    },
  ]
}

export function getModeratorStatusOrder(status: ModeratorStatus) {
  const positions = {
    approve: 1,
    on_moderation: 2,
    reject: 3,
  }

  return positions[status]
}
