import {PriorityData} from '../../atoms/static-data/item-priorities-atom'
import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'
import {handleErrorWithDefaultValue} from '../common/utils'

interface ResponsePriorityData {
  priority: string
  position: number
  color: string
}

export function getProcurementPriorityMap(): Promise<Array<PriorityData>> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/get-priority-items`,
    )
    .then(res => {
      const rawItems: Array<ResponsePriorityData> = res.data.priority_items
      return rawItems.map(remapToPriorityData)
    })
    .catch(handleErrorWithDefaultValue([]))
}

function remapToPriorityData(data: ResponsePriorityData): PriorityData {
  return {
    id: data.priority,
    title: data.priority,
    position: data.position,
    color: data.color,
  }
}

