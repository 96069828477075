import { API_URL } from 'src/config/environment'
import apiCreator from '../../api-creator'
import { handleErrorWithDefaultValue } from '../utils'

type Response = {
  reasons_channel_changed: Array<string>;
}

export function getPrtChannelChangeReasons(): Promise<Array<string>> {
  return apiCreator
    .get<Response>(
      `${API_URL.TWIN}/reasons-channel-changed`,
    )
    .then(response => response.data?.reasons_channel_changed ?? [])
    .catch(handleErrorWithDefaultValue([]))
}
