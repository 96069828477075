import {SimpleTable, SimpleTableColumnInfo} from '@appscience/simple-table'
import React, {memo} from 'react'
import {i18n_get} from '../../../../../../lng/i18n'
import {TextWithEllipsis} from '../../../../../../components/text-with-ellipsis'
import {joinStrings} from '@appscience/utils'
import {AmoIdCell} from '../../../../../common/cells/amo-id-cell'
import {AddItemsToOrderPopupItem} from '../model'
import { formatNumber } from '@app/utils/formatters/format-number'

type AddItemsToOrderPopupColumnId = Exclude<
  keyof AddItemsToOrderPopupItem,
  'id'
>

interface Props {
  items: Array<AddItemsToOrderPopupItem>
}

export const AddItemsToOrderPopupTable = memo(({items}: Props) => (
  <SimpleTable
    columns={getColumnsInfo()}
    rowsData={{
      fragments: items.map(item => (
        <>
          <TextField value={item.brand} />
          <AmoCell id={item.amoId} />
          <TextField value={item.invoiceName} />
          <TextField value={item.catalogueId} />
          <TextField value={formatNumber(item.cost.toFixed(2))} />
          <TextField value={item.quantity.toString()} />
        </>
      )),
      className: 'font-medium',
    }}
    className='max-h-[364px] text-center'
  />
))

interface TextFieldProps {
  value: string
}

interface AmoCellProps {
  id: string
}

const AmoCell = ({id}: AmoCellProps) => (
  <div className='flex items-center justify-center'>
    <AmoIdCell id={id} />
  </div>
)
 
function TextField({value}: TextFieldProps) {
  return (
    <div className={joinStrings('flex justify-center items-center overflow-hidden')}>
      <TextWithEllipsis text={value || '—'} />
    </div>
  )
}

function getColumnsInfo(): Array<
  SimpleTableColumnInfo<AddItemsToOrderPopupColumnId>
  > {
  return [
    {
      id: 'brand',
      fr: 4,
      title: i18n_get.t('Common.Brand'),
    },
    {
      id: 'amoId',
      fr: 4,
      title: i18n_get.t('Common.AMO'),
    },
    {
      id: 'invoiceName',
      fr: 9,
      title: i18n_get.t('Common.ItemInvoiceName'),
    },
    {
      id: 'catalogueId',
      fr: 5,
      title: i18n_get.t('Common.CatalogueID'),
    },
    {
      id: 'cost',
      fr: 4,
      title: i18n_get.t('Common.ItemCost'),
    },
    {
      id: 'quantity',
      fr: 3,
      title: i18n_get.t('Common.Quantity'),
    },
  ]
}
