import apiCreator from 'src/api/api-creator'
import { API_URL } from 'src/config/environment'
import { UserWithId } from './model'

export interface GetUsersResponse {
  users: Array<UserWithId>;
}

export async function getUsers(): Promise<GetUsersResponse> {
  return apiCreator
    .get<GetUsersResponse>(
      `${API_URL.AUTH}/users/list`,
    )
    .then(({ data }) => data)
}
