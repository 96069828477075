import { Store } from '@reatom/core'
import { createPrimitiveAtom } from '@reatom/core/primitives'
import { getTime, hoursToMilliseconds } from 'date-fns'
import { getApi } from 'src/api'
import { BankAccount } from 'src/api/common/lists/get-bank-accounts'

let lastInitTimestamp: number | null


export const bankAccountsAtom = createPrimitiveAtom<Array<BankAccount>>([])

export async function updateBankAccountsAtom({dispatch}: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }
  const data = await getApi().listsApi.getBankAccounts()
  dispatch(bankAccountsAtom.set(data))
  lastInitTimestamp = getTime(new Date())
  return data
}