import i18n, {TOptions} from 'i18next'
import {Namespace, TFuncKey, initReactI18next} from 'react-i18next'
import ruRU from './locales/ru-RU.json'
import enUS from './locales/en-US.json'

export const i18nResources = {
  ru: {translations: ruRU},
  en: {translations: enUS},
}

//TODO: написать скрипт, который парсит JSON и закидывает переводы в единый CSV и обратно. За основу можно взять библиотечку
//TODO: описать работу с текстами в README.MD
i18n
  .use(initReactI18next)
  .init({
    lng: defineLng(),
    ns: ['translations'],
    defaultNS: 'translations',
    debug: process.env.REACT_APP_ENVIRONMENT === 'development',
    interpolation: {
      escapeValue: true,
    },
    resources: i18nResources,
  })

interface I18nGetOptions extends TOptions {
  ns?: Namespace
}

type TFn = (key: TFuncKey<Namespace>, options?: I18nGetOptions) => string

export const i18n_get = {
  t: i18n.t as TFn,
}

function defineLng() {
  if (location.pathname === '/delivery-point/') {
    return 'ru'
  }
  return 'en'
}