import {Store} from '@reatom/core'
import {createPrimitiveAtom} from '@reatom/core/primitives'
import {getTime, hoursToMilliseconds} from 'date-fns'
import {indexBy} from 'ramda'
import { ExtendedCountriesResponseData } from 'src/api/common/lists/lists-api'
import {getApi} from '../../api'

let lastInitTimestamp: number | null

export const countriesAtom = createPrimitiveAtom<Record<string, ExtendedCountriesResponseData>>(
  {},
)

export async function updateCountriesAtom({dispatch}: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }
  const data = await getApi().listsApi.getCountries()
  const record = indexBy(x => x.name, data)
  dispatch(countriesAtom.set(record))
  lastInitTimestamp = getTime(new Date())
  return record
}
