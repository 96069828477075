
import { OrderDetailsData } from 'src/atoms/static-data/order-details-colors-item'
import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'
import {handleErrorWithDefaultValue} from './utils'

interface ResponsOrderDetailsData {
  id: string
  color: string
}

export function getOrderDetails(): Promise<Array<OrderDetailsData>> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/order-details-colors`,
    )
    .then(res => {
      const rawItems: Array<ResponsOrderDetailsData> = res.data.order_details_colors
      return rawItems.map(remapToOrderDetailsData)
    })
    .catch(handleErrorWithDefaultValue([]))
}

export function remapToOrderDetailsData(data: ResponsOrderDetailsData): OrderDetailsData {
  return {
    id: data.id,
    title: data.id,
    color: data.color,
  }
}
