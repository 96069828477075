import { IN_PROGRESS_ORDER_STATUS } from 'src/types/order-status'
import { CreateOrderPageOrderFields } from './create-order-page-order-fields.type'
import { CreateOrderPageItem } from './items/items.atom.type'

const EMPTY_ORDER: CreateOrderPageOrderFields = {
  storeId: null,
  orderStatusId: IN_PROGRESS_ORDER_STATUS,
  orderTimestamp: null,
  paymentTimestamp: null,
  confirmationTimestamp: null,
  paymentPriority: '',
  countryId: null,
  providerId: null,
  paymentPercentage: null,
  paymentMethodInfo: '',
  prepaymentAmount: null,
  currencyId: 'USD',
  sumVendorPrice: null,
  paymentMethodId: null,
  transferredForPaymentDate: null,
  paymentDetails: '',
  paymentType: '',
  scd: null,
  procurement: null,
  orderNumber: '',
  isModerationPassed: false,
  headOfPrtApprove: false,
  headOfFinanceApprove: false,
  sumCost: null,
  numberPO: '',
  tracking: '',
  taxPercent: null,
  sumOtherCost: '',
  managerContact: '',
  card: null,
  moderatorComment: '',
  tswNameId: null,
  hsCodeChina: '',
  exportDocuments: [],
  documentUrls: [],
  orderComment: '',
}

export function getInitOrderFields(items: Array<CreateOrderPageItem> = []): CreateOrderPageOrderFields {
  const result: CreateOrderPageOrderFields = { ...EMPTY_ORDER }

  items.forEach(item => {
    if (result.countryId === null && item.country) {
      result.countryId = item.country
    }

    if (result.storeId === null && item.store) {
      result.storeId = item.store
    }

    if (result.procurement === null && item.procurement) {
      result.procurement = item.procurement
    }

    if (result.scd === null && item.scd) {
      result.scd = item.scd
    }
  })

  return result
}
