import {indexByWithCb} from '@appscience/utils'
import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'
import {handleErrorWithDefaultValue} from '../common/utils'

interface ItemCoefResponseData {
  item_id: string
  coefficient: number
}

interface Params {
  itemIds: Array<string>
  sendTo: string
}

export function getPriceCoefficient({
  itemIds,
  sendTo,
}: Params): Promise<Record<string, number>> {
  return apiCreator
    .post(
      `${API_URL.TWIN}/get-price-coefficient`,
      {
        item_ids: itemIds,
        send_to: sendTo,
      },
    )
    .then(res => {
      const data: Array<ItemCoefResponseData> = res.data.item_coefficients
      return data.reduce(
        (acc: Record<string, number>, {item_id, coefficient}) => {
          acc[item_id] = coefficient
          return acc
        },
        {},
      )
    })
    .catch(
      handleErrorWithDefaultValue(
        indexByWithCb(
          itemIds,
          id => id,
          _ => 1,
        ),
      ),
    )
}
