import {MantineThemeComponents} from '@mantine/styles/lib/theme/types/MantineTheme'
import {ValueOfRecord} from 'ramda'
import {DefaultSizes} from '../foundations/sizes'
import {getCommonInputStyles} from './common'

export function getSelectStylesConfig(): ValueOfRecord<MantineThemeComponents> {
  return {
    styles: {
      input: {
        height: DefaultSizes['sm'],
        minHeight: DefaultSizes['sm'],
        ...getCommonInputStyles(),
      },
    },
  }
}

