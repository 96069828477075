import { pageLoadingAtom } from '../../../../atoms/page-loading-atom'
import { updateCardsAtom } from '../../../../atoms/static-data/cards-atom'
import { updateCurrenciesAtom } from '../../../../atoms/static-data/currencies-atom'
import { updateExportDocumentsAtom } from '../../../../atoms/static-data/export-documents-atom'
import { updateItemPrioritiesAtom } from '../../../../atoms/static-data/item-priorities-atom'
import { updateOrderStatusesAtom } from '../../../../atoms/static-data/order-statuses-atom'
import { updateExchangeRatesAtom } from '../../../../atoms/static-data/exchange-rates-atom'
import { updatePaymentMethodsAtom } from '../../../../atoms/static-data/payments-method-atom'
import { updateProcurementsAtom } from '../../../../atoms/static-data/procurements-atom'
import { updateStoresAtom } from '../../../../atoms/static-data/stores-atom'
import { UserRole } from '../../../../atoms/user-atom'
import { ServerTableSettings } from '../../../../components/table/utils/settings-atom'
import { updateTswNameColorsAtom } from '../../../../atoms/static-data/tsw-name-colors-atom'
import { declareAloneAction } from '../../../../hooks/use-alone-action'
import {
  invoiceTableSelectedOrderIdAtom,
  preordersPageTabAtom,
} from './main'
import { updateItemStatusesAtom } from 'src/atoms/static-data/item-statuses-atom'
import { updateWarehousesAtom } from 'src/atoms/static-data/warehouses-atom'
import { updateHazmatsAtom } from 'src/atoms/static-data/hazmats-atom'
import { updateOrderDetailsColorsAtom } from 'src/atoms/static-data/order-details-colors-item'
import { updateTermsOfPaymentAtom } from 'src/atoms/static-data/terms-of-payment'
import { updateBankAccountsAtom } from 'src/atoms/static-data/bank-accounts-atom'
import { updatePaymentPriorities } from 'src/atoms/static-data/payment-priorities-atom'
import { loadItems } from './loaders/load-items'
import { loadOrders } from './loaders/load-orders'
import { loadInvoices } from './loaders/load-invoices'

interface Payload {
  backgroundMode?: boolean
  settings: ServerTableSettings,
  isNewLazyLoading: boolean;
  role?: UserRole
}

export const loadPreordersData = declareAloneAction<Payload>(
  async ({
    store,
    payload: {
      backgroundMode,
      settings,
      isNewLazyLoading,
      role = 'default',
    },
  }) => {
    if (!backgroundMode) {
      store.dispatch(pageLoadingAtom.set('loading'))
    }

    const currentTab = preordersPageTabAtom.getState()
    const loadActionsMap = {
      'byOrders': () => loadOrders(store, role),
      'byItems': () => loadItems({
        store,
        settings,
        isNewLazyLoading,
      }),
      'byInvoices': () => loadInvoices({
        store,
        settings,
        byOrderId: invoiceTableSelectedOrderIdAtom.getState(),
      }),
    }

    await Promise.all([
      loadActionsMap[currentTab](),
      updateOrderStatusesAtom(store),
      updateWarehousesAtom(store),
      updateHazmatsAtom(store),
      updatePaymentMethodsAtom(store),
      updateCurrenciesAtom(store),
      updateTermsOfPaymentAtom(store),
      updateProcurementsAtom(store),
      updateBankAccountsAtom(store),
      updateStoresAtom(store),
      updatePaymentPriorities(store),
      updateExchangeRatesAtom(store),
      updateOrderDetailsColorsAtom(store),
      updateCardsAtom(store),
      updateItemStatusesAtom(store),
      updateItemPrioritiesAtom(store),
      updateTswNameColorsAtom(store),
      updateExportDocumentsAtom(store),
    ])

    store.dispatch(pageLoadingAtom.set('normal'))
  },
)
