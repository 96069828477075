import React from 'react'
import {useAction} from '@reatom/react'
import {Select} from '@mantine/core'

import {UserRole, userAtom} from '../../atoms/user-atom'
import {useAtomState} from '../../hooks/use-atom-state'

interface UserDataSelect {
  value: UserRole
  label: UserRole
}

const DATA_SELECT: UserDataSelect[] = [
  {
    value: 'default',
    label: 'default',
  },
  {
    value: 'head-of-procurement',
    label: 'head-of-procurement',
  },
  {
    value: 'head-of-finance',
    label: 'head-of-finance',
  },
  {
    value: 'finance-control',
    label: 'finance-control',
  },
]

export function BlockForTester() {
  const handleSetUserData = useAction(userAtom.set)
  const userData = useAtomState(userAtom)

  return (
    <Select
      style={{marginRight: '0.5rem'}}
      placeholder='выбор роли'
      data={DATA_SELECT}
      value={userData.role}
      onChange={(value: UserRole) =>
        handleSetUserData({...userData, role: value})
      }
    />
  )
}
