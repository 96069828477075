import {Atom} from '@reatom/core/src/types'
import {useAtom} from '@reatom/react'
import {useMemo} from 'react'

export function useAtomState<T extends Atom>(atom: T) {
  const [state] = useAtom(atom)
  return state
}

export function useMappedAtomState<T, R>(atom: Atom<T>, map: (v: T) => R): R {
  const [state] = useAtom(atom)
  return useMemo(() => map(state), [state])
}
