import {Paper, createStyles} from '@mantine/core'
import React from 'react'
import {TwinLogo} from '../../../components/icons/twin-logo'
import {mode} from '@appscience/utils'
import {SIDE_NAV_COLLAPSED_WIDTH} from '../../../config/constants'
import {DefaultLeftMenuItem, DefaultLeftMenuItemProps} from './menu-item'
import {useLeftMenuItems} from './use-menu-items'
import { useAtomState } from 'src/hooks/use-atom-state'
import { permissionAtom } from 'src/hooks/permissions/permission-atom'

const useStyles = createStyles(({colors, colorScheme}) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexShrink: 0,
    backgroundColor: mode(colors.blue[5], colors.dark[7])(colorScheme),
    width: SIDE_NAV_COLLAPSED_WIDTH,
  },
}))

function useAllowedMenuItems(
  menuItems: Array<DefaultLeftMenuItemProps>,
): Array<DefaultLeftMenuItemProps> {
  const { allowedRoutesSet } = useAtomState(permissionAtom)

  return menuItems
    .filter(({ redirectRoute }) => allowedRoutesSet.has(redirectRoute))
}

export function DefaultLeftNav() {
  const {classes} = useStyles(undefined)
  const menuItems = useLeftMenuItems()
  const allowedMenuItems = useAllowedMenuItems(menuItems)

  return (
    <Paper radius={0} className={classes.container}>
      <TwinLogo style={{margin: '20px', color: 'white'}} />
      {allowedMenuItems.map(props => (
        <DefaultLeftMenuItem key={props.redirectRoute} {...props} />
      ))}
    </Paper>
  )
}
