import { addGroup } from './add-group'
import { deleteGroupById } from './delete-group-by-id'
import { getGroupById } from './get-group-by-id'
import { getGroups } from './get-groups'
import { getInterfaces } from './get-interfaces'
import { getInterfaceObjects } from './get-interface-objects'
import { updateGroup } from './update-group'
import { getSuperGroup } from './get-super-group'
import { getUserPermissions } from './get-user-permissions'

export const groupsApi = {
  addGroup: addGroup,
  deleteGroupById: deleteGroupById,
  getGroupById: getGroupById,
  getGroups: getGroups,
  getInterfaces: getInterfaces,
  getInterfaceObjects: getInterfaceObjects,
  updateGroup: updateGroup,
  getSuperGroup: getSuperGroup,
  getUserPermissions: getUserPermissions,
}
