import { Store } from '@reatom/core'
import { createPrimitiveAtom } from '@reatom/core/primitives'
import { getTime, hoursToMilliseconds } from 'date-fns'
import { getApi } from 'src/api'

let lastInitTimestamp: number | null

export interface ClientType {
  name: string
  color: string 
}

export const clientTypesAtom = createPrimitiveAtom<Array<ClientType>>([])

export async function updateClientTypesAtom({dispatch}: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }
  const data = await getApi().listsApi.getClientTypes()
  dispatch(clientTypesAtom.set(data))
  lastInitTimestamp = getTime(new Date())
  return data
}