import {Nullable} from '../types'

export function cleanArray<T>(array: Array<Nullable<T>>): Array<T> {
  return array.filter(Boolean) as Array<T>
}

export function intersectionSets<T>(...sets: Set<T>[]): Set<T> {
  if (sets.length === 0) {
    return new Set()
  }
  let resultSet: Set<T> = new Set<T>(Array.from(sets[0]))
  for (let i = 1; i < sets.length; i++) {
    if (resultSet.size === 0) {
      break
    }
    // eslint-disable-next-line no-loop-func
    resultSet = new Set(Array.from(sets[i]).filter(x => resultSet.has(x)))
  }
  return resultSet
}

export function intersectionArrays<T>(...arrays: Array<T>[]): Array<T> {
  if (arrays.length === 0) {
    return []
  }
  let resultSet: Set<T> = new Set<T>(arrays[0])
  for (let i = 1; i < arrays.length; i++) {
    if (resultSet.size === 0) {
      break
    }
    // eslint-disable-next-line no-loop-func
    resultSet = new Set(arrays[i].filter(x => resultSet.has(x)))
  }
  return Array.from(resultSet)
}

export function differenceArrays<T>(first: Array<T>, second: Array<T>): Array<T> {
  const deductibleSet = new Set(second)
  return first.filter(x => !deductibleSet.has(x))
}

export function indexByWithCb<T, N, K extends string | number = string>(list: readonly T[], fn: (a: T) => K, cb: (x: T) => N): { [key in K]: N } {
  return list.reduce((acc, x) => {
    acc[fn(x)] = cb(x)
    return acc
  }, {} as Record<K, N>)
}

export function filterStringArray<T extends string, E extends T>(arr: Array<T>, ...excludes: Array<E>): Array<Exclude<T, E>> {
  if (excludes.length === 0) {
    return arr as Array<Exclude<T, E>>
  }
  const excludesSet: Set<T> = new Set(excludes)
  return arr.filter(x => !excludesSet.has(x)) as Array<Exclude<T, E>>
}