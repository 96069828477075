import { createAtom, getState } from '@reatom/core'
import { initOrderModerationInfo } from 'src/atoms/static-data/init-moderation-info-atom'
import { createOrderPageFieldsAtom } from './order-fields.atom'
import { orderSumLimitAtom } from './order-sum-limit.atom'
import { convertCurrency } from 'src/atoms/static-data/exchange-rates-atom'
import { createOrderPageItemsAtom } from './items'

export const isExceededInitialCostAtom = createAtom({
  items: createOrderPageItemsAtom,
  set: (value: boolean) => value,
  reset: () => false,
}, ({ onAction, onChange, get, schedule }, state = false) => {
  const items = get('items')
  const moderationInfo = initOrderModerationInfo.getState()

  onChange('items', () => {
    schedule(dispatch => {
      const sum = items.reduce(
        (acc: number, item) => acc + (item.cost || 0) + (item.tax?.value || 0) + (item.otherCost?.value || 0),
        0,
      )
      const fromCurrencyId = getState(createOrderPageFieldsAtom).currencyId || 'USD'
      const { currency: toCurrencyId } = getState(orderSumLimitAtom)
      const convertedSum = convertCurrency(fromCurrencyId, toCurrencyId, sum)
      const initialCost = moderationInfo
        ? moderationInfo.initOrderSum
        : 0
      const isExceededInitialCost = convertedSum > initialCost

      dispatch(isExceededInitialCostAtom.set(isExceededInitialCost))
    })
  })

  onAction('set', value => {
    state = value
  })

  onAction('reset', () => {
    state = false
  })

  return state
})
