import { getApi } from 'src/api'
import { InterfaceIdsEnum, PermissionGroup } from './model'

export async function getSuperGroup(): Promise<PermissionGroup> {
  const api = getApi()

  const [
    interfacesResponse,
    procurementObjectsResponse,
    providerObjectsResponse,
  ] = await Promise.all([
    api.groups.getInterfaces(),
    api.groups.getInterfaceObjects(InterfaceIdsEnum.Procurement),
    api.groups.getInterfaceObjects(InterfaceIdsEnum.Provider),
  ])

  return {
    name: '',
    interfaces: interfacesResponse.interfaces,
    objects: [
      ...procurementObjectsResponse.objects,
      ...providerObjectsResponse.objects,
    ],
  }
}
