import {getTemperatureMap} from '../common/get-temperature-map'
import {getTswNameMap} from '../common/get-tsw-name-map'
import {addProcurementItemsToOrder} from './add-items-to-order'
import {approveOrder} from './approve-order'
import {generatePODoc} from './generate-po-doc'
import {generatePONumber} from './generate-po-number'
import { getCurrencyRatesByDeals } from './get-currency-rates-by-deals'
import { getInvoices } from './get-invoices'
import {getEditItemPopupItems} from './get-item-by-id'
import {getProcurementItemsByIds} from './get-items-by-ids'
import {getProcurementNewOrderItems} from './get-new-order-items'
import {getProcurementOrderById} from './get-order-with-items'
import {getPreordersItems, getPreordersItemsByInvoice, getPreordersItemsByOrder} from './get-preorders-items'
import {getProcurementPreordersOrders} from './get-preorders-orders'
import {getProcurementPriorityMap} from './get-priority-map'
import {rejectOrder} from './reject-order'
import {removeProcurementItems} from './remove-items'
import {procurementReorder} from './remove-order'
import { saveInvoices } from './save-invoices'
import {saveProcurementItem} from './save-item'
import {saveProcurementItems} from './save-items'
import {saveProcurementOrder} from './save-order'
import {splitProcurementItemQuantity} from './split-item-quantity'

export const procurementApi = {
  getProcurementPriorityMap,
  approveOrder,
  rejectOrder,
  generatePONumber,
  generatePODoc,
  getOrderById: getProcurementOrderById,
  getPreordersOrders: getProcurementPreordersOrders,
  getInvoices: getInvoices,
  getNewOrderItems: getProcurementNewOrderItems,
  getItemsByIds: getProcurementItemsByIds,
  reorder: procurementReorder,
  getCurrencyRatesByDeals: getCurrencyRatesByDeals,
  getPreordersItemsByInvoice,
  saveOrder: saveProcurementOrder,
  getEditItemPopupItems: getEditItemPopupItems,
  getPreordersItems: getPreordersItems,
  getPreordersItemsByOrder: getPreordersItemsByOrder,
  removeItems: removeProcurementItems,
  addItemsToOrder: addProcurementItemsToOrder,
  splitItemQuantity: splitProcurementItemQuantity,
  saveItem: saveProcurementItem,
  saveInvoices: saveInvoices,
  saveItems: saveProcurementItems,
  getTemperatureMap,
  getTswNameMap,
}
