import {Store} from '@reatom/core'
import {createPrimitiveAtom} from '@reatom/core/primitives'
import {getTime, hoursToMilliseconds} from 'date-fns'
import {indexBy} from 'ramda'
import {getApi} from '../../api'

let lastInitTimestamp: number | null

export interface OrderDetailsData {
  id: string
  title: string
  color: string
}

export const orderDetailsColorsAtom = createPrimitiveAtom<
  Record<string, OrderDetailsData>
  >({})

export async function updateOrderDetailsColorsAtom({dispatch}: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }
  const data = await getApi().listsApi.getOrderDetails()
  const record = indexBy(x => x.id, data)
  dispatch(orderDetailsColorsAtom.set(record))
  lastInitTimestamp = getTime(new Date())
  return record
}