import { Store } from '@reatom/core'
import { createPrimitiveAtom } from '@reatom/core/primitives'
import { getTime, hoursToMilliseconds } from 'date-fns'
import { indexBy } from 'ramda'
import { ResponsibilitiesResponseData } from 'src/api/common/lists/lists-api'
import { getApi } from '../../api'
import { filterAllowedProcurements } from 'src/hooks/permissions/allowed-procurements'

let lastInitTimestamp: number | null

export const procurementsAtom = createPrimitiveAtom<
  Record<string, ResponsibilitiesResponseData>
>({})

export async function updateProcurementsAtom({ dispatch }: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }
  const procurements = await getApi().listsApi.getResponsibilities('procurement')
  const allowedProcurements = filterAllowedProcurements(procurements)

  const record = indexBy(x => x.name, allowedProcurements)
  dispatch(procurementsAtom.set(record))
  lastInitTimestamp = getTime(new Date())
  return record
}
