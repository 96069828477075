import { updateObject } from '@appscience/utils'
import { createAtom } from '@reatom/core'
import produce from 'immer'
import { isEmpty } from 'ramda'
import { createOrderPageInitValuesAtom } from './init-values-atom'
import { CreateOrderPageOrderFields } from './create-order-page-order-fields.type'
import { getInitOrderFields } from './get-init-order-fields'
import { autoSum, computePaymentTimestamp } from './order-fields.atom.utils'
import { createOrderPageItemsAtom } from './items'
import { isUnderTrackingStatus, WAITING_PREPAID_ORDER_STATUS } from 'src/types/order-status'
import { isWaitingForPrepaymentAtom } from './items/is-waiting-for-prepayment.atom'

interface SyncOtherCostParams {
  prevValue: number|null
  newValue: number|null
}

export const createOrderPageFieldsAtom = createAtom(
  {
    init: (value: CreateOrderPageOrderFields, areTheSameTswNames: boolean, tswName: string) => ({
      value,
      areTheSameTswNames,
      tswName,
    }),
    update: <K extends keyof CreateOrderPageOrderFields>(
      field: K,
      value: CreateOrderPageOrderFields[K],
    ) => ({ field, value }),
    syncOtherCost: (params: SyncOtherCostParams) => params,
  },
  ({ onAction, schedule }, state = getInitOrderFields()) => {
    onAction('init', ({ value, areTheSameTswNames, tswName }) => {
      state = value

      schedule(dispatch => {
        dispatch(createOrderPageInitValuesAtom.initOrderFields(value))

        if (areTheSameTswNames) {
          dispatch(createOrderPageFieldsAtom.update('tswNameId', tswName))
        }
      })
    })

    onAction('update', ({ field, value }) => {
      state = produce(state, draft => {
        updateObject(draft, field, value)

        if (typeof value !== 'boolean' && (field === 'taxPercent' || field === 'sumOtherCost')) {
          schedule(dispatch =>
            dispatch(createOrderPageItemsAtom.sync(field, value)),
          )
        }

        if (
          (field === 'paymentMethodId' || field === 'orderTimestamp') &&
          draft.paymentMethodId !== null &&
          draft.orderTimestamp !== null
        ) {
          draft.paymentTimestamp = computePaymentTimestamp(
            draft.paymentMethodId,
            draft.orderTimestamp,
          )
        }

        if (
          field === 'paymentType' &&
          value === 'prepayment' &&
          isUnderTrackingStatus(draft.orderStatusId) &&
          (draft.paymentMethodId === null || draft.paymentMethodId != 'card')
        ) {
          draft.orderStatusId = WAITING_PREPAID_ORDER_STATUS
        }

        if (
          field === 'providerId' &&
          draft.taxPercent === null &&
          (draft.providerId === 'Gemini' || draft.providerId === '10X')
        ) {
          schedule(dispatch =>
            dispatch(createOrderPageFieldsAtom.update('taxPercent', 7.75)),
          )
        }
      })

      if (['paymentType', 'orderStatusId', 'isModerationPassed'].includes(field)) {
        schedule(dispatch => dispatch(isWaitingForPrepaymentAtom.update(state)))
      }
    })

    onAction('syncOtherCost', ({ prevValue, newValue }) => {
      state = produce(state, draft => {
        if (isEmpty(draft.sumOtherCost)) {
          draft.sumOtherCost = newValue === null ? '' : newValue.toString()
        } else {
          const summands = draft.sumOtherCost.split('+').map(Number)
          const firstSummand = autoSum({
            sum: summands[0],
            prevValue,
            newValue,
          })
          const nextSummands = summands.slice(1).join('+')

          draft.sumOtherCost = nextSummands
            ? `${firstSummand}+${nextSummands}`
            : `${firstSummand}`
        }
      })
    })

    return state
  },
)
