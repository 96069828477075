import { AxiosResponse } from 'axios'
import apiCreator from '../../api-creator'
import { UserWithId } from './model'
import { API_URL } from 'src/config/environment'

export type UpdateUserRequest = UserWithId
export type UpdateUserResponseData = UserWithId
export type UpdateUserResponse = AxiosResponse<UpdateUserResponseData>

export async function updateUser(userWithId: UserWithId): Promise<UpdateUserResponseData> {
  return apiCreator
    .post<UpdateUserResponseData, UpdateUserResponse, UpdateUserRequest>(
      `${API_URL.AUTH}/users/update`,
      userWithId,
    )
    .then(({ data }) => data)
}
