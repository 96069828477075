import {primitiveAtomWithLS} from '@app/utils/reatom'
import { FeatureFlag, featureFlagsAtom } from './feature-flags-atom'

export type UserRole =
  | 'default'
  | 'head-of-procurement'
  | 'head-of-finance'
  | 'finance-control'

interface UserData {
  id: string
  email: string
  role: UserRole
}

export const userAtom = primitiveAtomWithLS<UserData>(
  {} as UserData,
  'userAtom',
)

//TODO:WMLN-2227 моковая реализация, пока не появилась система ролей.
export function getRoleByEmail(email: string): UserRole {
  const isQAMode = featureFlagsAtom.getState()[FeatureFlag.Qa]
  if (isQAMode) {
    return userAtom.getState().role ?? 'default'
  }
  
  if (isHeadOfProcurement(email)) {
    return 'head-of-procurement'
  } else if (isHeadOfFinance(email)) {
    return 'head-of-finance'
  } else if (isFinanceControl(email)) {
    return 'finance-control'
  }
  return 'default'
}

const HEAD_OF_PROCUREMENT_EMAILS: Array<string> = [
  'bergheim@gembioinc.com',
  'kristina.serdtseva@appscience.ru',
  'peter.makov@appscience.ru',
  'anastasia.shilova@appscience.ru',
]

const HEAD_OF_FINANCE_EMAILS: Array<string> = [
  'marina@appscience.ru',
]

const FINANCE_CONTROL_EMAILS: Array<string> = [
  'adelina.grishina@appscience.ru',
  'marina@appscience.ru',
  'ekaterina.gavrik@appscience.ru',
  'olga.bobrova@appscience.ru',
  'valeria.zhelilova@appscience.ru',
  'darya.sloeva@appscience.ru',
  'viktoriya.stasheyko@appscience.ru',
]

function isHeadOfProcurement(email: string): boolean {
  return HEAD_OF_PROCUREMENT_EMAILS.includes(email)
}

function isHeadOfFinance(email: string): boolean {
  return HEAD_OF_FINANCE_EMAILS.includes(email)
}

function isFinanceControl(email: string): boolean {
  return FINANCE_CONTROL_EMAILS.includes(email)
}
