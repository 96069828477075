import {defaultError} from '@app/utils/errors'
import axios from 'axios'
import {OrderStatusId} from '../../types/order-status'
import {PaymentMethodId} from '../../atoms/static-data/payments-method-atom'
import {checkNever} from '@appscience/utils'
import {Api_Item} from './item-model'
import {OrderStatusesResponseData} from './lists/lists-api'

export function remapToTitleWithId<ID extends string>(
  data: TitleWithId | ID,
): TitleWithId<ID> {
  return typeof data === 'object'
    ? {id: data.id as ID, title: data.title}
    : {id: data as ID, title: data}
}

export function remapToOrderStatus(
  data: OrderStatusesResponseData,
): TitleWithId<OrderStatusId> {
  isValidOrderStatusId(data.id)
  return {
    id: data.id,
    title: data.name,
  }
}

export function isValidOrderStatusId(id: OrderStatusId): id is OrderStatusId {
  switch (id) {
    case 'in_progress':
    case 'po_submitted':
    case 'store_handling':
    case 'under_tracking':
    case 'preparing_po':
    case 'waiting_prepaid':
      return true
    case 'on_revision':
    case 'on_moderation':
    case 'moderation_passed':
    case 'tbd':
      return false
    default:
      checkNever(id, `Unknown order status. Value: ${id}`)
      return false
  }
}

export function checkPaymentMethodId(id: PaymentMethodId): PaymentMethodId {
  switch (id) {
    case 'card':
    case 'credit_line':
    case 'prepayment':
    case 'net_30':
    case 'transfer':
      return id
    default:
      checkNever(id, `Unknown payment method. Value: ${id}`)
      return '' as PaymentMethodId
  }
}

export function handleErrorWithDefaultValue<T>(v: T): (e: object) => T {
  return (error: object) => {
    !axios.isCancel(error) && defaultError(error)
    return v
  }
}

export function getProcurementItemInvoicePrice(item: Api_Item): number | null {
  return item.invoice_price
}

export function getItemInvoicePrice(item: Api_Item): number | null {
  return item.invoice_price_it
}
