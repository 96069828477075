import {i18n_get} from '../lng/i18n'
import {checkNever} from '@appscience/utils'
import {AppRoute} from './types'

// eslint-disable-next-line complexity
export function getPageTitleByRoute(route: AppRoute): string {
  switch (route) {
    case '/':
    case '/404':
      return ''
    case '/auth':
    case '/auth/login':
      return ''
    case '/auth/forgot':
      return ''
    case '/auth/register':
      return ''
    case '/procurement':
    case '/procurement/new-order':
      return i18n_get.t('NewOrder.PageTitle')
    case '/procurement/new-order/create':
      return i18n_get.t('NewOrder.CreateOrder')
    case '/procurement/pre-orders':
      return i18n_get.t('PreOrders.PageTitle')
    case '/procurement/pre-orders/edit':
      return i18n_get.t('PreOrders.EditOrderTitle')
    case '/provider':
    case '/provider/to-warehouse/process':
      return i18n_get.t('Provider.ToWarehouse.AcceptancePage.Title')
    case '/provider/to-warehouse':
      return i18n_get.t('Provider.ToWarehouse.PageTitle')
    case '/provider/in-warehouse':
      return i18n_get.t('Provider.InWarehouse.PageTitle')
    case '/provider/in-warehouse/create-package':
      return i18n_get.t('Provider.InWarehouse.CreatePackage')
    case '/provider/from-warehouse':
      return i18n_get.t('Provider.FromWarehouse.PageTitle')
    case '/provider/from-warehouse/edit-package':
      return i18n_get.t('Provider.EditPackage')
    case '/delivery-point':
      return i18n_get.t('PackingListGenerator.PageTitle')
    case '/reorder':
      return i18n_get.t('Reorder.PageTitle')
    case '/iam':
    case '/iam/users':
      return 'Пользователи'
    case '/iam/users/add':
      return 'Добавить пользователя'
    case '/iam/users/edit':
      return 'Редактирование пользователя'
    case '/iam/groups':
      return 'Группы'
    case '/iam/groups/add':
      return 'Добавление группы пользователей'
    case '/iam/groups/edit':
      return 'Редактирование группы пользователей'
    case '/compliance':
    case '/compliance/scifinder':
      return i18n_get.t('Compliance.Scifinder.PageTitle')
    case '/compliance/excel-upload':
      return i18n_get.t('Compliance.Scifinder.ExcelUpload.PageTitle')
    case '/analogues':
      return i18n_get.t('Analogues.ExcelUpload.PageTitle')
    default:
      checkNever(route, `Unknown app route. Value: ${route}`)
      return ''
  }
}
