import { ComponentPropsWithRef } from 'react'
import { Redirect } from 'react-router-dom'
import { AppRoute } from '../types'

export interface TypedRedirectProps
  extends ComponentPropsWithRef<typeof Redirect> {
  to: AppRoute
  from?: AppRoute
}

export function TypedRedirect(props: TypedRedirectProps) {
  return <Redirect {...props} />
}
