import { createMultipleParam } from '@appscience/utils'
import { API_URL } from 'src/config/environment'
import { ToWarehouseRegisterItem } from '../../views/warehouse/to-warehouse/common/register-page/model/model'
import apiCreator from '../api-creator'
import { Api_Item } from '../common/item-model'
import { getItemInvoicePrice } from '../common/utils'
import { parseDate } from '@app/utils/date'

interface GetItemsByOrderIdsParams {
  orderId: string
  warehouseId: string
}

export function getProviderRegisterPageItemsByOrderId({
  warehouseId,
  orderId,
}: GetItemsByOrderIdsParams): Promise<Array<ToWarehouseRegisterItem>> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/items?step=to-warehouse&provider=${warehouseId}&order_id=${orderId}`,
    )
    .then(res => {
      const rawData: Array<Api_Item> = res.data.items
      return rawData.map(remapToProviderRegisterItem)
    })
}

interface GetItemsByIdsParams {
  itemIds: Array<string>
}

export function getRegisterItemsByIds(
  params: GetItemsByIdsParams,
): Promise<Array<ToWarehouseRegisterItem>> {
  return apiCreator
    .get(`${API_URL.TWIN}/items?${createMultipleParam(params.itemIds, 'id')}`)
    .then(res => {
      const rawData: Array<Api_Item> = res.data.items
      return rawData.map(remapToProviderRegisterItem)
    })
}

export function remapToProviderRegisterItem(
  data: Api_Item,
): ToWarehouseRegisterItem {
  return {
    id: data.id,
    amoId: data.deal_id,
    invoiceName: data.name,
    originalName: data.original_name,
    brand: data.brand,
    catalogueId: data.catalogue_id,
    quantity: data.quantity,
    sumCost: data.full_cost,
    itemCost: data.cost,
    orderInvoice: data.invoice_number,
    temperatureRegime: data.provider_temp,
    countryOfOrigin: data.country_of_origin,
    storeOrderNumber: data.store_order_number,
    providerHazmatType: data.provider_hazmat_type,
    hazmatComment: data.provider_hazmat,
    provider: data.provider,
    locations: data.provider_locations,
    lot: data.provider_lot,
    expirationDate: parseDate(data.provider_exp),
    currencyId: data.currency,
    actuallyDelivered: data.actually_delivered,
    warehouseId: data.to_warehouse_id || data.provider,
    exportComment: data.provider_comment,
    complianceComments: data.compliance_comments,
    procurementComments: data.procurement_comment,
    sdComments: data.sd_comments,
    orderNumbering: data.order_numbering,
    hsnCodes: data.hsn_codes,
    procurement: data.procurement,
    partnerInvoice: data.partner_invoice,
    track: data.track,
    casId: data.cas_id,
    poNumber: data.po_number,
  }
}

interface GetItemsByPackageIdParams {
  packageId: string
}

export function getHubRegisterPageItemsByPackageId({
  packageId,
}: GetItemsByPackageIdParams): Promise<Array<ToWarehouseRegisterItem>> {
  return apiCreator
    .get(`${API_URL.TWIN}/items?package_id=${packageId}`)
    .then(res => {
      const rawData: Array<Api_Item> = res.data.items
      return rawData.map(remapToHubRegisterItem)
    })
}

export function remapToHubRegisterItem(
  data: Api_Item,
): ToWarehouseRegisterItem {
  return {
    id: data.id,
    amoId: data.deal_id,
    invoiceName: data.name,
    originalName: data.original_name,
    brand: data.brand,
    catalogueId: data.catalogue_id,
    actuallyDelivered: data.actually_delivered,
    quantity: data.quantity,
    sumCost: getItemInvoicePrice(data) || 0,
    itemCost: data.cost,
    orderNumbering: data.order_numbering,
    orderInvoice: data.invoice_number,
    temperatureRegime: data.provider_temp,
    providerHazmatType: data.provider_hazmat_type,
    provider: data.provider,
    hazmatComment: data.provider_hazmat,
    locations: data.provider_locations,
    lot: data.provider_lot,
    countryOfOrigin: data.country_of_origin,
    expirationDate: parseDate(data.provider_exp),
    currencyId: data.currency,
    warehouseId: data.to_warehouse_id || data.provider,
    exportComment: data.provider_comment,
    storeOrderNumber: data.store_order_number,
    complianceComments: data.compliance_comments,
    procurementComments: data.procurement_comment,
    sdComments: data.sd_comments,
    hsnCodes: data.hsn_codes,
    procurement: data.procurement,
    partnerInvoice: data.partner_invoice,
    track: data.track,
    casId: data.cas_id,
    poNumber: data.po_number,
  }
}
