import {createLSDecorator, primitiveAtomWithLS} from '@app/utils/reatom'
import {createAtom} from '@reatom/core'
import {
  WarehouseData,
  WarehouseType,
} from '../../../atoms/static-data/warehouses-atom'
import {useAtomState} from '../../../hooks/use-atom-state'

export type ChipWarehouseType = Exclude<WarehouseType, 'delivery_point'>
export interface ViewWarehouseData extends WarehouseData {
  resultType: ChipWarehouseType
}
export type SelectedWarehousesSingleType = ChipWarehouseType | 'combo'

export const selectedWarehousesAtom = createAtom(
  {
    init: (v: WarehouseData) => v,
    set: (v: Array<WarehouseData>) => v,
  },
  ({onAction, schedule}, state: Array<ViewWarehouseData> = []) => {
    onAction('init', value => {
      const chipType = getWarehouseChipType(value)
      state = [{...value, resultType: chipType}]
      schedule(dispatch => {
        dispatch(warehousesChipTypeAtom.set(chipType))
        dispatch(selectedWarehousesSingleTypeAtom.set(chipType))
      })
    })
    onAction('set', values => {
      let newSingleType: SelectedWarehousesSingleType =
        values.length > 0 ? getWarehouseChipType(values[0]) : 'provider'
      state = values.map(x => {
        const resultType = getWarehouseChipType(x)
        newSingleType = newSingleType === resultType ? resultType : 'combo'
        return {...x, resultType}
      })
      schedule(dispatch => {
        newSingleType !== 'combo' &&
          dispatch(warehousesChipTypeAtom.set(newSingleType))
        dispatch(selectedWarehousesSingleTypeAtom.set(newSingleType))
      })
    })
    return state
  },
  {
    decorators: [
      createLSDecorator('toWarehouseCommonAtoms.selectedWarehouseAtom'),
    ],
  },
)

export const warehousesChipTypeAtom = primitiveAtomWithLS<ChipWarehouseType>(
  'provider',
  'warehousesChipTypeAtom',
)
export const selectedWarehousesSingleTypeAtom =
  primitiveAtomWithLS<SelectedWarehousesSingleType>(
    'provider',
    'selectedWarehousesSingleTypeAtom',
  )

export function useChipSelectedWarehouses() {
  const chipType = useAtomState(warehousesChipTypeAtom)
  const warehouses = useAtomState(selectedWarehousesAtom)
  return warehouses.filter(x => x.resultType === chipType)
}

function getWarehouseChipType(warehouse: WarehouseData): ChipWarehouseType {
  return warehouse.type === 'hub' || warehouse.type === 'delivery_point'
    ? 'hub'
    : 'provider'
}

export function getOneAreaWarehouses(
  selectedWarehouses: Array<ViewWarehouseData>,
  warehousesObj: Record<string, WarehouseData>,
): Array<WarehouseData> {
  if (selectedWarehouses.length === 0) {
    return []
  }
  const areaId = selectedWarehouses[0].areaId
  return Object.values(warehousesObj).filter(x => x.areaId === areaId)
}
