import { AxiosResponse } from 'axios'
import { API_URL } from 'src/config/environment'
import apiCreator from '../../api-creator'

export type DeleteUserRequest = {
  id: string;
}
export type DeleteUserResponseData = void
export type DeleteUserResponse = AxiosResponse<DeleteUserResponseData>

export async function deleteUserById(userId: string): Promise<DeleteUserResponseData> {
  return apiCreator
    .post<DeleteUserResponseData, DeleteUserResponse, DeleteUserRequest>(
      `${API_URL.AUTH}/users/delete`,
      {
        id: userId,
      },
    )
    .then(({ data }) => data)
}
