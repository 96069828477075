import { API_URL } from 'src/config/environment'
import { Invoice } from 'src/views/procurement/pre-orders-view/model/main'
import apiCreator from '../api-creator'
import { ServerTableSettings } from 'src/components/table/utils/settings-atom'
import { remapSettingsToSSTRequest } from '../common/server-side-params'
import { CompareType } from '../utils/compare-type'
import { TermsOfPayment } from 'src/types/order-status'

export interface Api_Invoice {
  invoice_number: string
  order_id: string
  invoice_sum: number
  order_number: string
  payment_priority: string
  invoice_comment: string
  ordered_date: string
  payment_method: string
  order_sum: number
  currency: string
  provider: string
  procurement: string
  additional_cost_sum: number
  registry_comment: string
  invoice_date: string
  store: string
  bank_account: string
  id: string
  invoice_check: 'ok' | 'not_ok' | 'not_checked_yet'
  paid_date: string
  registry_number: string
  terms_of_payment: TermsOfPayment
  payment_deadline: string
}

const remapToInvoiceTableItem = (invoice: Api_Invoice): Invoice => ({
  invoiceComment: invoice.invoice_comment,
  invoiceDate: invoice.invoice_date === '' ? undefined : new Date(invoice.invoice_date),
  invoiceCheck: invoice.invoice_check,
  paidDate: invoice.paid_date === '' ? undefined : new Date(invoice.paid_date),
  id: invoice.id,
  bankAccount: invoice.bank_account,
  registryComment: invoice.registry_comment,
  registryNumber: invoice.registry_number,
  paymentPriority: invoice.payment_priority,
  invoiceSum: invoice.invoice_sum,
  store: invoice.store,
  additionalCosts: invoice.additional_cost_sum,
  procurement: invoice.procurement,
  provider: invoice.provider,
  currency: invoice.currency,
  orderSum: invoice.order_sum,
  orderDate: invoice.ordered_date === '' ? undefined : new Date(invoice.ordered_date),
  paymentMethod: invoice.payment_method,
  orderNumber: invoice.order_number,
  orderId: invoice.order_id,
  termsOfPayment: invoice.terms_of_payment,
  invoiceNumber: invoice.invoice_number,
  paymentDeadline: invoice.payment_deadline === '' ? undefined : new Date(invoice.payment_deadline),
})

export const getInvoices = async ({ settings, byOrderId = ''}: { settings: ServerTableSettings, byOrderId?: string }) => {
  const body = {
    generic_query: remapSettingsToSSTRequest(settings),
  }

  if (byOrderId) {
    const orderIdFilter = {
      field: 'order_id',
      compare_type: 'contains' as CompareType,
      values: [byOrderId],
    }
    body.generic_query.filters.push(orderIdFilter)
  }
  
  return apiCreator
  .post(`${API_URL.TWIN}/get-invoices`, body)
  .then(res => {
    const rawItems: Array<Api_Invoice> = res.data.invoices
    return rawItems.map(remapToInvoiceTableItem)
  })

}
  

