import { createMultipleParam } from '@appscience/utils'
import { API_URL } from 'src/config/environment'
import { CreateOrderPageItem } from 'src/views/procurement/create-order/model/items/items.atom.type'
import apiCreator from '../api-creator'
import { Api_Item } from '../common/item-model'
import { getProcurementItemInvoicePrice } from '../common/utils'
import { parseDate, parseDateToTimestamp } from '@app/utils/date'

export function getProcurementItemsByIds(
  ids: Array<string>,
): Promise<Array<CreateOrderPageItem>> {
  return apiCreator
    .get(`${API_URL.TWIN}/items?${createMultipleParam(ids, 'id')}`)
    .then(res => {
      const rawItems: Array<Api_Item> = res.data.items
      return rawItems.map(remapToCreateOrderPageItem)
    })
}

export function remapToCreateOrderPageItem(
  data: Api_Item,
): CreateOrderPageItem {
  return {
    id: data.id,
    deadlineTimestamp: parseDateToTimestamp(data.deadline_date),
    brand: data.brand,
    additionalCosts: data.additional_costs,
    registryComment: data.registry_comment,
    esdFixed: data.esd_fixed || false,
    invoiceCheck: data.invoice_check,
    esdFixOriginal: parseDate(data.esd_fix_original_date),
    catalogue: data.catalogue_id,
    deadlineDate2: parseDate(data.deadline_date_2),
    deadlineDate: parseDate(data.deadline_date),
    exef: data.express,
    exefType: data.exef_type,
    clientType: data.client_type,
    paymentDetails: data.payment_details,
    paymentType: data.payment_type,
    sample: data.sample,
    bankAccount: data.bank_account,
    discountAmount: data.discount_absolute,
    discountPercent: data.discount_percent,
    discountComment: data.discount_comment,
    createdDate: parseDate(data.created_date),
    originalName: data.original_name,
    cost: null,
    initialCost: null,
    tax: null,
    otherCost: {
      value: null,
      acceptForTax: false,
    },
    quantity: data.quantity,
    invoiceName: data.name,
    shipmentTimestamp: null,
    esdFix: parseDate(data.esd_fix_date),
    clientPrice: data.final_price,
    amoId: data.deal_id,
    clientName: data.client_name,
    express: data.express || false,
    invoiceNumber: data.invoice_number,
    casNumber: data.cas_id,
    license: data.supply_mm_list,
    packing: data.package_size,
    url: data.item_url,
    nextCheckTimestamp: parseDateToTimestamp(data.next_check),
    isFree: false,
    invoicePrice: getProcurementItemInvoicePrice(data),
    initialInvoicePrice: getProcurementItemInvoicePrice(data),
    procurementComment: data.procurement_comment,
    providerComment: data.provider_comment,
    temperature: data.provider_temp,
    tswName: data.tsw_name,
    dgmStatus: data.dgm_status,
    deliveryMethod: data.method_of_delivery,
    isTender: data.is_tender,
    providerHazmatType: data.provider_hazmat_type,
    complianceTimestamp: parseDateToTimestamp(data.date_of_compliance),
    estimatedOrderTimestamp: parseDateToTimestamp(data.eod),
    costPrice: data.cost_price,
    inbound: data.inbound,
    supplyChain: data.supply_chain,
    outbound: data.outbound,
    quota: data.quota,
    vendorPrice: data.vendor_price,
    country: data.country,
    store: data.store,
    kit: data.is_kit,
    invoiceDate: parseDate(data.invoice_date) || undefined,
    paidDate: parseDate(data.supply_paid_date) || undefined,
    paymentDeadline: parseDate(data.payment_deadline) || undefined,
    registryNumber: data.registry_number,
    termsOfPayment: data.terms_of_payment,
    procurement: data.procurement,
    scd: data.responsible_scd,
    financeControlComment: data.finance_control_comment,
    orderStatus: data.order_status,
    exportDocuments: data.export_documents,
    hsCodeChina: data.hs_code_china,
    vendorRecommendation: data.vendor_recommend,
    orderComment: data.order_comment,
    orderDetails: data.order_details || '',
    procurementDlReason: data.procurement_dl_reason,
    procurementDlComments: data.procurement_dl_comments,
    clientPaymentReceived: data.is_payment_received || false,
    clientPaymentTerms: data.payment_condition || '',
    dlOrder: parseDate(data.dl_order),
    prtChannelChangeReason: data.prt_channel_change_reason || '',
    isShippedInAdvance: data.is_shipped_in_advance || false,
  }
}
