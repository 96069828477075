import { API_URL } from 'src/config/environment'
import apiCreator from '../../api-creator'

interface Params {
  packageId: string
}

export function returnToShipments({packageId}: Params): Promise<void> {
  return apiCreator
    .post(
      `${API_URL.TWIN}/cancel-package-shipment`,
      {
        package_id: packageId,
      },
    )
}
