import {AtomSelfBinded, Fn, Rec, createAtom} from '@reatom/core'
import {createPersist} from '@reatom/core/experiments'
import superjson from 'superjson'
import {PersistStorage} from '@reatom/core/experiments/persist'
import {createPrimitiveAtom} from '@reatom/core/primitives'
import {Store} from '@reatom/core/src/types'

export function primitiveAtomWithLS<T>(initState: T, LSName: string) {
  return createPrimitiveAtom<T>(initState, null, {
    decorators: [createLSDecorator(LSName)],
  })
}

export function createLSDecorator<T>(name: string) {
  const key = `erp_reatom_${name}`
  return createPersist(lsPersistStorage)<T>(key)
}

const lsPersistStorage: PersistStorage = {
  get: key => {
    const dataStr = localStorage?.getItem(key)
    return dataStr ? superjson.parse(dataStr) : undefined
  },
  set: (key, data) => localStorage?.setItem(key, superjson.stringify(data)),
  throttle: 150,
}

//@description Странно, но когда state = string|null и мы используем onSetEffect, то в момент экшена set(null), некоторые
// вышестоящие компоненты не реагируют на изменение атома. Но если изменить null на undefined или выпилить onSetEffect всё норм.
export function primitiveAtom<State>(
  initState: State,
  onSetEffect?: (dispatch: Store['dispatch'], newState: State) => void,
): PrimitiveAtom<State> {
  return createAtom(
    {
      set: (v: State) => v,
    },
    ({onAction, schedule}, state = initState) => {
      onAction('set', value => {
        state = value
        !!onSetEffect && schedule(dispatch => onSetEffect(dispatch, value))
      })
      return state
    },
  )
}

type PrimitiveAtom<
  State,
  Reducers extends Rec<Fn> = {set: (newState: State) => State},
> = AtomSelfBinded<State, Reducers>
