import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'
import { UserWithId } from '../iam/users/model'

export type CheckResponse = {
  user: UserWithId;
}

export async function check(): Promise<UserWithId> {
  return apiCreator
    .get<CheckResponse>(
      `${API_URL.AUTH}/check`,
    )
    .then(({ data }) => data.user)
}
