import {Loader} from '@mantine/core'
import {joinStrings} from '@appscience/utils'

type LoaderContainerProps = ClassNameProps<{ message?: string }>

export function LoaderContainer({className, message = ''}: LoaderContainerProps) {
  return (
    <div
      className={joinStrings(
        'flex flex-col justify-center items-center w-full h-full grow',
        className,
      )}
    >
      <Loader />
      {message && <div className='mt-2 text-lg opacity-80'>{message}</div>}
    </div>
  )
}
