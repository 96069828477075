import {
  Badge,
  Button,
  Paper,
  Switch,
  Text,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core'
import { MoonIcon, SunIcon } from '@heroicons/react/24/outline'
import i18n from 'i18next'
import React, { useState } from 'react'
import { DefaultActionIcon } from '../../components/action-icon'
import { useCurrentRoute, useRedirectFn } from '../../hooks/route-hooks'
import { i18n_get } from '../../lng/i18n'
import { getPageTitleByRoute } from '../../routes/titles'
import { checkNever, mode } from '@appscience/utils'
import { AUTH_TOKEN, HEADER_HEIGHT } from '../../config/constants'
import { PackingListGeneratorLayoutActions } from '../../views/packing-list-generator/content/layout-actions'
import { CreateOrderPageLayoutActions } from '../../views/procurement/create-order/content/layout-actions'
import { PreordersLayoutActions } from '../../views/procurement/pre-orders-view/common/header-layout-actions'
import { CreatePackagePageLayoutActions } from '../../views/warehouse/create-package/content/layout-actions'
import { FromWarehouseLayoutActions } from '../../views/warehouse/from-warehouse/common/layout-actions'
import { InWarehouseLayoutActions } from '../../views/warehouse/in-warehouse/content/layout-actions'
import { ToWarehouseLayoutActions } from '../../views/warehouse/to-warehouse/common/content/layout-actions'
import { ToWarehouseRegisterPageLayoutActions } from '../../views/warehouse/to-warehouse/common/register-page/common/layout-actions'
import { LogoutIcon } from '../../icons'
import { NewOrderLayoutActions } from 'src/views/procurement/new-order/content/layout-actions'
import { UsersTableActions } from 'src/views/iam/users/users-view/users-table-actions'
import { GroupsTableActions } from 'src/views/iam/groups/groups-view/content/groups-table/groups-table-actions'
import { useAction } from '@reatom/react'
import { permissionAtom } from 'src/hooks/permissions/permission-atom'
import { BlockForTester } from './block-for-tester'
import { FeatureFlag, featureFlagsAtom } from 'src/atoms/feature-flags-atom'
import { useAtomState } from 'src/hooks/use-atom-state'
import { ENVIRONMENT, IS_PROD_ENVIRONMENT } from 'src/config/environment'
import { OrderDetailsToggler } from './order-details-toggler'

export function DefaultLayoutHeader() {
  const route = useCurrentRoute()
  const additionalSection = useAdditionalSection()
  const redirectTo = useRedirectFn()
  const resetPermissions = useAction(permissionAtom.resetPermissions)
  const isQAMode = useAtomState(featureFlagsAtom)[FeatureFlag.Qa]

  const isCreateOrEditOrderPage = useHasOrderDetails()

  const doSignOut = () => {
    resetPermissions()
    localStorage.removeItem(AUTH_TOKEN)
    redirectTo('/auth/login')
  }

  return (
    <Paper
      className='flex items-center pl-6 pr-4 shrink-0'
      radius={0}
      sx={({ colors, colorScheme }) => ({
        height: HEADER_HEIGHT,
        borderBottomWidth: '1px',
        borderColor: mode(colors.gray[1], colors.gray[8])(colorScheme),
      })}
    >
      <div className='flex items-center mr-auto'>
        <Text
          className='font-semibold text-3xl mb-0 mr-5'
          sx={({ colors, colorScheme }) => ({
            color: mode(colors.dark[9], colors.gray[0])(colorScheme),
          })}
        >
          {getPageTitleByRoute(route)}
        </Text>
        {isCreateOrEditOrderPage && (
          <OrderDetailsToggler />
        )}
      </div>
      {isQAMode && (
        <BlockForTester />
      )}
      {additionalSection}
      {!IS_PROD_ENVIRONMENT && (
        <Badge
          variant='filled'
          size='lg'
        >
          {ENVIRONMENT} env
        </Badge>
      )}
      {/*<LngSwitch />*/}
      {/*<ToggleThemeButton />*/}
      <Button
        leftIcon={<LogoutIcon />}
        className='border-gray-200 ml-2 text-gray-700 px-2.5 hover:bg-gray-50'
        onClick={doSignOut}
        variant='outline'
      >
        {i18n_get.t('Logout')}
      </Button>
    </Paper>
  )
}

function LngSwitch() {
  const { colors } = useMantineTheme()
  const [checked, setChecked] = useState(i18n.language === 'ru')
  const bgColor = colors.gray[2]
  const color = colors.dark[4]

  return (
    <Switch
      checked={checked}
      size='lg'
      styles={{
        input: {
          backgroundColor: bgColor,
          fontSize: 12,
          borderColor: bgColor,
          ':after': { color, right: '15%' },
          ':checked': {
            backgroundColor: colors.gray[2],
            borderColor: colors.gray[2],
            ':after': { color, left: '15%' },
          },
        },
      }}
      onLabel={i18n_get.t('Common.Lng.RU')}
      offLabel={i18n_get.t('Common.Lng.EN')}
      onChange={event => {
        const value = event.currentTarget.checked
        setChecked(value)
        i18n.changeLanguage(value ? 'ru' : 'en').then(() => location.reload())
      }}
    />
  )
}

function ToggleThemeButton() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme()
  const Icon = mode(SunIcon, MoonIcon)(colorScheme)

  return (
    <DefaultActionIcon onClick={() => toggleColorScheme()}>
      <Icon className='w-6 h-6' />
    </DefaultActionIcon>
  )
}

function useAdditionalSection(): JSX.Element | null {
  const route = useCurrentRoute(
    ['/procurement', '/provider', '/delivery-point', '/reorder', '/compliance', '/iam', '/analogues'],
    'base',
  )
  switch (route) {
    case '/procurement/new-order':
      return <NewOrderLayoutActions />
    case '/procurement/pre-orders':
      return <PreordersLayoutActions />
    case '/provider/to-warehouse':
      return <ToWarehouseLayoutActions />
    case '/provider/to-warehouse/process':
      return <ToWarehouseRegisterPageLayoutActions />
    case '/procurement/new-order/create':
    case '/procurement/pre-orders/edit':
      return <CreateOrderPageLayoutActions />
    case '/provider/in-warehouse':
      return <InWarehouseLayoutActions />
    case '/provider/in-warehouse/create-package':
    case '/provider/from-warehouse/edit-package':
      return <CreatePackagePageLayoutActions />
    case '/provider/from-warehouse':
      return <FromWarehouseLayoutActions />
    case '/delivery-point':
      return <PackingListGeneratorLayoutActions />
    case '/reorder':
    case '/compliance/scifinder':
    case '/compliance/excel-upload':
    case '/analogues':
    case '/iam/users/add':
    case '/iam/users/edit':
    case '/iam/groups/add':
    case '/iam/groups/edit':
      return null
    case '/iam/users':
      return <UsersTableActions />
    case '/iam/groups':
      return <GroupsTableActions />
    default:
      checkNever(route, `Unknown procurement route. Value: ${route}`)
      return null
  }
}

function useHasOrderDetails(): boolean {
  const route = useCurrentRoute(
    ['/procurement', '/provider', '/delivery-point', '/reorder', '/compliance', '/iam', '/analogues'],
    'base',
  )

  return [
    '/procurement/new-order/create',
    '/procurement/pre-orders/edit',
  ].includes(route)
}
