import { createAtom } from '@reatom/core'
import { isWaitingPrepaymentStatus } from 'src/types/order-status'
import { createOrderPageItemsAtom } from './items.atom'
import produce from 'immer'
import { updateObject } from '@appscience/utils'
import { createOrderPageFieldsAtom } from '../order-fields.atom'
import { CreateOrderPageOrderFields } from '../create-order-page-order-fields.type'
import { isSumLimitExceededAtom } from '../is-sum-limit-exceeded.atom'

const WAITING_FOR_PREPAIMENT_PROCUREMENT_DL_REASON = 'Order in process: Waiting for payment to be done (Int)'

export const isWaitingForPrepaymentAtom = createAtom({
  update: (value: CreateOrderPageOrderFields) => value,
}, ({ onAction, schedule }, state = false) => {
  onAction('update', ({ paymentType, orderStatusId, isModerationPassed }) => {
    const isWaitingForPrepayment =
      paymentType === 'prepayment'
      && isWaitingPrepaymentStatus(orderStatusId)
    const wasWaitingForPrepayment = state

    schedule(dispatch => {
      if (isWaitingForPrepayment) {
        if (!wasWaitingForPrepayment) {
          dispatch(procurementDlReasonsAtom.initFromItems())
        }

        // dispatch(createOrderPageItemsAtom.updateAll({
        //   key: 'procurementDlReason',
        //   value: WAITING_FOR_PREPAIMENT_PROCUREMENT_DL_REASON,
        // }))

        const isSumLimitExceeded = isSumLimitExceededAtom.getState()
        const transferredForPaymentDate = isModerationPassed || !isSumLimitExceeded
          ? Date.now()
          : null

        dispatch(createOrderPageFieldsAtom.update('transferredForPaymentDate', transferredForPaymentDate))
      } else if (wasWaitingForPrepayment) {
        dispatch(procurementDlReasonsAtom.setToItems())
      }
    })

    state = isWaitingForPrepayment
  })

  return state
})

const procurementDlReasonsAtom = createAtom({
  initFromItems: () => { },
  setToItems: () => { },
}, ({ onAction, schedule }, state = new Map<number, string[]>()) => {
  onAction('initFromItems', () => {
    const items = createOrderPageItemsAtom.getState()
    state = items.reduce(
      (result, item) => result.set(item.index, item.procurementDlReason),
      new Map<number, string[]>(),
    )
  })

  onAction('setToItems', () => {
    const items = createOrderPageItemsAtom.getState()
    const newItems = produce(items, draft => {
      draft.forEach(itemDraft => {
        const procurementDlReason = state.get(itemDraft.index) || []
        updateObject(itemDraft, 'procurementDlReason', procurementDlReason)
      })
    })

    schedule(dispatch => dispatch(createOrderPageItemsAtom.init(newItems)))
  })

  return state
})
