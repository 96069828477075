import { API_URL } from 'src/config/environment'
import {AdditionalOrderFormState} from '../../views/reorder/additional-order-form/additional-order'
import apiCreator from '../api-creator'

interface SendAdditionalOrderDataForRequest {
  from_proposal?: {
    deal_id: number
    row_number_proposal_supply: number
  },
}

export function sendAdditionalForm(form: AdditionalOrderFormState): Promise<void> {
  const data: SendAdditionalOrderDataForRequest = {}

  data.from_proposal = {
    deal_id: Number(form.dealNumber),
    row_number_proposal_supply: Number(form.numberProposalSupply),
  }

  return apiCreator.post(`${API_URL.TWIN}/order/additional`, data)
}