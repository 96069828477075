import {
  assert,
  createMultipleParam,
} from '@appscience/utils'
import { API_URL } from 'src/config/environment'
import { ProcurementEditItemPopupDefaultItemData } from '../../views/common/edit-item-popup/procurement'
import apiCreator from '../api-creator'
import { Api_Item } from '../common/item-model'
import { parseDate, parseDateToTimestamp } from '@app/utils/date'

interface Response {
  remappedItems: Array<ProcurementEditItemPopupDefaultItemData>
  rawItems: Array<Api_Item>
}

export async function getEditItemPopupItems(
  ids: Array<string>,
): Promise<Response> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/items?${createMultipleParam(ids, 'id')}`,
    )
    .then(res => {
      const rawItems: Array<Api_Item> = res.data.items
      return {
        remappedItems: rawItems.map(remapToEditItemPopupItemData),
        rawItems,
      }
    })
}

export function remapToEditItemPopupItemData(
  data: Api_Item,
): ProcurementEditItemPopupDefaultItemData {
  return {
    id: data.id,
    invoiceTimestamp: parseDateToTimestamp(data.deal_approved_date),
    deadlineTimestamp: parseDateToTimestamp(data.deadline_date),
    clientName: data.client_name,
    responsibleSales:
      assert(data.responsible_sales, 'responsible_sales not found')?.name || '',
    responsibleProcurement: data.procurement,
    amoId: data.deal_id,
    clientPrice: data.final_price,
    partnerInterest: data.partners_interest,
    partnerInvoice: data.partner_invoice,
    extraChargeAmount: data.extra_charge_amount,
    extraChargePercent: data.extra_charge_percent,
    invoiceName: data.name,
    esdFix: parseDate(data.esd_fix_date),
    originalName: data.original_name,
    supposedStore: data.store,
    sample: data.sample,
    tswName: data.tsw_name,
    brand: data.brand,
    procurementDeadlineTimestamp: parseDateToTimestamp(data.estimated_order_date),
    country: data.country,
    catalogueId: data.catalogue_id,
    quantity: data.quantity,
    temperature: data.provider_temp,
    procurementComment: data.procurement_comment,
    provider: data.provider,
    status: data.procurement_status,
    procurementDlComment: data.procurement_dl_comments,
    priority: data.priority,
    cost: data.cost,
    estimatedOrderDate: parseDate(data.eod),
    hsCodeChina: data.hs_code_china,
    exportDocuments: data.export_documents,
  }
}
