import {checkNever, logError} from '@appscience/utils'
import {Store} from '@reatom/core'
import {getApi} from '../../../../api'
import {pageLoadingAtom} from '../../../../atoms/page-loading-atom'
import {updateBoxNumberAtom} from '../../../../atoms/static-data/box-number-colors-atom'
import {updateCurrenciesAtom} from '../../../../atoms/static-data/currencies-atom'
import {updateTemperaturesAtom} from '../../../../atoms/static-data/temperatures-atom'
import {updateTransportersAtom} from '../../../../atoms/static-data/transporters-atom'
import {declareAloneAction} from '../../../../hooks/use-alone-action'
import {defaultError} from '@app/utils/errors'
import {selectedWarehousesAtom} from '../../common/selected-warehouses-atom'
import {archiveItemsAtom, archivePackagesAtom} from '../archive/model/atoms'
import {
  readyToShipItemsAtom,
  readyToShipItemsTableSelectedIds,
  readyToShipItemsTableSelectedPackageId,
  readyToShipPackagesAtom,
  readyToShipPackagesTableSelectedId,
} from '../ready-to-ship/model/atoms'
import {
  shippedItemsAtom,
  shippedItemsTableSelectedIds,
  shippedItemsTableSelectedPackageId,
  shippedPackagesAtom,
  shippedPackagesTableSelectedId,
} from '../shipped/model/atoms'
import {FromWarehouseStatusTab, FromWarehouseTab} from './atoms'
import { updateSdExpsAtom } from 'src/atoms/static-data/sd-exps-atom'

interface Payload {
  tab: FromWarehouseTab
  status: FromWarehouseStatusTab
  backgroundMode?: boolean
}

export const loadFromWarehouseData = declareAloneAction<Payload, Promise<void>>(
  async ({store, payload: {status, tab, backgroundMode = false}}) => {
    try {
      !backgroundMode && store.dispatch(pageLoadingAtom.set('loading'))
      const selectedWarehouses = selectedWarehousesAtom.getState()
      if (selectedWarehouses.length === 0) {
        logError('Unexpected nullable "selectedWarehouse" value')
        store.dispatch(pageLoadingAtom.set('normal'))
        return
      }

      const requestPayload: RequestPayload = {
        warehouseIds: selectedWarehouses.map(x => x.id),
        status,
      }

      switch (tab) {
        case 'items':
          switch (status) {
            case 'readyToShip':
              await loadReadyToShipItems(store, requestPayload)
              break
            case 'shipped':
              await loadShippedItems(store, requestPayload)
              break
            case 'archive':
              await loadArchiveItems(store, requestPayload)
              break
          }
          break
        case 'packages':
          switch (status) {
            case 'readyToShip':
              await loadReadyToShipPackages(store, requestPayload)
              break
            case 'shipped':
              await loadShippedPackages(store, requestPayload)
              break
            case 'archive':
              await loadArchivePackages(store, requestPayload)
              break
          }
          break
        default:
          checkNever(tab, `Unknown tab: "${tab}"`)
      }

      store.dispatch(pageLoadingAtom.set('normal'))
    }
    catch (error) {
      defaultError(error as object)
      logError('Не удалось получить данные для From Warehouse')
    }
  },
)

interface RequestPayload {
  warehouseIds: Array<string>
  status: FromWarehouseStatusTab
}

async function loadReadyToShipItems(store: Store, payload: RequestPayload) {
  const api = getApi()
  const packageId =
    readyToShipItemsTableSelectedPackageId.getState() || undefined
  const [items] = await Promise.all([
    api.provider.fromWarehouse.getReadyToShipItems({
      ...payload,
      packageId,
    }),
    updateCurrenciesAtom(store),
    updateTemperaturesAtom(store),
    updateBoxNumberAtom(store),
    updateSdExpsAtom(store),
  ])
  store.dispatch(readyToShipItemsTableSelectedIds.set({}))
  store.dispatch(readyToShipItemsAtom.set(items))
}

async function loadReadyToShipPackages(store: Store, payload: RequestPayload) {
  const api = getApi()
  const [items] = await Promise.all([
    api.provider.fromWarehouse.getReadyToShipPackages(payload),
    updateCurrenciesAtom(store),
    updateTemperaturesAtom(store),
    updateBoxNumberAtom(store),
  ])
  store.dispatch(readyToShipPackagesTableSelectedId.set({}))
  store.dispatch(readyToShipPackagesAtom.set(items))
}

async function loadShippedItems(store: Store, payload: RequestPayload) {
  const api = getApi()
  const packageId = shippedItemsTableSelectedPackageId.getState() || undefined
  const [items] = await Promise.all([
    api.provider.fromWarehouse.getShippedItems({
      ...payload,
      packageId,
    }),
    updateCurrenciesAtom(store),
    updateTemperaturesAtom(store),
    updateBoxNumberAtom(store),
    updateSdExpsAtom(store),
  ])
  store.dispatch(shippedItemsTableSelectedIds.set({}))
  store.dispatch(shippedItemsAtom.set(items))
}

async function loadShippedPackages(store: Store, payload: RequestPayload) {
  const api = getApi()
  const [items] = await Promise.all([
    api.provider.fromWarehouse.getShippedPackages(payload),
    updateCurrenciesAtom(store),
    updateTemperaturesAtom(store),
    updateBoxNumberAtom(store),
  ])
  store.dispatch(shippedPackagesTableSelectedId.set({}))
  store.dispatch(shippedPackagesAtom.set(items))
}

async function loadArchiveItems(store: Store, payload: RequestPayload) {
  const api = getApi()
  const packageId = shippedItemsTableSelectedPackageId.getState() || undefined
  const [items] = await Promise.all([
    api.provider.fromWarehouse.getArchiveItems({
      ...payload,
      packageId,
    }),
    updateCurrenciesAtom(store),
    updateTemperaturesAtom(store),
    updateTransportersAtom(store),
    updateBoxNumberAtom(store),
    updateSdExpsAtom(store),
  ])
  store.dispatch(archiveItemsAtom.set(items))
}

async function loadArchivePackages(store: Store, payload: RequestPayload) {
  const api = getApi()
  const [items] = await Promise.all([
    api.provider.fromWarehouse.getArchivePackages(payload),
    updateCurrenciesAtom(store),
    updateTemperaturesAtom(store),
    updateTransportersAtom(store),
    updateBoxNumberAtom(store),
  ])
  store.dispatch(archivePackagesAtom.set(items))
}
