import { API_URL } from 'src/config/environment'
import {ReorderFormState} from '../../views/reorder/reorder-form/reorder'
import apiCreator from '../api-creator'

interface SendReorderDataForRequest {
  item_id: number
  refusal?: {
    row_number_proposal_supply: number
  },
  defect?: {
    row_number_proposal_supply: number
    count: number
  },
  not_refusal?: {
    row_number_proposal_supply: number
  }
}

export function sendReorderForm(form: ReorderFormState): Promise<void> {
  const data: SendReorderDataForRequest = {
    item_id: Number(form.idReorder),
  }

  switch (form.typeReorder) {
    case 'not_refusal':
      data.not_refusal = {
        row_number_proposal_supply: Number(form.numberProposalSupply),

      }
      break
    case 'cancel':
      data.refusal = {
        row_number_proposal_supply: Number(form.numberProposalSupply),
      }
      break
    case 'fault':
      data.defect = {
        row_number_proposal_supply: Number(form.numberProposalSupply),
        count: Number(form.quantity),
      }
      break
  }

  return apiCreator.post(`${API_URL.TWIN}/order/reorder`, data)
}