import { AxiosResponse } from 'axios'
import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'

export type UploadAnaloguesExcelFilesResponseData = {
  analogueWriteCount: number;
  crossReferenceWriteCount: number;
}
export type UploadAnaloguesExcelFilesResponse = AxiosResponse<UploadAnaloguesExcelFilesResponseData>

export async function uploadAnaloguesExcelFiles(
  s3Keys: Array<string>,
  abortSignal: AbortSignal,
): Promise<UploadAnaloguesExcelFilesResponseData> {
  const { job_id: jobId } = await getUploadAnaloguesExcelFilesJobId(
    s3Keys,
    abortSignal,
  )

  return getJobResult({
    jobId,
    abortSignal,
  })
}

type GetUploadAnaloguesExcelFilesJobIdRequest = {
  s3_keys: Array<string>;
}
type GetUploadAnaloguesExcelFilesJobIdResponseData = {
  job_id: string;
}
type GetUploadAnaloguesExcelFilesJobIdResponse = AxiosResponse<GetUploadAnaloguesExcelFilesJobIdResponseData>

async function getUploadAnaloguesExcelFilesJobId(
  s3Keys: Array<string>,
  abortSignal: AbortSignal,
): Promise<GetUploadAnaloguesExcelFilesJobIdResponseData> {
  return apiCreator
    .post<
      GetUploadAnaloguesExcelFilesJobIdResponseData,
      GetUploadAnaloguesExcelFilesJobIdResponse,
      GetUploadAnaloguesExcelFilesJobIdRequest
    >(
      `${API_URL.ANALOGUE}/upload/process`,
      {
        s3_keys: s3Keys,
      },
      {
        signal: abortSignal,
      },
    )
    .then(({ data }) => data)
}

type GetJobResultRequest = {
  jobId: string;
  abortSignal: AbortSignal;
  isPolling?: boolean;
}
enum GetJobResultResponseResultEnum {
  Success = 'success',
  Error = 'error',
  NoData = 'no_data',
}
type GetJobResultResponse = {
  status: GetJobResultResponseResultEnum;
  message: string;
  analogue_write_count: number;
  cross_reference_write_count: number;
}

async function getJobResult({
  jobId,
  abortSignal,
  isPolling = true,
}: GetJobResultRequest): Promise<UploadAnaloguesExcelFilesResponseData> {
  try {
    const axiosResponse = await apiCreator
      .get<GetJobResultResponse>(
        `${API_URL.ANALOGUE}/upload/get-job-result`,
        {
          params: {
            job_id: jobId,
            polling: isPolling,
          },
          signal: abortSignal,
        },
      )

    const responseData: GetJobResultResponse = axiosResponse.data

    if (
      axiosResponse.status === 200
      && responseData?.status === GetJobResultResponseResultEnum.Success
    ) {
      return {
        analogueWriteCount: responseData?.analogue_write_count ?? 0,
        crossReferenceWriteCount: responseData?.cross_reference_write_count ?? 0,
      }
    }

    if (responseData?.status === GetJobResultResponseResultEnum.NoData) {
      return await getJobResult({ jobId, abortSignal, isPolling })
    }

    if (responseData?.status === GetJobResultResponseResultEnum.Error) {
      throw new Error(responseData?.message)
    }

    throw new Error(`Failed long polling with status code ${axiosResponse.status}`)
  }
  catch (error) {
    return Promise.reject(error)
  }
}
