import { AxiosResponse } from 'axios'
import apiCreator from '../../api-creator'
import { User, UserWithId } from './model'
import { API_URL } from 'src/config/environment'

export type AddUserRequest = User
export type AddUserResponseData = UserWithId
export type AddGroupResponse = AxiosResponse<AddUserResponseData>

export async function addUser(user: User): Promise<AddUserResponseData> {
  return apiCreator
    .post<AddUserResponseData, AddGroupResponse, AddUserRequest>(
      `${API_URL.AUTH}/users/add`,
      user,
    )
    .then(({ data }) => data)
}
