import {useAction} from '@reatom/react'
import React, {PropsWithChildren, useEffect, useState} from 'react'
import {Toaster} from 'react-hot-toast'
import {BrowserRouter} from 'react-router-dom'
import {historyAtom} from './atoms/history-atom'
import {DefaultGlobalStyles} from './config/theme/foundations/default-global-styles'
import {
  getMantineTheme,
  isColorScheme,
} from './config/theme'
import {useTypedHistory} from './hooks/route-hooks'
import {OnlineStatusProvider} from './hooks/use-online-status'
import {useLocalStorage} from './hooks/use-local-storage/use-local-storage'
import {ConnectionLostToast} from './page-layouts/connection-lost-toast'
import {isNullable} from '@appscience/utils'
import {ExternalLayer} from './components/layers/external-layer'
import {Views} from './views'
import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
  useMantineTheme,
} from '@mantine/core'
import {ModalProvider} from '@appscience/hooks'
import { NotificationsProvider } from '@mantine/notifications'

export function App() {
  return (
    <React.StrictMode>
      <ProvidersWrapper>
        <ConnectionLostToast />
        <Views />
      </ProvidersWrapper>
    </React.StrictMode>
  )
}

interface IProps {
  children: React.ReactNode
}

const ProvidersWrapper = ({children}: IProps) => {
  const {initScheme} = useInitData()
  const {colors} = useMantineTheme()
  const [colorScheme, setColorScheme] = useState<ColorScheme>(initScheme)
  const {setValueToStorage} = useLocalStorage('common')
  const toggleColorScheme = (value?: ColorScheme) => {
    const newScheme = value || (colorScheme === 'dark' ? 'light' : 'dark')
    setColorScheme(newScheme)
    setValueToStorage('colorScheme', newScheme)
  }

  const toastBgStyle = {
    background: colors.blue[3],
    color: colors.dark[4],
  }

  return (
    <BrowserRouter>
      <OnlineStatusProvider>
        <AtomsInitializationLayer>
          <ColorSchemeProvider
            colorScheme={colorScheme}
            toggleColorScheme={toggleColorScheme}
          >
            <MantineProvider withNormalizeCSS theme={getMantineTheme(colorScheme)}>
              <NotificationsProvider>
                <DefaultGlobalStyles />
                <ModalProvider layer={ExternalLayer}>{children}</ModalProvider>
                <Toaster
                  position='top-center'
                  toastOptions={{
                    success: {style: toastBgStyle},
                    error: {style: toastBgStyle},
                    loading: {style: toastBgStyle},
                  }}
                />
              </NotificationsProvider>
            </MantineProvider>
          </ColorSchemeProvider>
        </AtomsInitializationLayer>
      </OnlineStatusProvider>
    </BrowserRouter>
  )
}

function AtomsInitializationLayer(props: PropsWithChildren<object>) {
  const handleInitRouter = useAction(historyAtom.set)
  const history = useTypedHistory()
  useEffect(() => {
    handleInitRouter(history)
  }, [handleInitRouter, history])

  return <>{props.children}</>
}

interface InitData {
  initScheme: ColorScheme
}

function useInitData(): InitData {
  const {getStorageValue} = useLocalStorage('common')
  const colorScheme = getStorageValue('colorScheme')
  return {
    initScheme:
      !isNullable(colorScheme) && isColorScheme(colorScheme)
        ? colorScheme
        : 'light',
  }
}
