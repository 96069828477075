import { createAtom } from '@reatom/core'
import { createPrimitiveAtom } from '@reatom/core/primitives'
import { getApi } from 'src/api'
import { PermissionGroupObjectTypeEnum, UserPermissions } from 'src/api/iam/groups/model'
import { UserWithId } from 'src/api/iam/users/model'
import { defaultError } from '@app/utils/errors'
import { errorMessage } from '@app/utils/feedback'
import { logError } from '@appscience/utils'
import { historyAtom } from 'src/atoms/history-atom'
import { AppRoute } from 'src/routes/types'
import { UserType } from 'src/views/iam/common/types'
import { getRoleByEmail, userAtom } from 'src/atoms/user-atom'
import { AUTH_TOKEN } from 'src/config/constants'
import { getAllowedRoutesSet } from './allowed-routes'
import { getAllowedObjectIdsSet } from './allowed-objects'
import { getAllowedUserTypesForUserCreationSet } from './allowed-user-types-for-user-creation'

export type PermissionAtomFields = {
  userWithId: UserWithId | null;
  userPermissions: UserPermissions | null;
  allowedRoutesSet: Set<AppRoute>;
  allowedWarehouseIdsSet: Set<string>;
  allowedProcurementIdsSet: Set<string>;
  allowedUserTypesForUserCreationSet: Set<UserType>;
}

function getInitState(): PermissionAtomFields {
  return {
    userWithId: null,
    userPermissions: null,
    allowedRoutesSet: new Set([]),
    allowedWarehouseIdsSet: new Set([]),
    allowedProcurementIdsSet: new Set([]),
    allowedUserTypesForUserCreationSet: new Set([]),
  }
}

export const permissionsLoadingStateAtom = createPrimitiveAtom<DefaultLoadingState>('loading')

export const permissionAtom = createAtom(
  {
    setPermissions: (value: PermissionAtomFields) => value,
    loadPermissions: () => { },
    resetPermissions: () => { },
  },
  ({ onAction, schedule }, state = getInitState()) => {
    onAction('setPermissions', value => (state = value))

    onAction('loadPermissions', () => {
      schedule(async dispatch => {
        try {
          dispatch([
            permissionsLoadingStateAtom.set('loading'),
            permissionAtom.resetPermissions(),
          ])
          const api = getApi()

          const { id: userId, email }: UserWithId = await api.auth.check()
          const [
            userPermissions,
            userWithId,
          ] = await Promise.all([
            api.groups.getUserPermissions(),
            api.users.getUserById(userId),
          ])
          const userRole = getRoleByEmail(email) // TODO change to permissions

          dispatch([
            userAtom.set({
              id: userId,
              email,
              role: userRole,
            }),
            permissionAtom.setPermissions({
              userPermissions,
              userWithId,
              allowedRoutesSet: getAllowedRoutesSet(
                userPermissions,
                userWithId,
                userRole,
              ),
              allowedWarehouseIdsSet: getAllowedObjectIdsSet(
                userPermissions,
                userWithId,
                PermissionGroupObjectTypeEnum.Warehouse,
              ),
              allowedProcurementIdsSet: getAllowedObjectIdsSet(
                userPermissions,
                userWithId,
                PermissionGroupObjectTypeEnum.Procurement,
              ),
              allowedUserTypesForUserCreationSet: getAllowedUserTypesForUserCreationSet(
                userPermissions,
                userWithId,
              ),
            }),
            permissionsLoadingStateAtom.set('normal'),
          ])
        } catch (e) {
          errorMessage('Не удалось получить права пользователя')
          defaultError(e as object)
          logError('Не удалось получить права пользователя')

          dispatch([
            permissionAtom.resetPermissions(),
            permissionsLoadingStateAtom.set('normal'),
          ])
          localStorage.removeItem(AUTH_TOKEN)

          const redirect = `${location.pathname}${location.search}`
          const authUrl = redirect
            ? `/auth/login?redirect=${redirect}`
            : `/auth/login`
          const history = historyAtom.getState()
          history.push(authUrl)
        }
      })
    })

    onAction('resetPermissions', () => (state = getInitState()))

    return state
  },
)
