import apiCreator from 'src/api/api-creator'
import { AxiosResponse } from 'axios'

export type UploadRequest = FormData
export type UploadScifinderExcelFileResponseData = void
export type UploadScifinderExcelFileResponse = AxiosResponse<UploadScifinderExcelFileResponseData>

export async function uploadFileToS3(
  file: File,
  presignedS3UploadLink: string,
  abortSignal: AbortSignal,
): Promise<UploadScifinderExcelFileResponseData> {
  return apiCreator
    .put<
      UploadScifinderExcelFileResponseData,
      UploadScifinderExcelFileResponse,
      File
    >(
      presignedS3UploadLink,
      file,
      {
        signal: abortSignal,
      },
    )
    .then(({ data }) => data)
}
