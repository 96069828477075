import {createPrimitiveAtom} from '@reatom/core/primitives'

export type PackingListGeneratorViewItem = PackingListGeneratorItem

export interface PackingListGeneratorItem {
  id: string
  clientName: string
  registrationDate: Date | null
  plannedDepartureDate: Date | null
  amoId: string
  billNumber: string
  billPosition: number
  invoiceName: string
  sumPrice: number
  brand: string
  catalogueId: string
  quantity: number
  lot: string
  temperature: string
  tswName: string
  currencyId: string
  seller: string
}

export const packingListGeneratorAtoms = {
  selectedRowIds: createPrimitiveAtom<Array<string>>([]),
  items: createPrimitiveAtom<Array<PackingListGeneratorItem>>([]),
}
