import React, {PropsWithChildren, useCallback, useMemo, useState} from 'react'
import {ModalContext} from './ModalContext'

interface Config<C extends React.FC<any>> {
  show: boolean,
  component: C,
  props: React.ComponentProps<C>,
}

type ConfigState<C extends React.FC<any>> = Record<string, Config<C>>

interface LayerProps {
  show: boolean,
  createContent: () => React.ReactNode,
}

type ModalProviderProps = PropsWithChildren<{
  layer: React.FC<LayerProps>,
}>

export function ModalProvider<C extends React.FC<any>>({
  layer: ExternalLayer,
  children,
}: ModalProviderProps) {
  const [modalsConfig, setConfig] = useState<ConfigState<C>>({})
  const hideModal = useCallback(
    (modalKey: string, onClose?: () => void) => {
      setConfig(prevConfig => ({ ...prevConfig, [modalKey]: { ...prevConfig[modalKey], show: false } }))

      if (onClose) {
        onClose()
      }
    },
    [setConfig],
  )
  const showModal = useCallback(
    (modalKey, component, props) => {
      setConfig(prevConfig => ({ ...prevConfig, [modalKey]: { show: true, component, props } }))
    },
    [setConfig],
  )
  const contextValue = useMemo(
    () => ({
      showModal,
      hideModal,
    }),
    [hideModal, showModal, modalsConfig],
  )

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      {Object.keys(modalsConfig).map(modalKey => {
        const { component: Component, show, props } = modalsConfig[modalKey]

        return <ExternalLayer
          key={modalKey}
          show={show}
          // @ts-ignore
          createContent={() => <Component {...props} />}
        />
      })}
    </ModalContext.Provider>
  )
}