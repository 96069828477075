import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'

interface SplitItemRequestData {
  id: string
  parts: Array<number>
  onProvider?: boolean
}

export function splitProcurementItemQuantity(
  data: SplitItemRequestData,
): Promise<void> {
  return apiCreator
    .post(
      `${API_URL.TWIN}/split-item`,
      {
        id: data.id,
        parts: data.parts,
        on_provider: data.onProvider ?? false,
      },
    )
}
