import {verify} from '@appscience/utils'
import {format} from 'date-fns'
import { API_URL } from 'src/config/environment'
import {STRING_DATE_FORMAT} from '../../config/constants'
import {CreatePackageFormState} from '../../views/warehouse/create-package/content/form/use-form'
import apiCreator from '../api-creator'

interface PackageData {
  id: string | null
  package_date: string
  items_quantity: number
  temperature: Array<string>
  box_numbers: Array<string>
  hazmat: boolean
  currency?: string
  package_sum: number
  package_invoice: string
  provider_comments: string
  total_gross_weight_kg: number
  invoice_number_bionify: string
}

interface ShipmentData {
  from_warehouse_id: string
  to_warehouse_id: string
  planned_departure_date: string
  tag: string
  transporter: string
  track_number: Array<string>
  departure_date: string
}

interface ItemDataRequest {
  id: string
  collected_in_package: boolean
  provider_temp: string
  provider_hazmat_type: string
  provider_comment: string
  provider_export_statuses: Array<string>
  box_numbers: Array<string>
  net_weight_kg: number
  gross_weight_kg: number
}

export interface CreatePackageItemDataForm {
  id: string
  isCollectedInPackage: boolean
  temperature: string
  hazmatComment: string
  coefInvoicePrice: number
  exportStatuses: Array<string>
  exportComment: string
  boxNumber: Array<string>
  netWeight: number
  toHub0: Date | null
  trackHub0: string
  tswed: Date | null
  hub0: string
  grossWeight: number
  providerHazmatType: string
}

export interface ProviderCreatePackageRequestData {
  package: PackageData
  shipment: ShipmentData
  items: Array<ItemDataRequest>
}

interface Params extends CreatePackageFormState {
  packageId?: string //Если есть id - то редактируем, иначе создаём
  items: Array<CreatePackageItemDataForm>
}

export function createProviderPackage(data: Params): Promise<void> {
  const itemsData: Array<ItemDataRequest> = data.items.map(item => ({
    id: item.id,
    collected_in_package: item.isCollectedInPackage,
    provider_hazmat_type: item.providerHazmatType,
    sent_to_hub_0: item.toHub0 ? format(item.toHub0, STRING_DATE_FORMAT).slice(0, 10) : '',
    hub_0: item.hub0,
    track_to_hub_0: item.trackHub0,
    registration_date: item.tswed ? format(item.tswed, STRING_DATE_FORMAT).slice(0, 10) : '',
    provider_hazmat: item.hazmatComment,
    provider_temp: item.temperature,
    provider_comment: item.exportComment,
    provider_export_statuses: item.exportStatuses,
    box_numbers: item.boxNumber,
    gross_weight_kg: item.grossWeight,
    net_weight_kg: item.netWeight,
  }))

  const payload: ProviderCreatePackageRequestData = {
    package: {
      id: data.packageId || null,
      package_date: data.packingDate
        ? format(data.packingDate, STRING_DATE_FORMAT)
        : '',
      items_quantity: data.items.length,
      temperature: data.temperatures,
      hazmat: data.items.some(item => !!item.hazmatComment),
      package_sum: data.items.reduce((result, item) => result + item.coefInvoicePrice, 0),
      package_invoice: data.packageInvoice,
      provider_comments: data.packageComment,
      box_numbers: data.boxNumber,
      total_gross_weight_kg: data.totalGrossWeight,
      invoice_number_bionify: data.invoiceNumberBionify,
    },
    shipment: {
      from_warehouse_id: verify(
        data.fromWarehouseId,
        `FromWarehouseId is not valid. Value: ${data.fromWarehouseId}`,
      ),
      to_warehouse_id: verify(
        data.toWarehouseId,
        `ToWarehouseId is not valid. Value: ${data.toWarehouseId}`,
      ),
      departure_date: data.departureDate
        ? format(data.departureDate, STRING_DATE_FORMAT)
        : '',
      planned_departure_date: '',
      tag: data.tag,
      transporter: data.transporterId,
      track_number: data.trackIds,
    },
    items: itemsData,
  }

  return apiCreator.post(`${API_URL.TWIN}/save-package`, payload)
}
