import { userAtom } from 'src/atoms/user-atom'
import { CreateOrderPageOrderFields } from '../../create-order-page-order-fields.type'
import { CreateOrderPageValidationOrderField, getCreateOrderPageRequiredOrderFields } from '../../errors/errors-atom'
import { CreateOrderPageRequiredItemField, validateCreateOrderPageItems } from '../../errors/item-fields-validation'
import { IndexedCreateOrderPageItem } from '../../items/items.atom.type'
import { filterObject } from '@appscience/utils'
import { isFC } from 'src/views/iam/common/utils'
import { isUnderTrackingStatus, isWaitingPrepaymentStatus } from 'src/types/order-status'
import { isEmpty, keys } from 'ramda'

interface ValidateReturnData {
  orderFields: Set<CreateOrderPageValidationOrderField>
  items: Map<string, Set<CreateOrderPageRequiredItemField | 'costLimit'>>
}

interface ValidateDataParams {
  items: Array<IndexedCreateOrderPageItem>
  orderFields: CreateOrderPageOrderFields
  isSumLimitExceeded: boolean
}

export function validateOrderPopupData({
  items,
  isSumLimitExceeded,
  orderFields,
}: ValidateDataParams): Omit<ValidateReturnData, 'validationEnabled'> | null {
  const requiredOrderFields: Set<keyof CreateOrderPageOrderFields> = new Set(
    getCreateOrderPageRequiredOrderFields(orderFields, isSumLimitExceeded),
  )
  const { role } = userAtom.getState()
  const invalidFields = filterObject(
    orderFields,
    (v, k) => requiredOrderFields
      .has(k) && !v && v !== 0,
  )
  const itemsErrors: Map<
    string,
    Set<CreateOrderPageRequiredItemField>
  > =
    (isUnderTrackingStatus(orderFields.orderStatusId) && !isFC(role))
      || isWaitingPrepaymentStatus(orderFields.orderStatusId)
      ? validateCreateOrderPageItems({
        items,
        currencyId: orderFields.currencyId,
        orderStatus: orderFields.orderStatusId,
      })
      : new Map()

  if ((isEmpty(invalidFields) && itemsErrors.size === 0)) {
    return null
  }

  return {
    orderFields: new Set(
      keys(invalidFields),
    ) as Set<CreateOrderPageValidationOrderField>,
    items: itemsErrors,
  }
}
