import {defaultError} from '@app/utils/errors'
import {successMessage} from '@app/utils/feedback'
import {Button} from '@mantine/core'
import {useState} from 'react'
import {getApi} from '../../../../../api'
import {warehousesAtom} from '../../../../../atoms/static-data/warehouses-atom'
import {useAloneAction} from '../../../../../hooks/use-alone-action'
import {useAtomState} from '../../../../../hooks/use-atom-state'
import {i18n_get} from '../../../../../lng/i18n'
import {readyToShipPackagesAtom} from '../../ready-to-ship/model/atoms'
import {loadFromWarehouseData} from '../load-data'

interface Props {
  packageId: string
}

export function ReadyToShipsPackagesLayoutActions({packageId}: Props) {
  const packages = useAtomState(readyToShipPackagesAtom)
  const warehouses = useAtomState(warehousesAtom)
  const fromWarehouseId = packages[packageId].fromWarehouseId
  const fromWarehouse = warehouses[fromWarehouseId]
  const [loadingButton, setLoadingButton] = useState<
    'returnToWarehouse' | 'cancelRegister' | null
  >(null)
  const handleLoadData = useAloneAction(loadFromWarehouseData)
  const api = getApi().provider.fromWarehouse

  const onCancelRegister = () => {
    setLoadingButton('cancelRegister')
    api
      .cancelRegisterPackage({packageId})
      .then(() => {
        successMessage(
          i18n_get.t('Provider.FromWarehouse.CancelRegister.SuccessMessage'),
        )
        return handleLoadData({
          tab: 'packages',
          status: 'readyToShip',
          backgroundMode: true,
        })
      })
      .catch(defaultError)
      .finally(() => setLoadingButton(null))
  }

  const onReturnToWarehouse = () => {
    setLoadingButton('returnToWarehouse')
    api
      .returnToWarehouse({
        type: 'package',
        packageId,
      })
      .then(() => {
        successMessage(
          i18n_get.t('Provider.FromWarehouse.ReturnToWarehouse.SuccessMessage'),
        )
        return handleLoadData({
          tab: 'packages',
          status: 'readyToShip',
          backgroundMode: true,
        })
      })
      .catch(defaultError)
      .finally(() => setLoadingButton(null))
  }

  return (
    <div>
      <Button
        disabled={loadingButton !== null}
        loading={loadingButton === 'returnToWarehouse'}
        onClick={onReturnToWarehouse}
      >
        {i18n_get.t('Provider.FromWarehouse.ReturnToTheWarehouse')}
      </Button>
      {fromWarehouse.type === 'hub' && (
        <Button
          disabled={loadingButton !== null}
          loading={loadingButton === 'cancelRegister'}
          onClick={onCancelRegister}
          className='ml-2'
        >
          {i18n_get.t('Common.CancelRegister')}
        </Button>
      )}
    </div>
  )
}
