import {ExportDocumentsData} from '../../../atoms/static-data/export-documents-atom'
import { API_URL } from 'src/config/environment'
import apiCreator from '../../api-creator'
import {handleErrorWithDefaultValue} from '../utils'

export function getExportDocumentsApi(): Promise<Array<ExportDocumentsData>> {
  return apiCreator
    .get(`${API_URL.TWIN}/export-documents`)
    .then(res => {
      const rawItems: Array<string> = res.data.export_documents
      return rawItems.map(remapToExportDocuments)
    })
    .catch(handleErrorWithDefaultValue([]))
}

export function remapToExportDocuments(item: string): ExportDocumentsData {
  return {
    id: item,
    title: item,
  }
}