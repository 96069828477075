export enum InterfaceIdsEnum {
  Procurement = 'procurement',
  Provider = 'provider',
}

export enum InterfaceSectionIdsEnum {
  NewOrder = 'new_order',
  Orders = 'orders',
  ToWarehouse = 'to_warehouse',
  InWarehouse = 'in_warehouse',
  FromWarehouse = 'from_warehouse',
}

export enum PermissionGroupObjectTypeEnum {
  Warehouse = 'warehouse',
  Procurement = 'procurement',
}

export type PermissionGroupInterfaceSection = {
  id: InterfaceSectionIdsEnum;
  name: string;
}

export type PermissionGroupInterface = {
  id: InterfaceIdsEnum;
  name: string;
  sections: Array<PermissionGroupInterfaceSection>;
}

export interface PermissionGroupObject {
  id: string;
  interface_ids: Array<InterfaceIdsEnum>;
  name: string;
  type: PermissionGroupObjectTypeEnum;
}

export interface UserPermissions {
  interfaces: Array<PermissionGroupInterface>;
  objects: Array<PermissionGroupObject>;
}

export interface PermissionGroup extends UserPermissions {
  name: string;
}

export interface PermissionGroupWithId extends PermissionGroup {
  id: string
}
