import apiCreator from 'src/api/api-creator'
import { API_URL } from 'src/config/environment'
import { handleErrorWithDefaultValue } from '../utils'


export const getComplianceStatuses = (): Promise<string[]> =>
  apiCreator
    .get(
      `${API_URL.TWIN}/compliance`,
    )
    .then(res => {
      const statuses: string[] = res.data.compliance
      return statuses
    })
    .catch(handleErrorWithDefaultValue([]))


