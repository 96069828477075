import { Store } from '@reatom/core'
import { userAtom } from 'src/atoms/user-atom'
import { INITIAL_LAZY_LOAD_PAGINATION, ServerTableSettings } from 'src/components/table/utils/settings-atom'
import { preordersItemsSettingsAtom } from '../settings'
import { PreordersItem, preordersItemsAtom, preordersItemsTableSelecteInvoiceAtom, preordersItemsTableSelectedOrderIdAtom, preordersItemsTableSelectedRowsAtom } from '../main'
import { getApi } from 'src/api'
import { isFC } from 'src/views/iam/common/utils'
import { RowSelectionState } from '@tanstack/react-table'
import { getPreordersItemsRequestSettings } from './load-items.utils'

export async function loadItems({
  store,
  settings,
  isNewLazyLoading,
}: {
  store: Store;
  settings: ServerTableSettings;
  isNewLazyLoading: boolean;
}): Promise<void> {
  if (isNewLazyLoading) {
    store.dispatch(preordersItemsSettingsAtom.areAllRowsLoaded.set(false))
    store.dispatch(preordersItemsAtom.set([]))
    store.dispatch(preordersItemsSettingsAtom.startKey.set({}))
    store.dispatch(preordersItemsSettingsAtom.paginationState.set(INITIAL_LAZY_LOAD_PAGINATION))
  }

  const role = userAtom.getState().role
  const filteredInvoiceNumber = preordersItemsTableSelecteInvoiceAtom.getState()
  const filteredOrderId = preordersItemsTableSelectedOrderIdAtom.getState()
  if (isFC(role) && filteredInvoiceNumber) {
    const items = await getApi().procurement.getPreordersItemsByInvoice(filteredInvoiceNumber)
    store.dispatch(preordersItemsAtom.set(items))
    return
  } else if (filteredOrderId) {
    const items = await getApi().procurement.getPreordersItemsByOrder(filteredOrderId)
    store.dispatch(preordersItemsAtom.set(items))
    return
  }

  const requestSettings = getPreordersItemsRequestSettings(settings)

  console.time('[Orders/Items] data')
  const {
    items: newItems,
    startKey: newStartKey,
  } = await getApi().procurement.getPreordersItems({
    settings: requestSettings,
  })
  console.timeEnd('[Orders/Items] data')

  if (newItems.length < settings.paginationState.pageSize) {
    store.dispatch(preordersItemsSettingsAtom.areAllRowsLoaded.set(true))

    if (!newItems.length) {
      return
    }
  }

  const previousItems: Array<PreordersItem> = preordersItemsAtom.getState()
  const items = previousItems.concat(newItems)
  const selectedKeysSet: Set<string> = new Set(
    Object.keys(preordersItemsTableSelectedRowsAtom.getState()),
  )
  const selectedRows = items
    .filter(x => selectedKeysSet.has(x.id))
    .reduce((acc: RowSelectionState, x) => {
      acc[x.id] = true
      return acc
    }, {})

  store.dispatch(preordersItemsSettingsAtom.startKey.set(newStartKey))
  store.dispatch(preordersItemsTableSelectedRowsAtom.set(selectedRows))

  console.time('[Orders/Items] ui')
  store.dispatch(preordersItemsAtom.set(items))
  console.timeEnd('[Orders/Items] ui')

  store.dispatch(preordersItemsSettingsAtom.paginationState.set(settings.paginationState))
}
