import { createAtom } from '@reatom/core'
import produce from 'immer'
import { isDef } from '@appscience/utils'
import { validators } from '@app/utils/errors'
import { validationEnabledAtom } from './validation-enabled-atom'
import { OrderStatusId, isUnderTrackingStatus, isWaitingPrepaymentStatus } from 'src/types/order-status'
import { userAtom } from 'src/atoms/user-atom'
import { IndexedCreateOrderPageItem } from '../items/items.atom.type'

export type CreateOrderPageRequiredItemField = Extract<
  keyof IndexedCreateOrderPageItem,
  | 'catalogue'
  | 'originalName'
  | 'cost'
  | 'discountComment'
  | 'shipmentTimestamp'
  | 'esdFixOriginal'
  | 'brand'
  | 'invoicePrice'
>

type CreateOrderPageItemError = keyof IndexedCreateOrderPageItem | 'costLimit'

export type CreateOrderPageItemErrorsState = Map<
  string,
  Set<CreateOrderPageItemError>
>

export function getCreateOrderPageInitItemErrors(): CreateOrderPageItemErrorsState {
  return new Map()
}

export const createOrderPageItemErrorsAtom = createAtom(
  {
    init: (v: CreateOrderPageItemErrorsState) => v,
    reset: (itemId?: string, field?: keyof IndexedCreateOrderPageItem) => ({
      itemId,
      field,
    }),
    checkField: <K extends keyof IndexedCreateOrderPageItem>(
      itemId: string,
      field: K,
      value: IndexedCreateOrderPageItem[K],
    ) => ({ itemId, field, value }),
  },
  ({ onAction }, state = getCreateOrderPageInitItemErrors()) => {
    onAction('init', value => (state = value))
    onAction('checkField', ({ itemId, field, value }) => {
      const validationEnabled = validationEnabledAtom.getState()
      if (!validationEnabled) {
        return
      }
      state = produce(state, draft => {
        const validate = validateFns[field]
        if (!isDef(validate)) {
          return
        }
        const errors = draft.get(itemId)
        if (validate(value)) {
          errors?.delete(field)
        } else if (errors) {
          errors.add(field)
        } else {
          draft.set(itemId, new Set([field]))
        }
      })
    })
    onAction('reset', ({ itemId, field }) => {
      state = itemId
        ? produce(state, draft => {
          if (field) {
            draft.get(itemId)?.delete(field)
          } else {
            draft.delete(itemId)
          }
        })
        : getCreateOrderPageInitItemErrors()
    })
    return state
  },
)

//-------------------------------------------------------------------------------------------------------------

const validateFns: TypedObject<
  keyof IndexedCreateOrderPageItem,
  (v: AnyType) => boolean
> = {
  catalogue: validators.string,
  originalName: validators.string,
  cost: validators.number,
  shipmentTimestamp: validators.truly,
  brand: validators.string,
  invoicePrice: validators.number,
}

export function getCreateOrderPageRequiredItemFields(
  isFreeItem = false,
  orderStatus?: OrderStatusId | undefined): Array<CreateOrderPageRequiredItemField> {
  const shouldValidate = orderStatus && (isUnderTrackingStatus(orderStatus) || isWaitingPrepaymentStatus(orderStatus))
  const { role } = userAtom.getState()

  const requiredFields: CreateOrderPageRequiredItemField[] = [
    'brand',
    'catalogue',
    'originalName',
    isFreeItem ? 'invoicePrice' : 'cost',
    'shipmentTimestamp',
    'discountComment',
  ]
  if ((shouldValidate && role !== 'finance-control')) {
    requiredFields.push('esdFixOriginal')
  }

  return requiredFields
}

interface ValidateParams {
  items: Array<IndexedCreateOrderPageItem>
  currencyId: string | null
  orderStatus: OrderStatusId
}

export function validateCreateOrderPageItems({
  items,
  orderStatus,
}: ValidateParams): CreateOrderPageItemErrorsState {
  const errorsMap: CreateOrderPageItemErrorsState = new Map()
  items.forEach(item => {
    const itemCostValue = item.isFree ? item.invoicePrice : item.cost
    const itemCostKey = item.isFree ? 'invoicePrice' : 'cost'

    const requiredFields = getCreateOrderPageRequiredItemFields(item.isFree, orderStatus)
    const errorsSet: Set<CreateOrderPageItemError> = new Set(
      requiredFields.filter(key =>
        key === itemCostKey
          ? !itemCostValue
          : !item[key],
      ))
    errorsSet.size > 0 && errorsMap.set(item.id, errorsSet)
  })
  return errorsMap
}
