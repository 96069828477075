import { ServerTableSettings } from 'src/components/table/utils/settings-atom'
import { preordersItemsSettingsAtom } from '../settings'
import { storeIdsFilterAtom } from '../main'
import { defaultStoresAtom } from 'src/atoms/static-data/stores-atom'
import { permissionAtom } from 'src/hooks/permissions/permission-atom'

function hasColumnFilterValue(
  settings: ServerTableSettings,
  filterId: string,
): boolean {
  const columnFilter = settings.filtrationState
    .find(filter => filter.id === filterId)
  return Boolean(columnFilter)
    && Array.isArray(columnFilter?.value)
    && (columnFilter?.value as Array<AnyType>)?.length > 0
}

export function getPreordersItemsRequestSettings(
  settings: ServerTableSettings,
): ServerTableSettings {
  const storeFilterIds = storeIdsFilterAtom.getState()
  const defaultStores = defaultStoresAtom.getState()
  const previousStartKey = preordersItemsSettingsAtom.startKey.getState()

  const hasProcurementFilter = hasColumnFilterValue(settings, 'procurement')
  const hasProviderFilter = hasColumnFilterValue(settings, 'provider')
  const { allowedWarehouseIdsSet, allowedProcurementIdsSet } = permissionAtom.getState()
  const newFiltrationState = settings.filtrationState
    .concat([
      ...(hasProcurementFilter
        ? []
        : [{
          id: 'procurement',
          value: [...allowedProcurementIdsSet],
        }]),
      ...(hasProviderFilter
        ? []
        : [{
          id: 'provider',
          value: [...allowedWarehouseIdsSet],
        }]),
      ...(storeFilterIds.length > 0
        ? [{
          id: 'store',
          value: storeFilterIds.flatMap(id => [
            id,
            ...(defaultStores[id]?.alternativeNames || []),
          ]),
        }]
        : []),
    ])

  return {
    ...settings,
    paginationState: {
      ...settings.paginationState,
      pageIndex: settings.paginationState.pageIndex + 1,
    },
    filtrationState: newFiltrationState,
    startKey: previousStartKey,
  }
}
