import {verify} from '@appscience/utils'
import {Store} from '@reatom/core'
import {createPrimitiveAtom} from '@reatom/core/primitives'
import {getTime, hoursToMilliseconds} from 'date-fns'
import {indexBy} from 'ramda'
import {getApi} from '../../api'
import {OrderStatusId} from '../../types/order-status'

let lastInitTimestamp: number | null

export const orderStatusesAtom = createPrimitiveAtom<
  Record<string, TitleWithId<OrderStatusId>>
>({})

export async function updateOrderStatusesAtom({dispatch}: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }
  const data = await getApi().listsApi.getOrderStatuses()
  const record = indexBy(x => x.id, data)
  dispatch(orderStatusesAtom.set(record))
  lastInitTimestamp = getTime(new Date())
  return record
}

export function getOrderStatusTitle(id: OrderStatusId) {
  return (
    verify(
      orderStatusesAtom.getState()[id],
      `OrderStatusesAtom error: status with id "${id}" not found`,
    )?.title || ''
  )
}
