import { API_URL } from 'src/config/environment'
import apiCreator from '../../api-creator'
import { handleErrorWithDefaultValue } from '../utils'
import { PaymentPercentagesData } from 'src/atoms/static-data/payment-percentages-atom'

type ResponsePaymentsPercentagesData = {
  name: string
  color: string
}

export function getPaymentPercentages(): Promise<Array<PaymentPercentagesData>> {
  return apiCreator
    .get(`${API_URL.TWIN}/payment-percentages`)
    .then(res => {
      const rawItems: Array<ResponsePaymentsPercentagesData> = res.data.payment_percentages
      return rawItems.map(remapToPaymentPercentages)
    })
    .catch(handleErrorWithDefaultValue([]))
}

function remapToPaymentPercentages(data: ResponsePaymentsPercentagesData): PaymentPercentagesData {
  return {
    id: data.name,
    title: data.name,
    color: data.color || '#ececec',
  }
}
