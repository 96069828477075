import { API_URL } from 'src/config/environment'
import { isNumber, logError } from '@appscience/utils'
import { PreordersItem } from '../../views/procurement/pre-orders-view/model/main'
import apiCreator from '../api-creator'
import { Api_Item } from '../common/item-model'
import {
  SSTRequest,
  SSTResponse,
  remapSettingsToSSTRequest,
} from '../common/server-side-params'
import { handleErrorWithDefaultValue } from '../common/utils'
import { ServerTableSettings } from '../../components/table/utils/settings-atom'
import { PaymentStatusEnum } from 'src/types/payment-status'
import { parseDate } from '@app/utils/date'

interface RequestBody {
  step: 'order-created'
  generic_query: SSTRequest
}

interface Response {
  items: Array<PreordersItem>
  startKey: object
  page: number
}

interface Params {
  settings: ServerTableSettings
}

export function getPreordersItems({ settings }: Params): Promise<Response> {
  const body: RequestBody = {
    step: 'order-created',
    // @ts-ignore TODO:WMLN-2623
    generic_query: remapSettingsToSSTRequest(settings),
  }

  return apiCreator
    .post<SSTResponse>(`${API_URL.TWIN}/items`, body)
    .then(res => ({
      items: res.data.items.map(remapToPreordersItem),
      startKey: res.data.pagination.start_key || {},
      page: res.data.pagination.page,
    }))
    .catch(
      handleErrorWithDefaultValue({
        items: [],
        startKey: {},
        page: 1,
      }),
    )
}

export function getPreordersItemsByOrder(
  orderId: string,
): Promise<Array<PreordersItem>> {
  return apiCreator
    .get(`${API_URL.TWIN}/get-order?id=${orderId}`)
    .then(res => {
      const rawData: Array<Api_Item> = res.data.items
      return rawData.map(remapToPreordersItem)
    })
    .catch(handleErrorWithDefaultValue([]))
}

export function getPreordersItemsByInvoice(
  invoiceNumber: string | null,
): Promise<Array<PreordersItem>> {
  return apiCreator
    .post(
      `${API_URL.TWIN}/items`,
      {
        generic_query: {},
        invoice_number: [invoiceNumber],
      })
    .then(res => {
      const rawData: Array<Api_Item> = res.data.items
      return rawData.map(remapToPreordersItem)
    })
    .catch(handleErrorWithDefaultValue([]))
}


export function remapToPreordersItem(data: Api_Item): PreordersItem {
  const quantityIsValid = isNumber(data.quantity)
  const quantity = quantityIsValid ? Number(data.quantity) : 0
  if (!quantityIsValid) {
    logError(`Quantity is not a number. Value: ${data.quantity}`)
  }

  return {
    id: data.id,
    orderId: data.order_id,
    deadlineDate: parseDate(data.deadline_date),
    esdDate: parseDate(data.esd_date),
    arrivalDate: parseDate(data.arrival_date),
    amoId: data.deal_id,
    isKit: data.is_kit,
    brand: data.brand,
    overpayment: data.proc_overpayment_rub,
    costSavings: data.cost_saving_rub,
    trackToHub: data.provider_track_to_hub,
    tagToHub: data.provider_tag_to_hub,
    trackToRu: data.provider_track_to_ru,
    tagToRu: data.provider_tag_to_ru,
    invoicePrice: data.invoice_price_main,
    invoicePriceOld: data.invoice_price,
    customPrice: data.custom_price_main,
    customPriceOld: data.custom_price,
    tswed: parseDate(data.registration_date),
    extraChargeAmount: data.extra_charge_amount,
    extraChargePercent: data.extra_charge_percent,
    partnersInterest: data.partners_interest,
    partnerInvoice: data.partner_invoice,
    invoiceName: data.name,
    sdComment: data.sd_comments,
    itemDetails: data.item_details,
    itemParts: data.item_parts,
    reasonOfCancel: data.reason_of_cancel,
    originalName: data.original_name,
    catalogueId: data.catalogue_id,
    quantity: quantity,
    price: data.full_cost,
    procurementComment: data.procurement_comment,
    priority: data.priority || '',
    provider: data.provider || null,
    orderDetails: data.order_details,
    currencyId: data.currency,
    procurement: data.procurement,
    store: data.store,
    orderNumber: data.store_order_number,
    invoiceNumber: data.invoice_number,
    statusId: data.order_status,
    orderDate: parseDate(data.ordered_date),
    paymentMethod: data.payment_method,
    headOfProcurementStatus: data.moderation_head_procurement,
    headOfFinanceStatus: data.moderation_head_finance,
    card: data.card,
    financeControlStatus: data.moderation_finance_control,
    temperature: data.provider_temp,
    dgmStatus: data.dgm_status,
    deliveryMethod: data.method_of_delivery,
    quota: data.quota,
    isTender: data.is_tender,
    providerHazmatType: data.provider_hazmat_type,
    complianceDate: parseDate(data.date_of_compliance),
    estimatedOrderDate: parseDate(data.eod),
    esdFix: parseDate(data.esd_fix_date),
    esdFixOriginal: parseDate(data.esd_fix_original_date),
    paidDate: parseDate(data.supply_paid_date),
    exportDocuments: data.export_documents,
    hsCodeChina: data.hs_code_china,
    orderComment: data.order_comment,
    paymentStatus: data.payment_status as PaymentStatusEnum,
    salesComment: data.sales_comment,
    proposalComment: data.commentary_for_procurement,
    dlOrder: parseDate(data.dl_order),
  }
}

//TODO:WMLN-2160 поддержать апишу
