import {HazmatData} from '../../../atoms/static-data/hazmats-atom'
import { API_URL } from 'src/config/environment'
import apiCreator from '../../api-creator'
import {handleErrorWithDefaultValue} from '../utils'

interface ResponseHazmatData {
  name: string
  color: string
}

export function getHazmatsApi(): Promise<Array<HazmatData>> {
  return apiCreator
    .get(`${API_URL.TWIN}/hazmats`)
    .then(res => {
      const rawItems: Array<ResponseHazmatData> = res.data.hazmat_items
      return rawItems.map(remapToHazmat)
    })
    .catch(handleErrorWithDefaultValue([]))
}

function remapToHazmat(data: ResponseHazmatData): HazmatData {
  return {
    name: data.name,
    color: data.color || '#ececec',
  }
}
