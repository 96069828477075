import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'

type GetPresignedS3UploadLinkResponse = {
  url: string;
}

export async function getPresignedS3UploadLink(
  filename: string,
  abortSignal: AbortSignal,
): Promise<GetPresignedS3UploadLinkResponse> {
  return apiCreator
    .get<GetPresignedS3UploadLinkResponse>(
      `${API_URL.SCIFINDER}/get-upload-url`,
      {
        params: {
          filename,
        },
        signal: abortSignal,
      },
    )
    .then(({ data }) => data)
}
