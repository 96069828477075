import { CreateOrderRequestOrderFields } from 'src/api/procurement/save-order'
import { CreateOrderPageOrderFields } from '../../create-order-page-order-fields.type'
import { filterObj, isDef, verify } from '@appscience/utils'
import { format } from 'date-fns'
import { STRING_DATE_FORMAT } from 'src/config/constants'

export function getVerifiedMapOrderFields(
  orderFields: CreateOrderPageOrderFields,
  validationEnabled: boolean,
): CreateOrderRequestOrderFields {
  if (validationEnabled) {
    const obj: CreateOrderRequestOrderFields = {
      store: verify(orderFields.storeId, getMessage('storeId')),
      payment_deadline: orderFields.paymentDeadline 
        ? format(verify(orderFields.paymentDeadline, getMessage('paymentDeadline')), STRING_DATE_FORMAT) 
        : '',
      payment_percentage: verify(orderFields.paymentPercentage, getMessage('paymentPercentage')),
      prepayment_amount: verify(orderFields.prepaymentAmount, getMessage('prepaymentAmount')),
      ordered_date: orderFields.orderTimestamp
        ? format(
          verify(orderFields.orderTimestamp, getMessage('orderTimestamp')),
          STRING_DATE_FORMAT,
        )
        : undefined,
      confirmed_date: orderFields.confirmationTimestamp === null
        ? null
        : format(orderFields.confirmationTimestamp, STRING_DATE_FORMAT),
      paid_date: orderFields.paymentTimestamp === null
        ? null
        : format(orderFields.paymentTimestamp, STRING_DATE_FORMAT),
      status: orderFields.orderStatusId,
      country: verify(orderFields.countryId, getMessage('countryId')),
      currency: verify(orderFields.currencyId, getMessage('currencyId')),
      provider: verify(orderFields.providerId, getMessage('providerId')),
      payment_method: verify(
        orderFields.paymentMethodId,
        getMessage('paymentMethodId'),
      ),
      responsible_procurement: verify(
        orderFields.procurement,
        getMessage('procurement'),
      ),
      store_order_number: orderFields.orderNumber,
      proc_trans_for_payment_date:
        verify(
          orderFields.transferredForPaymentDate === null
            ? undefined
            : format(
              orderFields.transferredForPaymentDate,
              STRING_DATE_FORMAT,
            ),
          getMessage('transferredForPaymentDate'),
        ),
      po_number: orderFields.numberPO || undefined,
      tracking_number: orderFields.tracking || undefined,
      payment_priority: orderFields.paymentPriority || '',
      payment_details: orderFields.paymentDetails,
      payment_type: orderFields.paymentType
        ? orderFields.paymentType
        : orderFields.paymentMethodInfo,
      brand_contact: orderFields.managerContact || undefined,
      sum_other_cost_formula: orderFields.sumOtherCost || undefined,
      sum_vendor_price: orderFields.sumVendorPrice || null,
      card: verify(orderFields.card, getMessage('card')),
      sum_tax: orderFields.taxPercent === null
        ? undefined
        : orderFields.taxPercent,
      tsw_name: orderFields.tswNameId,
      docs: [],
      order_comment: orderFields.orderComment,
    }

    return filterObj(isDef, obj)
  }

  const obj: Partial<CreateOrderRequestOrderFields> = {
    store: orderFields.storeId || undefined,
    ordered_date: orderFields.orderTimestamp === null
      ? undefined
      : format(orderFields.orderTimestamp, STRING_DATE_FORMAT),
    confirmed_date: orderFields.confirmationTimestamp === null
      ? undefined
      : format(orderFields.confirmationTimestamp, STRING_DATE_FORMAT),
    payment_deadline: orderFields.paymentDeadline
      ? format(orderFields.paymentDeadline, STRING_DATE_FORMAT) 
      : '',
    payment_percentage: orderFields.paymentPercentage || '',
    paid_date: orderFields.paymentTimestamp === null
      ? undefined
      : format(orderFields.paymentTimestamp, STRING_DATE_FORMAT),
    status: orderFields.orderStatusId,
    country: orderFields.countryId || undefined,
    currency: orderFields.currencyId || undefined,
    proc_trans_for_payment_date:
      orderFields.transferredForPaymentDate === null
        ? undefined
        : format(orderFields.transferredForPaymentDate, STRING_DATE_FORMAT),
    payment_priority: orderFields.paymentPriority || '',
    provider: orderFields.providerId || undefined,
    payment_details: orderFields.paymentDetails,
    payment_type: orderFields.paymentType
      ? orderFields.paymentType
      : orderFields.paymentMethodInfo,
    payment_method: orderFields.paymentMethodId || undefined,
    responsible_procurement: orderFields.procurement || undefined,
    store_order_number: orderFields.orderNumber,
    prepayment_amount: orderFields.prepaymentAmount || undefined,
    po_number: orderFields.numberPO || undefined,
    tracking_number: orderFields.tracking || undefined,
    brand_contact: orderFields.managerContact || undefined,
    sum_vendor_price: orderFields.sumVendorPrice || undefined,
    sum_other_cost_formula: orderFields.sumOtherCost || undefined,
    card: orderFields.card || undefined,
    sum_tax: orderFields.taxPercent === null
      ? undefined
      : orderFields.taxPercent,
    order_comment: orderFields.orderComment,
  }

  return filterObj(isDef, obj)
}

function getMessage(field: keyof CreateOrderPageOrderFields) {
  return `CreateOrderPage order fields verification failed. Field: ${field}`
}
