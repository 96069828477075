import { Store } from '@reatom/core'
import { createPrimitiveAtom } from '@reatom/core/primitives'
import { getTime, hoursToMilliseconds } from 'date-fns'
import { indexBy } from 'ramda'
import { getApi } from '../../api'

let lastInitTimestamp: number | null

export interface ProviderExportStatusData {
  title: string
  id: string
  color: string
}

export const providerExportStatusesAtom = createPrimitiveAtom<
  Record<string, ProviderExportStatusData>
>({})

export async function updateProviderExportStatusesAtom({ dispatch }: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }

  const data = await getApi().listsApi.getProviderExportStatuses()
  const record = indexBy(x => x.id, data)
  dispatch(providerExportStatusesAtom.set(record))
  lastInitTimestamp = getTime(new Date())
  return record
}
