import apiCreator from 'src/api/api-creator'
import { API_URL } from 'src/config/environment'
import { UserWithId } from './model'

export type GetUserByIdResponse = UserWithId

export async function getUserById(id: string): Promise<GetUserByIdResponse> {
  return apiCreator
    .get<GetUserByIdResponse>(
      `${API_URL.AUTH}/users/get`,
      {
        params: {
          id,
        },
      },
    )
    .then(({ data }) => data)
}
