import {ColorScheme, Tuple} from '@mantine/core'
import {MantineThemeOverride} from '@mantine/styles/lib/theme/types'
import {MantineTheme} from '@mantine/styles/lib/theme/types'
import {MantineThemeComponents} from '@mantine/styles/lib/theme/types/MantineTheme'
import {CSSObject} from '@mantine/styles/lib/tss'
import {getAutocompleteStylesConfig} from './components/autocomplete'
import { getBadgeStylesConfig } from './components/badge'
import {getButtonStylesConfig} from './components/button'
import {getCheckboxStylesConfig} from './components/checkbox'
import {getDatePickerStylesConfig} from './components/date-picker'
import {getMultiSelectStylesConfig} from './components/multi-select'
import {getNumberInputStylesConfig} from './components/number-input'
import {getSelectStylesConfig} from './components/select'
import {getTextareaStylesConfig} from './components/text-area'
import {getTextInputStylesConfig} from './components/text-input'

export function getMantineTheme(
  colorScheme: ColorScheme,
): MantineThemeOverride {
  const dark: Tuple<string, 10> = [
    '#f1f5f9',
    '#e2e8f0',
    '#cbd5e1',
    '#94a3b8',
    '#64748b',
    '#475569',
    '#334155',
    '#1e293b',
    '#0f172a',
    '#0c0d21',
  ]

  const blue: Tuple<string, 10> = [
    '#d0ebff',
    '#a5d8ff',
    '#74c0fc',
    '#4dabf7',
    '#3388FF',
    '#2962FF',
    '#1756ff',
    '#0048ff',
    '#0044FF',
    '#0000FF',
  ]

  return {
    fontFamily: 'Inter, Roboto, sans-serif',
    colorScheme,
    black: dark[5],
    primaryColor: 'blue',
    colors: {
      dark,
      blue,
    },
    radius: {
      xs: 4,
      sm: 8,
      md: 12,
      lg: 16,
      xl: 32,
    },
    defaultRadius: 'md',
    components: getMantineComponentsStyles(),
  }
}

type ProviderStyles = Record<
  string,
  | Record<string, CSSObject>
  | ((
      theme: MantineTheme,
      params: Record<string, any>,
    ) => Record<string, CSSObject>)
>

function getMantineComponentsStyles(): MantineThemeComponents {
  return {
    Select: getSelectStylesConfig(),
    Checkbox: getCheckboxStylesConfig(),
    MultiSelect: getMultiSelectStylesConfig(),
    DatePicker: getDatePickerStylesConfig(),
    TextInput: getTextInputStylesConfig(),
    NumberInput: getNumberInputStylesConfig(),
    Autocomplete: getAutocompleteStylesConfig(),
    Textarea: getTextareaStylesConfig(),
    Button: getButtonStylesConfig(),
    Badge: getBadgeStylesConfig(),
  }
}

export function isColorScheme(value: string): value is ColorScheme {
  return value === 'light' || value === 'dark'
}
