import { API_URL } from 'src/config/environment'
import apiCreator from '../../api-creator'
import {handleErrorWithDefaultValue} from '../utils'

export interface BankAccount {
  name: string
  provider: string
}

export function getBankAccounts(): Promise<Array<BankAccount>> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/bank-accounts`,
    )
    .then(res => {
      const rawItems: Array<BankAccount> = res.data.bank_accounts
      return rawItems
    })
    .catch(handleErrorWithDefaultValue([]))
}

