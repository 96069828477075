import {createAtom} from '@reatom/core'
import {Either, left, right} from '@sweet-monads/either'
import produce from 'immer'
import {isDef} from '@appscience/utils'
import {validators} from '@app/utils/errors'
import {ToWarehouseRegisterItem} from '../model'

type RegisterPageError = keyof ToWarehouseRegisterItem

type ItemErrorsState = Map<string, Set<RegisterPageError>>

export const registerPageItemErrorsAtom = createAtom(
  {
    reset: (itemId?: string, field?: keyof ToWarehouseRegisterItem) => ({
      itemId,
      field,
    }),
    checkField: <K extends keyof ToWarehouseRegisterItem>(
      itemId: string,
      field: K,
      value: ToWarehouseRegisterItem[K],
    ) => ({itemId, field, value}),
    init: (v: ItemErrorsState) => v,
  },
  ({onAction}, state: ItemErrorsState = new Map()) => {
    onAction('init', value => (state = value))
    onAction('checkField', ({itemId, field, value}) => {
      state = produce(state, draft => {
        const validate = validateFns[field]
        if (!isDef(validate)) {
          return
        }
        const errors = draft.get(itemId)
        if (validate(value)) {
          errors?.delete(field)
        } else if (errors) {
          errors.add(field)
        } else {
          draft.set(itemId, new Set([field]))
        }
      })
    })
    onAction('reset', ({itemId, field}) => {
      state = itemId
        ? produce(state, draft => {
            if (field) {
              draft.get(itemId)?.delete(field)
            } else {
              draft.delete(itemId)
            }
          })
        : new Map()
    })
    return state
  },
)

const validateFns: TypedObject<
  keyof ToWarehouseRegisterItem,
  (v: AnyType) => boolean
> = {
  temperatureRegime: validators.string,
  locations: validators.array,
  countryOfOrigin: validators.string,
  lot: validators.string,
  expirationDate: (v: number | null) => v !== null,
}

//-------------------------------------------------------------------------------------

export function registerPageValidateItems(
  items: Array<ToWarehouseRegisterItem>,
): Either<
  TypedError<'items', ItemErrorsState>,
  Array<NonNullableObj<ToWarehouseRegisterItem>>
> {
  const errorsMap: ItemErrorsState = new Map()
  items.forEach(item => {
    const itemErrors = getRegisterPageValidatableFields().filter(x => {
      const validate = validateFns[x]
      return isDef(validate) && !validate(item[x])
    })
    itemErrors.length > 0 && errorsMap.set(item.id, new Set(itemErrors))
  })

  return errorsMap.size > 0
    ? left({type: 'items', data: errorsMap})
    : right(items as Array<NonNullableObj<ToWarehouseRegisterItem>>)
}

export function getRegisterPageValidatableFields(): Array<
  keyof ToWarehouseRegisterItem
> {
  return ['lot', 'temperatureRegime', 'locations']
}
