import { Button } from '@mantine/core'
import { useCallback } from 'react'
import { historyAtom } from 'src/atoms/history-atom'
import { i18n_get } from 'src/lng/i18n'
import { useAtomState } from '../../../../hooks/use-atom-state'

export function UsersTableActions() {
  const history = useAtomState(historyAtom)

  const onCreate = useCallback(() => {
    history.typedPush('/iam/users/add')
  }, [history])

  return (
    <div className='flex items-center mr-3 space-x-3'>
      <Button
        onClick={onCreate}
      >
        {i18n_get.t('Users.TableActions.CreateUser')}
      </Button>
    </div>
  )
}
