import { indexBy, prop } from 'ramda'
import { createMultipleParam } from '@appscience/utils'
import { API_URL } from 'src/config/environment'
import { ToHubItem } from '../../views/warehouse/to-warehouse/hub/model'
import apiCreator from '../api-creator'
import { Api_Item } from '../common/item-model'
import { getItemInvoicePrice, handleErrorWithDefaultValue } from '../common/utils'
import { parseDate } from '@app/utils/date'

interface Params {
  warehouseIds: Array<string>
  packageId?: string
}

export function getToHubItems({
  warehouseIds,
  packageId,
}: Params): Promise<Record<string, ToHubItem>> {
  if (packageId !== undefined) {
    return apiCreator
      .get(`${API_URL.TWIN}/get-package?id=${packageId}`)
      .then(res => {
        const rawData: Array<Api_Item> = res.data.items
        return indexBy(prop('id'), rawData.map(remapToHubItem))
      })
      .catch(handleErrorWithDefaultValue({}))
  }
  return apiCreator
    .get(
      `${API_URL.TWIN}/items?step=in-package&${createMultipleParam(
        warehouseIds,
        'to_warehouse_id',
      )}&shipment_status=shipped`,
    )
    .then(res => {
      const rawData: Array<Api_Item> = res.data.items
      return indexBy(prop('id'), rawData.map(remapToHubItem))
    })
    .catch(handleErrorWithDefaultValue({}))
}

export function remapToHubItem(data: Api_Item): ToHubItem {
  return {
    id: data.id,
    airtableLink: data.airtable_link,
    isKit: data.is_kit,
    orderId: data.order_id,
    amoId: data.deal_id,
    invoiceName: data.name,
    originalName: data.original_name,
    brand: data.brand,
    poNumber: data.po_number,
    catalogueId: data.catalogue_id,
    sumCost: getItemInvoicePrice(data) || 0,
    quantity: data.quantity,
    currencyId: data.currency,
    invoiceNumber: data.package_invoice,
    temperatureId: data.provider_temp,
    hazmat: data.provider_hazmat,
    lot: data.provider_lot,
    prevWarehouseId: data.from_warehouse_id,
    transporterId: data.transporter,
    trackIds: data.track_number,
    departureDate: parseDate(data.departure_date),
    exportStatuses: data.provider_export_statuses || [],
    exportComment: data.provider_comment,
    boxNumber: data.box_numbers,
  }
}
