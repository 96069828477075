import { Store } from '@reatom/core'
import { createPrimitiveAtom } from '@reatom/core/primitives'
import { getTime, hoursToMilliseconds } from 'date-fns'
import { indexBy } from 'ramda'
import { getApi } from '../../api'

let lastInitTimestamp: number | null

export interface ProcurementDlReasonData {
  id: string
  title: string
  ruTitle: string
}

export const procurementDlReasonsAtom = createPrimitiveAtom<
  Record<string, ProcurementDlReasonData>
>({})

export async function updateProcurementDlReasonsAtom({ dispatch }: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }

  const data = await getApi().listsApi.getProcurementDelayReasons()
  const record = indexBy(x => x.id, data)

  dispatch(procurementDlReasonsAtom.set(record))
  lastInitTimestamp = getTime(new Date())

  return record
}
