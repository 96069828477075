import {
  ComponentPropsWithoutRef,
  ElementType,
  Context as ReactContext, createContext,
  useContext,
} from 'react'

export function createContextWithHook<T>(data: T): [ReactContext<T>, () => T] {
  const Context = createContext(data)
  return [Context, () => useContext(Context)]
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type ExtendedProps<P extends object = {}, E extends ElementType = 'div'> = P & Omit<ComponentPropsWithoutRef<E>, keyof P>
