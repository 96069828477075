import { API_URL } from 'src/config/environment'
import apiCreator from '../../api-creator'

interface Params {
  packageId: string
}

export function cancelRegisterPackage(data: Params): Promise<void> {
  return apiCreator
    .post(
      `${API_URL.TWIN}/cancel-register-package`,
      {
        package_id: data.packageId,
      },
    )
}
