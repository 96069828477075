import { createMultipleParam } from '@appscience/utils'
import { indexBy, prop } from 'ramda'
import { API_URL } from 'src/config/environment'
import { ToProviderOrder } from '../../views/warehouse/to-warehouse/provider/model'
import apiCreator from '../api-creator'
import { Api_Order } from '../common/order-model'
import { handleErrorWithDefaultValue } from '../common/utils'
import { parseDate } from '@app/utils/date'

interface Params {
  warehouseIds: Array<string>
}

export function getToProviderOrders({
  warehouseIds,
}: Params): Promise<Record<string, ToProviderOrder>> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/orders?step=to-warehouse&${createMultipleParam(
        warehouseIds,
        'provider',
      )}`,
    )
    .then(res => {
      const rawData: Array<Api_Order> = res.data.orders
      return indexBy(prop('id'), rawData.map(remapToProviderOrder))
    })
    .catch(handleErrorWithDefaultValue({}))
}

export function remapToProviderOrder(data: Api_Order): ToProviderOrder {
  return {
    id: data.id,
    orderDate: parseDate(data.ordered_date),
    invoiceNumber: data.invoice_number,
    orderNumber: data.store_order_number,
    paymentMethodId: data.payment_method || null,
    currencyId: data.currency,
    store: data.store,
    sumCost: data.sum_cost,
    fullItemsQuantity: data.full_items_quantity,
    sentToProviderItemsQuantity: data.sent_to_provider_items_quantity,
    latestEsdDate: parseDate(data.latest_esd_date),
    scd: data.responsible_scd,
    provider: data.provider,
    procurement: data.responsible_procurement,
    paidDate: parseDate(data.paid_date),
    trackNumber: data.tracking_number,
  }
}
