import { getApi } from 'src/api'
import { UserWithId } from './model'

export async function getCurrentUser(): Promise<UserWithId> {
  const api = getApi()

  const checkResponse: UserWithId = await api.auth.check()
  const userId: string = checkResponse?.id

  return api.users.getUserById(userId)
}
