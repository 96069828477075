import { createMultipleParam } from '@appscience/utils'
import { indexBy, prop } from 'ramda'
import { API_URL } from 'src/config/environment'
import { ReadyToShipPackage } from '../../../views/warehouse/from-warehouse/ready-to-ship/model/atoms'
import apiCreator from '../../api-creator'
import { Api_PackageAndShipment } from '../../common/shipment-model'
import { handleErrorWithDefaultValue } from '../../common/utils'
import { parseDate } from '@app/utils/date'

interface Params {
  warehouseIds: Array<string>
}

export function getReadyToShipPackages({
  warehouseIds,
}: Params): Promise<Record<string, ReadyToShipPackage>> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/packages?${createMultipleParam(
        warehouseIds,
        'from_warehouse_id',
      )}&shipment_status=ready_to_ship`,
    )
    .then(res => {
      const rawData: Array<Api_PackageAndShipment> = res.data.packages
      return indexBy(prop('id'), rawData.map(remapToReadyToShipPackage))
    })
    .catch(handleErrorWithDefaultValue({}))
}

export function remapToReadyToShipPackage({
  package: packageData,
  shipment,
}: Api_PackageAndShipment): ReadyToShipPackage {
  return {
    id: packageData.id,
    plannedDepartureDate: parseDate(shipment.planned_departure_date),
    toWarehouseId: shipment.to_warehouse_id,
    fromWarehouseId: shipment.from_warehouse_id,
    tag: shipment.tag,
    temperatures: packageData.temperature,
    boxNumber: packageData.box_numbers,
    isHazmat: packageData.hazmat,
    quantity: packageData.items_quantity,
    invoicePriceSum: packageData.package_sum,
    currencyId: packageData.currency,
    packageComment: packageData.provider_comments,
    invoiceNumber: packageData.package_invoice,
    transporter: shipment.transporter,
    trackIds: shipment.track_number,
    providerTagToHub: shipment.provider_tag_to_hub,
    providerTagToTransit: shipment.provider_tag_to_transit,
    providerTagToRu: shipment.provider_tag_to_ru,
    providerHub: shipment.provider_hub,
    providerTransit: shipment.provider_transit,
    procurementComment: shipment.procurement_comment,
    packageDate: packageData.package_date,
    packageInvoice: packageData.package_invoice,
    express: shipment.express ?? false,
  }
}
