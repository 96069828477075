import { isNumber, logError } from '@appscience/utils'
import { API_URL } from 'src/config/environment'
import { ServerTableSettings } from '../../components/table/utils/settings-atom'
import apiCreator from '../api-creator'
import { Api_Item } from '../common/item-model'
import {
  SSTRequest,
  SSTResponse,
  remapSettingsToSSTRequest,
} from '../common/server-side-params'
import { handleErrorWithDefaultValue } from '../common/utils'
import { NewOrderTableItem } from 'src/views/procurement/new-order/model/main'
import { parseDate } from '@app/utils/date'

interface RequestBody {
  step: 'procurement'
  generic_query: SSTRequest
}

interface Response {
  items: Array<NewOrderTableItem>
  startKey: object
}

interface Params {
  settings: ServerTableSettings
}

export function getProcurementNewOrderItems({
  settings,
}: Params): Promise<Response> {
  const body: RequestBody = {
    step: 'procurement',
    generic_query: remapSettingsToSSTRequest(settings),
  }

  return apiCreator
    .post<SSTResponse>(`${API_URL.TWIN}/items`, body)
    .then(res => ({
      items: res.data.items.map(remapToProcurementItem),
      startKey: res.data.pagination.start_key || {},
    }))
    .catch(
      handleErrorWithDefaultValue({
        items: [],
        startKey: {},
      }),
    )
}

export function remapToProcurementItem(data: Api_Item): NewOrderTableItem {
  const quantityIsValid = isNumber(data.quantity)
  const quantity = quantityIsValid ? Number(data.quantity) : 0
  if (!quantityIsValid) {
    logError(`Quantity is not a number. Value: ${data.quantity}`)
  }

  return {
    id: data.id,
    invoiceDate: parseDate(data.deal_approved_date),
    deadlineDate: parseDate(data.deadline_date),
    name: data.name,
    originalName: data.original_name,
    amoId: data.deal_id,
    clientName: data.client_name,
    complianceComment: data.compliance_comments,
    clientPrice: data.final_price,
    esdFix: parseDate(data.esd_fix_date || ''),
    clientType: data.client_type,
    exefType: data.exef_type,
    supposedStore: data.store,
    brand: data.brand,
    purchaseDate: parseDate(data.estimated_order_date),
    createdDate: parseDate(data.created_date),
    priority: data.priority,
    country: data.country,
    catalogueId: data.catalogue_id,
    quantity,
    procurement: data.procurement,
    proposalComment: data.commentary_for_procurement,
    procurementComment: data.procurement_comment,
    express: data.express || false,
    sample: data.sample,
    url: data.item_url,
    casNumber: data.cas_id,
    packing: data.package_size,
    license: data.supply_mm_list,
    compliance: data.compliance,
    exportDocuments: data.export_documents,
    hsCodeChina: data.hs_code_china,
    providerComment: data.provider_comment,
    provider: data.provider,
    status: data.procurement_status,
    orderDetails: data.order_details,
    temperature: data.provider_temp,
    tswName: data.tsw_name,
    dgmStatus: data.dgm_status,
    salesComment: data.sales_comment,
    isTender: data.is_tender,
    providerHazmatType: data.provider_hazmat_type,
    deliveryMethod: data.method_of_delivery,
    quota: data.quota,
    complianceDate: parseDate(data.date_of_compliance),
    estimatedOrderDate: parseDate(data.eod),
    vendorRecommendation: data.vendor_recommend,
    procurementComplianceSpecialist: data.proc_cmplns_specialist,
    dateOfProcurementCompliance: data.date_of_proc_compliance,
    clientPaymentReceived: data.is_payment_received || false,
    clientPaymentTerms: data.payment_condition || '',
    dlOrder: parseDate(data.dl_order),
    procurementDlReason: data.procurement_dl_reason,
  }
}
