import { getCurrencySymbol } from 'src/atoms/static-data/currencies-atom.utils'
import { formatNumber } from './format-number'
import { CurrencyIdEnum } from 'src/atoms/static-data/currencies-atom.type'

export function formatMoney(value: number, currencyId: CurrencyIdEnum | string | null | undefined, precision = 2): string {
  const formattedValue = formatNumber(value.toFixed(precision))
  if (!formattedValue) {
    return ''
  }

  if (!currencyId) {
    return formattedValue
  }

  const currencySymbol = getCurrencySymbol(currencyId)
  if (!currencySymbol) {
    return formattedValue
  }

  return currencySymbol + ' ' + formattedValue
}
