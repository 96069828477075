import { createMultipleParam } from '@appscience/utils'
import { indexBy, prop } from 'ramda'
import { API_URL } from 'src/config/environment'
import { ReadyToShipItem } from '../../../views/warehouse/from-warehouse/ready-to-ship/model/atoms'
import apiCreator from '../../api-creator'
import { Api_Item } from '../../common/item-model'
import {
  getItemInvoicePrice,
  handleErrorWithDefaultValue,
} from '../../common/utils'
import { parseDate } from '@app/utils/date'

interface Params {
  warehouseIds: Array<string>
  packageId?: string
}

export function getReadyToShipItems({
  warehouseIds,
  packageId,
}: Params): Promise<Record<string, ReadyToShipItem>> {
  if (packageId !== undefined) {
    return apiCreator
      .get(`${API_URL.TWIN}/get-package?id=${packageId}`)
      .then(res => {
        const rawData: Array<Api_Item> = res.data.items
        return indexBy(prop('id'), rawData.map(remapToReadyToShipItem))
      })
      .catch(handleErrorWithDefaultValue({}))
  }
  return apiCreator
    .get(
      `${API_URL.TWIN}/items?step=in-package&${createMultipleParam(
        warehouseIds,
        'from_warehouse_id',
      )}&shipment_status=ready_to_ship`,
    )
    .then(res => {
      const rawData: Array<Api_Item> = res.data.items
      return indexBy(prop('id'), rawData.map(remapToReadyToShipItem))
    })
    .catch(handleErrorWithDefaultValue({}))
}

export function remapToReadyToShipItem(data: Api_Item): ReadyToShipItem {
  return {
    id: data.id,
    airtableLink: data.airtable_link,
    amoId: data.deal_id,
    brand: data.brand,
    store: data.store,
    grossWeight: data.gross_weight_kg,
    netWeight: data.net_weight_kg,
    invoiceName: data.name,
    originalName: data.original_name,
    catalogueId: data.catalogue_id,
    temperatureId: data.provider_temp,
    toHub0: parseDate(data.sent_to_hub_0 || '') || null,
    hub0: data.hub_0,
    trackHub0: data.track_to_hub_0,
    tswed: parseDate(data.registration_date || '') || null,    
    hsCodeSecondary: data.hs_code_secondary,
    originCountry: data.country_of_origin,
    lot: data.provider_lot,
    hazmat: data.provider_hazmat,
    quantity: data.quantity,
    invoicePrice: getItemInvoicePrice(data) || 0,
    invoiceNumber: data.package_invoice,
    currencyId: data.currency,
    express: data.express ?? false,
    toWarehouseId: data.to_warehouse_id,
    plannedDepartureDate: parseDate(data.planned_departure_date),
    exportStatuses: data.provider_export_statuses || [],
    exportComment: data.provider_comment,
    boxNumber: data.box_numbers,
    providerTagToHub: data.provider_tag_to_hub,
    providerTagToTransit: data.provider_tag_to_transit,
    providerTagToRu: data.provider_tag_to_ru,
    providerHub: data.provider_hub,
    providerTransit: data.provider_transit,
    procurement: data.procurement,
    responsibleSCD: data.responsible_scd,
    procurementComment: data.procurement_comment,
    locations: data.provider_locations,
    prtComments: data.procurement_comment,
    sdExp: String(data.sd_exp || '').trim(),
    additionalCosts: data.additional_costs,
  }
}
