import {useEffect} from 'react'

export function useConfirmRefresh(enabled = true) {
  useEffect(() => {
    if (!enabled) {
      return
    }
    const unloadCallback = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
      return ''
    }

    window.addEventListener('beforeunload', unloadCallback)
    return () => window.removeEventListener('beforeunload', unloadCallback)
  })
}
