import { PermissionGroupObjectTypeEnum, UserPermissions } from 'src/api/iam/groups/model'
import { UserWithId } from 'src/api/iam/users/model'

export function getAllowedObjectIdsSet(
  userPermissions: UserPermissions | null,
  userWithId: UserWithId | null,
  objectType: PermissionGroupObjectTypeEnum,
): Set<string> {
  if (!userPermissions || !userWithId) {
    return new Set([])
  }

  const warehouseObjectIds: Array<string> = userPermissions.objects
    .filter(({ type }) => type === objectType)
    .map(({ id }) => id)

  return new Set(warehouseObjectIds)
}
