import { Store } from '@reatom/core'
import { createPrimitiveAtom } from '@reatom/core/primitives'
import { getTime, hoursToMilliseconds } from 'date-fns'
import { indexBy } from 'ramda'
import { getApi } from '../../api'
import { CurrencyData } from './currencies-atom.type'
import { CURRENCIES_ATOM_INITIAL_STATE } from './currencies-atom.utils'

let lastInitTimestamp: number | null

export const currenciesAtom = createPrimitiveAtom<Record<string, CurrencyData>>(
  CURRENCIES_ATOM_INITIAL_STATE,
  null,
)

export async function updateCurrenciesAtom({ dispatch }: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }
  const data = await getApi().listsApi.getCurrencies()
  const record = indexBy(x => x.id, data)
  dispatch(currenciesAtom.set(record))
  lastInitTimestamp = getTime(new Date())
  return record
}
