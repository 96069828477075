import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'

interface AddProcurementItemsToOrderRequestData {
  order_id: string
  item_ids: Array<string>
}

interface Params {
  orderId: string
  itemIds: Array<string>
}

export function addProcurementItemsToOrder(data: Params): Promise<void> {
  return apiCreator.post(
    `${API_URL.TWIN}/order-add-items`,
    remapToRequest(data),
  )
}

function remapToRequest(data: Params): AddProcurementItemsToOrderRequestData {
  return {
    order_id: data.orderId,
    item_ids: data.itemIds,
  }
}
