const NUMBER_FORMAT_REGEXP = /\B(?=(\d{3})+(?!\d))/g

export function formatNumber(value: string | undefined): string {
  if (!value || Number.isNaN(parseFloat(value))) {
    return ''
  }

  const [integerPart, fractionalPart] = value.split('.')
  const suffix = fractionalPart === '' || fractionalPart
    ? '.' + fractionalPart
    : ''

  return integerPart.replaceAll(NUMBER_FORMAT_REGEXP, ' ') + suffix
}
