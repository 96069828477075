import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'

interface Params {
  packageId: string
  itemIds: Array<string>
}

interface RequestData {
  package_id: string
  item_ids: Array<string>
}

export function addItemsToPackage(args: Params): Promise<void> {
  const data: RequestData = {
    package_id: args.packageId,
    item_ids: args.itemIds,
  }
  return apiCreator
    .post(
      `${API_URL.TWIN}/package-add-items`,
      data,
    )
}
