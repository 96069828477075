import {Store} from '@reatom/core'
import {createPrimitiveAtom} from '@reatom/core/primitives'
import {getTime, hoursToMilliseconds} from 'date-fns'
import {indexBy} from 'ramda'
import {getApi} from '../../api'

let lastInitTimestamp: number | null

export interface BrandsData {
  id: string
  title: string
  alternativeNames: Array<string>
}

export const brandsAtom = createPrimitiveAtom<Record<string, BrandsData>>({})

export async function updateBrandsAtom({dispatch}: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }
  const data = await getApi().listsApi.getBrands()
  const record = indexBy(x => x.id, data)
  dispatch(brandsAtom.set(record))
  lastInitTimestamp = getTime(new Date())
  return record
}
