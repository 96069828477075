import { createPrimitiveAtom } from '@reatom/core/primitives'
import { ExtendedCountriesResponseData, ProvidersResponseData, ResponsibilitiesResponseData } from 'src/api/common/lists/lists-api'
import { CurrencyData } from 'src/atoms/static-data/currencies-atom.type'
import { PaymentMethodData } from 'src/atoms/static-data/payments-method-atom'
import { TswNameData } from 'src/atoms/static-data/tsw-name-colors-atom'

export type CreateOrderPageType = 'create' | 'edit'

interface StaticData {
  orderId: string | null
  pageType: CreateOrderPageType | null
  stores: Array<TitleWithId>
  providers: Array<ProvidersResponseData>
  countries: Array<ExtendedCountriesResponseData>
  currencies: Array<CurrencyData>
  paymentMethods: Array<PaymentMethodData>
  brands: Array<TitleWithId>
  termsOfPayment: Array<TitleWithId>
  responsibleProcurements: Array<ResponsibilitiesResponseData>
  paymentDetails: Array<TitleWithId>
  paymentPercentages: Array<TitleWithId>
  tswNames: Array<TswNameData>
}

export const createOrderPageStaticDataAtom = createPrimitiveAtom<StaticData>({
  orderId: null,
  pageType: null,
  stores: [],
  countries: [],
  providers: [],
  currencies: [],
  paymentMethods: [],
  brands: [],
  termsOfPayment: [],
  responsibleProcurements: [],
  paymentDetails: [],
  paymentPercentages: [],
  tswNames: [],
})
