import { createMultipleParam } from '@appscience/utils'
import { API_URL } from 'src/config/environment'
import { AddItemsToPackagePopupItem } from '../../views/warehouse/in-warehouse/content/add-items-to-package-popup/model'
import apiCreator from '../api-creator'
import { Api_Item } from '../common/item-model'
import { getItemInvoicePrice } from '../common/utils'
import { parseDate } from '@app/utils/date'

export function getAddToPackagePopupItems(
  ids: Array<string>,
): Promise<Array<AddItemsToPackagePopupItem>> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/items?${createMultipleParam(ids, 'id')}`,
    )
    .then(res => {
      const rawData: Array<Api_Item> = res.data.items
      return rawData.map(remapToAddItemsToPackagePopupItem)
    })
}

export function remapToAddItemsToPackagePopupItem(
  x: Api_Item,
): AddItemsToPackagePopupItem {
  return {
    id: x.id,
    brand: x.brand,
    amoId: x.deal_id,
    originalName: x.original_name,
    catalogueId: x.catalogue_id,
    invoicePrice: getItemInvoicePrice(x) || 0,
    quantity: x.quantity,
    temperatureId: x.provider_temp,
    hazmat: x.provider_hazmat,
    expiredDate: parseDate(x.provider_exp),
  }
}
