import { equals } from 'ramda'
import { useAtomState } from 'src/hooks/use-atom-state'
import { createOrderPageInitValuesAtom } from 'src/views/procurement/create-order/model/init-values-atom'
import { createOrderPageItemsAtom } from 'src/views/procurement/create-order/model/items'
import { createOrderPageFieldsAtom } from 'src/views/procurement/create-order/model/order-fields.atom'

export function useNoChangesChecker() {
  const { initItems, initOrderFields } = useAtomState(createOrderPageInitValuesAtom)
  const items = useAtomState(createOrderPageItemsAtom)
  const orderFields = useAtomState(createOrderPageFieldsAtom)
  const initOrderFieldsValues = initOrderFields ? Object.values(initOrderFields) : []
  const orderFieldsValues = Object.values(orderFields)

  // TODO WHY FN AS RESULT ???
  return equals(items, initItems) && equals(initOrderFieldsValues, orderFieldsValues)
}
