import { Store } from '@reatom/core'
import { getApi } from 'src/api'
import { UserRole } from 'src/atoms/user-atom'
import { filterAllowedOrders } from 'src/hooks/permissions/allowed-orders'
import { preordersOrdersAtom } from '../main'
import { indexByWithCb } from '@appscience/utils'

export async function loadOrders(store: Store, role: UserRole): Promise<void> {
  const orders = await getApi().procurement.getPreordersOrders(role)
  const allowedOrders = filterAllowedOrders(orders)

  store.dispatch(preordersOrdersAtom.set(
    indexByWithCb(allowedOrders, x => x.id, x => x),
  ))
}
