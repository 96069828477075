import { AxiosResponse } from 'axios'
import apiCreator from '../api-creator'
import { API_URL } from 'src/config/environment'

export type DeleteOrderDocRequest = {
  order_id: string;
  url: string;
}
export type DeleteOrderDocResponseData = void
export type DeleteOrderDocResponse = AxiosResponse<DeleteOrderDocResponseData>

export async function deleteOrderDoc(orderId: string, url: string, abortSignal: AbortSignal): Promise<DeleteOrderDocResponse> {
  return apiCreator
    .post<DeleteOrderDocResponseData, DeleteOrderDocResponse, DeleteOrderDocRequest>(
      `${API_URL.TWIN}/delete-order-doc`,
      {
        order_id: orderId,
        url,
      },
      {
        signal: abortSignal,
      },
    )
}
