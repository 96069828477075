import { permissionAtom, permissionsLoadingStateAtom } from 'src/hooks/permissions/permission-atom'
import { useAtomState } from 'src/hooks/use-atom-state'
import { AppRoute } from '../types'
import { RouteLoader } from './route-loader'
import { TypedRedirect } from './typed-redirect'
import { TypedRoute, TypedRouteProps } from './typed-route'

interface PrivateRouteProps extends TypedRouteProps {
  redirectPath?: AppRoute;
}

export function PrivateRoute({ children, path, redirectPath, ...rest }: PrivateRouteProps) {
  const { allowedRoutesSet } = useAtomState(permissionAtom)
  const isPermissionsLoading = useAtomState(permissionsLoadingStateAtom) === 'loading'

  return (
    <TypedRoute
      {...rest}
      path={path}
      render={() => {
        if (isPermissionsLoading) {
          return <RouteLoader />
        }

        if (allowedRoutesSet.has(path)) {
          return children
        }

        return (
          <TypedRedirect
            to={redirectPath ?? '/'}
          />
        )
      }}
    />
  )
}
