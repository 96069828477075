import { Store } from '@reatom/core'
import debounce from 'lodash/debounce'
import { useCallback } from 'react'
import { useReatomStore } from './use-reatom-store'

interface AloneActionParams<P> {
  store: Store
  payload: P
}

export type AloneAction<P, R> = (p: AloneActionParams<P>) => R

export function declareAloneAction<P = void, R = void>(cb: AloneAction<P, R>): AloneAction<P, R> {
  const debouncedFn = debounce(cb, 500, {
    leading: false,
    trailing: true,
  }) as typeof cb
  return debouncedFn
}

export function useAloneAction<P = void, R = void>(cb: AloneAction<P, R>): (payload: P) => R {
  const store = useReatomStore()

  return useCallback(payload => cb({ store, payload }), [cb, store])
}
