import {ActionIcon, ActionIconProps, createStyles} from '@mantine/core'
import {PolymorphicComponentProps} from '@mantine/utils'
import {forwardRef} from 'react'
import {joinStrings, mode} from '@appscience/utils'

const useStyles = createStyles(({colors, colorScheme}) => ({
  defaultIcon: {
    border: `1px solid ${mode(colors.gray[4], colors.dark[6])(colorScheme)}`,
    transitionDuration: '150ms',
  },
}))

export const TransparentActionIcon = forwardRef<HTMLButtonElement, PolymorphicComponentProps<'button', ActionIconProps>>((
  {radius, size, className, children, ...props},
  ref,
) => (
  <ActionIcon
    ref={ref}
    radius={radius || 0}
    size={size || 'lg'}
    className={joinStrings(
      'border-none duration-150 hover:bg-transparent disabled:bg-transparent',
      className,
    )}
    {...props}
  >
    {children}
  </ActionIcon>
))

export const DefaultActionIcon = forwardRef<
  HTMLButtonElement,
  PolymorphicComponentProps<'button', ActionIconProps>
>(({radius, size, className, children, ...props}, ref) => {
  const {classes} = useStyles()

  return (
    <ActionIcon
      ref={ref}
      radius={radius || 'sm'}
      size={size || 'lg'}
      className={joinStrings(classes.defaultIcon, className)}
      {...props}
    >
      {children}
    </ActionIcon>
  )
})
