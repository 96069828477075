import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'

//Один из полей-ролей должен быть true
interface OrderModerationApproveRequest {
  from_head_of_procurement?: boolean,
  from_head_of_finance?: boolean,
  from_finance_control?: boolean,
  order_ids: Array<string>
}

export function approveOrder(data: OrderModerationApproveRequest): Promise<void> {
  return apiCreator
    .post(
      `${API_URL.TWIN}/order/moderation/approve`,
      data,
    )
}
