import { userAtom } from '../../../../../atoms/user-atom'
import { useAtomState } from '../../../../../hooks/use-atom-state'
import { isModerationStatus } from '../../../../../types/order-status'
import { createOrderPageAtoms } from '../../model/create-order-page-atoms'
import { createOrderPageFieldsAtom } from '../../model/order-fields.atom'
import { CreateOrderPageLayoutDefaultActions } from './create-order-page-layout-default-actions'
import { CreateOrderPageLayoutModeratorActions } from './moderator-actions'
import { isFC } from 'src/views/iam/common/utils'

export function CreateOrderPageLayoutActions() {
  const { role } = useAtomState(userAtom)
  const pageType = useAtomState(createOrderPageAtoms.staticData).pageType
  const orderStatus = useAtomState(createOrderPageFieldsAtom).orderStatusId

  if (isFC(role)) {
    return (
      <CreateOrderPageLayoutDefaultActions />
    )
  }

  if (role === 'default' || pageType === 'create') {
    return <CreateOrderPageLayoutDefaultActions />
  }

  return isModerationStatus(orderStatus) ? (
    <>
      <CreateOrderPageLayoutDefaultActions />
      <CreateOrderPageLayoutModeratorActions />
    </>
  ) : (
    <CreateOrderPageLayoutDefaultActions />
  )
}
