import React from 'react'
import {Button} from '@mantine/core'
import {pageLoadingAtom} from '../../../../../../atoms/page-loading-atom'
import {useRedirectFn} from '../../../../../../hooks/route-hooks'
import {useAloneAction} from '../../../../../../hooks/use-alone-action'
import {useAtomState} from '../../../../../../hooks/use-atom-state'
import {i18n_get} from '../../../../../../lng/i18n'
import {saveRegisterPageData} from '../model/external-actions/save-data'

export function ToWarehouseRegisterPageLayoutActions() {
  const saving = useAtomState(pageLoadingAtom) === 'saving'
  const handleSave = useAloneAction(saveRegisterPageData)
  const redirectTo = useRedirectFn('/provider')

  return (
    <div className='flex items-center mr-3 space-x-3'>
      <Button onClick={() => redirectTo('/to-warehouse')}>
        {i18n_get.t('Common.Cancel')}
      </Button>
      <Button loading={saving} onClick={() => handleSave()}>
        {i18n_get.t('Common.Save')}
      </Button>
    </div>
  )
}
