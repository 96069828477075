import {commaJoin} from '@appscience/utils'
import {Store} from '@reatom/core'
import {createPrimitiveAtom} from '@reatom/core/primitives'
import {getTime, hoursToMilliseconds} from 'date-fns'
import {getApi} from '../../api'
import {selectedWarehousesAtom} from '../../views/warehouse/common/selected-warehouses-atom'

let lastInitTimestamp: number | null
let lastWarehousesString: string | null

export const locationsAtom = createPrimitiveAtom<Record<string, Array<string>>>(
  {},
)

export async function updateLocationsAtom(
  {dispatch}: Store,
  warehouseIds?: Array<string>,
) {
  const requestWarehouseIds =
    warehouseIds || selectedWarehousesAtom.getState().map(x => x.id)
  const newWarehouseString = commaJoin(...requestWarehouseIds)
  if (
    lastWarehousesString &&
    lastWarehousesString === newWarehouseString &&
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }
  const record = await getApi().provider.getWarehouseLocations({
    warehouseIds: requestWarehouseIds,
  })
  dispatch(locationsAtom.set(record))
  lastInitTimestamp = getTime(new Date())
  lastWarehousesString = newWarehouseString
  return record
}
