import { Invoice } from 'src/views/procurement/pre-orders-view/model/main'
import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'
import { Api_Invoice } from './get-invoices'

export const saveInvoices = (
  invoices: Array<Invoice>,
): Promise<void> => {
  const data = {
    invoices: invoices.map(remapToInvoiceServerItem),
  }
  return apiCreator
    .post(
      `${API_URL.TWIN}/save-invoices`,
      data,
    )
}

const remapToInvoiceServerItem = (invoice: Invoice): Api_Invoice => ({
  invoice_comment: invoice.invoiceComment,
  invoice_date:  invoice.invoiceDate === undefined ? '' : invoice.invoiceDate.toISOString().slice(0, 10),
  invoice_check: invoice.invoiceCheck,
  paid_date: invoice.paidDate === undefined ? '' : invoice.paidDate.toISOString().slice(0, 10),
  registry_number: invoice.registryNumber,
  order_id: invoice.orderId,
  ordered_date: invoice.orderDate === undefined ? '' : invoice.orderDate.toISOString().slice(0, 10),
  payment_method: invoice.paymentMethod,
  order_sum: invoice.orderSum,
  currency: invoice.currency,
  provider: invoice.provider,
  procurement: invoice.procurement,
  bank_account: invoice.bankAccount,
  invoice_sum: invoice.invoiceSum,
  store: invoice.store,
  additional_cost_sum: invoice.additionalCosts,
  id: invoice.id,
  payment_priority: invoice.paymentPriority,
  registry_comment: invoice.registryComment,
  order_number: invoice.orderNumber,
  terms_of_payment: invoice.termsOfPayment,
  invoice_number: invoice.invoiceNumber,
  payment_deadline: invoice.paymentDeadline === undefined ? '' : invoice.paymentDeadline.toISOString().slice(0, 10),
})