import { API_URL } from 'src/config/environment'
import apiCreator from '../../api-creator'
import {handleErrorWithDefaultValue} from '../utils'


export const getDiscountComments = (): Promise<string[]> => 
  apiCreator
    .get(
      `${API_URL.TWIN}/discount-comments`,
    )
    .then(res => {
      const rawItems: string[] = res.data.discount_comments
      return rawItems
    })
    .catch(handleErrorWithDefaultValue([]))

