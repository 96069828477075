import {Store} from '@reatom/core'
import {createPrimitiveAtom} from '@reatom/core/primitives'
import {getTime, hoursToMilliseconds} from 'date-fns'
import {getApi} from '../../api'

let lastInitTimestamp: number | null

export interface ProviderLocationData {
  color: string
  name: string
}

export const providerLocationsAtom = createPrimitiveAtom<
  Record<string, ProviderLocationData>
>({})

export async function updateProviderLocationsAtom({dispatch}: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }
  const record = await getApi().provider.getProviderLocations()
  dispatch(providerLocationsAtom.set(record))
  lastInitTimestamp = getTime(new Date())
  return record
}
