import {Global} from '@mantine/core'
import {mode} from '@appscience/utils'

export function DefaultGlobalStyles() {
  return (
    <Global
      styles={({colors, colorScheme, fn}) => ({
        body: {
          margin: 0,
          fontFamily: `Inter, Roboto, 'sans-serif'`,
          lineHeight: 1.5,
          fontSize: '14px',
          color: colors.dark[5],
          fontWeight: 400,
          textSizeAdjust: 'none', //Для корректной вёрстки в safari
        },
        a: {
          color: mode(colors.blue[6], colors.blue[4])(colorScheme),
          textDecoration: 'none',
        },
        'a:hover': {
          color: mode(colors.blue[5], colors.blue[3])(colorScheme),
          textDecoration: 'underline',
          textUnderlineOffset: '1px',
          textDecorationColor: mode(
            colors.blue[5],
            colors.blue[3],
          )(colorScheme),
        },
        '::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
          scrollbarGutter: 'stable',
        },
        '::-webkit-scrollbar-track': {
          backgroundColor: mode(colors.gray[2], colors.dark[7])(colorScheme),
          borderRadius: '11px',
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: mode(
            colors.gray[4],
            fn.rgba(colors.blue[4], 0.55),
          )(colorScheme),
          borderRadius: '11px',
          transition: 'background-color 200ms ease-out',
        },
        '::-webkit-scrollbar-corner': {
          background: 'transparent',
        },
      })}
    />
  )
}
