import { indexBy, prop } from 'ramda'
import {
  assert,
  createMultipleParam,
} from '@appscience/utils'
import { API_URL } from 'src/config/environment'
import { ToProviderItem } from '../../views/warehouse/to-warehouse/provider/model'
import apiCreator from '../api-creator'
import { Api_Item } from '../common/item-model'
import { handleErrorWithDefaultValue } from '../common/utils'
import { parseDate } from '@app/utils/date'

interface Params {
  warehouseIds: Array<string>
  orderId?: string
}

export function getToProviderItems({
  warehouseIds,
  orderId,
}: Params): Promise<Record<string, ToProviderItem>> {
  let url = `${API_URL.TWIN}/items?step=to-warehouse&${createMultipleParam(
    warehouseIds,
    'provider',
  )}`
  url += orderId ? `&order_id=${orderId}` : ''

  return apiCreator
    .get(url)
    .then(res => {
      const rawData: Array<Api_Item> = res.data.items
      return indexBy(prop('id'), rawData.map(remapToProviderItem))
    })
    .catch(handleErrorWithDefaultValue({}))
}

export function remapToProviderItem(data: Api_Item): ToProviderItem {
  return {
    id: data.id,
    airtableLink: data.airtable_link,
    orderId: data.order_id,
    amoId: data.deal_id,
    invoiceName: data.name,
    originalName: data.original_name,
    brand: data.brand,
    catalogueId: data.catalogue_id,
    sumCost: data.full_cost,
    cost: data.cost,
    tax: data.tax,
    otherCost: data.other_cost,
    poNumber: data.po_number,
    quantity: data.quantity,
    esdDate: parseDate(data.esd_date),
    currencyId: data.currency,
    invoiceNumber: data.invoice_number,
    orderNumber: data.store_order_number,
    dealDate: parseDate(data.deal_approved_date),
    deadlineDate: parseDate(data.deadline_date),
    client: data.client_name,
    isKit: data.is_kit,
    salesId: assert(data.responsible_sales, 'responsible_sales not found')?.id || '',
    procurement: data.procurement,
    scd: data.responsible_scd,
    countryId: data.country,
    storeId: data.store,
    hazmatComment: data.provider_hazmat,
    temperature: data.provider_temp,
    sdComment: data.sd_comments,
    express: data.express || false,
    store: data.store,
    partnerInvoice: data.partner_invoice,
    track: data.track,
    casId: data.cas_id,
  }
}
