import { createBooleanAtom, createPrimitiveAtom } from '@reatom/core/primitives'
import { createOrderPageErrorAtoms } from './errors/errors-atom'
import { createOrderPageStaticDataAtom } from './create-order-page-static-data.atom'

export const createOrderPageAtoms = {
  state: createPrimitiveAtom<DefaultLoadingState>('loading'),
  staticData: createOrderPageStaticDataAtom,
  errorAtoms: createOrderPageErrorAtoms,
  shouldShowModerationInfoPopup: createBooleanAtom(false),
  orderSum: createPrimitiveAtom(0),
}
