import {TypedObject, joinStrings} from '@appscience/utils'
import React from 'react'
import {SimpleTableLabel} from './label'

type ColumnSizeData = TypedObject<'fr'|'px', number>

export interface SimpleTableColumnInfo<COLUMN_ID extends string> extends ColumnSizeData {
  id: COLUMN_ID,
  title?: string,
  required?: boolean,
}

type BorderType = 'head'|'bottom'

interface SimpleTableProps<COLUMN_ID extends string> {
  columns: Array<SimpleTableColumnInfo<COLUMN_ID>>,
  rowsData: {
    fragments: Array<JSX.Element>,
    className?: string,
  },
  hideHeader?: boolean,
  border?: BorderType|Array<BorderType>,
  fixHeader?: boolean,
  className?: string,
}

export function SimpleTable<COLUMN_ID extends string>({
  columns,
  rowsData,
  fixHeader,
  className,
  hideHeader,
  border,
}: SimpleTableProps<COLUMN_ID>) {
  const addHeadBorder = border && border.includes('head')
  const addBottomBorder = border && border.includes('bottom')
  const GRID_TEMPLATE_COLUMNS = columns.reduce((acc, x) => acc + `${getColumnSize(x)} `, '')

  return (
    <div className={joinStrings('overflow-y-auto bg-inherit', addBottomBorder && 'border-b', className)}>
      {!hideHeader && (
        <div
          className={joinStrings(
            'grid gap-x-4 items-center pb-2.5 mb-0.5 bg-inherit z-[1]',
            fixHeader && 'sticky top-0',
            addHeadBorder && 'border-b',
          )}
          style={{ gridTemplateColumns: GRID_TEMPLATE_COLUMNS }}
        >
          {columns.map(x => x.title && <SimpleTableLabel key={x.id} label={x.title} required={x.required} />)}
        </div>
      )}
      {rowsData.fragments.map((cells, index) => (
        <div
          key={index}
          className={joinStrings('grid gap-x-4 py-2 relative', rowsData.className)}
          style={{ gridTemplateColumns: GRID_TEMPLATE_COLUMNS }}
        >
          {cells}
        </div>
      ))}
    </div>
  )
}

function getColumnSize(data: ColumnSizeData): string {
  return data.px ? `${data.px}px` : `${data.fr || 1}fr`
}