import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'

interface RemoveItemsRequestData {
  item_ids: Array<string>
}

export function removeProcurementItems(ids: Array<string>): Promise<void> {
  const data: RemoveItemsRequestData = {item_ids: ids}
  return apiCreator
    .post(
      `${API_URL.TWIN}/order-delete-items`,
      data,
    )
}
