import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'

interface Params {
  itemIds: Array<string>
}

export function returnItemsFromStock(data: Params): Promise<void> {
  return apiCreator
    .post(
      `${API_URL.TWIN}/items-remove-from-stock`,
      {
        ids: data.itemIds,
      },
    )
}
