import {Store} from '@reatom/core'
import {createPrimitiveAtom} from '@reatom/core/primitives'
import {getTime, hoursToMilliseconds} from 'date-fns'
import {getApi} from '../../api'
import { indexBy } from 'ramda'

let lastInitTimestamp: number | null

export interface CountryOfOriginData {
  id: string
  title: string
  color: string
}

export const countriesOfOriginAtom = createPrimitiveAtom<
  Record<string, CountryOfOriginData>
>({})

export async function updateCountriesOfOriginAtom({dispatch}: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }
  const data = await getApi().listsApi.getCountriesOfOrigin()
  const record = indexBy(x => x.id, data)
  dispatch(countriesOfOriginAtom.set(record))
  lastInitTimestamp = getTime(new Date())
  return data
}
