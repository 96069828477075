import React, {SVGProps} from 'react'

export function TwinLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4 10.8588L4.48358 8H16.7936L16.31 10.8588H11.8938L9.95944 22.5644H6.48903L8.42336 10.8588H4Z'
        fill='currentColor'
      />
      <path
        d='M13.9086 23.8158L12.5432 12.8926H15.4606L15.93 20.0503H16.0207L19.0822 12.8926H21.461L22.165 20.0663H22.2504L25.0878 12.8926H28L23.0131 23.8158H20.4689L19.6102 17.1915H19.5249L16.4474 23.8158H13.9086Z'
        fill='currentColor'
      />
    </svg>
  )
}
