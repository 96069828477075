import { primitiveAtom, primitiveAtomWithLS } from '@app/utils/reatom'
import {
  ColumnFiltersState,
  GroupingState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table'
import { TABLE_ID } from './table-id'

export type SettingsAtoms = ReturnType<typeof createSettingsAtom>

export interface ServerTableSettings {
  sortState: SortingState
  searchState: string
  paginationState: PaginationState
  startKey: object
  filtrationState: ColumnFiltersState
}

export const INITIAL_LAZY_LOAD_PAGINATION: PaginationState = {
  pageIndex: 0,
  pageSize: 25,
}

function getInitialPaginationSize(tableId: string): number {
  switch (tableId) {
    case TABLE_ID.CREATE_ORDER:
    case TABLE_ID.IN_WAREHOUSE:
    case TABLE_ID.READY_TO_SHIPS_PACKAGES:
    case TABLE_ID.SHIPPED_PACKAGES:
    case TABLE_ID.ARCHIVE_PACKAGES:
    case TABLE_ID.READY_TO_SHIPS_ITEMS:
    case TABLE_ID.SHIPPED_ITEMS:
    case TABLE_ID.ARCHIVE_ITEMS:
    case TABLE_ID.CREATE_PACKAGE:
    case TABLE_ID.EDIT_PACKAGE: {
      return 200
    }
    default:
      return 25
  }
}

export type SettingsAtomsType = ReturnType<typeof createSettingsAtom>

export function createSettingsAtom({
  tableId,
  isLazyLoading = false,
}: {
  tableId: string
  isLazyLoading?: boolean
}) {
  return {
    sortState: primitiveAtomWithLS<SortingState>([], `${tableId}.sortState`),
    searchState: primitiveAtomWithLS('', `${tableId}.searchState`),
    paginationState: isLazyLoading
      ? primitiveAtom<PaginationState>(INITIAL_LAZY_LOAD_PAGINATION)
      : primitiveAtomWithLS<PaginationState>(
        {
          pageIndex: 0,
          pageSize: getInitialPaginationSize(tableId),
        },
        `${tableId}.paginationState`,
      ),
    filtrationState: primitiveAtomWithLS<ColumnFiltersState>(
      [],
      `${tableId}.filtrationState`,
    ),
    startKey: primitiveAtom({}),
    areAllRowsLoaded: primitiveAtom(false),
    columnSizing: primitiveAtomWithLS({}, `${tableId}.sizeState`),
    groupingState: primitiveAtomWithLS<GroupingState>([], `${tableId}.groupingState`),
  }
}

export function getSingleSettingsState(
  atoms: SettingsAtoms,
): ServerTableSettings {
  return {
    sortState: atoms.sortState.getState(),
    searchState: atoms.searchState.getState(),
    paginationState: atoms.paginationState.getState(),
    filtrationState: atoms.filtrationState.getState(),
    startKey: atoms.startKey.getState(),
  }
}
