import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'

interface GeneratePONumberParams {
  provider: string,
}

interface GeneratePONumberResponse {
  po_number: string,
}

export function generatePONumber({provider}: GeneratePONumberParams): Promise<string> {
  return apiCreator
    .post<GeneratePONumberResponse>(
      `${API_URL.TWIN}/order/generate-po-number`,
      {
        provider,
      },
    )
    .then(res => res.data.po_number)
}