import {logError} from '@appscience/utils'
import {Api_Item} from '../common/item-model'

export function adaptItemColumns(id: string): keyof Api_Item | string {
  switch (id) {
    case 'brand':
    case 'id':
    case 'card':
    case 'name':
    case 'country':
    case 'quantity':
    case 'store':
    case 'procurement':
    case 'compliance':
    case 'sample':
    case 'provider':
      return id
    case 'exportDocuments':
      return 'export_documents'
    case 'tswName':
      return 'tsw_name'
    case 'clientType':
      return 'client_type'
    case 'exefType':
      return 'exef_type'
    case 'url':
      return 'item_url'
    case 'temperature':
      return 'provider_temp'
    case 'status':
      return 'procurement_status'
    case 'priority':
      return 'priority'
    case 'license':
      return 'supply_mm'
    case 'orderDate':
      return 'ordered_date'
    case 'purchaseDate':
      return 'estimated_order_date'
    case 'estimatedOrderDate':
      return 'eod'
    case 'orderId':
      return 'order_id'
    case 'deliveryMethod':
      return 'method_of_delivery'
    case 'complianceDate':
      return 'date_of_compliance'
    case 'orderNumber':
      return 'store_order_number'
    case 'casNumber':
      return 'cas_id'
    case 'statusId':
      return 'order_status'
    case 'packing':
      return 'package_size'
    case 'esdDate':
      return 'esd_date'
    case 'dgmStatus':
      return 'dgm_status'
    case 'amoId':
      return 'deal_id'
    case 'proposalComment':
      return 'proposal_comment'
    case 'procurementComment':
      return 'procurement_comment'
    case 'invoiceName':
      return 'name'
    case 'originalName':
      return 'original_name'
    case 'invoiceNumber':
      return 'invoice_number'
    case 'paymentMethod':
      return 'payment_method'
    case 'deadlineDate':
      return 'deadline_date'
    case 'arrivalDate':
      return 'arrival_date'
    case 'express':
      return 'express'
    case 'supposedStore':
      return 'store'
    case 'orderDetails':
      return 'order_details'
    case 'clientPrice':
      return 'final_price'
    case 'catalogueId':
      return 'catalogue_id'
    case 'clientName':
      return 'client_name'
    case 'invoiceDate':
      return 'deal_approved_date'
    case 'price':
      return 'full_cost'
    // eslint-disable-next-line no-duplicate-case
    case 'tswName':
      return 'tsw_name'
    case 'isTender':
      return 'is_tender'
    case 'providerHazmatType':
      return 'provider_hazmat_type'
    case 'paymentStatus':
      return 'payment_status'
    case 'dlOrder':
      return 'dl_order'
    case 'procurementDlReason':
      return 'procurement_dl_reason'
    case 'isShippedInAdvance':
      return 'is_shipped_in_advance'
    case 'supply_paid_date':
      return 'supply_paid_date'
    case 'details':
    case 'actions':
    default:
      logError(
        'Wrong column field (unknown or only frontend field) in adapter.',
      )
      return ''
  }
}
