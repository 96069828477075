import { API_URL } from 'src/config/environment'
import apiCreator from '../../api-creator'
import {handleErrorWithDefaultValue} from '../utils'
import { ClientType } from 'src/atoms/static-data/client-types-atom'

export function getClientTypes(): Promise<ClientType[]> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/client-types`,
    )
    .then(res => {
      const rawItems: ClientType[] = res.data.client_types
      return rawItems
    })
    .catch(handleErrorWithDefaultValue([]))
}

