import {Button} from '@mantine/core'
import React from 'react'
import {useRedirectFn} from '../../../../../hooks/route-hooks'
import {useAtomState} from '../../../../../hooks/use-atom-state'
import {i18n_get} from '../../../../../lng/i18n'
import {isByItems} from '../../../../../types/types'
import {createMultipleParam, verify} from '@appscience/utils'
import {
  selectedWarehousesAtom,
  warehousesChipTypeAtom,
} from '../../../common/selected-warehouses-atom'
import {toHubAtoms} from '../../hub/model'
import {toWarehouseCurrentTab} from '../model'
import {toProviderAtoms} from '../../provider/model'
import {CheckMarkIcon} from '../../../../../icons'
import {useAction} from '@reatom/react'
import {pageLoadingAtom} from 'src/atoms/page-loading-atom'
import {removeFalseValues} from '@app/utils/remap'
import {getApi} from 'src/api'
import {registerPageLocationsAtom} from '../register-page/model/locations-atom'

export function ToWarehouseLayoutActions() {
  const selectedWarehouses = useAtomState(selectedWarehousesAtom)
  const warehouseChipType = useAtomState(warehousesChipTypeAtom)
  const currentTab = useAtomState(toWarehouseCurrentTab)

  if (selectedWarehouses.length === 0) {
    return null
  }

  return (
    <div className='flex items-center mr-3'>
      {isByItems(currentTab) ? (
        warehouseChipType === 'provider' ? (
          <ProviderItemsActions />
        ) : null
      ) : warehouseChipType === 'provider' ? (
        <ProviderOrdersActions />
      ) : (
        <HubPackagesActions />
      )}
    </div>
  )
}

function ProviderOrdersActions() {
  const orders = useAtomState(toProviderAtoms.orders)
  const selectedOrderId = useAtomState(
    toProviderAtoms.ordersTableAtoms.selectedRowId,
  )
  const setLoading = useAction(pageLoadingAtom.set)
  const redirectTo = useRedirectFn('/provider')
  const rowId = Object.keys(removeFalseValues(selectedOrderId))
  if (rowId.length !== 1) {
    return null
  }
  const selectedOrder = selectedOrderId && orders[rowId[0]]

  return (
    <Button
      children={i18n_get.t('Provider.ToWarehouse.Accept')}
      leftIcon={<CheckMarkIcon />}
      onClick={() => {
        setLoading('loading')
        redirectTo(
          `/to-warehouse/process?warehouseId=${selectedOrder.provider}&orderId=${selectedOrder.id}`,
        )
      }}
    />
  )
}

function ProviderItemsActions() {
  const items = useAtomState(toProviderAtoms.items)
  const selectedIndicesObj = useAtomState(
    toProviderAtoms.itemsTableAtoms.selectedRowIds,
  )
  const setLoading = useAction(pageLoadingAtom.set)
  const redirectTo = useRedirectFn('/provider')
  const selectedIndices = Object.keys(selectedIndicesObj)
  if (selectedIndices.length === 0) {
    return null
  }
  const selectedItems = Object.values(items).filter((item, index) =>
    selectedIndices.includes(item.id),
  )

  const selectedIds = selectedItems.map(el => el.id)

  return (
    <Button
      children={i18n_get.t('Provider.ToWarehouse.Accept')}
      leftIcon={<CheckMarkIcon />}
      onClick={() => {
        setLoading('loading')
        redirectTo(
          `/to-warehouse/process?${createMultipleParam(selectedIds, 'id')}`,
        )
      }}
    />
  )
}

function HubPackagesActions() {
  const selectedPackageIndicesObj = useAtomState(
    toHubAtoms.packagesTableAtoms.selectedRowId,
  )
  const locationsRecordAction = useAction(registerPageLocationsAtom.set)
  const selectedIndice = Object.keys(removeFalseValues(selectedPackageIndicesObj))
  const packages = useAtomState(toHubAtoms.packages)
  const selectedPackages = Object.values(packages).find(item =>
    selectedIndice.includes(item.id),
  )

  const selectedPackage =
    selectedPackages && verify(packages[selectedPackages.id])
  const redirectTo = useRedirectFn('/provider')
  if (!selectedPackage) {
    return null
  }

  const onClickHandler = async () => {
    const locationsRecord = await getApi().provider.getWarehouseLocations({
      warehouseIds: [selectedPackage.toWarehouseId],
    })
    locationsRecordAction(locationsRecord)
    redirectTo(`/to-warehouse/process?packageId=${selectedPackages.id}`)
  }

  return (
    <Button
      children={i18n_get.t('Provider.ToWarehouse.Accept')}
      leftIcon={<CheckMarkIcon />}
      onClick={onClickHandler}
    />
  )
}
