import { CSSObject, useMantineTheme } from "@mantine/core"

export function getCommonInputStyles(): CSSObject {

  const {colors} = useMantineTheme()

  const customDisabledColor = colors.gray[6]

  return { 
    paddingLeft: 8, 
    paddingRight: 8,
    ":disabled": {
      '::placeholder': {
        color: customDisabledColor,
      },
      color: customDisabledColor,
    }  
  }
}
