import { AxiosResponse } from 'axios'
import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'

export type UploadScifinderExcelFilesResponseData = {
  count: number;
}
export type UploadScifinderExcelFilesResponse = AxiosResponse<UploadScifinderExcelFilesResponseData>

export async function uploadScifinderExcelFiles(
  s3Keys: Array<string>,
  abortSignal: AbortSignal,
): Promise<UploadScifinderExcelFilesResponseData> {
  const { job_id: jobId } = await getUploadScifinderExcelFilesJobId(
    s3Keys,
    abortSignal,
  )

  return getJobResult<UploadScifinderExcelFilesResponseData>({
    jobId,
    abortSignal,
  })
}

type GetUploadScifinderExcelFilesJobIdRequest = {
  s3_keys: Array<string>;
}
type GetUploadScifinderExcelFilesJobIdResponseData = {
  job_id: string;
}
type GetUploadScifinderExcelFilesJobIdResponse = AxiosResponse<GetUploadScifinderExcelFilesJobIdResponseData>

async function getUploadScifinderExcelFilesJobId(
  s3Keys: Array<string>,
  abortSignal: AbortSignal,
): Promise<GetUploadScifinderExcelFilesJobIdResponseData> {
  return apiCreator
    .post<
      GetUploadScifinderExcelFilesJobIdResponseData,
      GetUploadScifinderExcelFilesJobIdResponse,
      GetUploadScifinderExcelFilesJobIdRequest
    >(
      `${API_URL.SCIFINDER}/upload-excels-async`,
      {
        s3_keys: s3Keys,
      },
      {
        signal: abortSignal,
      },
    )
    .then(({ data }) => data)
}

type GetJobResultRequest = {
  jobId: string;
  abortSignal: AbortSignal;
  isPolling?: boolean;
}
enum GetJobResultResponseResultEnum {
  Success = 'success',
  Error = 'error',
  NoData = 'no_data',
}
type GetJobResultResponse = {
  result: GetJobResultResponseResultEnum;
  data: string;
}

async function getJobResult<ResultDataType>({
  jobId,
  abortSignal,
  isPolling = true,
}: GetJobResultRequest): Promise<ResultDataType> {
  try {
    const axiosResponse = await apiCreator
      .get<GetJobResultResponse>(
        `${API_URL.SCIFINDER}/get-job-result`,
        {
          params: {
            id: jobId,
            polling: isPolling,
          },
          signal: abortSignal,
        },
      )

    const responseData: GetJobResultResponse = axiosResponse.data

    if (
      axiosResponse.status === 200
      && responseData?.result === GetJobResultResponseResultEnum.Success
    ) {
      return JSON.parse(responseData?.data) as ResultDataType
    }

    if (responseData?.result === GetJobResultResponseResultEnum.NoData) {
      return await getJobResult({ jobId, abortSignal, isPolling })
    }

    if (responseData?.result === GetJobResultResponseResultEnum.Error) {
      throw new Error(responseData?.data)
    }

    throw new Error(`Failed long polling with status code ${axiosResponse.status}`)
  }
  catch (error) {
    return Promise.reject(error)
  }
}
