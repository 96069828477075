import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'

interface Params {
  ids: Array<string>
}

export function addItemsToStock(data: Params): Promise<void> {
  return apiCreator
    .post(
      `${API_URL.TWIN}/items-add-to-stock`,
      {
        ids: data.ids,
      },
    )
}
