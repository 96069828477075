import { AxiosResponse } from 'axios'
import { API_URL } from 'src/config/environment'
import apiCreator from '../../api-creator'

export type DeleteGroupRequest = {
  id: string;
}
export type DeleteGroupResponseData = void
export type DeleteGroupResponse = AxiosResponse<DeleteGroupResponseData>

export async function deleteGroupById(groupId: string): Promise<DeleteGroupResponseData> {
  return apiCreator
    .post<DeleteGroupResponseData, DeleteGroupResponse, DeleteGroupRequest>(
      `${API_URL.AUTH}/delete-user-group`,
      {
        id: groupId,
      },
    )
    .then(({ data }) => data)
}
