export enum UserType {
  SuperAdmin = 'superadmin',
  Admin = 'admin',
  User = 'user',
}

export type Position = {
  id: string;
  title: string;
}

export type PermissionGroupBase = {
  id: string;
  title: string;
}

export type User = {
  id: string;
  amoId: string;
  firstName: string;
  lastName: string;
  email: string;
  gmail: string;
  phoneNumber: string;
  position: Position | null;
  userType: UserType;
  permissionGroup: PermissionGroupBase | null;
}

export type UserGroup = {
  id: string;
  title: string;
  interfaces: Array<string>;
  sections: Array<string>;
  procurementObjects: Array<string>;
  warehouseObjects: Array<string>;
}
