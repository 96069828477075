import {verify} from '@appscience/utils'
import {format} from 'date-fns'
import { API_URL } from 'src/config/environment'
import {STRING_DATE_FORMAT} from 'src/config/constants'
import {ToWarehouseRegisterItem} from '../../views/warehouse/to-warehouse/common/register-page/model/model'
import apiCreator from '../api-creator'
import { HubParams, HubRegisterRequestData, RegisterHubItem } from './register-items.type'

export function registerHubItems(params: HubParams): Promise<void> {
  const data: HubRegisterRequestData = {
    package_id: params.packageId,
    arrival_date:
      params.arrivalDate && format(params.arrivalDate, STRING_DATE_FORMAT),
    registration_date: format(params.registerDate, STRING_DATE_FORMAT),
    items: params.items.map(remapToItemData),
  }
  return apiCreator.post(`${API_URL.TWIN}/register-package`, data)
}

function remapToItemData(data: ToWarehouseRegisterItem): RegisterHubItem {
  const getMessage = (field: keyof RegisterHubItem) =>
    `Provider "Register Page" item fields verification failed. Field: ${field}`
  return {
    id: data.id,
    original_name: data.originalName,
    brand: data.brand,
    actually_delivered: data.actuallyDelivered,
    catalogue: data.catalogueId,
    country_of_origin: data.countryOfOrigin,
    provider_temp: verify(data.temperatureRegime, getMessage('provider_temp')),
    provider_lot: verify(data.lot, getMessage('provider_lot')),
    provider_hazmat_type: data.providerHazmatType,
    provider_hazmat: data.hazmatComment ?? '',
    provider_locations: data.locations,
    provider_exp: data.expirationDate
      ? format(verify(data.expirationDate), STRING_DATE_FORMAT)
      : '',
    provider_comment: data.exportComment,
    hsn_codes: data.hsnCodes,
    partner_invoice: data.partnerInvoice,
  }
}
