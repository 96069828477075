import { createAtom, getState } from '@reatom/core'
// import { createOrderPageFieldsAtom } from '../order-fields.atom'
import { orderSumLimitAtom } from './order-sum-limit.atom'
import { convertCurrency } from 'src/atoms/static-data/exchange-rates-atom'
// import { createOrderPageItemsAtom } from '../items'
import { IndexedCreateOrderPageItem } from './items/items.atom.type'
import { createPrimitiveAtom } from '@reatom/core/primitives'

const itemsAtom = createPrimitiveAtom<Array<IndexedCreateOrderPageItem>>([])

export const isSumLimitExceededAtom = createAtom(
  {
    // items: createOrderPageItemsAtom,
    items: itemsAtom,
    set: (value: boolean) => value,
    reset: () => { },
  },
  ({ onAction, get, schedule, onChange }, state = false) => {
    const items = get('items')

    onAction('set', value => {
      state = value
    })

    onAction('reset', () => {
      state = false
    })

    onChange('items', () => {
      schedule(async dispatch => {
        dispatch(isSumLimitExceededAtom.reset())

        if (!items.length) {
          return
        }

        const { cost, tax, otherCost } = items.reduce(
          (result: CreateOrderItemsSummary, item) => {
            result.cost += item.cost || 0
            result.tax += item.tax?.value || 0
            result.otherCost += item.otherCost?.value || 0
            return result
          },
          {
            cost: 0,
            tax: 0,
            otherCost: 0,
          },
        )
        // const fromCurrencyId = getState(createOrderPageFieldsAtom).currencyId || 'USD'
        const fromCurrencyId = 'USD'
        const { currency: toCurrencyId, headOfProcurementLimit } = getState(orderSumLimitAtom)

        const convertedOtherCost = convertCurrency(fromCurrencyId, toCurrencyId, otherCost)
        if (convertedOtherCost > 500) {
          dispatch(isSumLimitExceededAtom.set(true))
          return
        }

        const sum = convertedOtherCost < 250
          ? cost + tax
          : cost + tax + otherCost
        const convertedSum = convertCurrency(fromCurrencyId, toCurrencyId, sum)
        const isSumLimitExceeded = convertedSum > headOfProcurementLimit

        dispatch(isSumLimitExceededAtom.set(isSumLimitExceeded))
      })
    })

    return state
  },
)

interface CreateOrderItemsSummary {
  cost: number
  tax: number
  otherCost: number
}
