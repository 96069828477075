import { Store } from '@reatom/core'
import { getApi } from 'src/api'
import { ServerTableSettings } from 'src/components/table/utils/settings-atom'
import { invoicesAtom } from '../main'

export async function loadInvoices({
  store,
  settings,
  byOrderId,
}: {
  store: Store,
  settings: ServerTableSettings,
  byOrderId: string | null
}): Promise<void> {
  const invoices = await getApi().procurement.getInvoices({
    settings,
    byOrderId: byOrderId || '',
  })

  store.dispatch(invoicesAtom.set(invoices))
}
