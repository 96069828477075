import {logError} from '@appscience/utils'
import {CommonLocalStorage} from './types'
import {useMemo} from 'react'

export interface ErpStorage {
  common: CommonLocalStorage
}

type StorageKeys<T extends keyof ErpStorage> = keyof ErpStorage[T]
type KeyValue<
  Storage extends keyof ErpStorage,
  Key extends keyof ErpStorage[Storage],
> = ErpStorage[Storage][Key]

export function useLocalStorage<STORAGE extends keyof ErpStorage>(
  storage: STORAGE,
) {
  return useMemo(() => getLocalStorageCallbacks(storage), [storage])
}

const generateKey = (storage: keyof ErpStorage) => `erp_${storage}`

export function getLocalStorageCallbacks<STORAGE extends keyof ErpStorage>(
  storage: STORAGE,
) {
  function set<KEY extends StorageKeys<STORAGE>>(
    key: KEY,
    value: KeyValue<STORAGE, KEY>,
  ) {
    setValueToStorage(storage, key, value)
  }
  function get<KEY extends StorageKeys<STORAGE>>(key: KEY) {
    return getStorageValue(storage, key)
  }

  return {
    setValueToStorage: set,
    getStorageValue: get,
    getStorageObject: () => getStorageObject(storage),
  }
}

export function getStorageObject<STORAGE extends keyof ErpStorage>(
  storage: STORAGE,
): ErpStorage[STORAGE] | null {
  const rawValue = localStorage.getItem(generateKey(storage))
  if (rawValue === null) {
    return null
  }
  return parseJSON<ErpStorage[STORAGE]>(rawValue)
}

function getStorageValue<
  STORAGE extends keyof ErpStorage,
  KEY extends StorageKeys<STORAGE>,
>(storage: STORAGE, key: KEY): KeyValue<STORAGE, KEY> | null {
  const parsedData = getStorageObject(storage)
  return parsedData ? parsedData[key] : null
}

function setValueToStorage<
  STORAGE extends keyof ErpStorage,
  KEY extends StorageKeys<STORAGE>,
>(storage: STORAGE, key: KEY, value: KeyValue<STORAGE, KEY>) {
  const currentState = getStorageObject(storage)
  localStorage.setItem(
    generateKey(storage),
    JSON.stringify({
      ...currentState,
      [key]: value,
    }),
  )
}

export function parseJSON<EXPECTED = undefined>(json: string): EXPECTED | null {
  try {
    return JSON.parse(json) as EXPECTED
  }
  catch (error) {
    logError(`json parse failed. Value: ${json}`)
    return null
  }
}
