import { i18n_get } from 'src/lng/i18n'
import { CreateOrderPageRequiredItemField } from '../../errors/item-fields-validation'
import { getColumnTitle } from 'src/views/common/table-utils/get-column-title'

type ErrorField = CreateOrderPageRequiredItemField | 'costLimit'

export function getOrderValidationErrorMessage(
  itemsErrorFields: Array<ErrorField>,
  shouldShowFieldsName: boolean,
): string {
  if (!shouldShowFieldsName) {
    return i18n_get.t('Procurement.CreateOrderPage.DefaultErrorMessage')
  }

  const fieldsText = itemsErrorFields
    .map(errorField =>
      getColumnTitle(getColumnTitleId(errorField)),
    )
    .join(', ')

  return i18n_get.t('Procurement.CreateOrderPage.DetailedErrorMessage', { fields: fieldsText })
}

function getColumnTitleId(errorField: ErrorField): string {
  if (errorField === 'invoicePrice') {
    return 'cost'
  }

  if (errorField === 'costLimit') {
    return 'clientPrice'
  }

  return errorField
}
