import {BrandsData} from '../../../atoms/static-data/brands-atom'
import { API_URL } from 'src/config/environment'
import apiCreator from '../../api-creator'
import {handleErrorWithDefaultValue} from '../utils'

interface ResponseBrandData {
  name: string
  alternative_names: Array<string>
}

export function getBrandsApi(): Promise<Array<BrandsData>> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/brands`,
    )
    .then(res => {
      const rawItems: Array<ResponseBrandData> = res.data.brands
      return rawItems.map(remapToStore)
    })
    .catch(handleErrorWithDefaultValue([]))
}

function remapToStore(data: ResponseBrandData): BrandsData {
  return {
    id: data.name,
    title: data.name,
    alternativeNames: data.alternative_names,
  }
}
