import {joinStrings} from '@appscience/utils'
import styles from './external-layer.module.css'
import {Dialog, Transition} from '@headlessui/react'
import React, {Fragment} from 'react'

type ExternalLayerProps = {
  show: boolean
  onOverlayClick?: () => void
  createContent: () => React.ReactNode
}

export const ExternalLayer = React.memo(
  ({show, onOverlayClick, createContent}: ExternalLayerProps) => (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        open={show}
        onClose={() => onOverlayClick && onOverlayClick()}
        className='fixed z-10 inset-0 overflow-y-auto'
      >
        <div className='flex items-center justify-center min-h-screen'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay
              className={joinStrings(styles.overlay, 'fixed inset-0')}
            />
          </Transition.Child>
          {/* Используется не как Fragment, т. к. нужен DOM-узел, к которому применять эффекты */}
          <Transition.Child
            enter='transition ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='transition ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            {createContent()}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  ),
)
