import {useCallback, useMemo} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {
  AppRoute,
  AppRoutesMap,
  BaseAppRoute,
  RoutesMapIteration,
  TypedHistory,
  WithQuery,
} from '../routes/types'
import {isDef, logError} from '@appscience/utils'

type UseCurrentRouteType = 'base' | 'short'

export function useCurrentRoute(): AppRoute
export function useCurrentRoute<T extends BaseAppRoute>(
  data: T | Array<T>,
): AppRoutesMap[T]
export function useCurrentRoute<T extends BaseAppRoute>(
  data: T | Array<T>,
  type: 'base',
): RoutesMapIteration<T>

export function useCurrentRoute<T extends BaseAppRoute | undefined = undefined>(
  routes?: T | Array<T>,
  type: UseCurrentRouteType = 'short',
) {
  const location = useLocation()
  if (!isDef(routes)) {
    return trimQueryAndSlash(location.pathname)
  }

  const typedRoutes = Array.isArray(routes)
    ? routes
    : ([routes] as Array<BaseAppRoute>)
  for (const baseRoute of typedRoutes) {
    if (location.pathname.startsWith(baseRoute)) {
      return trimQueryAndSlash(
        type === 'base'
          ? location.pathname
          : location.pathname.slice(typedRoutes.length),
      )
    }
  }
  throw new Error(`The current router does not match the requested: ${routes}`)
}

function trimQueryAndSlash(route: string) {
  const queryIndex = route.indexOf('?')
  return queryIndex === -1
    ? route.endsWith('/')
      ? route.slice(0, route.length - 1)
      : route
    : route.slice(0, queryIndex)
}

export function useCurrentBaseRoute(): BaseAppRoute {
  const {pathname} = useLocation()
  const baseRoute: BaseAppRoute = pathname.slice(
    0,
    pathname.indexOf('/', 1) || undefined,
  )
  if (!isBaseRoute(baseRoute)) {
    logError(`The current router is not a base. Value: "${baseRoute}"`)
  }
  return baseRoute
}

function isBaseRoute(route: string): boolean {
  const bases: Set<BaseAppRoute> = new Set([
    '/auth',
    '/procurement',
    '/provider',
    '/iam',
    '/compliance',
  ])
  return bases.has(route as BaseAppRoute)
}

//-------------------------------------------
type RedirectCallback<T extends BaseAppRoute | undefined> = (
  route: T extends BaseAppRoute
    ? WithQuery<AppRoutesMap[T]>
    : WithQuery<AppRoute>,
) => void

//TODO:improvements Реализовать возможность использования не только baseRoute
export function useRedirectFn<T extends BaseAppRoute | undefined = undefined>(
  baseRoute?: T,
): RedirectCallback<T> {
  const history = useTypedHistory()
  const location = useLocation()
  const mainRoute = useCurrentRoute()

  return useCallback(route => {
    const newRoute = isDef(baseRoute) ? `${baseRoute}${route}` : route
    const currentRoute = `${mainRoute}${location.search}`
    if (newRoute !== currentRoute) {
      history.push(newRoute)
    }
  }, [baseRoute, history, location],
  )
}

export function useTypedHistory(): TypedHistory {
  const history = useHistory()
  return useMemo(
    () => ({
      ...history,
      typedPush: history.push,
    }),
    [history],
  )
}
