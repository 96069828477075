import {createLSDecorator} from '@app/utils/reatom'
import {updateObject, verify} from '@appscience/utils'
import {createAtom} from '@reatom/core'
import {createPrimitiveAtom} from '@reatom/core/primitives'
import {RowSelectionState} from '@tanstack/react-table'
import produce from 'immer'
import {CreatePackageFormState} from '../content/form/use-form'
import {CreatePackageItem} from './types'

export type CreatePackagePageModeData = CreatingModeData | EditingModeData
type CreatingModeData = {type: 'create'}
type EditingModeData = {
  type: 'edit'
  packageId: string
}

export const createPackagePageModeAtom =
  createPrimitiveAtom<CreatePackagePageModeData>({type: 'create'})
export const createPackagePageWarehouseAreaIdAtom =
  createPrimitiveAtom<string>('')
export const createPackagePageSelectedItemIdsAtom =
  createPrimitiveAtom<RowSelectionState>({})

export const createPackagePageItemsAtom = createAtom(
  {
    init: (items: Array<CreatePackageItem>) => items,
    updateField: <K extends keyof CreatePackageItem>(
      id: string,
      key: K,
      value: CreatePackageItem[K],
    ) => ({id, key, value}),
    syncFieldWithFirstItem: <K extends CreatePackageItem>({
      field,
      itemId,
    }: { 
      field: keyof K, 
      itemId: string,
    }) => ({ field, itemId }),
  },
  ({onAction, schedule}, state: Array<CreatePackageItem> = []) => {
    onAction('init', value => {
      state = value
      schedule(dispatch => {
        dispatch(
          createPackagePageSelectedItemIdsAtom.set(
            state.reduce((acc, currentItem) => {
              acc[currentItem.id] = true
              return acc
            }, {} as RowSelectionState),
          ),
        )
      })
    })
    onAction('updateField', ({id, key, value}) => {
      state = produce(state, draft => {
        const item = verify(draft.find(x => x.id === id))
        updateObject(item, key, value)
      })
    })
    onAction('syncFieldWithFirstItem', ({ field, itemId }) => {
      if (state[0].id === itemId) {
        state = produce(state, draft => {
          const item = verify(draft.find(draftItem => draftItem.id === itemId))

          draft.forEach(elem => {
            updateObject(elem, field, item[field])
          })
        })
      }
    })
    return state
  },
  {
    decorators: [createLSDecorator('createPackagePage.itemsAtom')],
  },
)

export const initFormDataAtom = createPrimitiveAtom<CreatePackageFormState>(
  {} as CreatePackageFormState,
)
