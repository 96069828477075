import {format} from 'date-fns'
import { API_URL } from 'src/config/environment'
import {STRING_DATE_FORMAT} from '../../config/constants'
import {ProcurementEditItemPopupDefaultItemData} from '../../views/common/edit-item-popup/procurement'
import apiCreator from '../api-creator'
import {Api_Item} from '../common/item-model'

interface SaveItemData extends Api_Item {
  id: string
  brand: string
  catalogue_id: string
  country: string
  procurement_comment: string
  name: string //mandatory for backend
  original_name: string
  store: string
  procurement: string
  provider: string
  procurement_dl_reason: Array<string>
}

interface SaveItemRequestData {
  items: Array<SaveItemData>
}

export function saveProcurementItems(
  items: Array<ProcurementEditItemPopupDefaultItemData>,
  initItems: Record<string, Api_Item>,
): Promise<void> {
  const data: SaveItemRequestData = {
    items: items.map(x => remapToSaveItemData(x, initItems)),
  }
  return apiCreator.post(`${API_URL.TWIN}/save-items`, data)
}


function remapToSaveItemData(
  data: ProcurementEditItemPopupDefaultItemData,
  initItems: Record<string, Api_Item>,
): SaveItemData {
  return {
    ...initItems[data.id],
    id: data.id,
    priority: data.priority,
    brand: data.brand,
    catalogue_id: data.catalogueId,
    country: data.country,
    procurement_comment: data.procurementComment,
    name: data.invoiceName,
    original_name: data.originalName,
    extra_charge_amount: data.extraChargeAmount,
    extra_charge_percent: data.extraChargePercent,
    esd_fix_date: data.esdFix == null
      ? ''
      : format(data.esdFix, STRING_DATE_FORMAT),
    partners_interest: data.partnerInterest,
    partner_invoice: data.partnerInvoice,
    store: data.supposedStore,
    sample: data.sample,
    procurement: data.responsibleProcurement,
    provider: data.provider,
    provider_temp: data.temperature,
    procurement_dl_comments: data.procurementDlComment,
    procurement_dl_reason: data.status,
    hs_code_china: data.hsCodeChina,
    export_documents: data.exportDocuments,
    cost: data.cost,
    eod: data.estimatedOrderDate
      ? format(data.estimatedOrderDate, STRING_DATE_FORMAT)
      : '',
  }
}
