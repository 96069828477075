import {TermsOfPaymentData} from '../../../atoms/static-data/terms-of-payment'
import { API_URL } from 'src/config/environment'
import {TermsOfPayment} from '../../../types/order-status'
import apiCreator from '../../api-creator'
import {handleErrorWithDefaultValue} from '../utils'

export function getTermsOfPaymentApi(): Promise<Array<TermsOfPaymentData>> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/terms-of-payment`,
    )
    .then(res => {
      const rawItems: Array<TermsOfPayment> = res.data.terms_of_payment
      return rawItems.map(remapToStore)
    })
    .catch(handleErrorWithDefaultValue([]))
}

function remapToStore(data: string): TermsOfPaymentData {
  return {
    id: data,
    title: data,
  }
}