import { API_URL } from 'src/config/environment'
import { DuplicateItemPopupData } from '../../views/warehouse/to-warehouse/provider/table-by-items/popups/duplicate/model/props'
import apiCreator from '../api-creator'
import { Api_Item } from '../common/item-model'
import { parseDate } from '@app/utils/date'

interface Response {
  remappedItem: DuplicateItemPopupData
  rawItem: Api_Item
}

export async function getDuplicateItemPopupData(id: string): Promise<Response> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/get-item?id=${id}`,
    )
    .then(res => {
      const rawItem: Api_Item = res.data.item
      return {
        remappedItem: remapToDuplicateItemPopupData(rawItem),
        rawItem,
      }
    })
}

export function remapToDuplicateItemPopupData(
  data: Api_Item,
): DuplicateItemPopupData {
  return {
    id: data.id,
    dealDate: parseDate(data.deal_approved_date),
    deadlineDate: parseDate(data.deadline_date),
    client: data.client_name,
    salesId: data.responsible_sales?.name || '',
    procurement: data.procurement,
    scd: data.responsible_scd,
    amoId: data.deal_id,
    countryId: data.country,
    storeId: data.store,
    brandId: data.brand,
    invoiceName: data.name,
    originalName: data.original_name,
    catalogueId: data.catalogue_id,
    quantity: data.quantity,
    sumCost: data.full_cost,
    currencyId: data.currency,
    hazmatComment: data.provider_hazmat,
    temperature: data.provider_temp,
  }
}
