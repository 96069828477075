import {logError} from '@appscience/utils'
import {EnvelopeIcon, LockClosedIcon} from '@heroicons/react/24/outline'
import {Button, TextInput} from '@mantine/core'
import {useForm} from '@mantine/form'
import {useAction} from '@reatom/react'
import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {getApi} from '../../../api'
import {getRoleByEmail, userAtom} from '../../../atoms/user-atom'
import {AUTH_TOKEN} from '../../../config/constants'
import {useQuery} from '../../../hooks/use-query'
import {i18n_get} from '../../../lng/i18n'
import {defaultError} from '@app/utils/errors'

export interface LoginFormState {
  email: string
  password: string
}

export const LoginForm = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()
  const query = useQuery()
  const handleSetUserData = useAction(userAtom.set)
  const form = useForm<LoginFormState>({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: x => validateEmail(x as string),
      password: defaultValidation,
    },
  })

  const onLogin = () => {
    if (form.validate().hasErrors) {
      return
    }

    setLoading(true)
    const {email, password} = form.values

    getApi()
      .auth
      .login({
        password,
        email,
      })
      .then(({ data }) => {
        handleSetUserData({
          id: email,
          email: email,
          role: getRoleByEmail(email),
        })
        localStorage.setItem('appSceienceEmail', email)
        localStorage.setItem(AUTH_TOKEN, data.auth_token)

        const redirect = query.get('redirect')
        history.push(redirect ? redirect : '/')
      })
      .catch(e => {
        defaultError(e)
        logError('Auth.ErrorMessage')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <form>
      <TextInput
        type='email'
        label={i18n_get.t('Auth.EmailLabel')}
        icon={<EnvelopeIcon className='text-blue-400 w-5 h-5' />}
        required
        {...form.getInputProps('email')}
      />
      <TextInput
        className='mt-5'
        type='password'
        label={i18n_get.t('Auth.PasswordLabel')}
        icon={<LockClosedIcon className='text-blue-400 w-5 h-5' />}
        required
        {...form.getInputProps('password')}
      />
      <Button className='mt-6 w-full' loading={loading} onClick={onLogin}>
        {i18n_get.t('Auth.SignIn')}
      </Button>
    </form>
  )
}

function defaultValidation(x: unknown): null | string {
  return x ? null : i18n_get.t('Auth.InputPass')
}

function validateEmail(x: string) {
  return /^(.+)@(.+)$/.test(x) ? null : i18n_get.t('Auth.EnterValidEmail')
}
