import { createMultipleParam } from '@appscience/utils'
import { indexBy, prop } from 'ramda'
import { API_URL } from 'src/config/environment'
import { ShippedPackage } from '../../../views/warehouse/from-warehouse/shipped/model/atoms'
import apiCreator from '../../api-creator'
import { Api_PackageAndShipment } from '../../common/shipment-model'
import { handleErrorWithDefaultValue } from '../../common/utils'
import { parseDate } from '@app/utils/date'

interface Params {
  warehouseIds: Array<string>
}

export function getShippedPackages({
  warehouseIds,
}: Params): Promise<Record<string, ShippedPackage>> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/packages?${createMultipleParam(
        warehouseIds,
        'from_warehouse_id',
      )}&shipment_status=shipped`,
    )
    .then(res => {
      const rawData: Array<Api_PackageAndShipment> = res.data.packages
      return indexBy(prop('id'), rawData.map(remapToShippedPackage))
    })
    .catch(handleErrorWithDefaultValue({}))
}

export function remapToShippedPackage({
  package: packageData,
  shipment,
}: Api_PackageAndShipment): ShippedPackage {
  return {
    id: packageData.id,
    departureDate: parseDate(shipment.departure_date),
    trackIds: shipment.track_number,
    fromWarehouseId: shipment.from_warehouse_id,
    toWarehouseId: shipment.to_warehouse_id,
    transporter: shipment.transporter,
    temperatures: packageData.temperature,
    boxNumber: packageData.box_numbers,
    isHazmat: packageData.hazmat,
    quantity: packageData.items_quantity,
    invoicePriceSum: packageData.package_sum,
    currencyId: packageData.currency,
    invoiceNumber: packageData.package_invoice,
    packageComment: packageData.provider_comments,
    tag: shipment.tag,
    express: shipment.express,
    providerTagToHub: packageData.provider_tag_to_hub,
    providerTagToTransit: packageData.provider_tag_to_transit,
    providerTagToRu: packageData.provider_tag_to_ru,
  }
}
