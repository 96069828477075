import {createPrimitiveAtom} from '@reatom/core/primitives'
import {RowSelectionState} from '@tanstack/react-table'
import {createSettingsAtom} from 'src/components/table/utils/settings-atom'
import { TABLE_ID } from 'src/components/table/utils/table-id'

//-----------------------ITEMS TABLE--------------------------
export type ShippedViewItem = ShippedItem

export interface ShippedItem {
  id: string
  airtableLink: string
  departureDate: Date | null
  toWarehouseId: string
  transporterId: string
  amoId: string
  brand: string
  invoiceName: string
  originalName: string
  catalogueId: string
  temperatureId: string
  store: string
  grossWeight: number
  netWeight: number
  toHub0: Date | null
  hub0: string
  trackHub0: string
  tswed: Date | null
  hazmat: string
  quantity: number
  invoicePrice: number
  hsCodeSecondary: string
  currencyId: string
  originCountry: string
  lot: string
  invoiceNumber: string
  exportStatuses: Array<string>
  exportComment: string
  boxNumber: Array<string>
  express: boolean
  locations: Array<string>
  prtComments: string
  providerTagToRu: string
  providerTagToTransit: string
  providerTagToHub: string
  procurement: string
  responsibleSCD: string
  sdExp: string
}

export const shippedItemsAtom = createPrimitiveAtom<
  Record<string, ShippedItem>
>({})
export const shippedItemsTableSelectedIds =
  createPrimitiveAtom<RowSelectionState>({})
export const shippedItemsTableSelectedPackageId = createPrimitiveAtom<
  string | null
>(null)

//-----------------------PACKAGES TABLE--------------------------
export type ShippedViewPackage = ShippedPackage

export interface ShippedPackage {
  id: string
  departureDate: Date | null
  fromWarehouseId: string
  toWarehouseId: string
  tag: string
  temperatures: Array<string>
  boxNumber: Array<string>
  transporter: string
  trackIds: Array<string>
  quantity: number
  invoicePriceSum: number
  invoiceNumber: string
  packageComment: string
  isHazmat: boolean
  currencyId: string
  express: boolean
  providerTagToRu: string
  providerTagToTransit: string
  providerTagToHub: string
}

export const shippedPackagesAtom = createPrimitiveAtom<
  Record<string, ShippedPackage>
>({})
export const shippedPackagesTableSelectedId =
  createPrimitiveAtom<RowSelectionState>({})

export const shippedItemsSettings = createSettingsAtom({
  tableId: TABLE_ID.SHIPPED_ITEMS,
  isLazyLoading: false,
})

export const shippedPackagesSettings = createSettingsAtom({
  tableId: TABLE_ID.SHIPPED_PACKAGES,
  isLazyLoading: false,
})
