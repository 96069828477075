import { defaultError } from '@app/utils/errors'
import { successMessage } from '@app/utils/feedback'
import { useModal } from '@appscience/hooks'
import { createMultipleParam } from '@appscience/utils'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import { Button } from '@mantine/core'
import { getApi } from '../../../../api'
import { useRedirectFn } from '../../../../hooks/route-hooks'
import { useAloneAction } from '../../../../hooks/use-alone-action'
import { useAtomState } from '../../../../hooks/use-atom-state'
import { i18n_get } from '../../../../lng/i18n'
import { useChipSelectedWarehouses } from '../../common/selected-warehouses-atom'
import {
  InWarehouseItem,
  inWarehouseAtoms,
  inWarehouseSelectedRowIdsAtom,
} from '../model/atoms'
import { inWarehouseLoadData } from '../model/load-data'
import { AddItemsToPackagePopup } from './add-items-to-package-popup'
import { AddItemsToPackagePopupItem } from './add-items-to-package-popup/model'
import { warehousesAtom } from 'src/atoms/static-data/warehouses-atom'

export function InWarehouseLayoutActions() {
  const selectedWarehouses = useChipSelectedWarehouses()
  const selectedRowIds = useAtomState(inWarehouseSelectedRowIdsAtom)

  return (
    <div className='flex items-center mr-3 space-x-2'>
      {selectedWarehouses.length === 0 || selectedRowIds.length === 0 ? null : (
        <ProviderActions selectedIds={selectedRowIds} />
      )}
    </div>
  )
}

interface ProviderActionsProps {
  selectedIds: Array<string>
}

function ProviderActions({ selectedIds }: ProviderActionsProps) {
  const [loadingButton, setLoadingButton] = useState<
    'returnFromStock' | 'addToStock' | 'cancelRegister' | null
  >(null)
  const warehousesObj = useAtomState(warehousesAtom)
  const itemsObj = useAtomState(inWarehouseAtoms.items)
  const redirectTo = useRedirectFn('/provider')
  const firstItem = itemsObj[selectedIds[0]]
  const firstItemWarehouseAreaId = Object.values(warehousesObj)
    .find(
      ({ id, title }) =>
        firstItem?.provider === id
        || firstItem?.hub === title,
    )?.areaId
  const allItemsIsNotStock = selectedIds.every(id => !itemsObj[id].isStock)

  const handleLoadData = useAloneAction(inWarehouseLoadData)
  const api = getApi().provider.inWarehouse

  const onCancelRegister = () => {
    setLoadingButton('cancelRegister')
    api
      .cancelRegisterItems({ itemIds: selectedIds })
      .then(() => {
        successMessage(
          i18n_get.t('Provider.FromWarehouse.CancelRegister.SuccessMessage'),
        )
        return handleLoadData({ backgroundMode: true })
      })
      .catch(defaultError)
      .finally(() => setLoadingButton(null))
  }

  const onAddToStock = () => {
    setLoadingButton('addToStock')
    api
      .addItemsToStock({ ids: selectedIds })
      .then(() => {
        successMessage(
          i18n_get.t('Provider.InWarehouse.ToStock.SuccessMessage'),
        )
        return handleLoadData({ backgroundMode: true })
      })
      .catch(defaultError)
      .finally(() => setLoadingButton(null))
  }

  const [openAddItemsToPackagePopup, hideAddItemsToPackagePopup] = useModal(
    AddItemsToPackagePopup,
    {
      title: i18n_get.t('AddItemsToPackagePopup.Title'),
      onSuccess: () => handleLoadData({ backgroundMode: true }),
      closeFn: () => hideAddItemsToPackagePopup(),
      getItems: () =>
        Promise.resolve(
          selectedIds.map(id =>
            remapToAddItemsToPackagePopupItem(itemsObj[id]),
          ),
        ),
    },
  )

  const onCreatePackage = () => {
    redirectTo(
      `/in-warehouse/create-package?areaId=${firstItemWarehouseAreaId}&${createMultipleParam(
        selectedIds,
        'id',
      )}`,
    )
  }

  return (
    <>
      {Boolean(firstItemWarehouseAreaId) && selectedIds.length > 0 && (
        <Button
          disabled={loadingButton !== null}
          leftIcon={<PlusCircleIcon className='w-5 h-5' />}
          onClick={onCreatePackage}
        >
          {i18n_get.t('Provider.InWarehouse.CreatePackageAction')}
        </Button>
      )}
      <Button
        onClick={openAddItemsToPackagePopup}
        disabled={loadingButton !== null}
      >
        {i18n_get.t('Provider.InWarehouse.AddToPackageAction')}
      </Button>
      {allItemsIsNotStock && (
        <Button
          loading={loadingButton === 'addToStock'}
          onClick={onAddToStock}
          disabled={loadingButton !== null}
        >
          {i18n_get.t('Provider.InWarehouse.ToStockAction')}
        </Button>
      )}
      <Button
        onClick={onCancelRegister}
        loading={loadingButton === 'cancelRegister'}
        disabled={loadingButton !== null}
      >
        {i18n_get.t('Provider.InWarehouse.CancelAcceptAction')}
      </Button>
    </>
  )
}

function remapToAddItemsToPackagePopupItem(
  x: InWarehouseItem,
): AddItemsToPackagePopupItem {
  return {
    id: x.id,
    brand: x.brand,
    amoId: x.amoId,
    originalName: x.originalName,
    catalogueId: x.catalogueId,
    invoicePrice: x.sumCost,
    quantity: x.quantity,
    temperatureId: x.temperatureRegime,
    hazmat: x.hazmatComment,
    expiredDate: x.expiredDate,
  }
}
