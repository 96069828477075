import { API_URL } from 'src/config/environment'
import apiCreator from '../../api-creator'
import {handleErrorWithDefaultValue} from '../utils'
import { ExefType } from 'src/atoms/static-data/exef-types-atom'

export function getExefTypes(): Promise<ExefType[]> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/exef-types`,
    )
    .then(res => {
      const rawItems: ExefType[] = res.data.exef_types
      return rawItems
    })
    .catch(handleErrorWithDefaultValue([]))
}
