import {
  DEFAULT_PRIORITIZED_COMPLIANCE_ROUTES,
  DEFAULT_PRIORITIZED_IAM_ROUTES,
  DEFAULT_PRIORITIZED_PROCUREMENT_ROUTES,
  DEFAULT_PRIORITIZED_PROVIDER_ROUTES,
  DEFAULT_PRIORITIZED_ROUTES,
} from 'src/hooks/permissions/routes'
import { TypedRedirectProps } from '../../routes/components/typed-redirect'
import { AppRoute } from '../../routes/types'

function getDefaultRouteFn({
  allowedRoutesSet,
  fallbackRoute,
}: {
  allowedRoutesSet: Set<AppRoute>,
  fallbackRoute: AppRoute,
}): (defaultPrioritizedRoutes: Array<AppRoute>) => AppRoute {
  return defaultPrioritizedRoutes =>
    defaultPrioritizedRoutes
      .find(route => allowedRoutesSet.has(route))
      ?? fallbackRoute
}

export function getAllowedRedirects(
  allowedRoutesSet: Set<AppRoute>,
): TypedObject<AppRoute, TypedRedirectProps> {
  const getDefaultRoute = getDefaultRouteFn({
    allowedRoutesSet,
    fallbackRoute: '/',
  })

  return {
    '/': {
      to: getDefaultRoute(DEFAULT_PRIORITIZED_ROUTES),
    },
    '/procurement': {
      to: getDefaultRoute(DEFAULT_PRIORITIZED_PROCUREMENT_ROUTES),
    },
    '/provider': {
      to: getDefaultRoute(DEFAULT_PRIORITIZED_PROVIDER_ROUTES),
    },
    '/iam': {
      to: getDefaultRoute(DEFAULT_PRIORITIZED_IAM_ROUTES),
    },
    '/compliance': {
      to: getDefaultRoute(DEFAULT_PRIORITIZED_COMPLIANCE_ROUTES),
    },
  }
}
