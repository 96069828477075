import { AxiosResponse } from 'axios'
import apiCreator from '../../api-creator'
import { PermissionGroupWithId } from './model'
import { API_URL } from 'src/config/environment'

export type UpdateGroupRequest = PermissionGroupWithId
export type UpdateGroupResponseData = PermissionGroupWithId
export type UpdateGroupResponse = AxiosResponse<UpdateGroupResponseData>

export async function updateGroup(groupWithId: PermissionGroupWithId): Promise<UpdateGroupResponseData> {
  return apiCreator
    .post<UpdateGroupResponseData, UpdateGroupResponse, UpdateGroupRequest>(
      `${API_URL.AUTH}/save-user-group`,
      groupWithId,
    )
    .then(({ data }) => data)
}
