import { assert } from '@appscience/utils'
import { Store } from '@reatom/core'
import { createPrimitiveAtom } from '@reatom/core/primitives'
import { getTime, hoursToMilliseconds } from 'date-fns'
import { indexBy } from 'ramda'
import { getApi } from '../../api'
import { filterAllowedWarehouses } from 'src/hooks/permissions/allowed-warehouses'

export type WarehouseType = 'provider' | 'hub' | 'delivery_point'

export interface WarehouseData {
  id: string
  title: string
  type: WarehouseType
  areaId: string
}

let lastInitTimestamp: number | null

export const warehousesAtom = createPrimitiveAtom<
  Record<string, WarehouseData>
>({})

export async function updateWarehousesAtom({ dispatch }: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return warehousesAtom.getState()
  }
  const warehouses = await getApi().listsApi.getWarehouses()
  const allowedWarehouses = filterAllowedWarehouses(warehouses)

  const record = indexBy(x => x.id, allowedWarehouses)
  dispatch(warehousesAtom.set(record))
  lastInitTimestamp = getTime(new Date())
  return record
}

export function getWarehouseTitle(id: string): string {
  return (
    assert(
      warehousesAtom.getState()[id],
      `WarehousesAtom error: warehouse with id "${id}" not found`,
    )?.title || ''
  )
}
