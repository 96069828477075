import { API_URL } from 'src/config/environment'
import apiCreator from '../../api-creator'
import { handleErrorWithDefaultValue } from '../utils'
import { PaymentDetailsData } from 'src/atoms/static-data/payment-details-atom'

type ResponsePaymentsDetailsData = {
  name: string
  color: string
}

export function getPaymentDetails(): Promise<Array<PaymentDetailsData>> {
  return apiCreator
    .get(`${API_URL.TWIN}/payment-details`)
    .then(res => {
      const rawItems: Array<ResponsePaymentsDetailsData> = res.data.payment_details
      return rawItems.map(remapToPaymentDetails)
    })
    .catch(handleErrorWithDefaultValue([]))
}

function remapToPaymentDetails(data: ResponsePaymentsDetailsData): PaymentDetailsData {
  return {
    id: data.name,
    title: data.name,
    color: data.color || '#ececec',
  }
}
