import {createAtom} from '@reatom/core'
import {isUnderTrackingStatus, isWaitingPrepaymentStatus} from '../../../../../types/order-status'
import {createOrderPageFieldsAtom} from '../order-fields.atom'
import {createOrderPageErrorAtoms} from './errors-atom'

export const validationEnabledAtom = createAtom(
  {
    orderFields: createOrderPageFieldsAtom,
  },
  ({onChange, schedule}, state = false) => {
    onChange('orderFields', fields => {
      const newState = isUnderTrackingStatus(fields.orderStatusId) || isWaitingPrepaymentStatus(fields.orderStatusId)
      const wasDisable = !newState && state
      state = newState
      wasDisable &&
        schedule(dispatch => {
          dispatch(createOrderPageErrorAtoms.orderFields.clear())
          dispatch(createOrderPageErrorAtoms.items.reset())
        })
    })
    return state
  },
)
