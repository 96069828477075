import {successMessage} from '@app/utils/feedback'
import {mergeInMany} from '@sweet-monads/either'
import {getApi} from '../../../../../../../api'
import {historyAtom} from '../../../../../../../atoms/history-atom'
import {pageLoadingAtom} from '../../../../../../../atoms/page-loading-atom'
import {declareAloneAction} from '../../../../../../../hooks/use-alone-action'
import {defaultError} from '@app/utils/errors'
import {i18n_get} from '../../../../../../../lng/i18n'
import {
  registerPageItemErrorsAtom,
  registerPageValidateItems,
} from '../errors/item-errors-atom'
import {registerPageAtoms, registerPageModeDataAtom} from '../model'
import {
  arrivalDateAtom,
  registerDateAtom,
  registerPageValidateRegisterDate,
} from '../registration-info-atom'

export const saveRegisterPageData = declareAloneAction(
  ({store: {dispatch, getState}}) => {
    try {
      dispatch(pageLoadingAtom.set('saving'))
      const history = getState(historyAtom)
      const allItems = getState(registerPageAtoms.items)
      const registerDate = getState(registerDateAtom).value
      const arrivalDate = getState(arrivalDateAtom)
      const pageMode = getState(registerPageModeDataAtom)
      mergeInMany([
        registerPageValidateItems(allItems),
        registerPageValidateRegisterDate(registerDate),
      ])
        .mapRight(async ([items, goodRegisterDate]) => {
          pageMode.type === 'hub-package'
            ? await getApi().provider.toWarehouse.registerHubItems({
                packageId: pageMode.packageId,
                arrivalDate,
                registerDate: goodRegisterDate,
                items: items,
              })
            : await getApi().provider.toWarehouse.registerProviderItems({
                arrivalDate,
                registerDate: goodRegisterDate,
                items: items,
              })
          dispatch(pageLoadingAtom.set('normal'))
          history.typedPush('/provider/to-warehouse')
          successMessage(
            pageMode.type === 'provider-order'
              ? i18n_get.t('Provider.RegisterOrder.SuccessMessage')
              : pageMode.type === 'hub-package'
              ? i18n_get.t('Provider.RegisterPackage.SuccessMessage')
              : i18n_get.t('Provider.RegisterItems.SuccessMessage'),
          )
        })
        .mapLeft(errors => {
          errors.forEach(x =>
            x.type === 'items'
              ? dispatch(registerPageItemErrorsAtom.init(x.data))
              : dispatch(registerDateAtom.set(null)),
          )
          dispatch(pageLoadingAtom.set('normal'))
          defaultError(i18n_get.t('Provider.RegisterPage.DefaultErrorMessage'))
        })
    } catch (error) {
      defaultError(error as object)
      dispatch(pageLoadingAtom.set('normal'))
    }
  },
)
