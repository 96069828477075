import React from 'react'
import { toPairs } from 'ramda'
import { withDefaultLayout } from '../page-layouts/default-layout/default-layout'
import { verify } from '@appscience/utils'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ProviderStage } from './warehouse'
import { RouteLoader } from 'src/routes/components/route-loader'
import { TypedRedirect } from 'src/routes/components/typed-redirect'
import { PrivateRoute } from 'src/routes/components/private-route'
import { PublicRoute } from 'src/routes/components/public-route'
import { usePermissions } from 'src/hooks/permissions/use-permissions'
import { AuthView } from './auth-view'
import { Page404 } from './page-404'

const NewOrderPage = React.lazy(() => import('./procurement/new-order'))
const PreordersView = React.lazy(() => import('./procurement/pre-orders-view'))
const CreateOrderPage = React.lazy(() => import('./procurement/create-order/create-order-page'))
const EditOrderPage = React.lazy(() => import('./procurement/create-order/edit-order-page'))
const ToWarehouseView = React.lazy(() => import('./warehouse/to-warehouse'))
const ToWarehouseRegisterPage = React.lazy(() => import('./warehouse/to-warehouse/common/register-page'))
const InWarehouseView = React.lazy(() => import('./warehouse/in-warehouse'))
const CreatePackagePage = React.lazy(() => import('./warehouse/create-package/create-package-page'))
const EditPackagePage = React.lazy(() => import('./warehouse/create-package/edit-package-page'))
const FromWarehousePage = React.lazy(() => import('./warehouse/from-warehouse'))
const GroupAddView = React.lazy(() => import('./iam/group-add-view'))
const GroupEditView = React.lazy(() => import('./iam/group-edit-view'))
const GroupsView = React.lazy(() => import('./iam/groups-view'))
const UserAddView = React.lazy(() => import('./iam/user-add-view'))
const UserEditView = React.lazy(() => import('./iam/user-edit-view'))
const UsersView = React.lazy(() => import('./iam/users-view'))
const ReorderPage = React.lazy(() => import('./reorder'))
const PackingListGeneratorView = React.lazy(() => import('./packing-list-generator'))
const ScifinderPDFConverterPage = React.lazy(() => import('./compliance/scifinder-pdf-converter-page'))
const ScifinderExcelUploadPage = React.lazy(() => import('./compliance/scifinder-excel-upload-page'))
const AnaloguesExcelUploadPage = React.lazy(() => import('./analogues'))

export function Views() {
  const {
    isPermissionsLoading,
    allowedRedirects,
  } = usePermissions()

  return (
    <Switch>
      {toPairs(allowedRedirects)
        .map(([path, redirectProps]) => (
          <PublicRoute
            key={path}
            path={path}
            exact
          >
            {isPermissionsLoading && (
              <RouteLoader />
            )}
            {!isPermissionsLoading && (
              <TypedRedirect
                {...verify(redirectProps)}
              />
            )}
          </PublicRoute>
        ),
        )}

      <Route
        exact
        strict
        path='/:url*'
        render={({ location }) => (
          <Redirect to={`${location.pathname}/${location.search}`} />
        )}
      />

      {/* AUTH PAGE */}
      <PublicRoute
        path='/auth'
      >
        <AuthView />
      </PublicRoute>

      {/* 404 PAGE */}
      <PublicRoute
        path='/404'
        exact
      >
        <Page404 />
      </PublicRoute>

      <React.Suspense fallback={withDefaultLayout(<RouteLoader />)}>

        {/* NEW ORDER PAGE */}
        <PrivateRoute
          path='/procurement/new-order'
          exact
        >
          {withDefaultLayout(<NewOrderPage />)}
        </PrivateRoute>

        {/* ORDERS PAGE (tabs: Orders, Invoices, Items*/}
        <PrivateRoute
          path='/procurement/pre-orders'
          exact
        >
          {withDefaultLayout(<PreordersView />)}
        </PrivateRoute>

        {/* CREATE ORDER PAGE */}
        <PrivateRoute
          path='/procurement/new-order/create'
          exact
        >
          {withDefaultLayout(<CreateOrderPage />)}
        </PrivateRoute>

        {/* EDIT ORDER PAGE */}
        <PrivateRoute
          path='/procurement/pre-orders/edit'
          exact
        >
          {withDefaultLayout(<EditOrderPage />)}
        </PrivateRoute>

        {/* TO WAREHOUSE PAGE */}
        <PrivateRoute
          path='/provider/to-warehouse'
          exact
        >
          <ProviderStage>
            {withDefaultLayout(<ToWarehouseView />)}
          </ProviderStage>
        </PrivateRoute>

        {/* ACCEPTANCE PAGE */}
        <PrivateRoute
          path='/provider/to-warehouse/process'
        >
          <ProviderStage>
            {withDefaultLayout(<ToWarehouseRegisterPage />)}
          </ProviderStage>
        </PrivateRoute>

        {/* IN WAREHOUSE PAGE */}
        <PrivateRoute
          path='/provider/in-warehouse'
          exact
        >
          <ProviderStage>
            {withDefaultLayout(<InWarehouseView />)}
          </ProviderStage>
        </PrivateRoute>

        {/* CREATE PACKAGE PAGE */}
        <PrivateRoute
          path='/provider/in-warehouse/create-package'
        >
          <ProviderStage>
            {withDefaultLayout(<CreatePackagePage />)}
          </ProviderStage>
        </PrivateRoute>

        {/* EDIT PACKAGE PAGE */}
        <PrivateRoute
          path='/provider/from-warehouse/edit-package'
        >
          <ProviderStage>
            {withDefaultLayout(<EditPackagePage />)}
          </ProviderStage>
        </PrivateRoute>
        {/* FROM WAREHOUSE PAGE */}
        <PrivateRoute
          path='/provider/from-warehouse'
          exact
        >
          <ProviderStage>
            {withDefaultLayout(<FromWarehousePage />)}
          </ProviderStage>
        </PrivateRoute>

        {/* ADMIN PANEL - USERS PAGE */}
        <PrivateRoute
          path='/iam/users'
          exact
        >
          {withDefaultLayout(<UsersView />)}
        </PrivateRoute>

        {/* ADMIN PANEL - ADD USERS PAGE */}
        <PrivateRoute
          path='/iam/users/add'
          exact
        >
          {withDefaultLayout(<UserAddView />)}
        </PrivateRoute>

        {/* ADMIN PANEL - EDIT USERS PAGE */}
        <PrivateRoute
          path='/iam/users/edit'
          exact
        >
          {withDefaultLayout(<UserEditView />)}
        </PrivateRoute>

        {/* ADMIN PANEL - GROUPS PAGE */}
        <PrivateRoute
          path='/iam/groups'
          exact
        >
          {withDefaultLayout(<GroupsView />)}
        </PrivateRoute>

        {/* ADMIN PANEL - ADD GROUP PAGE */}
        <PrivateRoute
          path='/iam/groups/add'
          exact
        >
          {withDefaultLayout(<GroupAddView />)}
        </PrivateRoute>

        {/* ADMIN PANEL - EDIT USERS PAGE */}
        <PrivateRoute
          path='/iam/groups/edit'
          exact
        >
          {withDefaultLayout(<GroupEditView />)}
        </PrivateRoute>

        {/* REORDER PAGE */}
        <PublicRoute
          path='/reorder'
          exact
        >
          {withDefaultLayout(<ReorderPage />)}
        </PublicRoute>

        {/* WAREHOUSE PAGE */}
        <PublicRoute
          path='/delivery-point'
          exact
        >
          {withDefaultLayout(<PackingListGeneratorView />)}
        </PublicRoute>

        {/* SCIFINDER PDF CONVERTER PAGE */}
        <PublicRoute
          path='/compliance/scifinder'
          exact
        >
          {withDefaultLayout(<ScifinderPDFConverterPage />)}
        </PublicRoute>

        {/* SCIFINDER CAS/HS CODE EXCEL UPLOAD PAGE */}
        <PublicRoute
          path='/compliance/excel-upload'
          exact
        >
          {withDefaultLayout(<ScifinderExcelUploadPage />)}
        </PublicRoute>

        {/* ANALOGUES EXCEL UPLOAD PAGE */}
        <PublicRoute
          path='/analogues'
          exact
        >
          {withDefaultLayout(<AnaloguesExcelUploadPage />)}
        </PublicRoute>
      </React.Suspense>

      <Route
        path='*'
      >
        <TypedRedirect
          to={'/404'}
        />
      </Route>
    </Switch>
  )
}
