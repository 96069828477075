import {TemperatureData} from '../../atoms/static-data/temperature-colors-atom'
import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'
import {handleErrorWithDefaultValue} from './utils'

interface ResponseTemperatureData {
  name: string
  color: string
}

export function getTemperatureMap(): Promise<Array<TemperatureData>> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/temperatures`,
    )
    .then(res => {
      const rawItems: Array<ResponseTemperatureData> = res.data.temperatures
      return rawItems.map(remapToTemperatureData)
    })
    .catch(handleErrorWithDefaultValue([]))
}

export function remapToTemperatureData(data: ResponseTemperatureData): TemperatureData {
  return {
    id: data.name,
    title: data.name,
    color: data.color,
  }
}
