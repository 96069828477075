import { defaultError } from '@app/utils/errors'
import { logError } from '@appscience/utils'
import { RowSelectionState } from '@tanstack/react-table'
import { updateWarehousesAtom } from 'src/atoms/static-data/warehouses-atom'
import { updateProviderssAtom } from 'src/atoms/static-data/providers-atom'
import { getApi } from '../../../../api'
import { pageLoadingAtom } from '../../../../atoms/page-loading-atom'
import { updateBrandsAtom } from '../../../../atoms/static-data/brands-atom'
import { updateCountriesAtom } from '../../../../atoms/static-data/countries-atom'
import { updateExportDocumentsAtom } from '../../../../atoms/static-data/export-documents-atom'
import { updateItemPrioritiesAtom } from '../../../../atoms/static-data/item-priorities-atom'
import { updateItemStatusesAtom } from '../../../../atoms/static-data/item-statuses-atom'
import { updateProcurementsAtom } from '../../../../atoms/static-data/procurements-atom'
import { defaultStoresAtom, updateStoresAtom } from '../../../../atoms/static-data/stores-atom'
import { updateTemperatureColorsAtom } from '../../../../atoms/static-data/temperature-colors-atom'
import { updateTswNameColorsAtom } from '../../../../atoms/static-data/tsw-name-colors-atom'
import { INITIAL_LAZY_LOAD_PAGINATION, ServerTableSettings } from '../../../../components/table/utils/settings-atom'
import { declareAloneAction } from '../../../../hooks/use-alone-action'
import {
  newOrderStoreIdsFilterAtom,
  NewOrderTableItem,
  newOrderTableItemsAtom,
  newOrderTableSelectedRowsAtom,
} from './main'
import { newOrderTableSettingsAtoms } from './settings'
import { updateTemperaturesAtom } from 'src/atoms/static-data/temperatures-atom'
import { updateHazmatsAtom } from 'src/atoms/static-data/hazmats-atom'
import { permissionAtom } from 'src/hooks/permissions/permission-atom'
import { updateComplianceStatusesAtom } from 'src/atoms/static-data/compliance-statuses-atom'
import { updateClientTypesAtom } from 'src/atoms/static-data/client-types-atom'
import { updateExefTypesAtom } from 'src/atoms/static-data/exef-types-atom'
import { updateProcurementDlReasonsAtom } from 'src/atoms/static-data/procurement-dl-reasons'
import { updateOrderDetailsColorsAtom } from 'src/atoms/static-data/order-details-colors-item'

function hasColumnFilterValue(
  settings: ServerTableSettings,
  filterId: string,
): boolean {
  const columnFilter = settings.filtrationState
    .find(filter => filter.id === filterId)
  return Boolean(columnFilter)
    && Array.isArray(columnFilter?.value)
    && (columnFilter?.value as Array<AnyType>)?.length > 0
}

function getNewOrderItemsRequestSettings(
  settings: ServerTableSettings,
): ServerTableSettings {
  const storeFilterIds = newOrderStoreIdsFilterAtom.getState()
  const defaultStores = defaultStoresAtom.getState()
  const previousStartKey = newOrderTableSettingsAtoms.startKey.getState()

  const hasProcurementFilter = hasColumnFilterValue(settings, 'procurement')
  const hasProviderFilter = hasColumnFilterValue(settings, 'provider')
  const { allowedWarehouseIdsSet, allowedProcurementIdsSet } = permissionAtom.getState()
  const newFiltrationState = settings.filtrationState
    .concat([
      //No paid items shown in new order view
      {
        id: 'supply_paid_date',
        value: '',
      },
      ...(hasProcurementFilter
        ? []
        : [{
          id: 'procurement',
          value: [...allowedProcurementIdsSet],
        }]),
      ...(hasProviderFilter
        ? []
        : [{
          id: 'provider',
          value: [...allowedWarehouseIdsSet],
        }]),
      ...(storeFilterIds.length > 0
        ? [{
          id: 'store',
          value: storeFilterIds.flatMap(id => [
            id,
            ...(defaultStores[id]?.alternativeNames || []),
          ]),
        }]
        : []),
    ])

  return {
    ...settings,
    paginationState: {
      ...settings.paginationState,
      pageIndex: settings.paginationState.pageIndex + 1,
    },
    filtrationState: newFiltrationState,
    startKey: previousStartKey,
  }
}

interface Payload {
  settings: ServerTableSettings;
  isNewLazyLoading: boolean;
}

export const loadNewOrderPageData = declareAloneAction<Payload>(
  async ({
    store,
    payload: {
      settings,
      isNewLazyLoading,
    },
  }) => {
    try {
      store.dispatch(pageLoadingAtom.set('loading'))

      if (isNewLazyLoading) {
        store.dispatch(newOrderTableSettingsAtoms.areAllRowsLoaded.set(false))
        store.dispatch(newOrderTableItemsAtom.set([]))
        store.dispatch(newOrderTableSettingsAtoms.startKey.set({}))
        store.dispatch(newOrderTableSettingsAtoms.paginationState.set(INITIAL_LAZY_LOAD_PAGINATION))
      }

      const api = getApi()

      console.time('[New Order] data')
      const [{ items: newItems, startKey }] = await Promise.all([
        api.procurement.getNewOrderItems({
          settings: getNewOrderItemsRequestSettings(settings),
        }),
        updateCountriesAtom(store),
        updateWarehousesAtom(store),
        updateStoresAtom(store),
        updateHazmatsAtom(store),
        updateBrandsAtom(store),
        updateProviderssAtom(store),
        updateClientTypesAtom(store),
        updateExefTypesAtom(store),
        updateProcurementsAtom(store),
        updateItemStatusesAtom(store),
        updateItemPrioritiesAtom(store),
        updateComplianceStatusesAtom(store),
        updateTemperaturesAtom(store),
        updateTemperatureColorsAtom(store),
        updateTswNameColorsAtom(store),
        updateExportDocumentsAtom(store),
        updateProcurementDlReasonsAtom(store),
        updateOrderDetailsColorsAtom(store),
      ])
      console.timeEnd('[New Order] data')

      if (
        newItems.length < settings.paginationState.pageSize
        || !Object.keys(startKey).length
      ) {
        store.dispatch(newOrderTableSettingsAtoms.areAllRowsLoaded.set(true))

        if (!newItems.length) {
          return
        }
      }

      const previousItems: Array<NewOrderTableItem> = newOrderTableItemsAtom.getState()
      const items = previousItems.concat(newItems)
      const selectedKeysSet: Set<string> = new Set(
        Object.keys(newOrderTableSelectedRowsAtom.getState()),
      )
      const selectedRows = items
        .filter(x => selectedKeysSet.has(x.id))
        .reduce((acc: RowSelectionState, x) => {
          acc[x.id] = true
          return acc
        }, {})

      store.dispatch(newOrderTableSelectedRowsAtom.set(selectedRows)) // ? TODO ask Nikita
      store.dispatch(newOrderTableSettingsAtoms.startKey.set(startKey))
      console.time('[New Order] ui')
      store.dispatch(newOrderTableItemsAtom.set(items))
      console.timeEnd('[New Order] ui')
      store.dispatch(newOrderTableSettingsAtoms.paginationState.set(settings.paginationState))
    } catch (e) {
      defaultError(e as object)
      logError('Не удалось получить данные для NewOrderTable')
    } finally {
      store.dispatch(pageLoadingAtom.set('normal'))
    }
  },
)
