import { createSetAtom } from '@reatom/core/primitives'
import { isUnderTrackingStatus, isWaitingPrepaymentStatus } from '../../../../../types/order-status'
import { createOrderPageItemErrorsAtom } from './item-fields-validation'
import { CreateOrderPageOrderFields } from '../create-order-page-order-fields.type'

type CreateOrderPageRequiredOrderField = Extract<
  keyof CreateOrderPageOrderFields,
  | 'storeId'
  | 'orderTimestamp'
  | 'countryId'
  | 'providerId'
  | 'paymentDeadline'
  | 'paymentPriority'
  | 'currencyId'
  | 'paymentMethodId'
  | 'transferredForPaymentDate'
  | 'procurement'
  | 'orderStatusId'
  | 'paymentType'
  | 'prepayment'
  | 'card'
  | 'tswNameId'
  | 'exportDocuments'
>

export type CreateOrderPageValidationOrderField =
  | CreateOrderPageRequiredOrderField
  | Extract<keyof CreateOrderPageOrderFields, 'sumOtherCost'>

export function getCreateOrderPageRequiredOrderFields({
  orderStatusId,
  isModerationPassed,
  paymentType,
  paymentMethodId,
}: CreateOrderPageOrderFields, isSumLimitExceeded: boolean): Array<CreateOrderPageValidationOrderField> {
  const isCardPayment = paymentMethodId === 'card'

  const needsModeration = !isModerationPassed && isSumLimitExceeded && isWaitingPrepaymentStatus(orderStatusId)
  const required: Array<CreateOrderPageValidationOrderField> = isCardPayment
    ? [
      'orderStatusId',
      'currencyId',
      'storeId',
      'card',
      'providerId',
    ]
    : [
      'orderStatusId',
      'currencyId',
      'storeId',
      'providerId',
    ]

  if (isUnderTrackingStatus(orderStatusId)) {
    required.push(
      'orderTimestamp',
      'countryId',
      'paymentMethodId',
      'paymentType',
      'sumOtherCost',
      'procurement',
    )
  }
  if (isWaitingPrepaymentStatus(orderStatusId) && paymentType === 'prepayment') {
    required.push(
      'countryId',
      'paymentMethodId',
      'paymentType',
      'sumOtherCost',
      ...(needsModeration ? [] : ['transferredForPaymentDate'] as CreateOrderPageValidationOrderField[]),
      'procurement',
    )
  }

  return required
}

export const createOrderPageErrorAtoms = {
  orderFields: createSetAtom<CreateOrderPageValidationOrderField>(),
  items: createOrderPageItemErrorsAtom,
}
