import {TswNameData} from '../../atoms/static-data/tsw-name-colors-atom'
import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'
import {handleErrorWithDefaultValue} from './utils'

export interface ResponseTswNameData {
  name: string
  color: string
}

export function getTswNameMap(): Promise<Array<TswNameData>> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/tsw-names`,
    )
    .then(res => {
      const rawItems: Array<ResponseTswNameData> = res.data.tsw_names
      return rawItems.map(remapToTswNameData)
    })
    .catch(handleErrorWithDefaultValue([]))
}

export function remapToTswNameData(data: ResponseTswNameData): TswNameData {
  return {
    id: data.name,
    title: data.name,
    color: data.color,
  }
}