import {primitiveAtom} from '@app/utils/reatom'
import {createPrimitiveAtom} from '@reatom/core/primitives'
import {RowSelectionState} from '@tanstack/react-table'
import {toWarehouseCurrentTab} from '../common/model'

//-----------------------ITEMS TABLE--------------------------
export type ToHubItemView = ToHubItem

export interface ToHubItem {
  id: string
  airtableLink: string
  orderId: string
  departureDate: Date | null
  prevWarehouseId: string
  invoiceNumber: string
  trackIds: string
  amoId: string
  poNumber: string
  brand: string
  invoiceName: string
  originalName: string
  catalogueId: string
  sumCost: number
  currencyId: string
  quantity: number
  temperatureId: string
  hazmat: string
  lot: string
  isKit: boolean
  transporterId: string
  exportStatuses: Array<string>
  exportComment: string
  boxNumber: Array<string>
}

export const toHubItemsSelectedPackageIdAtom = primitiveAtom<string | null>(
  null,
  dispatch => dispatch(toWarehouseCurrentTab.set('byItems')),
)
const itemsTableAtoms = {
  selectedRowIds: createPrimitiveAtom<RowSelectionState>({}),
}

//-----------------------PACKAGES TABLE--------------------------
export type ToHubPackageView = ToHubPackage

export interface ToHubPackage {
  id: string
  departureDate: Date | null
  plannedDeliveryDate: Date | null
  prevWarehouseId: string
  invoiceNumber: string
  trackIds: Array<string>
  tag: string
  temperatures: Array<string>
  isHazmat: boolean
  itemsQuantity: number
  transporterId: string
  sumCost: number
  currencyId: string
  packageComment: string
  toWarehouseId: string
  express: boolean
}

const packagesTableAtoms = {
  selectedRowId: createPrimitiveAtom<RowSelectionState>({}),
}

export const toHubAtoms = {
  itemsTableAtoms,
  packagesTableAtoms,
  items: createPrimitiveAtom<Record<string, ToHubItem>>({}),
  packages: createPrimitiveAtom<Record<string, ToHubPackage>>({}),
}
