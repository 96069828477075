import {Store} from '@reatom/core'
import {createPrimitiveAtom} from '@reatom/core/primitives'
import {getTime, hoursToMilliseconds} from 'date-fns'
import {indexBy} from 'ramda'
import {getApi} from '../../api'
import {verify} from '@appscience/utils'

let lastInitTimestamp: number | null

export type FinanceControl = 'True' | 'False' | 'In progress'

export type PaymentMethodId = 'card' | 'credit_line' | 'prepayment' | 'net_30' | 'transfer'

export interface PaymentMethodData extends TitleWithId {
  id: PaymentMethodId
  title: string
  allowed: boolean
}

export const paymentMethodsAtom = createPrimitiveAtom<
  Record<string, PaymentMethodData>
>({})

export async function updatePaymentMethodsAtom({dispatch}: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }
  const data = await getApi().listsApi.getPaymentMethods()
  const record = indexBy(x => x.id, data)
  dispatch(paymentMethodsAtom.set(record))
  lastInitTimestamp = getTime(new Date())
  return record
}

export function getPaymentMethodTitle(id: string) {
  return (
    verify(
      paymentMethodsAtom.getState()[id],
      `paymentMethodsAtom error: currency with id "${id}" not found`,
    )?.title || ''
  )
}

export function getPaymentMethodOrder(status: PaymentMethodId): number {
  const positions: Record<PaymentMethodId, number> = {
    card: 1,
    credit_line: 2,
    prepayment: 3,
    net_30: 4,
    transfer: 5,
  }
  return positions[status]
}
