import { defaultError } from '@app/utils/errors'
import { logError } from '@appscience/utils'
import {
  WarehouseData,
  WarehouseType,
} from '../../../atoms/static-data/warehouses-atom'
import { API_URL } from 'src/config/environment'
import {
  PaymentMethodData,
  PaymentMethodId,
} from '../../../atoms/static-data/payments-method-atom'
import { OrderStatusId } from '../../../types/order-status'
import apiCreator from '../../api-creator'
import { remapStringToTitleWithId } from '../../common'
import { getProcurementPriorityMap } from '../../procurement/get-priority-map'
import { getTswNameMap } from '../get-tsw-name-map'
import {
  checkPaymentMethodId,
  handleErrorWithDefaultValue,
  remapToOrderStatus,
} from '../utils'
import { getBrandsApi } from './get-brands'
import { getExportDocumentsApi } from './get-export-documents'
import { getStoresApi } from './get-stores'
import { TemperatureData } from 'src/atoms/static-data/temperatures-atom'
import { getHazmatsApi } from './get-hazmats'
import { getTermsOfPaymentApi } from './terms-of-payment'
import { getOrderDetails } from '../get-order-details'
import { getComplianceStatuses } from './get-compliance-statuses'
import { getBankAccounts } from './get-bank-accounts'
import { getExefTypes } from './get-exef-types'
import { getClientTypes } from './get-client-types'
import { SdExpData } from 'src/atoms/static-data/sd-exps-atom'
import { getDiscountComments } from './get-discount-comments'
import { CountryOfOriginData } from 'src/atoms/static-data/country-of-origin-atom'
import { getPaymentPriorities } from './get-payment-prioirties'
import { getProcurementDelayReasons } from './get-procurement-delay-reasons'
import { CurrencyData } from 'src/atoms/static-data/currencies-atom.type'
import { getPrtChannelChangeReasons } from './get-prt-channel-change-reasons'
import { getPaymentDetails } from './get-payment-details'
import { getPaymentPercentages } from './get-payment-percentages'

export const listsApi = {
  getStores: getStoresApi,
  getHazmats: getHazmatsApi,
  getExportDocuments: getExportDocumentsApi,
  getPriorities: getProcurementPriorityMap,
  getResponsibilities,
  getComplianceStatuses,
  getCurrencies,
  getTswNameMap,
  getExefTypes,
  getPaymentPriorities,
  getClientTypes,
  getDiscountComments,
  getCountries,
  getOrderDetails,
  getPaymentMethods,
  getProviders,
  getBrands: getBrandsApi,
  getBankAccounts,
  getTermsOfPayment: getTermsOfPaymentApi,
  getOrderStatuses,
  getTemperatures,
  getSdExps,
  getProviderExportStatuses,
  getCountriesOfOrigin,
  getWarehouses,
  getTransporters,
  getItemStatuses,
  getExportStatuses,
  getCards,
  getProcurementDelayReasons,
  getPrtChannelChangeReasons,
  getPaymentDetails,
  getPaymentPercentages,
}

export interface CardsResponseData {
  name: string
  procurement: string
  provider: string
}

export interface CurrencyResponseData {
  id: string
  symbol: string
}
export interface ResponsibilitiesResponseData {
  name: string
  color: string
}

export interface ExtendedCountriesResponseData {
  name: string
  color: string
  flag: string
}

export interface StoresResponseData {
  name: string
  color: string
  alternative_names: string[]
}

export interface ProvidersResponseData {
  name: string
  color: string
}

interface PaymentMethodsResponseData {
  id: PaymentMethodId
  name: string
  allowed: boolean
}

export interface OrderStatusesResponseData {
  id: OrderStatusId
  name: string
}

function getCountries(): Promise<Array<ExtendedCountriesResponseData>> {
  return apiCreator
    .get(`${API_URL.TWIN}/countries-extended`)
    .then(res => {
      const rawItems: Array<ExtendedCountriesResponseData> = res.data.countries
      return rawItems
    })
    .catch(handleErrorWithDefaultValue([]))
}

function getCurrencies(): Promise<Array<CurrencyData>> {
  return apiCreator
    .get(`${API_URL.TWIN}/currencies`)
    .then(res => {
      const rawItems: Array<CurrencyResponseData> = res.data.currencies
      return rawItems.map(x => ({
        id: x.id,
        title: `${x.id}, ${x.symbol}`,
        symbol: x.symbol,
      }))
    })
    .catch(handleErrorWithDefaultValue([]))
}

function getPaymentMethods(): Promise<Array<PaymentMethodData>> {
  return apiCreator
    .get(`${API_URL.TWIN}/payment-methods`)
    .then(res => {
      const rawItems: Array<PaymentMethodsResponseData> =
        res.data['payment_methods']
      return rawItems.map(x => ({
        id: checkPaymentMethodId(x.id),
        title: x.name,
        allowed: x.allowed,
      }))
    })
    .catch(handleErrorWithDefaultValue([]))
}

function getProviders(): Promise<Array<ProvidersResponseData>> {
  return apiCreator
    .get(`${API_URL.TWIN}/providers`)
    .then(res => {
      const rawItems: Array<ProvidersResponseData> = res.data.providers
      return rawItems
    })
    .catch(handleErrorWithDefaultValue([]))
}

type ResponsibleRole = 'scd' | 'procurement'

function getResponsibilities(
  role: ResponsibleRole,
): Promise<Array<ResponsibilitiesResponseData>> {
  return apiCreator
    .get(`${API_URL.TWIN}/responsible?role=${role}`)
    .then(res => {
      const rawItems: Array<ResponsibilitiesResponseData> = res.data.responsible
      return rawItems
    })
    .catch(handleErrorWithDefaultValue([]))
}

interface ResponseCountryOfOriginData {
  name: string
  color: string
}

function getCountriesOfOrigin(): Promise<Array<CountryOfOriginData>> {
  return apiCreator
    .get(`${API_URL.TWIN}/countries-of-origin`)
    .then(res => {
      const rawItems: Array<ResponseCountryOfOriginData> = res.data.countries_of_origin
      return rawItems.map(remapToCountryOfOriginData)
    })
    .catch(handleErrorWithDefaultValue([]))
}

export function remapToCountryOfOriginData(data: ResponseCountryOfOriginData): CountryOfOriginData {
  return {
    id: data.name,
    title: data.name,
    color: data.color,
  }
}

function getOrderStatuses(): Promise<Array<TitleWithId<OrderStatusId>>> {
  return apiCreator
    .get(`${API_URL.TWIN}/statuses-orders`)
    .then(res => {
      const rawItems: Array<OrderStatusesResponseData> = res.data.statuses
      return rawItems.map(remapToOrderStatus)
    })
    .catch(handleErrorWithDefaultValue([]))
}

function getTemperatures(): Promise<Array<TemperatureData>> {
  return apiCreator
    .get(`${API_URL.TWIN}/temperatures`)
    .then(res => {
      const rawItems: Array<{ name: string; color: string }> =
        res.data.temperatures
      return rawItems.map(item => ({
        id: item.name,
        color: item.color,
        title: item.name,
      }))
    })
    .catch(handleErrorWithDefaultValue([]))
}

function getSdExps(): Promise<Array<SdExpData>> {
  return apiCreator
    .get(`${API_URL.TWIN}/sd-exps`)
    .then(res => {
      const rawItems: Array<{ name: string; color: string }> =
        res.data.sd_exps
      return rawItems.map(item => ({
        id: item.name,
        color: item.color,
        title: item.name,
      }))
    })
    .catch(handleErrorWithDefaultValue([]))
}

function getProviderExportStatuses(): Promise<Array<SdExpData>> {
  return apiCreator
    .get(`${API_URL.TWIN}/provider-export-statuses`)
    .then(res => {
      const rawItems: Array<{ name: string; color: string }> =
        res.data.provider_export_statuses
      return rawItems.map(item => ({
        id: item.name,
        color: item.color,
        title: item.name,
      }))
    })
    .catch(handleErrorWithDefaultValue([]))
}

function getCards(): Promise<Array<CardsResponseData>> {
  return apiCreator
    .get(`${API_URL.TWIN}/cards`)
    .then(res => res.data.cards)
    .catch(handleErrorWithDefaultValue([]))
}

function getExportStatuses(): Promise<Array<TitleWithId>> {
  return apiCreator
    .get(`${API_URL.TWIN}/export-statuses`)
    .then(res => {
      const rawItems: Array<string> = res.data.export_statuses
      return rawItems.map(remapStringToTitleWithId)
    })
    .catch(handleErrorWithDefaultValue([]))
}

function getTransporters(): Promise<Array<TitleWithId>> {
  return apiCreator
    .get(`${API_URL.TWIN}/transporters`)
    .then(res => {
      const rawItems: Array<string> = res.data.transporters
      return rawItems.map(remapStringToTitleWithId)
    })
    .catch(error => {
      defaultError(error)
      return []
    })
}

function getItemStatuses(): Promise<Array<TitleWithId>> {
  return apiCreator
    .get(`${API_URL.TWIN}/procurement-statuses`)
    .then(res => {
      const rawItems: Array<string> = res.data.procurement_statuses
      return rawItems.map(remapStringToTitleWithId)
    })
    .catch(handleErrorWithDefaultValue([]))
}

type Api_WarehouseType = 'Provider' | 'HUB' | 'Delivery point' | 'Transit'

interface WarehouseResponse {
  id: string
  name: string
  type: Array<Api_WarehouseType>
  area_id: string
}

function getWarehouses(): Promise<Array<WarehouseData>> {
  return apiCreator
    .get(`${API_URL.TWIN}/warehouses`)
    .then(res => {
      const rawItems: Array<WarehouseResponse> = res.data.warehouses
      return rawItems.map(remapToWarehouseData)
    })
    .catch(handleErrorWithDefaultValue([]))
}

function remapToWarehouseData(data: WarehouseResponse): WarehouseData {
  return {
    id: data.id,
    title: data.name,
    type: remapToWarehouseType(data.type),
    areaId: data.area_id,
  }
}

function remapToWarehouseType(data: Array<Api_WarehouseType>): WarehouseType {
  for (let i = 0; i < data.length; i++) {
    const type = data[i]
    switch (type) {
      case 'Provider':
        return 'provider'
      case 'HUB':
      case 'Transit':
        return 'hub'
      case 'Delivery point':
        return 'delivery_point'
    }
  }
  if (data.length === 0) {
    logError('Error, warehouse without type !')
  }
  return 'provider'
}
