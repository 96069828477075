import {API_URL} from 'src/config/environment'
import apiCreator from '../api-creator'

interface CurrencyRateResponseData {
  deal_id: string
  currency_to_rub_rates: Record<string, number>
}

export const getCurrencyRatesByDeals = (ids: string[]) =>
  apiCreator
    .post(`${API_URL.TWIN}/proposal/currency-rates`, {
      deal_ids: ids,
    })
    .then(res =>
      res.data.rates.reduce(
        (
          acc: Record<string, Record<string, number>>,
          currDeal: CurrencyRateResponseData,
        ) => {
          acc[currDeal.deal_id] = currDeal.currency_to_rub_rates
          return acc
        },
        {},
      ),
    )
