import {ComponentProps, FC, useCallback, useContext, useMemo} from 'react'
import {ModalContext} from './ModalContext'

const generateModalKey = (() => {
  let count = 0

  return () => `${++count}`
})()

type ReturnDefault = [() => void, () => void]
type ReturnWithProps<C extends FC<any>> = [(props: ComponentProps<C>) => void, () => void]

//TODO:improvement исключить из в пропсов showModal проп show, т. к. он прокидывается ModalProvider, а не ручками.
export function useModal<C extends FC<any>>(component: C, props: ComponentProps<C>): ReturnDefault
export function useModal<C extends FC<any>>(component: C): ReturnWithProps<C>
export function useModal<C extends FC<any>>(component: C, initProps?: ComponentProps<C>) {
  const key = useMemo(generateModalKey, [])
  const {showModal, hideModal} = useContext(ModalContext)
  const showFn = useMemo(() => (initProps
    ? () => showModal(key, component, initProps)
    : (props: ComponentProps<C>) => showModal(key, component, props)),
  [showModal, key, component, initProps],
  )
  const hideFn = useCallback(() => hideModal(key), [hideModal, key])

  return [showFn, hideFn]
}