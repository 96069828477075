import {createStyles} from '@mantine/core'
import React from 'react'
import {mode} from '@appscience/utils'
import {DefaultLayoutHeader} from './default-layout-header'
import {DefaultLeftNav} from './left-nav/default-left-nav'
import {HEADER_HEIGHT, SIDE_NAV_COLLAPSED_WIDTH} from '../../config/constants'

const useStyles = createStyles(({colors, colorScheme}) => ({
  root: {
    display: 'flex',
    minHeight: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: `calc(100vw - ${SIDE_NAV_COLLAPSED_WIDTH}px)`,
    backgroundColor: mode(colors.gray[0], colors.gray[0])(colorScheme),
    '& > div:nth-of-type(2)': {
      height: `calc(100% - ${HEADER_HEIGHT}px)`,
    },
  },
}))

export function withDefaultLayout(children: React.ReactNode) {
  return <DefaultLayout>{children}</DefaultLayout>
}

interface IPropsDefaultLayout {
  children: React.ReactNode
}

const DefaultLayout = ({children}: IPropsDefaultLayout) => {
  const {classes} = useStyles()

  return (
    <div className={classes.root}>
      <DefaultLeftNav />
      <div className={classes.content}>
        <DefaultLayoutHeader />
        {children}
      </div>
    </div>
  )
}
