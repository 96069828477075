import { addOrderDoc } from './add-order-doc'
import { deleteOrderDoc } from './delete-order-doc'
import { getPresignedS3UploadLink } from './get-presigned-s3-upload-link'
import { uploadOrderFilesToS3 } from './upload-order-files-to-s3'

export const orderFilesApi = {
  addOrderDoc: addOrderDoc,
  deleteOrderDoc: deleteOrderDoc,
  getPresignedS3UploadLink: getPresignedS3UploadLink,
  uploadOrderFilesToS3: uploadOrderFilesToS3,
}
