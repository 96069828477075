import { successMessage } from '@app/utils/feedback'
import { getApi } from '../../../../../../api'
import { historyAtom } from '../../../../../../atoms/history-atom'
import { AppRoute, WithQuery } from '../../../../../../routes/types'
import { declareAloneAction } from '../../../../../../hooks/use-alone-action'
import { i18n_get } from '../../../../../../lng/i18n'
import {
  isUnderTrackingStatus, isWaitingPrepaymentStatus,
} from '../../../../../../types/order-status'
import {
  intersectionSets,
  toFixedNum,
} from '@appscience/utils'
import { defaultError } from '@app/utils/errors'
import { createOrderPageAtoms } from '../../create-order-page-atoms'
import { validationEnabledAtom } from '../../errors/validation-enabled-atom'
import { getCreateOrderPopupItemFullCost } from '../../../content/items-section/fields/currency-sum-cost'
import { convertCurrency } from 'src/atoms/static-data/exchange-rates-atom'
import { initOrderModerationInfo } from 'src/atoms/static-data/init-moderation-info-atom'
import { areOrderDetailsOpenedAtom } from 'src/page-layouts/default-layout/order-details-toggler/order-details-toggler.atom'
import { validateOrderPopupData } from './validate-order-popup-data'
import { getVerifiedMapOrderFields } from './get-verified-map-order-fields'
import { getVerifiedMapItemFields } from './get-verified-map-item-fields'
import { getOrderValidationErrorMessage } from './get-order-validation-error-message'
import { CreateOrderRequestItemData, CreateOrderRequestOrderFields } from 'src/api/procurement/save-order'
import { createOrderPageItemsAtom } from '../../items'
import { orderFilesAtom } from '../../../content/order-files/order-files.atom'
import { createOrderPageFieldsAtom } from '../../order-fields.atom'
import { isExceededInitialCostAtom } from '../../is-exceeded-initial-cost.atom'
import { isSumLimitExceededAtom } from '../../is-sum-limit-exceeded.atom'

export const createOrderPageSaveData = declareAloneAction(
  async ({ store: { dispatch, getState } }) => {
    try {
      const history = getState(historyAtom)
      const initModerationInfo = getState(initOrderModerationInfo)
      const errorAtoms = createOrderPageAtoms.errorAtoms
      const exceedesInitialCost = getState(isExceededInitialCostAtom)
      const isSumLimitExceeded = getState(isSumLimitExceededAtom)
      dispatch(createOrderPageAtoms.state.set('saving'))

      const { orderId, pageType } = getState(createOrderPageAtoms.staticData)
      const orderFields = getState(createOrderPageFieldsAtom)
      const items = getState(createOrderPageItemsAtom)

      const { attachedFilesMap } = getState(orderFilesAtom)
      const orderDocuments: Array<string> = [...attachedFilesMap.values()]
        .map(orderFile => orderFile.s3Url)
        .filter(Boolean) as Array<string>

      const validationEnabled = getState(validationEnabledAtom)
      const errors = validateOrderPopupData({
        items,
        orderFields,
        isSumLimitExceeded,
      })

      if (errors !== null) {
        dispatch(areOrderDetailsOpenedAtom.set(true))
        dispatch(errorAtoms.orderFields.change(_ => errors.orderFields))
        dispatch(errorAtoms.items.init(errors.items))

        const itemsErrorFields = [...intersectionSets(...errors.items.values())]
        const printFieldsName =
          errors.orderFields.size === 0 && itemsErrorFields.length <= 5 //Скорее всего пользователь не видит ошибочные поля.
        defaultError(
          getOrderValidationErrorMessage(itemsErrorFields, printFieldsName),
        )

        dispatch(createOrderPageAtoms.state.set('normal'))
        return
      }
      dispatch([errorAtoms.orderFields.clear(), errorAtoms.items.reset()])

      const USDOrderSum = convertCurrency(
        orderFields.currencyId,
        'USD',
        items.reduce((sum, item) => sum + getCreateOrderPopupItemFullCost(item), 0),
      )
      const requestOrder: CreateOrderRequestOrderFields = {
        id: orderId || undefined,
        ...getVerifiedMapOrderFields(orderFields, validationEnabled),
        card: orderFields.card || '',
        sum_cost_usd: toFixedNum(USDOrderSum, 2),
        tsw_name: orderFields.tswNameId || '',
        docs: orderDocuments,
      }
      const requestItems: Array<CreateOrderRequestItemData> = items.map(item => getVerifiedMapItemFields(item, validationEnabled))

      const {
        data: { order_id },
      } = await getApi().procurement.saveOrder({
        order: requestOrder,
        items: requestItems,
      })

      if (
        (!initModerationInfo?.moderationPassed || exceedesInitialCost) &&
        (isUnderTrackingStatus(orderFields.orderStatusId) || isWaitingPrepaymentStatus(orderFields.orderStatusId)) &&
        isSumLimitExceeded
      ) {
        dispatch(createOrderPageAtoms.shouldShowModerationInfoPopup.setTrue())
      }

      const pageTypeUrl: WithQuery<AppRoute> =
        pageType === 'create'
          ? orderFields.orderStatusId === 'preparing_po'
            ? `/procurement/pre-orders/edit?id=${order_id}`
            : '/procurement/new-order'
          : '/procurement/pre-orders'

      history.typedPush(pageTypeUrl)
      dispatch(createOrderPageAtoms.state.set('normal'))
      successMessage(
        pageType === 'create'
          ? i18n_get.t('Procurement.CreateOrderPage.SuccessMessage')
          : i18n_get.t('Procurement.EditOrderPage.SuccessMessage'),
      )
    } catch (error) {
      defaultError(error as object)
      dispatch(createOrderPageAtoms.state.set('normal'))
    }
  },
)
