import React from 'react'

interface ModalContextData {
  showModal: <C extends React.FC<any>>(key: string, component: C, props: React.ComponentProps<C>) => void,
  hideModal: (key: string, onClose?: () => void) => void,
}

export const ModalContext = React.createContext<ModalContextData>({
  showModal: () => {},
  hideModal: () => {},
})
