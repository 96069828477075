import {DefaultButtonsProps, PopupDefaultContent} from '@appscience/popups'
import {joinStrings} from '@appscience/utils'

type NotificationPopupProps = {
  title: string
  description?: string
  buttonsInfo: DefaultButtonsProps
  className?: string
}

export function NotificationPopup({
  title,
  description,
  buttonsInfo,
  className,
}: NotificationPopupProps) {
  return (
    <PopupDefaultContent
      className={joinStrings('max-w-[400px]', className)}
      createContent={() => (
        <>
          <div className='text-xl font-semibold'>{title}</div>
          {description && (
            <div className='mt-2 text-md font-normal'>{description}</div>
          )}
        </>
      )}
      buttonsInfo={buttonsInfo}
    />
  )
}
