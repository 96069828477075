import { assert } from '@appscience/utils'
import { Store } from '@reatom/core'
import { createPrimitiveAtom } from '@reatom/core/primitives'
import { getTime, hoursToMilliseconds } from 'date-fns'
import { indexBy } from 'ramda'
import { getApi } from '../../api'

let lastInitTimestamp: number | null

export interface SdExpData {
  title: string
  id: string
  color: string
}

export const sdExpsAtom = createPrimitiveAtom<
  Record<string, SdExpData>
>({})

export async function updateSdExpsAtom({ dispatch }: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }
  const data = await getApi().listsApi.getSdExps()
  const record = indexBy(x => x.id, data)
  dispatch(sdExpsAtom.set(record))
  lastInitTimestamp = getTime(new Date())
  return record
}

export function getSdExpsTitle(id: string) {
  return (
    assert(
      sdExpsAtom.getState()[id],
      `SdExpsAtom error: sdExp with id "${id}" not found`,
    )?.title || ''
  )
}

export function assertSdExp(id: string): string | null {
  return (
    assert(
      sdExpsAtom.getState()[id],
      `SdExpsAtom assertion failed: sdExp with id "${id}" not found`,
    )?.id || null
  )
}
