import {MantineThemeComponents} from '@mantine/styles/lib/theme/types/MantineTheme'
import {ValueOfRecord} from 'ramda'

export function getButtonStylesConfig(): ValueOfRecord<MantineThemeComponents> {
  return {
    styles: { root: {
      paddingLeft: 16,
      paddingRight: 16,
    }}
  }
}
