import { createAtom } from '@reatom/core'
import { CreateOrderPageItem } from './items/items.atom.type'
import { CreateOrderPageOrderFields } from './create-order-page-order-fields.type'

interface CreateOrderInitValues {
  initItems: Array<CreateOrderPageItem>
  initOrderFields: CreateOrderPageOrderFields | null,
}

export const createOrderPageInitValuesAtom = createAtom(
  {
    initItems: (value: Array<CreateOrderPageItem>) => value,
    initOrderFields: (value: CreateOrderPageOrderFields | null) => value,
  },
  ({ onAction }, state: CreateOrderInitValues = { initItems: [], initOrderFields: null }) => {
    onAction('initItems', value => {
      state = { ...state, initItems: value }
    })

    onAction('initOrderFields', value => {
      state = { ...state, initOrderFields: value }
    })

    return state
  },
)
