
import { API_URL } from 'src/config/environment'
import apiCreator from '../../api-creator'
import {handleErrorWithDefaultValue} from '../utils'
import { ProcurementDlReasonData } from 'src/atoms/static-data/procurement-dl-reasons'

interface ResponseProcurementDlReasonData {
  name: string;
  russian_name: string;
}

export function getProcurementDelayReasons(): Promise<Array<ProcurementDlReasonData>> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/procurement-dl-reasons`,
    )
    .then(res => {
      const rawItems: Array<ResponseProcurementDlReasonData> = res.data.procurement_dl_reasons
      return rawItems.map(remapToProcurementDlReasonData)
    })
    .catch(handleErrorWithDefaultValue([]))
}

export function remapToProcurementDlReasonData(data: ResponseProcurementDlReasonData): ProcurementDlReasonData {
  return {
    id: data.name,
    title: data.name,
    ruTitle: data.russian_name,
  }
}
