import { PlusCircleIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import { Button } from '@mantine/core'
import { toast } from 'react-hot-toast'
import { getApi } from '../../../api'
import { useAtomState } from '../../../hooks/use-atom-state'
import { i18n_get } from '../../../lng/i18n'
import { packingListGeneratorAtoms } from '../model/atoms'

export function PackingListGeneratorLayoutActions() {
  const [loading, setLoading] = useState(false)
  const selectedRowIds = useAtomState(packingListGeneratorAtoms.selectedRowIds)
  if (selectedRowIds.length === 0) {
    return null
  }

  const onGenerateDocuments = () => {
    setLoading(true)
    const promise = getApi().packingList.generatePackingList({ item_ids: selectedRowIds })
      .then(res => {
        window.open(res.docUrl, '_blank')
        window.open(res.docUrlForClient, '_blank')
      })
      .finally(() => setLoading(false))
    toast.promise(promise, {
      loading: i18n_get.t('PackingListGenerator.GenerateDocument.LoadingMessage'),
      success: i18n_get.t('PackingListGenerator.GenerateDocument.SuccessMessage'),
      error: i18n_get.t('PackingListGenerator.GenerateDocument.ErrorMessage'),
    })
  }

  return (
    <div className='flex items-center mr-3 space-x-2'>
      <Button disabled={loading} onClick={onGenerateDocuments} leftIcon={<PlusCircleIcon className='w-5 h-5' />}
      >
        {i18n_get.t('PackingListGenerator.CreateDocumentAction')}
      </Button>
    </div>
  )
}
