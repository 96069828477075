import {CSSObject} from '@emotion/styled'
import {ActionIcon, CopyButton, Tooltip} from '@mantine/core'
import {IconCheck, IconCopy} from '@tabler/icons'
import {mergeDeepRight} from 'ramda'
import React from 'react'

interface DefaultTooltipProps extends React.ComponentProps<typeof Tooltip> {
  maxWidth?: 'auto' | number
}

function getDefaultStyles({
  maxWidth,
}: DefaultTooltipProps): Record<string, CSSObject> {
  return {
    tooltip: {
      pointerEvents: 'auto',
      zIndex: 100,
      whiteSpace: 'pre-line',
      maxWidth:
        maxWidth === undefined
          ? '350px'
          : maxWidth === 'auto'
          ? 'auto'
          : `${maxWidth}px`,
    },
  }
}

export function DefaultTooltip(props: DefaultTooltipProps) {
  const styles = props.styles
    ? mergeDeepRight(getDefaultStyles(props), props.styles)
    : getDefaultStyles(props)

  return (
    <Tooltip
      withinPortal
      styles={styles}
      openDelay={300}
      closeDelay={100}
      events={{hover: true, focus: false, touch: true}}
      {...props}
    >
      {props.children}
    </Tooltip>
  )
}

export function TooltipWithCopy(props: DefaultTooltipProps) {
  const item =
    typeof props.label === 'string' ? (
      <div className='flex justify-between'>
        <span>{props.label}</span>
        <CopyButton value={props.label} timeout={2000}>
          {({copied, copy}) => (
            <ActionIcon
              onClick={copy}
              variant='transparent'
              color={copied ? 'green' : 'gray'}
            >
              {copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
            </ActionIcon>
          )}
        </CopyButton>
      </div>
    ) : (
      props.label
    )

  return <DefaultTooltip {...props} label={item} />
}

export function HintTooltip(props: DefaultTooltipProps) {
  return (
    <DefaultTooltip
      position='bottom'
      openDelay={500}
      events={{hover: true, focus: false, touch: true}}
      styles={{
        tooltip: {
          padding: '1px 8px',
          fontSize: '12px',
        },
      }}
      {...props}
      children={props.children}
    />
  )
}

export function withHintTooltip(label: string, children: React.ReactNode) {
  return <HintTooltip label={label} children={children} />
}
