import { createPrimitiveAtom } from '@reatom/core/primitives'

export const enum FeatureFlag {
  Qa,
}

type FeatureFlags = Record<FeatureFlag, boolean>

export const featureFlagsAtom = createPrimitiveAtom<FeatureFlags>({
  [FeatureFlag.Qa]: localStorage.getItem('qa') === '1',
})
