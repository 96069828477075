import { indexBy, prop } from 'ramda'
import { createMultipleParam } from '@appscience/utils'
import { API_URL } from 'src/config/environment'
import { CreatePackageItem } from '../../views/warehouse/create-package/model/types'
import apiCreator from '../api-creator'
import { Api_Item } from '../common/item-model'
import { getItemInvoicePrice } from '../common/utils'
import { parseDate } from '@app/utils/date'

interface Params {
  ids: Array<string>
}

export function getCreatePackageItems({
  ids,
}: Params): Promise<Record<string, CreatePackageItem>> {
  return apiCreator
    .get(`${API_URL.TWIN}/items?${createMultipleParam(ids, 'id')}`)
    .then(res => {
      const rawData: Array<Api_Item> = res.data.items
      return indexBy(prop('id'), rawData.map(remapToCreatePackageItem))
    })
}

export function remapToCreatePackageItem(data: Api_Item): CreatePackageItem {
  return {
    id: data.id,
    isCollectedInPackage: false,
    amoId: data.deal_id,
    invoiceName: data.name,
    providerHazmatType: data.provider_hazmat_type,
    originalName: data.original_name,
    orderNumbering: data.order_numbering,
    brand: data.brand,
    catalogueId: data.catalogue_id,
    toHub0: parseDate(data.sent_to_hub_0 || '') || null,
    hub0: data.hub_0,
    trackHub0: data.track_to_hub_0,
    tswed: parseDate(data.registration_date || '') || null,
    initInvoicePrice: getItemInvoicePrice(data) || 0,
    coefInvoicePrice: getItemInvoicePrice(data) || 0,
    quantity: data.quantity,
    operationLetitId: data.operation_letit_id,
    hsCodeSecondary: data.hs_code_secondary || '',
    netWeight: data.net_weight_kg,
    grossWeight: data.gross_weight_kg,
    locations: data.provider_locations,
    currencyId: data.currency,
    expiredDate: parseDate(data.provider_exp),
    temperature: data.provider_temp,
    countryOfOrigin: data.country_of_origin,
    hazmatComment: data.provider_hazmat,
    lot: data.provider_lot,
    exportComment: data.provider_comment,
    exportStatuses: data.provider_export_statuses || [],
    boxNumber: data.box_numbers || [],
    hsnCodes: data.hsn_codes || '',
  }
}
