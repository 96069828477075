import apiCreator from 'src/api/api-creator'
import { API_URL } from 'src/config/environment'
import { JobTitle } from './model'

export interface GetJobTitlesResponse {
  job_titles: Array<JobTitle>;
}

export async function getJobTitles(): Promise<GetJobTitlesResponse> {
  return apiCreator
    .get<GetJobTitlesResponse>(
      `${API_URL.AUTH}/list-job-titles`,
    )
    .then(({ data }) => data)
}
