import { ActionIcon } from '@mantine/core'
import { useAtom } from '@reatom/react'
import { IconChevronDown, IconChevronUp } from '@tabler/icons'
import { areOrderDetailsOpenedAtom } from './order-details-toggler.atom'
import { useCallback } from 'react'

export function OrderDetailsToggler() {
  const [areOrderDetailsOpened, { set: setAreOrderDetailsOpened }] = useAtom(areOrderDetailsOpenedAtom)

  const toggle = useCallback(() => {
    setAreOrderDetailsOpened(!areOrderDetailsOpened)
  }, [areOrderDetailsOpened, setAreOrderDetailsOpened])

  return (
    <ActionIcon
      variant='transparent'
      onClick={toggle}
    >
      {areOrderDetailsOpened && (
        <IconChevronDown
          size={50}
        />
      )}
      {!areOrderDetailsOpened && (
        <IconChevronUp
          size={50}
        />
      )}
    </ActionIcon>
  )
}
