import {
  DocumentMagnifyingGlassIcon,
  UserGroupIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import {useCurrentBaseRoute} from '../../../hooks/route-hooks'
import {i18n_get} from '../../../lng/i18n'
import {getPageTitleByRoute} from '../../../routes/titles'
import {checkNever} from '@appscience/utils'
import {DefaultLeftMenuItemProps} from './menu-item'
import {
  BoxLeftMenuIcon,
  CarIcon,
  HomeIcon,
  PlusCircleIcon,
} from '../../../icons'

export function useLeftMenuItems(): Array<DefaultLeftMenuItemProps> {
  const route = useCurrentBaseRoute()
  switch (route) {
    case '/procurement':
      return getProcurementMenuItems()
    case '/provider':
      return getProviderMenuItems()
    case '/delivery-point':
      return getDeliveryPointMenuItems()
    case '/reorder':
      return getReorderMenuItems()
    case '/iam':
      return getIAMMenuItems()
    case '/auth':
      return []
    case '/compliance':
      return getComplianceMenuItems()
    case '/analogues':
      return getAnaloguesMenuItems()
    default:
      checkNever(route, `Unknown base route. Value: ${route}`)
      return []
  }
}

function getComplianceMenuItems(): Array<DefaultLeftMenuItemProps> {
  return [
    {
      text: i18n_get.t('Compliance.Scifinder.MenuItem'),
      redirectRoute: '/compliance/scifinder',
      icon: DocumentMagnifyingGlassIcon,
    },
    {
      text: i18n_get.t('Compliance.Scifinder.ExcelUpload.MenuItem'),
      redirectRoute: '/compliance/excel-upload',
      icon: DocumentMagnifyingGlassIcon,
    },
  ]
}

function getAnaloguesMenuItems(): Array<DefaultLeftMenuItemProps> {
  return [
    {
      text: i18n_get.t('Analogues.ExcelUpload.MenuItem'),
      redirectRoute: '/analogues',
      icon: DocumentMagnifyingGlassIcon,
    },
  ]
}

function getDeliveryPointMenuItems(): Array<DefaultLeftMenuItemProps> {
  return [
    {
      text: i18n_get.t('PackingListGenerator.WarehouseMenuItem'),
      redirectRoute: '/delivery-point',
      icon: PlusCircleIcon,
    },
  ]
}

function getReorderMenuItems(): Array<DefaultLeftMenuItemProps> {
  return [
    {
      text: i18n_get.t('Reorder.MenuItem'),
      redirectRoute: '/reorder',
      icon: PlusCircleIcon,
    },
  ]
}

function getProcurementMenuItems(): Array<DefaultLeftMenuItemProps> {
  return [
    {
      text: getPageTitleByRoute('/procurement/new-order'),
      redirectRoute: '/procurement/new-order',
      icon: PlusCircleIcon,
    },
    {
      text: i18n_get.t('PreOrders.LeftMenuLabel'),
      redirectRoute: '/procurement/pre-orders',
      icon: BoxLeftMenuIcon,
    },
  ]
}

function getProviderMenuItems(): Array<DefaultLeftMenuItemProps> {
  return [
    {
      text: getPageTitleByRoute('/provider/to-warehouse'),
      redirectRoute: '/provider/to-warehouse',
      icon: CarIcon,
    },
    {
      text: getPageTitleByRoute('/provider/in-warehouse'),
      redirectRoute: '/provider/in-warehouse',
      icon: HomeIcon,
    },
    {
      text: getPageTitleByRoute('/provider/from-warehouse'),
      redirectRoute: '/provider/from-warehouse',
      icon: BoxLeftMenuIcon,
    },
  ]
}

function getIAMMenuItems(): Array<DefaultLeftMenuItemProps> {
  return [
    {
      text: getPageTitleByRoute('/iam/users'),
      redirectRoute: '/iam/users',
      icon: UserIcon,
    },
    {
      text: getPageTitleByRoute('/iam/groups'),
      redirectRoute: '/iam/groups',
      icon: UserGroupIcon,
    },
  ]
}
