import { Store } from '@reatom/core'
import { createPrimitiveAtom } from '@reatom/core/primitives'
import { getTime, hoursToMilliseconds } from 'date-fns'
import { getApi } from 'src/api'
import { PaymentPriorityData } from 'src/api/common/lists/get-payment-prioirties'

let lastInitTimestamp: number | null


export const paymentPrioritiesAtom = createPrimitiveAtom<Array<PaymentPriorityData>>([])

export async function updatePaymentPriorities({dispatch}: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }
  const data = await getApi().listsApi.getPaymentPriorities()
  dispatch(paymentPrioritiesAtom.set(data))
  lastInitTimestamp = getTime(new Date())
  return data
}