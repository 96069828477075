import {ActionIcon, Text, createStyles} from '@mantine/core'
import React from 'react'
import {useCurrentRoute, useRedirectFn} from '../../../hooks/route-hooks'
import {AppRoute} from '../../../routes/types'
import {mode} from '@appscience/utils'

interface UseStylesParams {
  activeItem: boolean
}

const useStyles = createStyles(
  ({colors, colorScheme, white, fn}, params: UseStylesParams | undefined) => ({
    menuItem: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: 'auto',
      padding: '8px',
      transitionDuration: '200ms',
      color: white,
      backgroundColor: params?.activeItem
        ? mode(fn.rgba(white, 0.16), colors.gray[0])(colorScheme)
        : 'transparent',
      border: 'none',
      '&:hover': {
        backgroundColor: fn.rgba(white, 0.1),
      },

      '& > div': {
        marginTop: '8px',
        lineHeight: '12px',
        textAlign: 'center',
      },
    },
  }),
)

export interface DefaultLeftMenuItemProps {
  text: string
  icon: React.FC<React.ComponentProps<'svg'>>
  redirectRoute: AppRoute
}

export function DefaultLeftMenuItem({
  text,
  icon: Icon,
  redirectRoute,
}: DefaultLeftMenuItemProps) {
  const redirectTo = useRedirectFn()
  const currentRoute = useCurrentRoute()

  const {classes} = useStyles({
    activeItem: currentRoute.includes(redirectRoute),
  })

  return (
    <ActionIcon
      radius={0}
      className={classes.menuItem}
      onClick={() => redirectTo(redirectRoute)}
    >
      <Icon className='w-5 h-5 shrink-0' />
      <Text className='text-[10px] font-semibold mt-1'>{text}</Text>
    </ActionIcon>
  )
}
