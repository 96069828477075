import {Button} from '@mantine/core'
import {useModal} from '@appscience/hooks'
import {useAloneAction} from '../../../../../hooks/use-alone-action'
import {useAtomState} from '../../../../../hooks/use-atom-state'
import {i18n_get} from '../../../../../lng/i18n'
import {VuesaxLinearBox} from '../../../../../icons'
import {
  newOrderTableItemsAtom,
  newOrderTableSelectedRowsAtom,
} from 'src/views/procurement/new-order/model/main'
import {NewOrderTableItem} from '../../model/main'
import {loadNewOrderPageData} from '../../model/load-data'
import {AddItemsToOrderPopup} from '../add-items-to-order-popup'
import {NewOrderLayoutButton} from './create-order'
import {getSingleSettingsState} from 'src/components/table/utils/settings-atom'
import {newOrderTableSettingsAtoms} from '../../model/settings'

export function NewOrderLayoutActions() {
  const items = useAtomState(newOrderTableItemsAtom)
  const selectedRowIds = useAtomState(newOrderTableSelectedRowsAtom)
  const selectedIndexesSet: Set<string> = new Set(Object.keys(selectedRowIds))
  if (selectedIndexesSet.size <= 0) {
    return null
  }
  const selectedRows = items.filter(item => selectedIndexesSet.has(item.id))
  const sortedIds = selectedRows.map(x => x.id)

  return (
    <div className='flex items-center mr-2 space-x-2'>
      <NewOrderLayoutButton selectedIds={sortedIds} />
      <AddToOrderButton selectedRows={selectedRows} />
    </div>
  )
}

interface AddToOrderButtonProps {
  selectedRows: Array<NewOrderTableItem>
}

function AddToOrderButton({selectedRows}: AddToOrderButtonProps) {
  const handleLoadData = useAloneAction(loadNewOrderPageData)
  const [openPopup, hidePopup] = useModal(AddItemsToOrderPopup, {
    title: i18n_get.t('NewOrder.AddItemsToOrderPopup.Title'),
    getItems: () =>
      Promise.resolve(
        selectedRows.map(x => ({
          id: x.id,
          brand: x.brand,
          amoId: x.amoId,
          invoiceName: x.name,
          catalogueId: x.catalogueId,
          cost: x.clientPrice,
          quantity: x.quantity,
        })),
      ),
    closeFn: () => hidePopup(),
    onSuccess: () => {
      handleLoadData({
        settings: getSingleSettingsState(newOrderTableSettingsAtoms),
        isNewLazyLoading: true,
      })
      return Promise.resolve()
    },
  })

  return (
    <Button
      leftIcon={<VuesaxLinearBox />}
      onClick={openPopup}
      children={i18n_get.t('NewOrder.AddToOrder')}
    />
  )
}
