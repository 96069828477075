import {primitiveAtomWithLS} from '@app/utils/reatom'
import {createPrimitiveAtom} from '@reatom/core/primitives'
import {RowSelectionState} from '@tanstack/react-table'

export const newOrderStoreIdsFilterAtom = primitiveAtomWithLS<Array<string>>(
  [],
  'NewOrder.storeIdsFilterAtom',
)

export interface NewOrderTableItem {
  id: string
  invoiceDate: Date | null
  deadlineDate: Date | null
  priority: string
  clientName: string
  amoId: string
  clientPrice: number
  name: string
  complianceComment: string
  esdFix: Date | null
  brand: string
  purchaseDate: Date | null
  createdDate: Date | null
  sample: boolean
  catalogueId: string
  clientType: Array<string>
  exefType: string[]
  quantity: number
  salesComment: string
  supposedStore: string
  country: string
  originalName: string
  procurement: string
  proposalComment?: string
  procurementComment?: string
  express: boolean
  url: string
  casNumber: string
  packing: string
  license: string[]
  compliance: string[]
  exportDocuments: Array<string>
  hsCodeChina: string
  providerComment?: string
  provider?: string
  status?: Array<string>
  orderDetails: string
  temperature: string
  tswName: string
  quota: string
  dgmStatus: string
  deliveryMethod: string
  isTender: boolean
  providerHazmatType: string
  complianceDate: Date | null
  estimatedOrderDate: Date | null
  vendorRecommendation: string
  procurementComplianceSpecialist: string
  dateOfProcurementCompliance: string
  clientPaymentReceived: boolean
  clientPaymentTerms: string
  dlOrder: Date | null
  procurementDlReason: Array<string>
}

export const newOrderTableItemsAtom = createPrimitiveAtom<
  Array<NewOrderTableItem>
>([])
export const newOrderTableSelectedRowsAtom =
  createPrimitiveAtom<RowSelectionState>({})
