import { createAtom } from '@reatom/core'
import produce from 'immer'
import { historyAtom } from '../../../../../atoms/history-atom'
import {
  calcSumExpression,
  isNullable,
  updateObject,
  verify,
} from '@appscience/utils'
import { createOrderPageStaticDataAtom } from '../index'
import { createOrderPageItemErrorsAtom } from '../errors/item-fields-validation'
import { createOrderPageInitValuesAtom } from '../init-values-atom'
import { CreateOrderPageItem, IndexedCreateOrderPageItem, ItemOtherCostInfo } from './items.atom.type'
import { CreateOrderPageOrderFields } from '../create-order-page-order-fields.type'
import { createOrderPageFieldsAtom } from '../order-fields.atom'
import { isManuallyTax, updateTaxValuesWithManually } from './items.atom.utils'

export const createOrderPageItemsAtom = createAtom(
  {
    init: (items: Array<CreateOrderPageItem>) => items.map((item, index) => ({ ...item, index })),
    toggleAcceptOtherCostForTax: (data: { id: string; acceptForAll?: boolean }) => data,
    togglePrepayment: (data: { id: string; acceptForAll?: boolean }) => data,
    remove: (id: string) => id,
    update: <K extends keyof IndexedCreateOrderPageItem>(
      id: string,
      field: K,
      value: IndexedCreateOrderPageItem[K],
    ) => ({ id, field, value }),
    updateMultipleBy: <K extends IndexedCreateOrderPageItem>({
      id,
      byField,
      byFieldValue,
      key,
      value,
    }: {
      id: string,
      byField: keyof K,
      byFieldValue: K[keyof K],
      key: keyof K,
      value: K[keyof K],
    }) => ({ byField, byFieldValue, id, key, value }),
    updateAll: <K extends IndexedCreateOrderPageItem>({
      key,
      value,
    }: {
      key: keyof K,
      value: K[keyof K],
    }) => ({ key, value }),
    syncMultipleByField: <K extends IndexedCreateOrderPageItem>({
      byField,
      id,
      fieldsToSync,
    }: {
      byField: keyof K,
      id: string,
      fieldsToSync: Array<keyof K>
    }) => ({ byField, id, fieldsToSync }),
    sync: <K extends keyof CreateOrderPageOrderFields>(
      field: K,
      value: CreateOrderPageOrderFields[K],
    ) => ({ field, value }),
    reset: () => { },
  },
  (
    { onAction, schedule },
    items: Array<IndexedCreateOrderPageItem> = [],
  ) => {
    onAction('reset', () => {
      items = []
    })

    onAction('init', value => {
      items = value
      schedule(dispatch => {
        dispatch(createOrderPageInitValuesAtom.initItems(value))
      })
    })

    onAction('updateAll', ({ key, value }) => {
      items = produce(items, draft => {
        draft.forEach(elem => {
          updateObject(elem, key, value)
        })
      })
    })

    onAction('remove', id => {
      if (items.length <= 1) {
        const pageType = createOrderPageStaticDataAtom.getState().pageType
        historyAtom
          .getState()
          .typedPush(
            pageType === 'create'
              ? '/procurement/new-order'
              : '/procurement/pre-orders',
          )
        return
      }

      const index = items.findIndex(({ id: itemId }) => itemId === id)

      items = [
        ...items.slice(0, index),
        ...items.slice(index + 1, items.length + 1),
      ]
    })

    onAction('toggleAcceptOtherCostForTax', ({ id, acceptForAll }) => {
      items = produce(items, draft => {
        const selectedItem = verify(draft.find(itemDraft => itemDraft.id === id))
        const newValue = !selectedItem.otherCost.acceptForTax

        if (acceptForAll) {
          draft.forEach(({ otherCost }) => (otherCost.acceptForTax = newValue))
        } else {
          selectedItem.otherCost.acceptForTax = newValue
        }

        const taxPercent = createOrderPageFieldsAtom.getState().taxPercent
        taxPercent && updateTaxValuesWithManually(draft, taxPercent)
      })
    })

    onAction('updateMultipleBy', ({ id, byField, byFieldValue, key, value }) => {
      items = produce(items, draft => {
        const item = verify(draft.find(itemDraft => itemDraft.id === id))
        updateObject(item, key, value)

        draft.forEach(elem => {
          if (byFieldValue && elem[byField] === byFieldValue) {
            updateObject(elem, key, value)
          }
        })
      })
    })

    onAction('syncMultipleByField', ({ byField, fieldsToSync, id }) => {
      items = produce(items, draft => {
        const item = verify(draft.find(itemDraft => itemDraft.id === id))

        draft.forEach(elem => {
          if (elem[byField] === item[byField]) {
            fieldsToSync.forEach(key => {
              updateObject(item, key, elem[key])
            })
          }
        })
      })
    })

    onAction('update', ({ id, field, value }) => {
      items = produce(items, draft => {
        const item = verify(draft.find(itemDraft => itemDraft.id === id))
        const prevValue = item[field]
        updateObject(item, field, value)

        const taxPercent = createOrderPageFieldsAtom.getState().taxPercent
        if (field === 'cost' && !isNullable(taxPercent)) {
          updateTaxValuesWithManually(draft, taxPercent)
        }

        if (field === 'otherCost') {
          const prevOtherCost = prevValue as ItemOtherCostInfo
          const newOtherCost = value as ItemOtherCostInfo
          const prevOtherCostValue = prevOtherCost.value
          const newOtherCostValue = newOtherCost.value
          schedule(dispatch =>
            dispatch(
              createOrderPageFieldsAtom.syncOtherCost({
                prevValue: prevOtherCostValue,
                newValue: newOtherCostValue,
              }),
            ),
          )

          if (newOtherCost.acceptForTax && taxPercent) {
            updateTaxValuesWithManually(draft, taxPercent)
          }
        }

        if (field === 'shipmentTimestamp' || field === 'nextCheckTimestamp') {
          draft.forEach(itemDraft => {
            itemDraft[field] = itemDraft[field] === null
              ? (value as number)
              : itemDraft[field]
          })
        }

        if (field === 'esdFixOriginal') {
          const firstItem = draft[0]
          if (id === firstItem.id) {
            draft.forEach(currentItem => {
              currentItem[field] = item[field] == null
                ? (value as Date)
                : item[field]
            })
          }
        }

        if (field === 'isFree') {
          item.tax = { value: 0, manually: false }
          item.otherCost = { value: 0, acceptForTax: false }
          item.cost = value
            ? null
            : item.initialCost
          item.invoicePrice = value
            ? item.initialInvoicePrice
            : null
        }
      })

      schedule(dispatch => {
        dispatch(createOrderPageItemErrorsAtom.checkField(id, field, value))
      })
    })

    onAction('sync', ({ field, value }) => {
      items = produce(items, draft => {
        if (field === 'taxPercent') {
          const taxPercent = value as number | null
          const allTaxIsManually = draft.every(itemDraft => isManuallyTax(itemDraft.tax))

          if (isNullable(taxPercent)) {
            draft.forEach(itemDraft => {
              const tax = itemDraft.tax
              itemDraft.tax = !allTaxIsManually && isManuallyTax(tax)
                ? tax
                : null
            })
          } else {
            updateTaxValuesWithManually(draft, taxPercent)
          }
        }

        if (field === 'sumOtherCost') {
          const taxPercent = createOrderPageFieldsAtom.getState().taxPercent
          const sumOtherCost = value as string | null
          const sum = sumOtherCost === null ? null : calcSumExpression(sumOtherCost)
          const otherCostValue = isNullable(sum)
            ? null
            : sum / draft.length

          draft.forEach(itemDraft => {
            itemDraft.otherCost = {
              ...itemDraft.otherCost,
              value: otherCostValue,
            }
          })

          if (taxPercent) {
            updateTaxValuesWithManually(draft, taxPercent)
          }
        }
      })
    })

    return items
  },
)
