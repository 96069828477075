import {joinStrings} from '@appscience/utils'

type TextWithEllipsis = {
  text: string
  maxLines?: number,
  className?: string
}

export function TextWithEllipsis({text, className, maxLines = 1}: TextWithEllipsis) {
  return (
    <span
      className={joinStrings(
        `line-clamp-${maxLines}`,
        className,
      )}
      title={text}
    >
      {text}
    </span>
  )
}
