import {MantineThemeComponents} from '@mantine/styles/lib/theme/types/MantineTheme'
import {ValueOfRecord} from 'ramda'

export function getBadgeStylesConfig(): ValueOfRecord<MantineThemeComponents> {
  return {
    styles: {
      root: {
        height: 24,
        padding: '4px 8px',
        lineHeight: 16,
        fontSize: 13,
        fontWeight: 500,
        textTransform: 'none',
      },
    }
  }
}
