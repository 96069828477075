import apiCreator from 'src/api/api-creator'
import { API_URL } from 'src/config/environment'
import { PermissionGroupWithId } from './model'

export type GetGroupByIdResponse = PermissionGroupWithId

export async function getGroupById(id: string): Promise<GetGroupByIdResponse> {
  return apiCreator
    .get<GetGroupByIdResponse>(
      `${API_URL.AUTH}/get-user-group`,
      {
        params: {
          id,
        },
      },
    )
    .then(({ data }) => data)
}
