import { API_URL } from 'src/config/environment'
import apiCreator from '../../api-creator'

interface ReturnToWarehouseItems {
  type: 'items'
  itemIds: Array<string>
}

interface ReturnToWarehousePackage {
  type: 'package'
  packageId: string
}

type Params = ReturnToWarehouseItems | ReturnToWarehousePackage

export function returnToWarehouse(data: Params): Promise<void> {
  if (data.type === 'items') {
    return apiCreator
      .post(
        `${API_URL.TWIN}/package-delete-items`,
        {
          item_ids: data.itemIds,
        },
      )
  }
  return apiCreator
    .post(
      `${API_URL.TWIN}/remove-package`,
      {
        package_id: data.packageId,
      },
    )
}
