import {ExchangeRates} from '../../atoms/static-data/exchange-rates-atom'
import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'
import {handleErrorWithDefaultValue} from './utils'

export const currenciesApi = {
  getExchangeRates,
}

interface Api_ExchangeRateData {
  amount: number
}

type CurrencyId = string
export type Api_GetExchangeRates = Record<CurrencyId, Api_ExchangeRateData>

function getExchangeRates(): Promise<ExchangeRates> {
  return apiCreator
    .get(
      `${API_URL.CURRENCIES}/current`,
    )
    .then(res => {
      const rawRates: Api_GetExchangeRates = res.data.currencies
      return remapToExchangeRates(rawRates)
    })
    .catch(handleErrorWithDefaultValue({}))
}

export function remapToExchangeRates(rawRates: Api_GetExchangeRates) {
  const result = Object.entries(rawRates).reduce(
    (acc: ExchangeRates, [key, data]) => {
      acc[key] = data.amount
      return acc
    },
    {},
  )
  result['RUB'] = 1 //TODO:improvements:api

  return result
}
