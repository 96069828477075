import {defaultError} from '@app/utils/errors'
import {successMessage} from '@app/utils/feedback'
import {useModal} from '@appscience/hooks'
import {useState} from 'react'
import {Button, useMantineTheme} from '@mantine/core'
import {getApi} from '../../../../api'
import {userAtom} from '../../../../atoms/user-atom'
import {NotificationPopup} from '../../../../components/popups/notification-popup'
import {getSingleSettingsState} from '../../../../components/table/utils/settings-atom'
import {useRedirectFn} from '../../../../hooks/route-hooks'
import {useAloneAction} from '../../../../hooks/use-alone-action'
import {useAtomState} from '../../../../hooks/use-atom-state'
import {i18n_get} from '../../../../lng/i18n'
import {isModerationStatus, isUnderTrackingStatus} from '../../../../types/order-status'
import {isByItems} from '../../../../types/types'
import {useModerateOrderHandlers} from '../../common/moderator/use-moderate-order-handlers'
import {loadPreordersData} from '../model/load-data'
import {
  preordersItemsAtom,
  preordersItemsTableSelectedRowsAtom,
  preordersOrdersAtom, preordersOrdersTableSelectedIdsAtom,
  preordersPageTabAtom,
} from '../model/main'
import {preordersItemsSettingsAtom} from '../model/settings'
import { isFC } from 'src/views/iam/common/utils'

export function PreordersLayoutActions() {
  const currentTab = useAtomState(preordersPageTabAtom)
  const {role} = useAtomState(userAtom)

  return (
    <div className='flex items-center mr-3'>
      {isByItems(currentTab)
        ? <TableByItemsSectionContent />
        : (['default', 'head-of-procurement'].includes(role)
          ? <TableByOrdersProcurementActions />
          : <TableByOrdersModeratorActions />
        )
      }
    </div>
  )
}

function TableByItemsSectionContent() {
  const [loading, setLoading] = useState(false)
  const selectedRowIdsObj = useAtomState(preordersItemsTableSelectedRowsAtom)
  const items = useAtomState(preordersItemsAtom)
  const selectedRowIds = items.reduce((acc: Array<string>, item, index) => {
    if (index in selectedRowIdsObj) {
      acc.push(item.id)
    }
    return acc
  }, [])

  const handleLoadData = useAloneAction(loadPreordersData)
  const [openPopup, hidePopup] = useModal(NotificationPopup)
  if (selectedRowIds.length === 0) {
    return null
  }

  const onClick = () => openPopup({
    title: i18n_get.t('PreOrders.RemoveFromOrderQuestion'),
    description: i18n_get.t('PreOrders.RemoveFromOrderDescription'),
    buttonsInfo: {
      success: {
        onClick: () => {
          setLoading(true)
          getApi().procurement.removeItems(selectedRowIds)
            .then(() => {
              successMessage(
                i18n_get.t(
                  'Procurement.OrdersPage.RemoveItemsSuccessMessage',
                ),
              )
              handleLoadData({
                settings: getSingleSettingsState(preordersItemsSettingsAtom),
                isNewLazyLoading: true,
              })
              hidePopup()
            })
            .catch(defaultError)
            .finally(() => setLoading(false))
        },
        text: i18n_get.t('Common.Remove'),
        loading,
      },
      cancel: { onClick: hidePopup },
    },
  })

  return (
    <Button color='red' onClick={onClick}>
      {i18n_get.t('PreOrders.RemoveFromOrder')}
    </Button>
  )
}


function TableByOrdersModeratorActions() {
  const selectedRowIds = useAtomState(preordersOrdersTableSelectedIdsAtom)
  const orders = useAtomState(preordersOrdersAtom)
  const {colors} = useMantineTheme()
  const handleLoadData = useAloneAction(loadPreordersData)
  const {role} = useAtomState(userAtom)
  const {onReject, onApprove, loading} = useModerateOrderHandlers({
    ids: Object.keys(selectedRowIds),
    onSuccessApprove: () => handleLoadData({
      backgroundMode: true,
      settings: getSingleSettingsState(preordersItemsSettingsAtom),
      isNewLazyLoading: true,
    }),
    onSuccessReject: () => handleLoadData({
      backgroundMode: true,
      settings: getSingleSettingsState(preordersItemsSettingsAtom),
      isNewLazyLoading: true,
    }),
  })

  const showButtons = isFC(role)
    ? Object.keys(selectedRowIds).every(id => isUnderTrackingStatus(orders[id].statusId))
    : Object.keys(selectedRowIds).every(id => isModerationStatus(orders[id].statusId))

  if (Object.keys(selectedRowIds).length === 0 || !showButtons) {
    return null
  }

  return (
    <>
      <Button 
        style={{
          backgroundColor: colors.green[8],
        }} 
        onClick={onApprove} 
        disabled={loading === 'approve'} 
        className='mr-2'
      >
        {i18n_get.t('Common.Approve')}
      </Button>
      <Button 
        style={{
          backgroundColor: colors.red[8],
        }} 
        onClick={onReject} 
        disabled={loading === 'reject'}
      >
        {i18n_get.t('Common.Reject')}
      </Button>
    </>
  )
}


function TableByOrdersProcurementActions() {
  const redirectFn = useRedirectFn('/procurement')
  const [loading, setLoading] = useState(false)
  const [openPopup, hidePopup] = useModal(NotificationPopup)
  const selectedRowIds = useAtomState(preordersOrdersTableSelectedIdsAtom)
  const handleLoadData = useAloneAction(loadPreordersData)
  const rowIds = Object.keys(selectedRowIds)
  if (rowIds.length !== 1) {
    return null
  }

  const onClick = () => openPopup({
    title: i18n_get.t('PreOrders.CancelOrderQuestion'),
    description: i18n_get.t('PreOrders.CancelOrderDescription'),
    buttonsInfo: {
      success: {
        onClick: () => {
          setLoading(true)
          getApi()
            .procurement.reorder(rowIds[0])
            .then(() => {
              successMessage(i18n_get.t('Procurement.OrdersPage.ReorderSuccessMessage'))
              handleLoadData({
                settings: getSingleSettingsState(preordersItemsSettingsAtom),
                isNewLazyLoading: true,
              })
              hidePopup()
              redirectFn('/new-order')
            })
            .catch(defaultError)
            .finally(() => setLoading(false))
        },
        text: i18n_get.t('Common.Yes'),
        loading,
      },
      cancel: {
        onClick: hidePopup,
        text: i18n_get.t('Common.No'),
      },
    },
  })

  return (
    <Button color='red' onClick={onClick}>
      {i18n_get.t('PreOrders.Reorder')}
    </Button>
  )
}
