export const TABLE_ID = {
  NEW_ORDER: 'NewOrderTable',
  CREATE_ORDER: 'CreateOrderTable',
  PREORDERS_BY_ORDERS: 'PreordersTableByOrders',
  PREORDERS_BY_ITEMS: 'PreordersTableByItems',
  INVOICES: 'Invoices',
  TO_WAREHOUSE_ITEMS: 'ToProviderItemsTable',
  TO_WAREHOUSE_ORDERS: 'ToProviderOrdersTable',
  TO_WAREHOUSE_REGISTER_ITEMS: 'TO_WAREHOUSE_REGISTER_ITEMS_TABLE',
  TO_WAREHOUSE_HUB_ITEMS: 'ToHubItemsTable',
  TO_WAREHOUSE_HUB_PACKAGES: 'ToHubPackagesTable',
  IN_WAREHOUSE: 'InWarehouse',
  READY_TO_SHIPS_PACKAGES: 'ReadyToShipsPackagesTableId',
  SHIPPED_PACKAGES: 'ShippedPackagesTableId',
  ARCHIVE_PACKAGES: 'ArchivePackagesTableId',
  READY_TO_SHIPS_ITEMS: 'ReadyToShipItemsTable',
  SHIPPED_ITEMS: 'ShippedItemsTableId',
  ARCHIVE_ITEMS: 'ArchiveItemsTableId',
  CREATE_PACKAGE: 'CreatePackage',
  EDIT_PACKAGE: 'EditPackage',
}
