import { defaultError } from '@app/utils/errors'
import { useAction } from '@reatom/react'
import toast from 'react-hot-toast'
import { getApi } from 'src/api'
import { useRedirectFn } from 'src/hooks/route-hooks'
import { useAloneAction } from 'src/hooks/use-alone-action'
import { useAtomState } from 'src/hooks/use-atom-state'
import { createOrderPageAtoms } from 'src/views/procurement/create-order/model/create-order-page-atoms'
import { createOrderPageSaveData } from 'src/views/procurement/create-order/model/external-actions/save-data/save-data'
import { createOrderPageItemsAtom } from 'src/views/procurement/create-order/model/items'
import { orderIdAtom } from 'src/views/procurement/create-order/model/order-id.atom'

export function useHandlers(tswNameId: string | null) {
  const pageType = useAtomState(createOrderPageAtoms.staticData).pageType
  const handleSaveData = useAloneAction(createOrderPageSaveData)
  const redirectTo = useRedirectFn('/procurement')
  const handleUpdateField = useAction(createOrderPageItemsAtom.update)
  const items = useAtomState(createOrderPageItemsAtom)
  const orderId = useAtomState(orderIdAtom)

  return {
    onCancel() {
      toast.dismiss()
      redirectTo(pageType === 'create' ? '/new-order' : '/pre-orders')
    },
    onSave() {
      items.forEach(elem => handleUpdateField(elem.id, 'tswName', tswNameId))

      handleSaveData()
    },
    onGeneratePO(setLoadingGeneratePO: (val: boolean) => void) {
      setLoadingGeneratePO(true)

      if (orderId) {
        getApi().procurement.generatePODoc(orderId)
          .then(res => {
            window.open(res, '_blank')
          })
          .catch(defaultError)
          .finally(() => setLoadingGeneratePO(false))
      }
    },
  }
}
