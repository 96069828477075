import {primitiveAtom} from '@app/utils/reatom'
import {createSettingsAtom} from '../../../../components/table/utils/settings-atom'
import { TABLE_ID } from 'src/components/table/utils/table-id'

export const preordersItemsSettingsAtom = createSettingsAtom({
  tableId: TABLE_ID.PREORDERS_BY_ITEMS,
  isLazyLoading: true,
})
export const preordersItemsTablePagesAtom = primitiveAtom(1)

export const preordersOrdersSettingsAtom = createSettingsAtom({
  tableId: TABLE_ID.PREORDERS_BY_ORDERS,
})

export const invoicesTableSettingsAtom = createSettingsAtom({
  tableId: TABLE_ID.INVOICES,
})
