import {format} from 'date-fns'
import { API_URL } from 'src/config/environment'
import {STRING_DATE_FORMAT} from '../../config/constants'
import {WarehouseEditItemPopupData} from '../../views/common/edit-item-popup/provider'
import apiCreator from '../api-creator'
import {Api_Item} from '../common/item-model'
import {toFixedNum} from '@appscience/utils'

interface SaveItemData extends Api_Item {
  id: string
  brand: string
  original_name: string
  catalogue_id: string
  provider_comment: string
  actually_delivered: number
  provider_hazmat: string
  provider_temp: string
  provider_export_statuses: Array<string>
  provider_lot: string
  provider_exp: string
  provider_locations: Array<string>
}

interface SaveItemRequestData {
  items: Array<SaveItemData>
}

export function editWarehouseItems(
  items: Array<WarehouseEditItemPopupData>,
  initItems: Record<string, Api_Item>,
): Promise<void> {
  const data: SaveItemRequestData = {
    items: items.map(item => remapToSaveItemData(item, initItems)),
  }
  return apiCreator.post(`${API_URL.TWIN}/save-items`, data)
}

function remapToSaveItemData(
  data: WarehouseEditItemPopupData,
  initItems: Record<string, Api_Item>,
): SaveItemData {
  return {
    ...initItems[data.id],
    id: data.id,
    extra_charge_percent: toFixedNum(data.extraChargePercent, 2),
    additional_costs: toFixedNum(data.additionalCosts, 2),
    brand: data.brand,
    catalogue_id: data.catalogueId,
    provider_comment: data.exportComment,
    original_name: data.originalName,
    extra_charge_amount: data.extraChargeAmount,
    partner_invoice: data.partnerInvoice,
    partners_interest: data.partnerInterest,
    name: data.invoiceName,
    provider_temp: data.temperature,
    provider_hazmat: data.hazmatComment,
    country: data.country,
    store: data.supposedStore,
    actually_delivered: data.actuallyDelivered,
    procurement_dl_comments: data.procurementDlComment,
    provider_export_statuses: data.exportStatuses,
    box_numbers: data.boxNumber,
    provider_lot: data.lot,
    provider_locations: data.locations,
    provider_exp: data.expiredDate
      ? format(data.expiredDate, STRING_DATE_FORMAT)
      : '',
    provider_tag_to_hub: data.tagToHub,
    provider_tag_to_transit: data.tagToTransit,
    provider_tag_to_ru: data.tagToRu,
    country_of_origin: data.countryOfOrigin,
    hsn_codes: data.hsnCodes,
  }
}
