import { Button } from '@mantine/core'
import React, { useState } from 'react'
import { userAtom } from '../../../../../../atoms/user-atom'
import { useAtomState } from '../../../../../../hooks/use-atom-state'
import { i18n_get } from '../../../../../../lng/i18n'
import { createOrderPageAtoms } from '../../../model/create-order-page-atoms'
import { createOrderPageFieldsAtom } from '../../../model/order-fields.atom'
import { ON_MODERATION_ORDER_STATUS } from 'src/types/order-status'
import { poNumberLoadingAtom } from '../../../model/po-number-loading.atom'
import { useNoChangesChecker } from './hooks/use-no-changes-checker'
import { useHandlers } from './hooks/use-handlers'

export function CreateOrderPageLayoutDefaultActions() {
  const state = useAtomState(createOrderPageAtoms.state)
  const noChanges = useNoChangesChecker()
  const loadingPONumber = useAtomState(poNumberLoadingAtom)
  const { role } = useAtomState(userAtom)
  const pageType = useAtomState(createOrderPageAtoms.staticData).pageType
  const { providerId, numberPO, tswNameId } = useAtomState(createOrderPageFieldsAtom)
  const { onCancel, onSave, onGeneratePO } = useHandlers(tswNameId)
  const [loadingGeneratePO, setLoadingGeneratePO] = useState(false)
  const orderStatusId = useAtomState(createOrderPageFieldsAtom).orderStatusId

  const isOnModeration = ON_MODERATION_ORDER_STATUS === orderStatusId

  if (state === 'loading') {
    return null
  }

  return (
    <div className='flex items-center mr-2 space-x-2'>
      {pageType === 'edit' && ['default', 'head-of-procurement'].includes(role) && (
        <Button
          onClick={() => onGeneratePO(setLoadingGeneratePO)}
          loading={loadingGeneratePO}
          disabled={!providerId || !numberPO}
        >
          {i18n_get.t('Common.GeneratePO')}
        </Button>
      )}
      <Button
        onClick={onCancel}
        disabled={state === 'saving'}
      >
        {i18n_get.t('Common.Cancel')}
      </Button>
      <Button
        onClick={onSave}
        loading={state === 'saving'}
        disabled={noChanges || loadingPONumber || isOnModeration || state === 'saving'}
      >
        {i18n_get.t('Common.Save')}
      </Button>
    </div>
  )
}
