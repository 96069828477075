import { useAtom } from '@reatom/react'
import React from 'react'
import { Button, useMantineTheme } from '@mantine/core'
import { getSingleSettingsState } from '../../../../../components/table/utils/settings-atom'
import { useAloneAction } from '../../../../../hooks/use-alone-action'
import { useAtomState } from '../../../../../hooks/use-atom-state'
import { i18n_get } from '../../../../../lng/i18n'
import { useModerateOrderHandlers } from '../../../common/moderator/use-moderate-order-handlers'
import { loadPreordersData } from '../../../pre-orders-view/model/load-data'
import { preordersItemsSettingsAtom } from '../../../pre-orders-view/model/settings'
import { createOrderPageAtoms } from '../../model/create-order-page-atoms'
import { useRedirectFn } from 'src/hooks/route-hooks'
import { userAtom } from 'src/atoms/user-atom'
import { isFC } from 'src/views/iam/common/utils'

export function CreateOrderPageLayoutModeratorActions() {
  const [state] = useAtom(createOrderPageAtoms.state)
  const handleLoadData = useAloneAction(loadPreordersData)
  const orderId = useAtomState(createOrderPageAtoms.staticData).orderId
  const redirectTo = useRedirectFn()
  const { role } = useAtomState(userAtom)
  const { colors } = useMantineTheme()

  const { loading, onReject, onApprove } = useModerateOrderHandlers({
    onSuccessApprove: () => {
      handleLoadData({
        settings: getSingleSettingsState(preordersItemsSettingsAtom),
        isNewLazyLoading: true,
      })
      if (!isFC(role)) {
        redirectTo('/procurement/pre-orders')
      }
    },
    onSuccessReject: () => {
      handleLoadData({
        settings: getSingleSettingsState(preordersItemsSettingsAtom),
        isNewLazyLoading: true,
      })
      if (!isFC(role)) {
        redirectTo('/procurement/pre-orders')
      }
    },
    ids: [orderId as string],
  })

  if (state === 'loading' || orderId === null) {
    return null
  }

  return (
    <>
      <Button
        style={{
          backgroundColor: colors.green[8],
        }}
        onClick={onApprove}
        disabled={loading === 'approve'}
      >
        {i18n_get.t('Common.Approve')}
      </Button>
      <Button
        style={{
          backgroundColor: colors.red[8],
        }}
        onClick={onReject}
        disabled={loading === 'reject'}
        className='mx-2'
      >
        {i18n_get.t('Common.Reject')}
      </Button>
    </>
  )
}