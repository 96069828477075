import { nullableSum, presiceNumber } from '@appscience/utils'
import { IndexedCreateOrderPageItem, ItemTaxInfo } from './items.atom.type'

/** @description Грязная функция для обновления состояния */
export function updateTaxValuesWithManually(
  items: Array<IndexedCreateOrderPageItem>,
  taxPercent: number,
) {
  items.forEach(x => {
    if (isManuallyTax(x.tax)) {
      return
    }
    const cost = x.otherCost.acceptForTax
      ? nullableSum(x.cost, x.otherCost.value)
      : x.cost

    x.tax = cost ? { value: presiceNumber((cost * taxPercent) / 100, 2) } : null
  })
}

export function isManuallyTax(info: ItemTaxInfo | null) {
  return !!info?.manually
}
