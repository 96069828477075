import apiCreator from 'src/api/api-creator'
import { API_URL } from 'src/config/environment'
import { PermissionGroupWithId } from './model'

export interface GetGroupsResponse {
  groups: Array<PermissionGroupWithId>;
}

export async function getGroups(): Promise<GetGroupsResponse> {
  return apiCreator
    .get<GetGroupsResponse>(
      `${API_URL.AUTH}/list-user-groups`,
    )
    .then(({ data }) => data)
}
