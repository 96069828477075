import { convertScifinderPDFFiles } from './convert-scifinder-pdf-files'
import { getPresignedS3UploadLink } from './get-presigned-s3-upload-link'
import { uploadFileToS3 } from './upload-file-to-s3'
import { uploadScifinderExcelFiles } from './upload-scifinder-excel-files'
import { getComplianceBaseDownloadLink } from './get-compliance-base-download-url'

export const scifinderApi = {
  convertScifinderPDFFiles,
  getPresignedS3UploadLink,
  uploadFileToS3,
  uploadScifinderExcelFiles,
  getComplianceBaseDownloadLink,
}
