import {defaultError} from '@app/utils/errors'
import {successMessage} from '@app/utils/feedback'
import {Button} from '@mantine/core'
import {useState} from 'react'
import {getApi} from '../../../../../api'
import {pageLoadingAtom} from '../../../../../atoms/page-loading-atom'
import {useAloneAction} from '../../../../../hooks/use-alone-action'
import {useAtomState} from '../../../../../hooks/use-atom-state'
import {i18n_get} from '../../../../../lng/i18n'
import {
  readyToShipItemsTableSelectedIds,
  readyToShipPackagesTableSelectedId,
} from '../../ready-to-ship/model/atoms'
import {shippedPackagesTableSelectedId} from '../../shipped/model/atoms'
import {fromWarehouseStatusTabAtom, fromWarehouseTabAtom} from '../atoms'
import {loadFromWarehouseData} from '../load-data'
import {ReadyToShipsItemsLayoutActions} from './ready-to-ships-items'
import {ReadyToShipsPackagesLayoutActions} from './ready-to-ships-packages'

export function FromWarehouseLayoutActions() {
  const loadingState = useAtomState(pageLoadingAtom)
  const status = useAtomState(fromWarehouseStatusTabAtom)

  function getContent(): JSX.Element | null {
    if (loadingState !== 'normal') {
      return null
    }

    switch (status) {
      case 'readyToShip':
        return <ReadyToShipActions />
      case 'shipped':
        return <ShippedActions />
      case 'archive':
      default:
        return null
    }
  }

  return <div className='mr-3'>{getContent()}</div>
}

function ReadyToShipActions() {
  const tab = useAtomState(fromWarehouseTabAtom)
  const selectedPackageId = Object.keys(useAtomState(readyToShipPackagesTableSelectedId))
  const selectedItemIds = Object.keys(useAtomState(readyToShipItemsTableSelectedIds))

  return tab === 'packages' && selectedPackageId.length ? (
    <ReadyToShipsPackagesLayoutActions packageId={selectedPackageId[0]} />
  ) : tab === 'items' && selectedItemIds.length > 0 ? (
    <ReadyToShipsItemsLayoutActions selectedIds={selectedItemIds} />
  ) : null
}

function ShippedActions() {
  const handleLoadData = useAloneAction(loadFromWarehouseData)
  const [loading, setLoading] = useState(false)
  const tab = useAtomState(fromWarehouseTabAtom)
  const selectedPackageId = useAtomState(shippedPackagesTableSelectedId)

  const onClick = (packageId: string) => {
    setLoading(true)
    getApi()
      .provider.fromWarehouse.returnToShipments({packageId})
      .then(() => {
        successMessage(
          i18n_get.t('Provider.FromWarehouse.ReturnToShipments.SuccessMessage'),
        )
        return handleLoadData({
          status: 'shipped',
          tab: 'packages',
          backgroundMode: true,
        })
      })
      .catch(defaultError)
      .finally(() => setLoading(false))
  }

  return tab === 'packages' && Object.keys(selectedPackageId).length ? (
    <Button loading={loading} onClick={() => onClick(Object.keys(selectedPackageId)[0])}>
      {i18n_get.t('Provider.FromWarehouse.ReturnToShipments')}
    </Button>
  ) : null
}
