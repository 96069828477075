import apiCreator from 'src/api/api-creator'
import { API_URL } from 'src/config/environment'
import { PermissionGroupInterface } from './model'

export type GetInterfacesResponse = {
  interfaces: Array<PermissionGroupInterface>;
}

export async function getInterfaces(): Promise<GetInterfacesResponse> {
  return apiCreator
    .get<GetInterfacesResponse>(
      `${API_URL.AUTH}/list-interfaces`,
    )
    .then(({ data }) => data)
}
