import { AxiosResponse } from 'axios'
import apiCreator from '../../api-creator'
import { PermissionGroup, PermissionGroupWithId } from './model'
import { API_URL } from 'src/config/environment'

export type AddGroupRequest = PermissionGroup
export type AddGroupResponseData = PermissionGroupWithId
export type AddGroupResponse = AxiosResponse<AddGroupResponseData>

export async function addGroup(group: PermissionGroup): Promise<AddGroupResponseData> {
  return apiCreator
    .post<AddGroupResponseData, AddGroupResponse, AddGroupRequest>(
      `${API_URL.AUTH}/save-user-group`,
      group,
    )
    .then(({ data }) => data)
}
