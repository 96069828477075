import {createAtom} from '@reatom/core'
import {createPrimitiveAtom} from '@reatom/core/primitives'
import {Either, left, right} from '@sweet-monads/either'

interface State {
  value: Date | null
  hasError: boolean
}

function getInitState(): State {
  return {value: new Date(), hasError: false}
}

export const registerDateAtom = createAtom(
  {
    reset: () => {},
    set: (value: Date | null) => value,
  },
  ({onAction, schedule}, state: State = getInitState()) => {
    onAction('reset', () => (state = getInitState()))
    onAction('set', value => {
      registerPageValidateRegisterDate(value)
        .mapRight(v => {
          state = {value: v, hasError: false}
        })
        .mapLeft(() => {
          state = {value: null, hasError: true}
        })
    })
    return state
  },
)

export const arrivalDateAtom = createPrimitiveAtom<Date | null>(new Date())

export function registerPageValidateRegisterDate(
  value: Date | null,
): Either<TypedError<'register'>, Date> {
  return value === null ? left({type: 'register'}) : right(value)
}
