import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'

export interface ICancelReason {
  value: string,
  label: string
}

interface ICancelReasonRequest {
  id: string,
  name: string
}

interface Response {
  remappedItems: Array<ICancelReason>
  rawItems: Array<ICancelReasonRequest>
}

export async function getCancelReasons(): Promise<Response> {
  const res = await apiCreator.get(`${API_URL.TWIN}/get-reorder-reasons`)
  const rawItems: Array<ICancelReasonRequest> = res.data.reasons

  return {
    remappedItems: rawItems.map(remapToCancelReasonData),
    rawItems,
  }
}

export function remapToCancelReasonData(
  data: ICancelReasonRequest,
): ICancelReason {
  return {
    value: data.id,
    label: data.name,
  }
}