import { createPrimitiveAtom } from '@reatom/core/primitives'

export interface OrderSumLimitData {
  headOfProcurementLimit: number,
  headOfFinanceLimit: number,
  currency: string,
}

export const orderSumLimitAtom = createPrimitiveAtom<OrderSumLimitData>({
  headOfProcurementLimit: 7000,
  headOfFinanceLimit: 30000,
  currency: 'USD',
})
