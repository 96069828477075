import apiCreator from 'src/api/api-creator'
import {API_URL} from 'src/config/environment'

export interface PaymentPriorityData {
    id: string
    name: string
    color: string
}

export const getPaymentPriorities = (): Promise<PaymentPriorityData[]> =>
  apiCreator
    .get(`${API_URL.TWIN}/payment-priorities`)
    .then(({data}) => data.payment_priorities)
