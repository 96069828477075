import {indexByWithCb} from '@appscience/utils'
import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'
import {handleErrorWithDefaultValue} from '../common/utils'

interface ProviderLocationsResponseData {
  name: string
  color: string
}

export function getProviderLocations(): Promise<
  Record<string, ProviderLocationsResponseData>
> {
  return apiCreator
    .get(`${API_URL.TWIN}/provider-locations`)
    .then(res => {
      const data: Array<ProviderLocationsResponseData> = res.data.locations
      const record = indexByWithCb(
        data,
        x => x.name,
        x => x,
      )
      return record
    })
    .catch(handleErrorWithDefaultValue({}))
}
