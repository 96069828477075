import { FinanceControl } from '../../atoms/static-data/payments-method-atom'
import { UserRole } from '../../atoms/user-atom'
import { API_URL } from 'src/config/environment'
import { isFC } from '../../views/iam/common/utils'
import { PreordersOrder } from '../../views/procurement/pre-orders-view/model/main'
import apiCreator from '../api-creator'
import { Api_Order } from '../common/order-model'
import { handleErrorWithDefaultValue } from '../common/utils'
import { PaymentStatusEnum } from 'src/types/payment-status'
import { parseDate } from '@app/utils/date'

export function getProcurementPreordersOrders(role: UserRole): Promise<
  Array<PreordersOrder>
> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/orders`,
      {
        params: {
          ...(isFC(role) && { step: 'finance-control' }),
        },
      },
    )
    .then(res => {
      const rawData: Array<Api_Order> = res.data.orders
      return rawData.map(remapToPreordersOrder)
    })
    .catch(handleErrorWithDefaultValue([]))
}

const mapForModerationFinanceControl: { [key: string]: FinanceControl } = {
  'approve': 'True',
  'reject': 'False',
  'on_moderation': 'In progress',
}

export function remapToPreordersOrder(data: Api_Order): PreordersOrder {
  return {
    id: data.id,
    orderDate: parseDate(data.ordered_date),
    statusId: data.status,
    invoiceNumber: data.invoice_number,
    invoiceNumbers: data.invoice_numbers,
    paymentPriority: data.payment_priority,
    poNumber: data.po_number,
    additionalCosts: data.additional_cost_sum,
    orderNumber: data.store_order_number,
    transferredForPaymentDate: parseDate(data.proc_trans_for_payment_date),
    paymentMethodId: data.payment_method || null,
    orderSumUSD: data.sum_cost_usd,
    procurement: data.responsible_procurement,
    currencyId: data.currency || '',
    store: data.store,
    arrived: parseDate(data.arrived),
    provider: data.provider || null,
    sumPrice: data.sum_cost,
    deadlineDate: parseDate(data.deadline),
    itemsQuantity: data.full_items_quantity,
    headOfProcurementStatus: data.moderation_head_procurement,
    headOfFinanceStatus: data.moderation_head_finance,
    card: data.card,
    financeControlStatus: data.moderation_finance_control,
    moderationComment: data.moderation_comment,
    tswName: data.tsw_name || '',
    paymentStatus: data.payment_status as PaymentStatusEnum,
    financeControl: mapForModerationFinanceControl[data.moderation_finance_control],
    orderComment: data.order_comment,
  }
}
