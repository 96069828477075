import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'

type GetLimitsResponse = {
  procurement_limits: Array<{
    provider: string;
    store: string;
    limit_week: number;
    before_limit_week: number;
    limit_month: number;
    before_limit_month: number;
    days_week: string;
    days_month: string;
  }>
}

export async function getLimits(
  provider: string,
  store: string,
): Promise<GetLimitsResponse> {
  return apiCreator
    .get<GetLimitsResponse>(
      `${API_URL.TWIN}/procurement-limits`,
      {
        params: {
          provider,
          store,
        },
      },
    )
    .then(({ data }) => data)
}
