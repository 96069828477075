import { addUser } from './add-user'
import { getUsers } from './get-users'
import { getUserById } from './get-user-by-id'
import { deleteUserById } from './delete-user-by-id'
import { updateUser } from './update-user'
import { getJobTitles } from './get-job-titles'
import { getCurrentUser } from './get-current-user'

export const usersApi = {
  addUser: addUser,
  getUserById: getUserById,
  getUsers: getUsers,
  deleteUserById: deleteUserById,
  updateUser: updateUser,
  getJobTitles: getJobTitles,
  getCurrentUser: getCurrentUser,
}
