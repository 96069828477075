import {createAtom} from '@reatom/core'
import {createLSDecorator} from '@app/utils/reatom'
import {inWarehouseRedefinedColumnsVisibilityAtom} from './atoms'

export type InWarehouseChip = 'send' | 'stock'

export const inWarehouseChipsAtom = createAtom(
  {
    set: (ids: Array<InWarehouseChip>) => ids,
  },
  ({onAction, schedule}, state: Array<InWarehouseChip> = ['send']) => {
    onAction('set', ids => {
      state = ids

      const sendEnabled = ids.includes('send')
      schedule(dispatch => {
        dispatch(
          inWarehouseRedefinedColumnsVisibilityAtom.set({
            deadlineDate: sendEnabled,
          }),
        )
      })
    })
    return state
  },
  {decorators: [createLSDecorator('InWarehouse.Chips')]},
)
