import apiCreator from 'src/api/api-creator'
import { API_URL } from 'src/config/environment'
import { AxiosResponse } from 'axios'

export type ConvertRequest = FormData
export type ConvertScifinderPDFFilesResponseData = {
  link: string;
}
export type ConvertScifinderPDFFilesResponse = AxiosResponse<ConvertScifinderPDFFilesResponseData>

export async function convertScifinderPDFFiles(
  pdfFiles: Array<File>,
): Promise<ConvertScifinderPDFFilesResponseData> {
  const FILES_FORM_KEY = 'files'
  const formData = pdfFiles
    .reduce((result, file) => {
      result.append(FILES_FORM_KEY, file)
      return result
    }, new FormData())

  return apiCreator
    .post<
      ConvertScifinderPDFFilesResponseData,
      ConvertScifinderPDFFilesResponse,
      FormData
    >(
      `${API_URL.SCIFINDER}/convert`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    .then(({ data }) => data)
}
