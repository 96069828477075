import {isNullable} from '@appscience/utils'
import {format} from 'date-fns'
import isDate from 'lodash/isDate'
import {ServerTableSettings} from '../../components/table/utils/settings-atom'
import {adaptItemColumns} from '../utils/columns-adapter'
import {CompareType, getDefaultCompareTypeByColumn} from '../utils/compare-type'
import {EMPTY_STORE_ID} from './filters/store-filter'
import {Api_Item} from './item-model'
import { STRING_DATE_FORMAT } from 'src/config/constants'

interface PaginationData {
  page: number
  page_size: number
  start_key: object
}

export interface SSTResponse {
  items: Array<Api_Item>;
  pagination: {
    items: number;
    page: number;
    start_key: object;
  },
}

interface FilterData {
  field: string,
  compare_type: CompareType,
  values: Array<unknown>,
}

export interface SSTRequest {
  search_query: {query: string},
  pagination: PaginationData,
  sort: Array<{field: string, order: 'ASC'|'DESC'}>,
  filters: Array<FilterData>,
}

export function remapSettingsToSSTRequest(data: ServerTableSettings): SSTRequest {
  return {
    search_query: {query: data.searchState},
    pagination: {
      page: data.paginationState.pageIndex || 1,
      page_size: data.paginationState.pageSize || 25,
      start_key: data.startKey || {},
    },
    sort: data.sortState.map(x => ({
      field: adaptItemColumns(x.id),
      order: x.desc ? 'DESC' : 'ASC',
    })),
    filters: data.filtrationState.filter(x => !isNullable(x.value)).map(x => ({
      field: adaptItemColumns(x.id),
      compare_type: getDefaultCompareTypeByColumn(x.id),
      values: Array.isArray(x.value) ? x.value.map(mapFilterValue) : [mapFilterValue(x.value)],
    })),
  }
}

//Бекенд ждёт только строковые значения
function mapFilterValue(value: unknown): string {
  if (value === EMPTY_STORE_ID) {
    return ''
  }
  if (isDate(value)) {
    return format(value, STRING_DATE_FORMAT)
  }
  return typeof value === 'string' ? value : String(value)
}