// S3 url example
// https://appscience-twin-order-docs.s3.us-east-1.amazonaws.com/1C355_Aminoethane-2-thiols.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQV222VWJEC5GGRFZ%2F20230728%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230728T174747Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&x-id=PutObject&X-Amz-Signature=71b7cddc4a566662370a8fed2068321426a296af9d23275f9807d778b930a9d2

export function getFilenameFromS3Url(s3Url: string): string {
  const parts = new URL(s3Url).pathname.split('/')
  return parts[parts.length - 1]
}

export function getOrderFileUrl(s3Url: string | null, data: File | null) {
  if (s3Url) {
    const url = new URL(s3Url)
    return `${url.origin}${url.pathname}`
  }

  if (data) {
    return URL.createObjectURL(data)
  }

  return null
}
