import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'

interface GeneratePODocResponse {
  doc_url: string,
}

export function generatePODoc(orderId: string): Promise<string> {
  return apiCreator
    .get<GeneratePODocResponse>(
      `${API_URL.TWIN}/order/generate-po-doc?id=${orderId}`,
    )
    .then(res => res.data.doc_url)
}