import { verify } from '@appscience/utils'
import { format } from 'date-fns'
import { IndexedCreateOrderPageItem } from '../../items/items.atom.type'
import { CreateOrderRequestItemData } from 'src/api/procurement/save-order'
import { STRING_DATE_FORMAT } from 'src/config/constants'

export function getVerifiedMapItemFields(
  item: IndexedCreateOrderPageItem,
  validationEnabled: boolean,
): CreateOrderRequestItemData {
  const invoiceInfo = {
    additional_costs: item.additionalCosts,
    registry_comment: item.registryComment,
    invoice_check: item.invoiceCheck,
    bank_account: item.bankAccount,
    supply_paid_date: item.paidDate == null
      ? ''
      : format(item.paidDate, STRING_DATE_FORMAT),
    payment_deadline: item.paymentDeadline == null
      ? ''
      : format(item.paymentDeadline, STRING_DATE_FORMAT),
    registry_number: item.registryNumber,
    invoice_date: item.invoiceDate == null
      ? ''
      : format(item.invoiceDate, STRING_DATE_FORMAT),
  }

  if (validationEnabled) {
    return {
      id: verify(item.id, getMessage('id')),
      brand: verify(item.brand, getMessage('brand')),
      catalogue_id: verify(item.catalogue, getMessage('catalogue')),
      original_name: verify(item.originalName, getMessage('originalName')),
      esd_fixed: item.esdFixed,
      name: item.invoiceName,
      esd_fix_original_date: verify(item.esdFixOriginal, getMessage('esdFixOriginal'))
        ? format(item.esdFixOriginal || 0, STRING_DATE_FORMAT)
        : null,
      cost: item.isFree ? 0 : verify(item.cost, getMessage('cost')),
      tax: item.isFree ? 0 : item.tax?.value,
      quantity: verify(item.quantity, getMessage('quantity')),
      other_cost: item.isFree
        ? 0
        : item.otherCost.value == null
          ? 0
          : item.otherCost.value,
      esd_fix_date: verify(item.esdFix, getMessage('esdFix'))
        ? format(item.esdFix || 0, STRING_DATE_FORMAT)
        : undefined,
      esd_date: format(
        verify(item.shipmentTimestamp, getMessage('shipmentTimestamp')),
        STRING_DATE_FORMAT,
      ),
      procurement_comment: item.procurementComment,
      sample: item.sample,
      next_check:
        item.nextCheckTimestamp == null
          ? null
          : format(item.nextCheckTimestamp, STRING_DATE_FORMAT),
      invoice_price: item.isFree
        ? verify(item.invoicePrice, getMessage('invoicePrice'))
        : undefined,
      tsw_name: item.tswName,
      discount_absolute: item.discountAmount,
      discount_percent: item.discountPercent,
      discount_comment: item.discountComment,
      vendor_price: item.vendorPrice,
      payment_type: item.paymentType,
      terms_of_payment: item.termsOfPayment,
      finance_control_comment: item.financeControlComment,
      invoice_number: item.invoiceNumber,
      is_kit: item.kit,
      ...invoiceInfo,
      hs_code_china: item.hsCodeChina,
      order_comment: item.orderComment,
      eod: item.estimatedOrderTimestamp == null
        ? null
        : format(item.estimatedOrderTimestamp, STRING_DATE_FORMAT),
      order_details: item.orderDetails,
      procurement_dl_reason: item.procurementDlReason,
      procurement_dl_comments: item.procurementDlComments,
      prt_channel_change_reason: item.prtChannelChangeReason,
      is_shipped_in_advance: item.isShippedInAdvance,
    }
  }

  return {
    id: item.id,
    payment_type: item.paymentType,
    brand: item.brand,
    catalogue_id: item.catalogue,
    vendor_price: item.vendorPrice,
    original_name: item.originalName,
    name: item.invoiceName,
    esd_fix_original_date: item.esdFixOriginal === null
      ? null
      : format(item.esdFixOriginal, STRING_DATE_FORMAT),
    cost: item.isFree ? 0 : item.cost || 0,
    tax: item.isFree ? 0 : item.tax?.value,
    is_kit: item.kit,
    quantity: item.quantity,
    sample: item.sample,
    discount_absolute: item.discountAmount,
    discount_percent: item.discountPercent,
    discount_comment: item.discountComment,
    other_cost: item.isFree
      ? 0
      : item.otherCost.value == null
        ? 0
        : item.otherCost.value,
    esd_date:
      item.shipmentTimestamp == null
        ? null
        : format(item.shipmentTimestamp, STRING_DATE_FORMAT),
    esd_fix_date: item.esdFix == null
      ? undefined
      : format(verify(item.esdFix, getMessage('esdFix')), STRING_DATE_FORMAT),
    esd_fixed: item.esdFixed,
    next_check:
      item.nextCheckTimestamp == null
        ? null
        : format(item.nextCheckTimestamp, STRING_DATE_FORMAT),
    invoice_price: item.isFree
      ? item.invoicePrice == null
        ? undefined
        : item.invoicePrice
      : undefined,
    procurement_comment: item.procurementComment,
    tsw_name: item.tswName,
    ...invoiceInfo,
    terms_of_payment: item.termsOfPayment,
    finance_control_comment: item.financeControlComment,
    invoice_number: item.invoiceNumber,
    hs_code_china: item.hsCodeChina,
    order_comment: item.orderComment,
    eod: item.estimatedOrderTimestamp == null
      ? null
      : format(item.estimatedOrderTimestamp, STRING_DATE_FORMAT),
    order_details: item.orderDetails,
    procurement_dl_reason: item.procurementDlReason,
    procurement_dl_comments: item.procurementDlComments,
    prt_channel_change_reason: item.prtChannelChangeReason,
    is_shipped_in_advance: item.isShippedInAdvance,
  }
}

function getMessage(field: keyof IndexedCreateOrderPageItem) {
  return `CreateOrderPage item fields verification failed. Field: ${field}`
}
