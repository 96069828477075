import { UserRole } from 'src/atoms/user-atom'

const EMAIL_REGEXP = /^\S+@\S+$/

export function isValidEmail(value: string): boolean {
  return EMAIL_REGEXP.test(value)
}

export function isValidGmail(value: string): boolean {
  return value === '-' || isValidEmail(value)
}

export const isFC = (role: UserRole): boolean => 
  role === 'finance-control' || role === 'head-of-finance'
