import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid'
import {Dialog} from '@mantine/core'
import {useEffect, useState} from 'react'
import {useOnlineStatus} from '../hooks/use-online-status'
import {i18n_get} from '../lng/i18n'

export function ConnectionLostToast() {
  const isOnline = useOnlineStatus()
  const [opened, setOpened] = useState(false)

  useEffect(() => {
    !isOnline && setOpened(true)
  }, [isOnline])

  const iconClass = 'w-6 h-6 mr-1.5'

  return (
    <Dialog
      opened={opened}
      withCloseButton={isOnline}
      onClose={() => setOpened(false)}
      size='lg'
      radius='md'
      className='flex items-center font-medium'
      styles={{closeButton: {top: 13}}}
      position={{left: 16, bottom: 16}}
      transition='skew-up'
    >
      {isOnline ? (
        <CheckCircleIcon className={iconClass} color='green' />
      ) : (
        <ExclamationTriangleIcon className={iconClass} color='red' />
      )}
      {isOnline
        ? i18n_get.t('Common.ConnectionRestored')
        : i18n_get.t('Common.ConnectionLost')}
    </Dialog>
  )
}
