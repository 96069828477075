import {MantineThemeComponents} from '@mantine/styles/lib/theme/types/MantineTheme'
import {ValueOfRecord} from 'ramda'
import {getCommonInputStyles} from './common'

export function getDatePickerStylesConfig(): ValueOfRecord<MantineThemeComponents> {
  return {
    defaultProps: {
      withinPortal: true,
    },
    styles: { input: getCommonInputStyles() },
  }
}
