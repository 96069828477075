import { isDef } from '@appscience/utils'
import { API_URL } from 'src/config/environment'
import { CreateOrderPageItem } from 'src/views/procurement/create-order/model/items/items.atom.type'
import apiCreator from '../api-creator'
import { Api_Item } from '../common/item-model'
import { Api_Order } from '../common/order-model'
import { getProcurementItemInvoicePrice } from '../common/utils'
import { parseDate, parseDateToTimestamp } from '@app/utils/date'
import { CreateOrderPageLoadData } from 'src/views/procurement/create-order/model/external-actions/load-data-impl.type'
import { CreateOrderPageOrderFields } from 'src/views/procurement/create-order/model/create-order-page-order-fields.type'

interface GetOrderResponseData {
  order: Api_Order
  items: Array<Api_Item>
}

export function getProcurementOrderById(
  id: string,
): Promise<CreateOrderPageLoadData> {
  return apiCreator
    .get<GetOrderResponseData>(`${API_URL.TWIN}/get-order?id=${id}`)
    .then(({ data }) => ({
      orderData: {
        id: data.order.id,
        fields: remapToCreateOrderPageOrderFields(data.order),
      },
      items: data.items.map(remapToCreateOrderPageItem),
    }))
}

export function remapToCreateOrderPageOrderFields(
  data: Api_Order,
): CreateOrderPageOrderFields {
  return {
    prepaymentAmount: data.prepayment_amount,
    storeId: data.store,
    isModerationPassed: data.is_moderation_passed,
    orderStatusId: data.status,
    transferredForPaymentDate: parseDate(data.proc_trans_for_payment_date),
    orderTimestamp: parseDateToTimestamp(data.ordered_date),
    paymentTimestamp: parseDateToTimestamp(data.paid_date),
    confirmationTimestamp: parseDateToTimestamp(data.confirmed_date),
    countryId: data.country,
    providerId: data.provider,
    paymentPriority: data.payment_priority,
    paymentPercentage: data.payment_percentage,
    paymentDetails: data.payment_details,
    paymentType: data.payment_type,
    currencyId: data.currency,
    sumCost: data.sum_cost,
    paymentMethodInfo: data.payment_type, 
    headOfPrtApprove: data.moderation_head_procurement === 'approve',
    headOfFinanceApprove: data.moderation_head_finance === 'approve',
    tswNameId: data.tsw_name === '' ? null : data.tsw_name,
    paymentMethodId: data.payment_method || null,
    scd: data.responsible_scd,
    procurement: data.responsible_procurement,
    orderNumber: data.store_order_number,
    sumVendorPrice: data.sum_vendor_price,
    numberPO: data.po_number,
    tracking: data.tracking_number,
    managerContact: data.brand_contact,
    taxPercent: isDef(data.sum_tax) ? data.sum_tax : null,
    sumOtherCost: data.sum_other_cost_formula || '',
    card: data.card,
    moderatorComment: data.moderation_comment,
    hsCodeChina: data.hs_code_china,
    exportDocuments: data.export_documents,
    documentUrls: data.docs ?? [],
    orderComment: data.order_comment,
  }
}

export function remapToCreateOrderPageItem(
  data: Api_Item,
): CreateOrderPageItem {
  return {
    id: data.id,
    deadlineTimestamp: parseDateToTimestamp(data.deadline_date),
    brand: data.brand,
    catalogue: data.catalogue_id,
    esdFixed: data.esd_fixed || false,
    esdFixOriginal: parseDate(data.esd_fix_original_date),
    createdDate: parseDate(data.created_date),
    registryComment: data.registry_comment,
    invoiceCheck: data.invoice_check,
    discountAmount: data.discount_absolute,
    discountPercent: data.discount_percent,
    deadlineDate: parseDate(data.deadline_date),
    deadlineDate2: parseDate(data.deadline_date_2),
    exef: data.express,
    exefType: data.exef_type,
    clientType: data.client_type,
    paymentDetails: data.payment_details,
    paymentType: data.payment_type,
    discountComment: data.discount_comment,
    originalName: data.original_name,
    esdFix: parseDate(data.esd_fix_date || ''),
    additionalCosts: data.additional_costs,
    invoiceNumber: data.invoice_number,
    bankAccount: data.bank_account,
    cost: data.cost,
    initialCost: data.cost,
    sample: data.sample,
    tax: {
      value: data.tax,
    },
    otherCost: {
      value: data.other_cost,
    },
    quantity: data.quantity,
    invoiceName: data.name,
    shipmentTimestamp: parseDateToTimestamp(data.esd_date),
    clientPrice: data.final_price,
    amoId: data.deal_id,
    clientName: data.client_name,
    express: data.express || false,
    casNumber: data.cas_id,
    license: data.supply_mm_list,
    packing: data.package_size,
    url: data.item_url,
    nextCheckTimestamp: parseDateToTimestamp(data.next_check),
    isFree: !!data.invoice_price && !data.cost,
    invoicePrice: getProcurementItemInvoicePrice(data),
    initialInvoicePrice: getProcurementItemInvoicePrice(data),
    procurementComment: data.procurement_comment,
    providerComment: data.provider_comment,
    providerHazmatType: data.provider_hazmat_type,
    temperature: data.provider_temp,
    tswName: data.tsw_name,
    dgmStatus: data.dgm_status,
    deliveryMethod: data.method_of_delivery,
    isTender: data.is_tender,
    complianceTimestamp: parseDateToTimestamp(data.date_of_compliance),
    estimatedOrderTimestamp: parseDateToTimestamp(data.eod),
    costPrice: data.cost_price,
    inbound: data.inbound,
    supplyChain: data.supply_chain,
    outbound: data.outbound,
    quota: data.quota,
    kit: data.is_kit,
    country: data.country,
    invoiceDate: parseDate(data.invoice_date) || undefined,
    paidDate: parseDate(data.supply_paid_date) || undefined,
    paymentDeadline: parseDate(data.payment_deadline) || undefined,
    registryNumber: data.registry_number,
    termsOfPayment: data.terms_of_payment,
    store: data.store,
    vendorPrice: data.vendor_price,
    procurement: data.procurement,
    scd: data.responsible_scd,
    financeControlComment: data.finance_control_comment,
    orderStatus: data.order_status,
    exportDocuments: data.export_documents,
    hsCodeChina: data.hs_code_china,
    vendorRecommendation: data.vendor_recommend,
    orderComment: data.order_comment,
    orderDetails: data.order_details || '',
    procurementDlReason: data.procurement_dl_reason,
    procurementDlComments: data.procurement_dl_comments,
    clientPaymentReceived: data.is_payment_received || false,
    clientPaymentTerms: data.payment_condition || '',
    dlOrder: parseDate(data.dl_order),
    prtChannelChangeReason: data.prt_channel_change_reason || '',
    isShippedInAdvance: data.is_shipped_in_advance || false,
  }
}
