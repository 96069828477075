import React from 'react'
import { nullableSum } from '@appscience/utils'
import { CreateOrderPageItemFieldProps } from './common'
import { CreateOrderPageItem } from '../../../model/items/items.atom.type'
import { formatNumber } from '@app/utils/formatters/format-number'

export function getCreateOrderPopupItemFullCost(item: CreateOrderPageItem): number {
  return nullableSum(item.cost, item.tax?.value, item.otherCost?.value)
}

export function CurrencySumCostField({ item }: CreateOrderPageItemFieldProps) {
  const sumValue = getCreateOrderPopupItemFullCost(item).toFixed(2)

  return <div>{formatNumber(sumValue)}</div>
}
