import {format} from 'date-fns'
import { API_URL } from 'src/config/environment'
import {STRING_DATE_FORMAT} from '../../config/constants'
import {DuplicateItemPopupData} from '../../views/warehouse/to-warehouse/provider/table-by-items/popups/duplicate/model/props'
import apiCreator from '../api-creator'
import {Api_Item, Api_ResponsibleSales} from '../common/item-model'

interface DuplicateProviderItemRequestData extends Api_Item {
  id: string
  brand: string
  catalogue_id: string
  country: string
  deadline_date: string
  name: string
  deal_approved_date: string
  original_name: string
  procurement: string
  store: string
  save_as_new: true
  client_name: string
  responsible_sales: Api_ResponsibleSales | null
  responsible_scd: string
  deal_id: string
  quantity: number
  cost: number
  provider_hazmat: string
  provider_temp: string
}

export function duplicateProviderItem(
  formData: DuplicateItemPopupData,
  initRawItem: Api_Item,
): Promise<void> {
  return apiCreator
    .post(
      `${API_URL.TWIN}/save-item`,
      remapToDuplicateProviderItemRequestData(
        formData,
        initRawItem,
      ),
    )
}

export function remapToDuplicateProviderItemRequestData(
  data: DuplicateItemPopupData,
  initRawItem: Api_Item,
): DuplicateProviderItemRequestData {
  return {
    ...initRawItem,
    save_as_new: true,
    id: data.id,
    brand: data.brandId,
    catalogue_id: data.catalogueId,
    country: data.countryId,
    deadline_date: data.deadlineDate
      ? format(data.deadlineDate, STRING_DATE_FORMAT)
      : '',
    name: data.invoiceName,
    original_name: data.originalName,
    procurement: data.procurement,
    provider_hazmat: data.hazmatComment,
    store: data.storeId,
    deal_approved_date: data.dealDate
      ? format(data.dealDate, STRING_DATE_FORMAT)
      : '',
    client_name: data.client,
    responsible_scd: data.scd,
    deal_id: data.amoId,
    quantity: data.quantity,
    cost: data.sumCost,
    provider_temp: data.temperature, //TODO:responsible_sales
  }
}
