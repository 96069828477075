import apiCreator from 'src/api/api-creator'
import { API_URL } from 'src/config/environment'
import { UserPermissions } from './model'

export type GetUserPermissionsResponse = UserPermissions

export async function getUserPermissions(): Promise<GetUserPermissionsResponse> {
  return apiCreator
    .get<GetUserPermissionsResponse>(
      `${API_URL.AUTH}/user-permissions`,
    )
    .then(({ data }) => data)
}
