import {
  assert,
  createMultipleParam,
} from '@appscience/utils'
import { API_URL } from 'src/config/environment'
import { WarehouseEditItemPopupData } from '../../views/common/edit-item-popup/provider'
import apiCreator from '../api-creator'
import { Api_Item } from '../common/item-model'
import { parseDate, parseDateToTimestamp } from '@app/utils/date'

interface Response {
  remappedItems: Array<WarehouseEditItemPopupData>
  rawItems: Array<Api_Item>
}

export async function getEditItemPopupItems(
  ids: Array<string>,
): Promise<Response> {
  const res = await apiCreator.get(
    `${API_URL.TWIN}/items?${createMultipleParam(ids, 'id')}`,
  )
  const rawItems: Array<Api_Item> = res.data.items
  return {
    remappedItems: rawItems.map(remapToEditItemPopupToWarehouseData),
    rawItems,
  }
}

export function remapToEditItemPopupToWarehouseData(
  data: Api_Item,
): WarehouseEditItemPopupData {
  return {
    id: data.id,
    invoiceTimestamp: parseDateToTimestamp(data.deal_approved_date),
    deadlineTimestamp: parseDateToTimestamp(data.deadline_date),
    clientName: data.client_name,
    responsibleSales:
      assert(data.responsible_sales, 'responsible_sales not found')?.name || '',
    responsibleProcurement: data.procurement,
    amoId: data.deal_id,
    itemSum: data.full_cost,
    cost: data.cost,
    invoiceName: data.name,
    originalName: data.original_name,
    supposedStore: data.store,
    brand: data.brand,
    country: data.country,
    catalogueId: data.catalogue_id,
    quantity: data.quantity,
    actuallyDelivered: data.actually_delivered,
    partnerInterest: data.partners_interest,
    partnerInvoice: data.partner_invoice,
    extraChargePercent: data.extra_charge_percent,
    extraChargeAmount: data.extra_charge_amount,
    responsibleSCD: data.responsible_scd,
    procurementDlComment: data.procurement_dl_comments,
    exportComment: data.provider_comment,
    countryOfOrigin: data.country_of_origin,
    currencyId: data.currency,
    hazmatComment: data.provider_hazmat,
    temperature: data.provider_temp,
    exportStatuses: data.provider_export_statuses || [],
    boxNumber: data.box_numbers,
    expiredDate: parseDate(data.provider_exp),
    locations: data.provider_locations,
    lot: data.provider_lot,
    additionalCosts: data.additional_costs,
    tagToHub: data.provider_tag_to_hub || '',
    tagToTransit: data.provider_tag_to_transit || '',
    tagToRu: data.provider_tag_to_ru || '',
    hsnCodes: data.hsn_codes || '',
  }
}
