import { assert } from '@appscience/utils'
import { currenciesAtom } from './currencies-atom'
import { CurrencyData } from './currencies-atom.type'

export function getCurrencySymbol(id: string) {
  return (
    assert(
      currenciesAtom.getState()[id],
      `currenciesAtom error: currency with id "${id}" not found`,
    )?.symbol || ''
  )
}

export const CURRENCIES_ATOM_INITIAL_STATE: Record<string, CurrencyData> = {
  'RUB': {
    id: 'RUB',
    symbol: '₽',
    title: 'RUB',
  },
  'USD': {
    id: 'USD',
    symbol: '$',
    title: 'USD',
  },
}
