import {PopupDefaultContent, PopupDefaultContentProps} from '@appscience/popups'
import {useConfirmRefresh} from '../../hooks/use-confirm-refresh'

interface DefaultModalProps extends PopupDefaultContentProps {
  confirmRefresh?: boolean
}

export function DefaultModal({
  confirmRefresh = true,
  ...props
}: DefaultModalProps) {
  useConfirmRefresh(confirmRefresh)
  return <PopupDefaultContent {...props} />
}
