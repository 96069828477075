import { API_URL } from 'src/config/environment'
import { CreatePackageFormState } from '../../views/warehouse/create-package/content/form/use-form'
import { CreatePackageItem } from '../../views/warehouse/create-package/model/types'
import apiCreator from '../api-creator'
import { Api_Item } from '../common/item-model'
import { Api_Package } from '../common/package-model'
import { Api_Shipment } from '../common/shipment-model'
import { getItemInvoicePrice } from '../common/utils'
import { parseDate } from '@app/utils/date'

interface Params {
  id: string
}

export interface GetPackageByIdResponse {
  package: Api_Package
  shipment: Api_Shipment
  items: Array<Api_Item>
}

interface Response extends CreatePackageFormState {
  items: Array<CreatePackageItem>
}

export function getPackageDataById({ id }: Params): Promise<Response> {
  return apiCreator
    .get<GetPackageByIdResponse>(`${API_URL.TWIN}/get-package?id=${id}`)
    .then(({ data }) => ({
      ...remapToCreatePackageFormState(data.package, data.shipment),
      items: data.items.map(remapToCreatePackageItem),
    }))
}

export function remapToCreatePackageItem(data: Api_Item): CreatePackageItem {
  return {
    id: data.id,
    isCollectedInPackage: data.collected_in_package,
    amoId: data.deal_id,
    brand: data.brand,
    orderNumbering: data.order_numbering,
    providerHazmatType: data.provider_hazmat_type,
    invoiceName: data.name,
    toHub0: parseDate(data.sent_to_hub_0 || '') || null,
    hub0: data.hub_0,
    trackHub0: data.track_to_hub_0,
    tswed: parseDate(data.registration_date || '') || null,
    originalName: data.original_name,
    catalogueId: data.catalogue_id,
    quantity: data.quantity,
    operationLetitId: data.operation_letit_id,
    hsCodeSecondary: data.hs_code_secondary || '',
    initInvoicePrice: getItemInvoicePrice(data) || 0,
    coefInvoicePrice: getItemInvoicePrice(data) || 0,
    locations: data.provider_locations,
    currencyId: data.currency,
    netWeight: data.net_weight_kg,
    grossWeight: data.gross_weight_kg,
    hazmatComment: data.provider_hazmat,
    temperature: data.provider_temp,
    countryOfOrigin: data.country_of_origin,
    lot: data.provider_lot,
    expiredDate: parseDate(data.provider_exp),
    exportStatuses: data.provider_export_statuses || [],
    exportComment: data.provider_comment,
    boxNumber: data.box_numbers,
    hsnCodes: data.hsn_codes || '',
  }
}

export function remapToCreatePackageFormState(
  packageData: Api_Package,
  shipment: Api_Shipment,
): CreatePackageFormState {
  return {
    packingDate: parseDate(packageData.package_date),
    toWarehouseId: shipment.to_warehouse_id,
    fromWarehouseId: shipment.from_warehouse_id,
    boxNumber: packageData.box_numbers,
    temperatures: packageData.temperature,
    tag: shipment.tag,
    packageInvoice: packageData.package_invoice,
    trackIds: shipment.track_number,
    packageComment: packageData.provider_comments,
    departureDate: parseDate(shipment.departure_date),
    transporterId: shipment.transporter,
    totalGrossWeight: shipment.total_gross_weight,
    invoiceNumberBionify: packageData.invoice_number_bionify,
  }
}
