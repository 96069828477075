import { parseStringDateToDate, parseStringDateToTimestamp } from '@appscience/utils'
import { parse } from 'date-fns'

export function rusDateParser(dateString: string): Date {
  return parse(dateString, 'dd.MM.yyyy', new Date())
}

export const extractDateFromOrderId = (orderId: string): string | undefined =>
  orderId.match(/-(\d{2}\/\d{2}\/\d{4})-?/)?.[1]

export function parseDate(rawDate: string | null | undefined): Date | null {
  return rawDate
    ? parseStringDateToDate(rawDate)
    : null
}

export function parseDateToTimestamp(rawDate: string | null | undefined): number | null {
  return rawDate
    ? parseStringDateToTimestamp(rawDate)
    : null
}
