import React, {useContext, useEffect, useState} from 'react'

const OnlineStatusContext = React.createContext(true)

interface IProps {
  children: React.ReactNode
}

export const OnlineStatusProvider = ({children}: IProps) => {
  const [isOnline, setIsOnline] = useState<boolean>(true)

  useEffect(() => {
    const onOffline = () => setIsOnline(false)
    const onOnline = () => setIsOnline(true)

    window.addEventListener('offline', onOffline)
    window.addEventListener('online', onOnline)

    return () => {
      window.removeEventListener('offline', onOffline)
      window.removeEventListener('online', onOnline)
    }
  }, [])

  return (
    <OnlineStatusContext.Provider value={isOnline}>
      {children}
    </OnlineStatusContext.Provider>
  )
}

export const useOnlineStatus = () => useContext(OnlineStatusContext)
