import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'

export function procurementReorder(id: string): Promise<void> {
  return apiCreator
    .post(
      `${API_URL.TWIN}/remove-order`,
      { id },
    )
}
