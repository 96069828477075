import {Store} from '@reatom/core'
import {createPrimitiveAtom, createSetAtom} from '@reatom/core/primitives'
import {getTime, hoursToMilliseconds} from 'date-fns'
import {getApi} from '../../api'

let lastInitTimestamp: number | null

export interface StoreData {
  id: string
  title: string
  providers: {
    [key: string]: {
      paymentType: string
      paymentDetails: string
      paymentPercentage: string
    }
  }
  alternativeNames: Array<string>
  color: string,
}

export const defaultStoresAtom = createPrimitiveAtom<Record<string, StoreData>>({})
export const storesWithAlternativeNamesAtom = createPrimitiveAtom<Record<string, StoreData>>({})
export const allStoresNamesAtom = createSetAtom<string>()

export async function updateStoresAtom({dispatch}: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }
  const data = await getApi().listsApi.getStores()
  const onlyAlternativeStoresObj: Record<string, StoreData> = {}
  const defaultStoresObj: Record<string, StoreData> = {}
  data.forEach(store => {
    defaultStoresObj[store.id] = store
    store.alternativeNames.forEach(name => {
      onlyAlternativeStoresObj[name] = {
        ...store,
        id: name,
        title: name,
        providers: store.providers,
        alternativeNames: [
          store.title,
          ...store.alternativeNames.filter(x => x !== name),
        ],
      }
    })
  })

  dispatch(storesWithAlternativeNamesAtom.set({...defaultStoresObj, ...onlyAlternativeStoresObj}))
  dispatch(defaultStoresAtom.set(defaultStoresObj))
  dispatch(allStoresNamesAtom.change(() => new Set(Object.keys(defaultStoresObj))))
  lastInitTimestamp = getTime(new Date())
  return defaultStoresObj
}
