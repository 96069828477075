import {Store} from '@reatom/core'
import {createPrimitiveAtom, createSetAtom} from '@reatom/core/primitives'
import {getTime, hoursToMilliseconds} from 'date-fns'
import {getApi} from 'src/api'

let lastInitTimestamp: number | null

export interface HazmatData {
  name: string
  color: string
}

export const hazmatsAtom = createPrimitiveAtom<Record<string, HazmatData>>({})

export const allHazmatsNamesAtom = createSetAtom<string>()

export async function updateHazmatsAtom({dispatch}: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }
  const data = await getApi().listsApi.getHazmats()
  const defaultHazmatsObj: Record<string, HazmatData> = {}
  data.forEach(hazmat => (defaultHazmatsObj[hazmat.name] = hazmat))

  dispatch(hazmatsAtom.set(defaultHazmatsObj))
  dispatch(
    allHazmatsNamesAtom.change(() => new Set(Object.keys(defaultHazmatsObj))),
  )
  lastInitTimestamp = getTime(new Date())
  return defaultHazmatsObj
}
