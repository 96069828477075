import {Store} from '@reatom/core'
import {createPrimitiveAtom} from '@reatom/core/primitives'
import {getTime, hoursToMilliseconds} from 'date-fns'
import {getApi} from '../../api'
import {assert} from '@appscience/utils'

let lastInitTimestamp: number | null

export type ExchangeRates = Record<string, number> //CurrencyId - RUB

export const exchangeRatesAtom = createPrimitiveAtom<ExchangeRates>({}, null)

export async function updateExchangeRatesAtom({dispatch}: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }
  const record = await getApi().currencies.getExchangeRates()
  dispatch(exchangeRatesAtom.set(record))
  lastInitTimestamp = getTime(new Date())
  return record
}

export function getRubRate(currencyId: string): number {
  const rate = exchangeRatesAtom.getState()[currencyId]
  assert(rate, `Rate for ${currencyId} was not defined.`)
  return rate || 0
}

export function convertToRub(currencyId: string, sum: number): number {
  if (Number.isNaN(sum)) return 0
  const rate = getRubRate(currencyId)
  return rate * sum
}

export function convertCurrency(
  fromCurrencyId: string,
  toCurrentyId: string,
  sum: number,
): number {
  if (Number.isNaN(sum) || sum === 0) return 0
  return (getRubRate(fromCurrencyId) / getRubRate(toCurrentyId)) * sum
}
