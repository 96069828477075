import { getApi } from '../../../../api'
import { updateBoxNumberAtom } from '../../../../atoms/static-data/box-number-colors-atom'
import { updateCurrenciesAtom } from '../../../../atoms/static-data/currencies-atom'
import { updateExportStatusesAtom } from '../../../../atoms/static-data/export-statuses-atom'
import { updateHazmatsAtom } from '../../../../atoms/static-data/hazmats-atom'
import { updateLocationsAtom } from '../../../../atoms/static-data/locations-atom'
import { updateTemperatureColorsAtom } from '../../../../atoms/static-data/temperature-colors-atom'
import { updateTemperaturesAtom } from '../../../../atoms/static-data/temperatures-atom'
import { declareAloneAction } from '../../../../hooks/use-alone-action'
import { defaultError } from '@app/utils/errors'
import { logError } from '@appscience/utils'
import { selectedWarehousesAtom } from '../../common/selected-warehouses-atom'
import { inWarehouseAtoms } from './atoms'
import { inWarehouseChipsAtom } from './chips-atom'
import { updateProviderssAtom } from 'src/atoms/static-data/providers-atom'
import { pageLoadingAtom } from 'src/atoms/page-loading-atom'
import { updateWarehousesAtom } from 'src/atoms/static-data/warehouses-atom'
import { updateProviderLocationsAtom } from 'src/atoms/static-data/provider-locations-atom'
import { updateSdExpsAtom } from 'src/atoms/static-data/sd-exps-atom'
import { updateCountriesOfOriginAtom } from 'src/atoms/static-data/country-of-origin-atom'
import { updateProviderExportStatusesAtom } from 'src/atoms/static-data/provider-export-statuses-atom'

interface PayloadData {
  backgroundMode?: boolean
}

export const inWarehouseLoadData = declareAloneAction<
  PayloadData,
  Promise<void>
>(async ({ store, payload: { backgroundMode } }) => {
  try {
    !backgroundMode && store.dispatch(pageLoadingAtom.set('loading'))
    const enabledChips = inWarehouseChipsAtom.getState()
    const selectedWarehouses = selectedWarehousesAtom.getState()
    if (selectedWarehouses.length === 0) {
      logError('Unexpected nullable "selectedWarehouses" value')
      store.dispatch(pageLoadingAtom.set('normal'))
      return
    }

    const api = getApi()
    await Promise.all([
      updateCurrenciesAtom(store),
      updateWarehousesAtom(store),
      updateProviderssAtom(store),
      updateLocationsAtom(store),
      updateTemperaturesAtom(store),
      updateSdExpsAtom(store),
      updateExportStatusesAtom(store),
      updateBoxNumberAtom(store),
      updateTemperatureColorsAtom(store),
      updateHazmatsAtom(store),
      updateProviderLocationsAtom(store),
      updateCountriesOfOriginAtom(store),
      updateProviderExportStatusesAtom(store),
    ])
    const items = await api.provider.inWarehouse.getInWarehouseItems({
      warehouseIds: selectedWarehouses.map(x => x.id),
      send: enabledChips.includes('send'),
      stock: enabledChips.includes('stock'),
    })

    store.dispatch(inWarehouseAtoms.selectedRows.set({}))
    store.dispatch(inWarehouseAtoms.items.set(items))
    store.dispatch(pageLoadingAtom.set('normal'))
  } catch (error) {
    defaultError(error as object)
    logError('Не удалось получить данные для In Warehouse')
  }
})
