import { AxiosResponse } from 'axios'
import apiCreator from '../api-creator'
import { API_URL } from 'src/config/environment'

type ItemRequest = {
  id: number;
}
export type GetSupplyChainCostsRequest = {
  store: string;
  provider: string;
  items: Array<ItemRequest>;
}

type SupplyChain = {
  supply_chain: string;
  inbound: number;
  outbound: number;
}
type ItemResponse = {
  id: number;
  supply_chain_cost_changed: boolean;
  supply_chains: Array<SupplyChain>;
  planned_supply_chain: SupplyChain;
}
export type GetSupplyChainCostsResponseData = {
  items: Array<ItemResponse>;
}
export type GetSupplyChainCostsResponse = AxiosResponse<GetSupplyChainCostsResponseData>

export async function getSupplyChainCosts(request: GetSupplyChainCostsRequest): Promise<GetSupplyChainCostsResponseData> {
  return apiCreator
    .post<GetSupplyChainCostsResponseData, GetSupplyChainCostsResponse, GetSupplyChainCostsRequest>(
      `${API_URL.TWIN}/supply-chain-costs`,
      request,
    )
    .then(({ data }) => data)
}
