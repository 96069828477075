import axios, {AxiosRequestConfig, AxiosRequestHeaders} from 'axios'
import * as _history from 'history'
import {AUTH_TOKEN} from '../config/constants'
import {defaultError} from '@app/utils/errors'
import {i18n_get} from '../lng/i18n'
import { IS_TEST_ENVIRONMENT } from 'src/config/environment'

const history = _history.createBrowserHistory()
const apiCreator = axios.create({
  withCredentials: false,
})
const pendingRequests = new Set<string>()

const DEV_MODE_PAYLOAD_KEY = 'x-dev-mode'

const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'x-auth-token'
const PUBLIC_REQUEST_KEY = 'public-request'

function getUrl(config: AxiosRequestConfig): string | undefined {
  const queryParams = Object.entries(config.params ?? {})
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
  const url = config.url

  return url && queryParams && `${url}?${queryParams}`
}

apiCreator.interceptors.request.use(
  config => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN)
    const headers = config.headers as AxiosRequestHeaders

    if (IS_TEST_ENVIRONMENT) {
      headers[DEV_MODE_PAYLOAD_KEY] = 'true'
    }

    if (jwtToken && !headers[PUBLIC_REQUEST_KEY]) {
      headers[TOKEN_PAYLOAD_KEY] = jwtToken
    }

    const controller = new AbortController()
    const url = getUrl(config)

    if (url && pendingRequests.has(url)) {
      controller.abort()
    }
    else if (url) {
      pendingRequests.add(url)
    }

    return {
      ...config,
      // signal: controller.signal, // TODO refactor | custom signals are ignored
    }
  },
  error => {
    defaultError(error)
    Promise.reject(error)
  },
)

apiCreator.interceptors.response.use(
  response => {
    const newToken = response.headers[AUTH_TOKEN]
    if (newToken) {
      localStorage.setItem(AUTH_TOKEN, newToken)
    }

    const url = getUrl(response.config)
    url && pendingRequests.delete(url)
    return response
  },
  error => {
    if (axios.isCancel(error)) {
      return Promise.reject(error)
    }

    const url = error?.response?.config?.url
    const location = window.location
    const hasRedirect = location.search.includes('?redirect=')
    url && pendingRequests.delete(url)

    if (error?.response?.status === 403) {
      if (hasRedirect) {
        return Promise.reject(i18n_get.t('Auth.OutdatedToken'))
      }
      localStorage.removeItem(AUTH_TOKEN)
      const redirect = `${location.pathname}${location.search}`
      history.push(`${ENTRY_ROUTE}?redirect=${redirect}`)
      window.location.reload()
      return Promise.reject(i18n_get.t('Auth.OutdatedToken'))
    }

    if (error?.response?.data?.message) {
      return Promise.reject(error?.response?.data?.message)
    }

    return Promise.reject({
      message: error?.response?.data,
    })
  },
)

export default apiCreator
