import apiCreator from 'src/api/api-creator'
import { AxiosResponse } from 'axios'

export type UploadRequest = FormData
export type UploadFileResponseData = void
export type UploadFileResponse = AxiosResponse<UploadFileResponseData>

export async function uploadFileToS3(
  file: File,
  presignedS3UploadLink: string,
  abortSignal: AbortSignal,
): Promise<UploadFileResponseData> {
  return apiCreator
    .put<
      UploadFileResponseData,
      UploadFileResponse,
      File
    >(
      presignedS3UploadLink,
      file,
      {
        signal: abortSignal,
      },
    )
    .then(({ data }) => data)
}
