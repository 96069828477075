import { AxiosResponse } from 'axios'
import apiCreator from '../api-creator'
import { API_URL } from 'src/config/environment'

export type AddOrderDocRequest = {
  order_id: string;
  url: string;
}
export type AddOrderDocResponseData = void
export type AddOrderDocResponse = AxiosResponse<AddOrderDocResponseData>

export async function addOrderDoc(orderId: string, url: string): Promise<AddOrderDocResponse> {
  return apiCreator
    .post<AddOrderDocResponseData, AddOrderDocResponse, AddOrderDocRequest>(
      `${API_URL.TWIN}/add-order-doc`,
      {
        order_id: orderId,
        url,
      },
    )
}
