import { mode } from '@appscience/utils'
import { createStyles } from '@mantine/core'
import React from 'react'
import { i18n_get } from '../../../../../../lng/i18n'
import { AddItemsToPackagePopupPackage } from '../model'
import { formatMoney } from '@app/utils/formatters/format-money'

const useStyles = createStyles(({ colors, colorScheme }) => ({
  formula: {
    paddingLeft: 5,
    color: mode(colors.blue[5], colors.blue[5])(colorScheme),
  },
}))

interface ResultSumLabelProps {
  selectedPackage: AddItemsToPackagePopupPackage
  itemsSum: number
}

export function AddItemsToPackagePopupSumLabel({
  selectedPackage,
  itemsSum,
}: ResultSumLabelProps) {
  const { classes } = useStyles()
  const { currencyId, cost } = selectedPackage

  return (
    <div className='flex font-medium mt-3'>
      {i18n_get.t('AddItemsToPackagePopup.ResultSumLabel')}
      <div className={classes.formula}>
        {i18n_get.t('AddItemsToPackagePopup.SumFormula', {
          packageSum: formatMoney(cost, currencyId),
          itemsSum: formatMoney(itemsSum, currencyId),
          resultSum: formatMoney(itemsSum + cost, currencyId),
        })}
      </div>
    </div>
  )
}
