import apiCreator from 'src/api/api-creator'
import { API_URL } from 'src/config/environment'
import { InterfaceIdsEnum, PermissionGroupObject } from './model'

export type GetInterfaceObjectsResponse = {
  objects: Array<PermissionGroupObject>;
}

export async function getInterfaceObjects(interfaceId: InterfaceIdsEnum): Promise<GetInterfaceObjectsResponse> {
  return apiCreator
    .get<GetInterfaceObjectsResponse>(
      `${API_URL.AUTH}/list-objects`,
      {
        params: {
          interface_id: interfaceId,
        },
      },
    )
    .then(({ data }) => data)
}
