import {createMultipleParam, indexByWithCb} from '@appscience/utils'
import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'
import {handleErrorWithDefaultValue} from '../common/utils'

interface WarehouseLocationsResponseData {
  warehouse: string
  locations: Array<string>
}

interface Params {
  warehouseIds: Array<string>
}

export function getWarehouseLocations({
  warehouseIds,
}: Params): Promise<Record<string, Array<string>>> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/warehouse-locations?${createMultipleParam(
        warehouseIds,
        'warehouses',
      )}`,
    )
    .then(res => {
      const data: Array<WarehouseLocationsResponseData> =
        res.data.warehouse_locations
      const record = indexByWithCb(
        data,
        x => x.warehouse,
        x => x.locations,
      )
      warehouseIds.forEach(x => {
        if (record[x] === undefined) record[x] = []
      })
      return record
    })
    .catch(
      handleErrorWithDefaultValue(
        indexByWithCb(
          warehouseIds,
          id => id,
          _ => [],
        ),
      ),
    )
}
