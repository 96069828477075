import { useAtom } from '@reatom/react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AUTH_TOKEN } from 'src/config/constants'
import { permissionAtom, permissionsLoadingStateAtom } from './permission-atom'
import { useCurrentRoute, useTypedHistory } from '../route-hooks'
import { AppRoute, WithQuery } from 'src/routes/types'
import { TypedRedirectProps } from 'src/routes/components/typed-redirect'
import { getAllowedRedirects } from 'src/hooks/permissions/allowed-redirects'
import { useAtomState } from '../use-atom-state'

type UsePermissionsReturnType = {
  isPermissionsLoading: boolean;
  allowedRedirects: TypedObject<AppRoute, TypedRedirectProps>;
}

export function usePermissions(): UsePermissionsReturnType {
  const location = useLocation()
  const history = useTypedHistory()
  const currentRoute = useCurrentRoute()
  const authToken = localStorage.getItem(AUTH_TOKEN)
  const [
    { allowedRoutesSet },
    { loadPermissions },
  ] = useAtom(permissionAtom)
  const permissionsLoadingState = useAtomState(permissionsLoadingStateAtom)

  useEffect(() => {
    if (currentRoute.includes('/auth')) {
      return
    }

    if (!authToken) {
      // TODO authAtom.redirectToLogin() to prevent location deps
      const redirect = `${location.pathname}${location.search}`
      const authUrl: WithQuery<AppRoute> = redirect
        ? `/auth/login?redirect=${redirect}`
        : `/auth/login`
  
      history.typedPush(authUrl)
      return
    }

    loadPermissions()
  }, [authToken, loadPermissions, history])

  return {
    isPermissionsLoading: permissionsLoadingState === 'loading',
    allowedRedirects: getAllowedRedirects(allowedRoutesSet),
  }
}
