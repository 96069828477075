import { ComponentPropsWithRef } from 'react'
import { Route } from 'react-router'
import { AppRoute } from '../types'

export interface TypedRouteProps extends ComponentPropsWithRef<typeof Route> {
  path: AppRoute;
}

export function TypedRoute(props: TypedRouteProps) {
  return <Route {...props} />
}
