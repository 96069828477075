import { UserPermissions } from 'src/api/iam/groups/model'
import { UserWithId } from 'src/api/iam/users/model'
import { UserType } from 'src/views/iam/common/types'

const USER_TYPE_TO_ALLOWED_USER_TYPES_FOR_CREATION_MAP: Record<UserType, Array<UserType>> = {
  [UserType.User]: [],
  [UserType.Admin]: [UserType.User],
  [UserType.SuperAdmin]: [UserType.User, UserType.SuperAdmin, UserType.Admin],
}

export function getAllowedUserTypesForUserCreationSet(
  userPermissions: UserPermissions | null,
  userWithId: UserWithId | null,
): Set<UserType> {
  if (!userPermissions || !userWithId) {
    return new Set([])
  }

  return new Set(USER_TYPE_TO_ALLOWED_USER_TYPES_FOR_CREATION_MAP[userWithId.role])
}
