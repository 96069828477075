import { PaymentMethodId } from 'src/atoms/static-data/payments-method-atom'
import { addDays } from 'date-fns'
import { isNullable, presiceNumber } from '@appscience/utils'

export function computePaymentTimestamp(
  paymentMethod: PaymentMethodId,
  orderTimestamp: number,
): number {
  return paymentMethod === 'card' || paymentMethod === 'prepayment'
    ? orderTimestamp
    : addDays(orderTimestamp, 30).getTime()
}

interface AutoSumFnParams {
  sum: number | null
  newValue: number | null
  prevValue: number | null
}

export function autoSum({ newValue, sum, prevValue }: AutoSumFnParams): number | null {
  if (isNullable(sum)) {
    return newValue
  }

  const difference =
    newValue === null
      ? prevValue === null
        ? 0
        : -prevValue
      : prevValue === null
        ? newValue
        : newValue - prevValue

  return presiceNumber(sum + difference, 2)
}
