import {PlusCircleIcon} from '../../../../../icons'
import {useRedirectFn} from '../../../../../hooks/route-hooks'
import {i18n_get} from '../../../../../lng/i18n'
import {Button} from '@mantine/core'

interface Props {
  selectedIds: Array<string>
}

export function NewOrderLayoutButton({selectedIds}: Props) {
  const redirectTo = useRedirectFn('/procurement')

  return (
    <Button
      leftIcon={<PlusCircleIcon className='w-5 h-5' />}
      onClick={() =>
        redirectTo(`/new-order/create?id=${selectedIds.join(',')}`)
      }
    >
      {i18n_get.t('NewOrder.CreateOrder')}
    </Button>
  )
}
