import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'

type GetComplianceBaseDownloadLinkResponse = {
  url: string;
}

export async function getComplianceBaseDownloadLink(
  abortSignal: AbortSignal,
): Promise<GetComplianceBaseDownloadLinkResponse> {
  return apiCreator
    .get<GetComplianceBaseDownloadLinkResponse>(
      `${API_URL.SCIFINDER}/get-download-link-compliance-base-csv`,
      {
        signal: abortSignal,
      },
    )
    .then(({ data }) => data)
}