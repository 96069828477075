import './index.css'
import './lng/i18n'
import React from 'react'
import ReactDOM from 'react-dom'
import {App} from './App'
import * as Sentry from '@sentry/react'
import {BrowserTracing} from '@sentry/tracing'
import {enableMapSet} from 'immer'

if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
  Sentry.init({
    dsn: 'https://fa8962b0b9eb4214832cb26e172ad17e@o1092268.ingest.sentry.io/6500158',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
    debug: process.env.REACT_APP_ENVIRONMENT === 'production',
  })
}

if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
  console.log(`Now REACT_APP_ENVIRONMENT = ${process.env.REACT_APP_ENVIRONMENT}`)
}

enableMapSet()

ReactDOM.render(<App />, document.getElementById('root'))
