import {hasOwnProperty, logError} from '@appscience/utils'
import {toast} from 'react-hot-toast'

interface ControlledErrorInfo {
  toastMessage: string
  consoleMessage?: string
}

interface DefaultErrorObj {
  message?: string
  reason_code?: string
}

type ErrorResponse = DefaultErrorObj | string

export function defaultError(args: ControlledErrorInfo | ErrorResponse) {
  try {
    if (typeof args === 'string') {
      toast.error(args)
      return
    }
    if (hasOwnProperty(args, 'toastMessage')) {
      logError(args.consoleMessage)
      toast.error(args.toastMessage)
      return
    }
    toast.error(args?.message || 'Unknown backend error')
  }
  catch (error) {
    toast.error('Panic!')
    logError(`Panic! Args: ${JSON.stringify(args)}`)
    logError(`Panic! Error value: ${JSON.stringify(error)}`)
  }
}

export const validators = {
  string: (value: string | null) => !!(value && value.trim()),
  number: (value: number | null | undefined) => value != null,
  truly: (value: unknown) => Boolean(value),
  array: (value: Array<unknown>) => value.length > 0,
}
