import { SimpleTable, SimpleTableColumnInfo } from '@appscience/simple-table'
import React, { memo } from 'react'
import { getTemperaturesTitle } from '../../../../../../atoms/static-data/temperatures-atom'
import { i18n_get } from '../../../../../../lng/i18n'
import { TextWithEllipsis } from '../../../../../../components/text-with-ellipsis'
import {
  defaultFormatDate,
  joinStrings,
} from '@appscience/utils'
import { AmoIdCell } from '../../../../../common/cells/amo-id-cell'
import { AddItemsToPackagePopupItem } from '../model'
import { formatNumber } from '@app/utils/formatters/format-number'

type AddItemsToOrderPopupColumnId = Exclude<
  keyof AddItemsToPackagePopupItem,
  'id'
>

interface Props {
  items: Array<AddItemsToPackagePopupItem>
}

// TODO ?
//TODO:improvement перенести content ячейки в columns-prop, чтобы при измнении порядка столбцов не менять это в двух местах.
export const AddItemsToPackagePopupTable = memo(({ items }: Props) => (
  <SimpleTable
    columns={getColumnsInfo()}
    rowsData={{
      fragments: items.map(item => (
        <>
          <AmoIdCell id={item.amoId} />
          <TextField value={item.originalName} />
          <TextField value={item.brand} />
          <TextField value={item.catalogueId} />
          <TextField value={item.quantity.toString()} />
          <TextField
            value={formatNumber(item.invoicePrice.toFixed(2))}
          />
          <TextField value={getTemperaturesTitle(item.temperatureId)} />
          <TextField value={item.hazmat} />
          <TextField value={defaultFormatDate(item.expiredDate)} />
        </>
      )),
      className: 'font-medium',
    }}
    className='max-h-[364px]'
    fixHeader
  />
))

interface TextFieldProps {
  value: string
}

function TextField({ value }: TextFieldProps) {
  return (
    <div className={joinStrings('flex items-center overflow-hidden')}>
      <TextWithEllipsis text={value || '—'} />
    </div>
  )
}

function getColumnsInfo(): Array<
  SimpleTableColumnInfo<AddItemsToOrderPopupColumnId>
> {
  return [
    {
      id: 'amoId',
      fr: 12,
      title: i18n_get.t('Common.AMO'),
    },
    {
      id: 'originalName',
      fr: 30,
      title: i18n_get.t('Common.ItemInvoiceName'),
    },
    {
      id: 'brand',
      fr: 20,
      title: i18n_get.t('Common.Brand'),
    },
    {
      id: 'catalogueId',
      fr: 12,
      title: i18n_get.t('Common.CatalogueID'),
    },
    {
      id: 'quantity',
      fr: 8,
      title: i18n_get.t('Common.Quantity'),
    },
    {
      id: 'invoicePrice',
      fr: 12,
      title: i18n_get.t('Common.ItemSum'),
    },
    {
      id: 'temperatureId',
      fr: 12,
      title: i18n_get.t('Common.Temperature'),
    },
    {
      id: 'hazmat',
      fr: 10,
      title: i18n_get.t('Common.HazmatComment'),
    },
    {
      id: 'expiredDate',
      fr: 15,
      title: i18n_get.t('Common.ExpirationDate'),
    },
  ]
}
