import {PopupDefaultContent} from '@appscience/popups'
import {joinStrings} from '@appscience/utils'
import {Textarea} from '@mantine/core'
import {useState} from 'react'

type NotificationPopupProps = {
  title: string
  question: string
  onSuccess: (msg: string) => void,
  loading: boolean,
  closeFn: () => void,
  className?: string
}

export function ReasonInputPopup({
  title,
  question,
  className,
  onSuccess,
  loading,
  closeFn,
}: NotificationPopupProps) {
  const [reason, setReason] = useState('')

  return (
    <PopupDefaultContent
      className={joinStrings('w-[400px]', className)}
      title={title}
      createContent={() => <Content
        question={question}
        setReason={setReason}
      />}
      closeFn={closeFn}
      buttonsInfo={{
        success: {
          onClick: () => onSuccess(reason),
          loading,
        },
      }}
    />
  )
}

interface ContentProps {
  question: string,
  setReason: (v: string) => void,
}

function Content({
  question,
  setReason,
}: ContentProps) {
  return (
    <>
      <div className='text-normal font-semibold mb-2'>{question}</div>
      <Textarea onBlur={e => setReason(e.currentTarget.value)} />
    </>
  )
}