import {createContextWithHook} from '@app/utils/react'
import {isEmpty} from 'ramda'
import {useEffect} from 'react'
import {
  updateWarehousesAtom,
  warehousesAtom,
} from '../../atoms/static-data/warehouses-atom'
import {declareAloneAction, useAloneAction} from '../../hooks/use-alone-action'
import {useAtomState} from '../../hooks/use-atom-state'
import {isDef, logError} from '@appscience/utils'
import {selectedWarehousesAtom} from './common/selected-warehouses-atom'

interface ProviderContextData {
  warehousesLoading: boolean
}

export const [WarehouseContext, useWarehouseContext] =
  createContextWithHook<ProviderContextData>({
    warehousesLoading: false,
  })

interface ProviderStageProps {
  children: React.ReactNode;
}

export function ProviderStage({ children }: ProviderStageProps) {
  const handleLoadWarehouses = useAloneAction(loadWarehousesData)
  const warehouses = useAtomState(warehousesAtom)

  useEffect(() => {
    handleLoadWarehouses()
  }, [handleLoadWarehouses])

  return (
    <WarehouseContext.Provider
      value={{
        warehousesLoading: isEmpty(warehouses),
      }}
    >
      {children}
    </WarehouseContext.Provider>
  )
}

const loadWarehousesData = declareAloneAction(async ({store}) => {
  const warehousesData = await updateWarehousesAtom(store)
  const selectedWarehouses = selectedWarehousesAtom.getState()
  const isValidWarehouses =
    selectedWarehouses.length > 0 &&
    selectedWarehouses.every(x => isDef(warehousesData[x.id]))
  const warehousesArray = Object.values(warehousesData)
  if (warehousesArray.length === 0) {
    logError('Unexpected error. Warehouses list is empty')
    return
  }
  !isValidWarehouses &&
    store.dispatch(selectedWarehousesAtom.init(warehousesArray[0]))
})
