import { API_URL } from 'src/config/environment'
import { PackingListGeneratorItem } from '../../views/packing-list-generator/model/atoms'
import apiCreator from '../api-creator'
import { Api_Item } from '../common/item-model'
import { handleErrorWithDefaultValue } from '../common/utils'
import { parseDate } from '@app/utils/date'

export function getPackingListGeneratorItems(): Promise<Array<PackingListGeneratorItem>> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/items?step=on-delivery-point`,
    )
    .then(res => {
      const rawData: Array<Api_Item> = res.data.items
      return rawData.map(remapToPackingListGeneratorItem)
    })
    .catch(handleErrorWithDefaultValue([]))
}

export function remapToPackingListGeneratorItem(
  data: Api_Item,
): PackingListGeneratorItem {
  return {
    id: data.id,
    clientName: data.client_name,
    registrationDate: parseDate(data.register_obo_date),
    brand: data.brand,
    amoId: data.deal_id,
    invoiceName: data.name,
    billNumber: data.bill_number,
    billPosition: data.bill_position,
    catalogueId: data.catalogue_id,
    quantity: data.quantity,
    sumPrice: data.final_price,
    temperature: data.operations_storage_temperature,
    tswName: data.tsw_name,
    lot: data.provider_lot,
    currencyId: data.currency,
    seller: data.seller,
    plannedDepartureDate: parseDate(data.planned_shipping_date),
  }
}
