import { authApi } from './auth'
import { currenciesApi } from './common/currencies-api'
import { listsApi } from './common/lists/lists-api'
import { packingListApi } from './packing-list'
import { procurementApi } from './procurement'
import { providerApi } from './provider'
import { reorderApi } from './reorder'
import { groupsApi } from './iam/groups'
import { usersApi } from './iam/users'
import { scifinderApi } from './scifinder'
import { analoguesApi } from './analogues'
import { orderFilesApi } from './order-files'
import { awsS3Api } from './aws-s3'
import { limitsApi } from './limits'
import { supplyChainCostsApi } from './supply-chain-costs'

export function getProductionApi() {
  return {
    auth: authApi,
    procurement: procurementApi,
    provider: providerApi,
    currencies: currenciesApi,
    listsApi,
    packingList: packingListApi,
    reorderApi,
    groups: groupsApi,
    users: usersApi,
    scifinder: scifinderApi,
    analogues: analoguesApi,
    orderFiles: orderFilesApi,
    awsS3: awsS3Api,
    limits: limitsApi,
    supplyChainCosts: supplyChainCostsApi,
  }
}
