import {getBoxNumberMap} from '../common/get-box-number-map'
import {addItemsToPackage} from './add-items-to-package'
import {addItemsToStock} from './add-to-stock'
import {cancelRegisterItems} from './cancel-register-items'
import {createProviderPackage} from './create-package'
import {duplicateProviderItem} from './duplicate-item'
import {editWarehouseItems} from './edit-warehouse-items'
import {cancelRegisterPackage} from './from-warehouse/cancel-register-package'
import {getArchiveItems} from './from-warehouse/getArchiveItems'
import {getArchivePackages} from './from-warehouse/getArchivePackages'
import {getReadyToShipItems} from './from-warehouse/getReadyToShipItems'
import {getReadyToShipPackages} from './from-warehouse/getReadyToShipPackages'
import {getShippedItems} from './from-warehouse/getShippedItems'
import {getShippedPackages} from './from-warehouse/getShippedPackages'
import {returnToShipments} from './from-warehouse/return-to-shipments'
import {returnToWarehouse} from './from-warehouse/return-to-warehouse'
import {getDuplicateItemPopupData} from './get-duplicate-item-popup-data'
import {getEditItemPopupItems} from './get-edit-item-popup-items'
import {getPriceCoefficient} from './get-price-coefficient'
import {getProviderLocations} from './get-provider-locations'
import {
  getHubRegisterPageItemsByPackageId,
  getProviderRegisterPageItemsByOrderId,
  getRegisterItemsByIds,
} from './get-provider-register-page-items'
import {getWarehouseLocations} from './get-warehouse-locations'
import {getAddToPackagePopupItems} from './getAddToPackagePopupItems'
import {getCreatePackageItems} from './getCreatePackageItems'
import {getInWarehouseItems} from './getInWarehouseItems'
import {getPackageDataById} from './getPackageDataById'
import {getToHubItems} from './getToHubItems'
import {getToHubPackages} from './getToHubPackages'
import {getToProviderItems} from './getToProviderItems'
import {getToProviderOrders} from './getToProviderOrders'
import {registerHubItems} from './register-hub-items'
import {registerProviderItems} from './register-provider-items'

import {returnItemsFromStock} from './return-from-stock'
import {splitItemIntoSubsets} from './split-the-set'

export const providerApi = {
  getProviderRegisterPageItemsByOrderId,
  getItemsByIds: getRegisterItemsByIds,
  getPriceCoefficient,
  getWarehouseLocations,
  editWarehouseItems,
  getEditItemPopupItems,
  addItemsToPackage,
  getAddToPackagePopupItems,
  getBoxNumberMap,
  getProviderLocations,
  toWarehouse: {
    getToProviderOrders,
    getToProviderItems,
    getToHubItems,
    getToHubPackages,
    registerProviderItems,
    registerHubItems,
    getHubRegisterPageItemsByPackageId,
    duplicateProviderItem,
    getDuplicateItemPopupData,
    splitItemIntoSubsets,
  },
  inWarehouse: {
    getInWarehouseItems,
    getCreatePackageItems,
    createProviderPackage,
    returnItemsFromStock,
    addItemsToStock,
    cancelRegisterItems,
  },
  fromWarehouse: {
    getReadyToShipItems,
    getReadyToShipPackages,
    getShippedItems,
    getShippedPackages,
    getArchivePackages,
    getArchiveItems,
    returnToShipments,
    returnToWarehouse,
    getPackageDataById,
    cancelRegisterPackage,
  },
}
