import {useEffect, useState} from 'react'
import {getApi} from '../../../../../api'
import {UserRole, userAtom} from '../../../../../atoms/user-atom'
import {useAtomState} from '../../../../../hooks/use-atom-state'
import {
  AddItemsToOrderPopupItem,
  AddItemsToOrderPopupProps,
  AddItemsToOrderPopupStaticData,
} from './model'

export function useAddItemsToOrderPopupLoadedState({
  getItems,
}: AddItemsToOrderPopupProps) {
  const {role} = useAtomState(userAtom)
  const [loadingState, setLoadingState] =
    useState<DefaultLoadingState>('loading')
  const [staticData, setStaticData] =
    useState<AddItemsToOrderPopupStaticData | null>(null)

  useEffect(() => {
    setLoadingState('loading')
    loadData({getItems, role}).then(data => {
      setStaticData(data)
      setLoadingState('normal')
    })
  }, [getItems, role])

  return {
    staticData,
    loadingState,
    setLoadingState,
  }
}

interface LoadDataParams {
  getItems: () => Promise<Array<AddItemsToOrderPopupItem>>
  role: UserRole
}

async function loadData({
  getItems,
  role,
}: LoadDataParams): Promise<AddItemsToOrderPopupStaticData> {
  const [items, orders] = await Promise.all([
    getItems(),
    getApi().procurement.getPreordersOrders(role),
  ])
  return {
    items,
    orders: orders.map(x => ({
      id: x.id,
      date: x.orderDate,
      store: x.store,
      status: x.statusId,
      orderNumber: x.orderNumber,
    })),
  }
}
