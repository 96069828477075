import { defaultError } from '@app/utils/errors'
import { successMessage } from '@app/utils/feedback'
import { Select, SelectItem } from '@mantine/core'
import React, { forwardRef, useMemo, useState } from 'react'
import { getApi } from '../../../../../api'
import { LoaderContainer } from '../../../../../components/loader-container'
import { DefaultModal } from '../../../../../components/popups/default-modal'
import { DEFAULT_SELECT_LIST_LIMIT } from '../../../../../config/constants'
import { i18n_get } from '../../../../../lng/i18n'
import { isUnderTrackingStatus } from '../../../../../types/order-status'
import { getPopupDefaultButtons } from '@appscience/popups'
import { verify } from '@appscience/utils'
import { SimpleTableLabel } from '@appscience/simple-table'
import { AddItemsToOrderPopupTable } from './content/table'
import {
  AddItemsToOrderPopupProps,
  AddItemsToOrderPopupStaticData,
} from './model'
import { useAddItemsToOrderPopupLoadedState } from './use-loaded-state'
import { extractDateFromOrderId } from '@app/utils/date'

export function AddItemsToOrderPopup(props: AddItemsToOrderPopupProps) {
  const { title, onSuccess, closeFn } = props
  const { staticData, loadingState, setLoadingState } =
    useAddItemsToOrderPopupLoadedState(props)

  return (
    <DefaultModal
      title={title}
      createContent={() =>
        loadingState === 'loading' ? (
          <LoaderContainer />
        ) : (
          <AddItemsToOrderPopupContent
            staticData={verify(staticData)}
            closeFn={closeFn}
            onSuccess={onSuccess}
            loadingState={loadingState}
            setLoadingState={setLoadingState}
          />
        )
      }
      closeFn={closeFn}
      className='w-[856px] min-h-[400px] py-8 px-9'
    />
  )
}

interface ContentProps {
  staticData: AddItemsToOrderPopupStaticData
  onSuccess: () => Promise<void>
  closeFn: () => void
  setLoadingState: (v: DefaultLoadingState) => void
  loadingState: DefaultLoadingState
}

interface ItemComponentProps extends SelectItem {
  value: string
  date: string
  store: string
}

export const ItemComponent = forwardRef<HTMLDivElement, ItemComponentProps>(
  ({ orderNumber, store, value, className, ...others }, ref) => (
    <div
      {...others}
      ref={ref}
      className={`grid gap-10 grid-cols-[110px_150px_150px] ${className}`}
    >
      <div>{extractDateFromOrderId(value)}</div>
      <div>{store}</div>
      <div>{orderNumber}</div>
    </div>
  ),
)

function AddItemsToOrderPopupContent({
  staticData: { items, orders },
  onSuccess,
  closeFn,
  setLoadingState,
  loadingState,
}: ContentProps) {
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null)
  const data = useMemo(
    () =>
      orders
        .filter(x => !isUnderTrackingStatus(x.status))
        .map(order => ({
          value: order.id,
          label: order.id,
          orderNumber: order.orderNumber,
          store: order.store,
        })),
    [orders],
  )

  return (
    <div className='flex flex-col grow mt-2.5 bg-inherit'>
      <AddItemsToOrderPopupTable items={items} />
      <Select
        label={
          <SimpleTableLabel label={i18n_get.t('NewOrder.SelectOrderLabel')} />
        }
        placeholder={i18n_get.t('NewOrder.SelectOrderPlaceholder')}
        value={selectedOrderId}
        onChange={setSelectedOrderId}
        data={data}
        itemComponent={ItemComponent}
        required
        limit={DEFAULT_SELECT_LIST_LIMIT}
        searchable
        className='w-full pt-3 mb-auto'
      />
      <div className='flex justify-end mt-4 space-x-2'>
        {getPopupDefaultButtons({
          success: {
            onClick: () => {
              if (selectedOrderId === null) {
                return
              }
              setLoadingState('saving')
              getApi()
                .procurement.addItemsToOrder({
                  orderId: selectedOrderId,
                  itemIds: items.map(x => x.id),
                })
                .then(() =>
                  successMessage(
                    i18n_get.t('NewOrder.AddItemsToOrderPopup.SuccessMessage'),
                  ),
                )
                .then(onSuccess)
                .then(closeFn)
                .catch(defaultError)
                .finally(() => setLoadingState('normal'))
            },
            disabled: selectedOrderId === null,
            loading: loadingState === 'saving',
          },
          cancel: { onClick: closeFn },
        })}
      </div>
    </div>
  )
}
