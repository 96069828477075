import {StoreData} from '../../../atoms/static-data/stores-atom'
import {API_URL} from 'src/config/environment'
import apiCreator from '../../api-creator'
import {handleErrorWithDefaultValue} from '../utils'

interface ResponseStoreData {
  name: string
  providers: {
    [key: string]: {
      payment_type: string
      payment_details: string
      payment_percentage: number
    }
  }
  alternative_names: Array<string>
  color: string
}

export function getStoresApi(): Promise<Array<StoreData>> {
  return apiCreator
    .get(`${API_URL.TWIN}/stores`)
    .then(res => {
      const rawItems: Array<ResponseStoreData> = res.data.stores
      return rawItems.map(remapToStore)
    })
    .catch(handleErrorWithDefaultValue([]))
}

function remapToStore(data: ResponseStoreData): StoreData {
  return {
    id: data.name,
    title: data.name,
    providers: Object.keys(data.providers).reduce(
      (acc, currKey) => ({
        ...acc,
        [currKey]: {
          paymentDetails: data.providers[currKey].payment_details,
          paymentType: data.providers[currKey].payment_type,
          paymentPercentage: data.providers[currKey].payment_percentage,
        },
      }),
      {},
    ),
    alternativeNames: data.alternative_names,
    color: data.color || '#ececec',
  }
}
