import { defaultError } from '@app/utils/errors'
import { successMessage } from '@app/utils/feedback'
import { useModal } from '@appscience/hooks'
import { useState } from 'react'
import { getApi } from '../../../../api'
import { userAtom } from '../../../../atoms/user-atom'
import { useAtomState } from '../../../../hooks/use-atom-state'
import { i18n_get } from '../../../../lng/i18n'
import { ReasonInputPopup } from '../../pre-orders-view/common/reason-input-popup'
import { useAction } from '@reatom/react'
import { useAloneAction } from 'src/hooks/use-alone-action'
import { createOrderPageFieldsAtom } from '../../create-order/model/order-fields.atom'
import { createOrderPageStaticDataAtom } from '../../create-order/model/create-order-page-static-data.atom'
import { createOrderPageItemsAtom } from '../../create-order/model/items'
import { createOrderPageSaveData } from '../../create-order/model/external-actions/save-data'

interface UseModerateOrderHandlersParams {
  ids: Array<string>,
  onSuccessApprove: () => void,
  onSuccessReject: () => void,
}

export function useModerateOrderHandlers({
  ids,
  onSuccessApprove,
  onSuccessReject,
}: UseModerateOrderHandlersParams) {
  const { role } = useAtomState(userAtom)
  const [loading, setLoading] = useState<'approve' | 'reject' | null>(null)
  const [openRejectPopup, hideRejectPopup] = useModal(ReasonInputPopup, {
    title: i18n_get.t('Common.Reject'),
    question: i18n_get.t('Common.ModeratorComment'),
    closeFn: () => hideRejectPopup(),
    onSuccess: msg => rejectImpl(msg),
    loading: loading === 'reject',
  })
  const { tswNameId } = useAtomState(createOrderPageFieldsAtom)

  const handleUpdateField = useAction(createOrderPageItemsAtom.update)
  const items = useAtomState(createOrderPageItemsAtom)
  const pageType = useAtomState(createOrderPageStaticDataAtom).pageType
  items.forEach(elem => handleUpdateField(elem.id, 'tswName', tswNameId))
  const saveOrder = useAloneAction(createOrderPageSaveData)
  const shouldSaveOrder = pageType === 'edit' && role === 'finance-control'
  const onApprove = () => {
    setLoading('approve')
    getApi().procurement.approveOrder({
      from_finance_control: role === 'finance-control',
      from_head_of_finance: role === 'head-of-finance',
      from_head_of_procurement: role === 'head-of-procurement',
      order_ids: ids,
    })
      .then(() => {
        successMessage(i18n_get.t('PreOrders.Approve.SuccessMessage'))
        onSuccessApprove()
      })
      .then(() => {
        if (shouldSaveOrder) {
          saveOrder()
        }
      })
      .catch(defaultError)
      .finally(() => setLoading(null))
  }
  const rejectImpl = (msg: string) => {
    setLoading('reject')
    getApi().procurement.rejectOrder({
      from_finance_control: role === 'finance-control',
      from_head_of_finance: role === 'head-of-finance',
      from_head_of_procurement: role === 'head-of-procurement',
      order_ids: ids,
      moderation_comment: msg,
    })
      .then(() => {
        successMessage(i18n_get.t('PreOrders.Reject.SuccessMessage'))
        onSuccessReject()
        hideRejectPopup()
      })
      .then(() => {
        if (shouldSaveOrder) {
          saveOrder()
        }
      })
      .catch(defaultError)
      .finally(() => setLoading(null))
  }

  return {
    onApprove,
    onReject: openRejectPopup,
    loading,
  }
}