import {primitiveAtomWithLS} from '@app/utils/reatom'
import {createPrimitiveAtom} from '@reatom/core/primitives'
import {RowSelectionState} from '@tanstack/react-table'

export type FromWarehouseStatusTab = 'readyToShip' | 'shipped' | 'archive'
export const fromWarehouseStatusTabAtom =
  primitiveAtomWithLS<FromWarehouseStatusTab>(
    'readyToShip',
    'from-warehouse.selectedPackageStatusAtom',
  )

export type FromWarehouseTab = 'packages' | 'items'
export const fromWarehouseTabAtom = primitiveAtomWithLS<FromWarehouseTab>(
  'packages',
  'fromWarehouse.tabAtom',
)

export const fromWarehouseTableSelectedRowsAtom =
  createPrimitiveAtom<RowSelectionState>({})
