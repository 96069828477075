import { createPrimitiveAtom } from '@reatom/core/primitives'

interface ModerationConditions {
    moderationPassed: boolean
    initOrderSum: number
}

export const initOrderModerationInfo = createPrimitiveAtom<ModerationConditions | null>({
    moderationPassed: false,
    initOrderSum: 0,
})
