export function caseInsensitiveSubstr(value: string, search: string): boolean {
  return value.toLowerCase().includes(search.toLowerCase())
}

export function joinStrings(...values: (string|undefined|null|false)[]): string {
  return values.filter(Boolean).join(' ')
}


interface GetUrlHostOptions {
  keepWWW: boolean,
}

export function getUrlHost(url: string, options?: GetUrlHostOptions): string | null {
  const matches = url.match(/^(?:https?|ftp):\/\/([^/?#]+)(?:[/?#]|$)/i)
  if (matches === null) {
    return null
  }

  const value = matches[1]
  return options && !options.keepWWW && value.slice(0, 4).toLowerCase() === 'www.'
    ? value.slice(4)
    : value
}

export function getUrlHostname(url: string, options?: GetUrlHostOptions) {
  const value = getUrlHost(url, options)
  if (!value) {
    return null
  }

  const matches = value.match(/(.*):\d+$/i)
  return matches ? matches[1] : value
}

export function createMultipleParam<T>(data: Array<T>, name: string): string {
  return data.reduce((acc: string, item, index) => (index === 0 ? `${name}=${item}` : `${acc}&${name}=${item}`), '')
}

export function commaJoin(...values: (string|undefined|null|false)[]): string {
  return values.filter(Boolean).join(', ')
}