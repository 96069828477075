import {defaultError} from '@app/utils/errors'
import {successMessage} from '@app/utils/feedback'
import {useModal} from '@appscience/hooks'
import {Button} from '@mantine/core'
import {useState} from 'react'
import {getApi} from '../../../../../api'
import {useAloneAction} from '../../../../../hooks/use-alone-action'
import {i18n_get} from '../../../../../lng/i18n'
import {AddItemsToPackagePopup} from '../../../in-warehouse/content/add-items-to-package-popup'
import {loadFromWarehouseData} from '../load-data'

interface Props {
  selectedIds: Array<string>
}

export function ReadyToShipsItemsLayoutActions({selectedIds}: Props) {
  const [loading, setLoading] = useState(false)
  const handleLoadData = useAloneAction(loadFromWarehouseData)

  const onReturnToWarehouse = () => {
    setLoading(true)
    getApi()
      .provider.fromWarehouse.returnToWarehouse({
        type: 'items',
        itemIds: selectedIds,
      })
      .then(() => {
        successMessage(
          i18n_get.t(
            'Provider.FromWarehouse.ReturnToTheWarehouse.SuccessMessage',
          ),
        )
        return handleLoadData({
          tab: 'items',
          status: 'readyToShip',
          backgroundMode: true,
        })
      })
      .catch(defaultError)
      .finally(() => setLoading(false))
  }

  const [openAddItemsToPackagePopup, hideAddItemsToPackagePopup] = useModal(
    AddItemsToPackagePopup,
    {
      title: i18n_get.t('Provider.FromWarehouse.AddToAnotherPackage'),
      onSuccess: () =>
        handleLoadData({
          backgroundMode: true,
          tab: 'items',
          status: 'readyToShip',
        }),
      closeFn: () => hideAddItemsToPackagePopup(),
      getItems: () => getApi().provider.getAddToPackagePopupItems(selectedIds),
    },
  )

  return (
    <>
      <Button loading={loading} onClick={onReturnToWarehouse} className='mr-2'>
        {i18n_get.t('Provider.FromWarehouse.ReturnToTheWarehouse')}
      </Button>
      <Button loading={loading} onClick={openAddItemsToPackagePopup}>
        {i18n_get.t('Provider.FromWarehouse.AddToAnotherPackage')}
      </Button>
    </>
  )
}
