import {primitiveAtom, primitiveAtomWithLS} from '@app/utils/reatom'
import {createPrimitiveAtom} from '@reatom/core/primitives'
import {RowSelectionState} from '@tanstack/react-table'
import {ModeratorStatus, OrderStatusId, TermsOfPayment} from '../../../../types/order-status'
import {FinanceControl, PaymentMethodId} from '../../../../atoms/static-data/payments-method-atom'
import {OrdersAndItemsTab} from '../../../../types/types'
import { PaymentStatusEnum } from 'src/types/payment-status'

//-----------------------COMMON--------------------------
export const preordersPageTabAtom = primitiveAtomWithLS<OrdersAndItemsTab>(
  'byOrders',
  'preordersPageTabAtom',
)
export const storeIdsFilterAtom = primitiveAtomWithLS<Array<string>>(
  [],
  'Preorders.storeIdsFilterAtom',
)
export const procurementIdsFilterAtom = primitiveAtomWithLS<Array<string>>(
  [],
  'Preorders.procurementIdsFilterAtom',
)

export const warehousesFilterAtom = primitiveAtomWithLS<Array<string>>(
  [],
  'Preorders.warehousesFilterAtom',
)

//-----------------------ITEMS TABLE--------------------------
export type PreordersViewItem = PreordersItem

export interface PreordersItem {
  id: string
  orderId: string
  deadlineDate: Date | null
  orderDate: Date | null
  statusId: OrderStatusId
  amoId: string
  esdDate: Date | null
  arrivalDate: Date | null
  esdFixOriginal: Date | null
  invoiceNumber: string
  costSavings: number
  overpayment: number
  orderNumber: string
  extraChargeAmount: number
  extraChargePercent: number
  partnersInterest: number
  partnerInvoice: string
  itemDetails: string
  trackToHub: string
  tagToHub: string
  trackToRu: string
  tagToRu: string
  invoicePrice: number | null
  invoicePriceOld: number | null
  customPrice: number | null
  esdFix: Date | null
  customPriceOld: number | null
  tswed: Date | null
  itemParts: string
  reasonOfCancel: string
  store: string
  brand: string
  invoiceName: string
  originalName: string
  catalogueId: string
  quantity: number
  price: number
  currencyId: string | null
  provider: string | null
  priority: string
  procurement: string
  paymentMethod: PaymentMethodId
  procurementComment: string
  sdComment: string
  orderDetails: string
  headOfProcurementStatus: ModeratorStatus
  headOfFinanceStatus: ModeratorStatus
  card: string | null
  financeControlStatus: ModeratorStatus
  temperature: string
  dgmStatus: string
  isKit: boolean
  deliveryMethod: string
  isTender: boolean
  providerHazmatType: string
  complianceDate: Date | null
  estimatedOrderDate: Date | null
  quota: string
  paidDate: Date | null
  exportDocuments: Array<string>
  hsCodeChina: string
  orderComment: string
  paymentStatus: PaymentStatusEnum
  salesComment: string
  proposalComment: string
  dlOrder: Date | null
}

export const preordersItemsAtom = createPrimitiveAtom<Array<PreordersItem>>([])
export const preordersItemsTableSelectedRowsAtom =
  createPrimitiveAtom<RowSelectionState>({})

export const preordersItemsTableSelectedOrderIdAtom = primitiveAtom<
  string | null
>(null, dispatch => dispatch(preordersPageTabAtom.set('byItems')))

export const preordersItemsTableSelecteInvoiceAtom = primitiveAtom<
string | null
>(null, dispatch => dispatch(preordersPageTabAtom.set('byItems')))

//-----------------------ORDERS TABLE--------------------------
export type PreordersViewOrder = PreordersOrder

export interface PreordersOrder {
  id: string
  deadlineDate: Date | null
  orderDate: Date | null
  arrived: Date | null
  statusId: OrderStatusId
  transferredForPaymentDate: Date | null
  store: string
  invoiceNumbers: string
  paymentMethodId: PaymentMethodId
  poNumber: string
  invoiceNumber: string
  paymentPriority: string
  orderNumber: string
  sumPrice: number
  procurement: string
  additionalCosts: number
  orderSumUSD: number
  currencyId: string | null
  provider: string | null
  itemsQuantity: number
  headOfProcurementStatus: ModeratorStatus
  headOfFinanceStatus: ModeratorStatus
  card: string
  financeControlStatus: ModeratorStatus
  moderationComment: string
  tswName: string
  paymentStatus: PaymentStatusEnum
  financeControl: FinanceControl
  orderComment: string
}

export const preordersOrdersAtom = createPrimitiveAtom<
  Record<string, PreordersViewOrder>
>({})
export const preordersOrdersTableSelectedIdsAtom =
  createPrimitiveAtom<RowSelectionState>({})


export interface Invoice {
  invoiceDate: Date | undefined
  paymentDeadline: Date | undefined
  invoiceCheck: 'ok' | 'not_checked_yet' | 'not_ok'
  id: string
  invoiceComment: string
  store: string
  paidDate: Date | undefined
  orderNumber: string
  invoiceSum: number
  additionalCosts: number
  procurement: string
  provider: string
  currency: string
  orderSum: number
  orderDate: Date | undefined
  paymentMethod: string
  bankAccount: string
  paymentPriority: string
  registryNumber: string
  registryComment: string
  termsOfPayment: TermsOfPayment
  orderId: string
  invoiceNumber: string
}

export const invoicesAtom = createPrimitiveAtom<
  Array<Invoice>
>([])

export const invoicesSelectedRowsAtom = createPrimitiveAtom<RowSelectionState>({})

export const invoiceTableSelectedOrderIdAtom = primitiveAtom<
  string | null
>(null, dispatch => dispatch(preordersPageTabAtom.set('byInvoices')))
