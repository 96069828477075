import {ChatBubbleLeftEllipsisIcon} from '@heroicons/react/24/outline'
import {createStyles} from '@mantine/core'
import {useClientTableContext} from '@appscience/data-table'
import {DefaultTooltip} from '../../../components/default-tooltip'
import {caseInsensitiveSubstr, joinStrings, mode} from '@appscience/utils'
import {generateAMOUrl} from '../../procurement/common/helpers'
import { memo } from 'react'

interface AmoIdCellProps {
  id: string
  comment?: string
}

const useStyles = createStyles(({colors, colorScheme}) => ({
  commentIcon: {
    display: 'inline',
    width: '20px',
    height: '20px',
    verticalAlign: 'middle',
    marginLeft: '6px',
    marginBottom: '1px',
    color: mode(colors.blue[6], colors.blue[6])(colorScheme),
  },
  searchedCommentIcon: {
    color: mode(colors.orange[6], colors.blue[6])(colorScheme),
  },
}))

export const AmoIdCell = memo(({id, comment}: AmoIdCellProps) => {
  const {classes} = useStyles()
  const {searchValue} = useClientTableContext()

  return (
    <>
      <a href={generateAMOUrl(id)} target='_blank'>
        {id}
      </a>
      {comment && (
        <DefaultTooltip label={comment}>
          <ChatBubbleLeftEllipsisIcon
            className={joinStrings(
              'self-start',
              classes.commentIcon,
              searchValue !== '' &&
                caseInsensitiveSubstr(comment, searchValue) &&
                classes.searchedCommentIcon,
            )}
          />
        </DefaultTooltip>
      )}
    </>
  )
})
