import {format, getTime, isValid, parseISO} from 'date-fns'
import {isNullable} from '../check'
import {logError} from '../logger'
import {Nullable} from '../types'

export function parseStringDateToTimestamp(value: string): number | null {
  try {
    const parsedDate = parseISO(value)
    if (isValid(parsedDate)) {
      return getTime(parsedDate)
    }
    logError(`Invalid date format. Value: ${value}`)
    return null
  } catch {
    logError(`Unexpected error in "parseStringDateToTimestamp". Value: ${value}`)
    return null
  }
}

export function parseStringDateToDate(value: string): Date | null {
  try {
    const parsedDate = parseISO(value)
    if (isValid(parsedDate)) {
      return parsedDate
    }
    logError(`Invalid date format. Value: ${value}`)
    return null
  }
  catch {
    logError(`Unexpected error in "parseStringDateToDate". Value: ${value}`)
    return null
  }
}

export function dateInRange(value: Date, range: [Date, Date]): boolean {
  return range[0] <= value && value <= range[1]
}

export function defaultFormatDate(timestamp: Nullable<number|Date>): string {
  try {
    return isNullable(timestamp) ? '' : format(timestamp, 'dd.MM.yyyy')
  }
  catch {
    logError(`Unexpected error in "defaultFormatDate". Value: ${timestamp}`)
    return ''
  }
}