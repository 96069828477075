import {BoxNumberData} from '../../atoms/static-data/box-number-colors-atom'
import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'
import {handleErrorWithDefaultValue} from './utils'

interface ResponseBoxNumberData {
  name: string
  color: string
}

export function getBoxNumberMap(): Promise<Array<BoxNumberData>> {
  return apiCreator
    .get(`${API_URL.TWIN}/boxes`)
    .then(res => {
      const rawItems: Array<ResponseBoxNumberData> = res.data.boxes
      return rawItems.map(remapToBoxNumberData)
    })
    .catch(handleErrorWithDefaultValue([]))
}

export function remapToBoxNumberData(data: ResponseBoxNumberData): BoxNumberData {
  return {
    id: data.name,
    title: data.name,
    color: data.color,
  }
}