import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'

interface GeneratePackingListRequestData {
  item_ids: Array<string>,
}

interface GeneratePackingListResponseData {
  doc_url: string,
  doc_url_for_client: string,
}

interface ReturnData {
  docUrl: string,
  docUrlForClient: string,
}

export function generatePackingList(data: GeneratePackingListRequestData): Promise<ReturnData> {
  return apiCreator
    .post<GeneratePackingListResponseData>(
      `${API_URL.TWIN}/packing-list-doc`,
      data,
    )
    .then(res => ({
      docUrl: res.data.doc_url,
      docUrlForClient: res.data.doc_url_for_client,
    }))
}