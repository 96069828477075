import {Store} from '@reatom/core'
import {createPrimitiveAtom} from '@reatom/core/primitives'
import {getTime, hoursToMilliseconds} from 'date-fns'
import {getApi} from '../../api'

let lastInitTimestamp: number | null


export const complianceStatusesAtom = createPrimitiveAtom<string[]>([])

export async function updateComplianceStatusesAtom({dispatch}: Store) {
  if (
    lastInitTimestamp &&
    lastInitTimestamp + hoursToMilliseconds(2) > getTime(new Date())
  ) {
    return
  }
  const data = await getApi().listsApi.getComplianceStatuses()
  dispatch(complianceStatusesAtom.set(data))
  lastInitTimestamp = getTime(new Date())
  return data
}