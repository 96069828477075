import {MantineThemeComponents} from '@mantine/styles/lib/theme/types/MantineTheme'
import {ValueOfRecord} from 'ramda'

export function getCheckboxStylesConfig(): ValueOfRecord<MantineThemeComponents> {
  return {
    defaultProps: {
      size: 'xs',
    },
    styles: {
      input: {
        cursor: 'pointer',
      },
      label: {
        cursor: 'pointer',
        paddingLeft: 8,
        fontSize: 14,
      },
      icon: {
        width: 10,
        height: 10,
      }
    }
  }
}
